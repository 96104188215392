import { exceptionAdapter } from './reducer'

export const {
  selectAll: selectAllExceptions,
  selectById: selectExceptionById,
} = exceptionAdapter.getSelectors(state => state.exceptions)

export const {
  selectAll: selectExceptionHistoryLogs,
} = exceptionAdapter.getSelectors(state => state.exceptions.history_logs)
