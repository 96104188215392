import { usersAdapter } from './reducer'
import { LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const {
  selectById: selectUserById,
  selectAll: selectAllUsers,
} = usersAdapter.getSelectors(state => state.users)

export const selectAllUsersForSelect = state => {
  const users = selectAllUsers(state)

  return [
    ...users.map(user => ({
      label: `${user.firstname} ${user.lastname}`,
      value: user.id,
    })),
  ]
}

export const isUsersFetching = state =>
  state.users.status === LOADING_STATUS && state.users.status !== SUCCESS_STATUS
