/* eslint-disable no-template-curly-in-string */
import { string, number, object, array, ValidationError } from 'yup'

export const maxFaxValue = 9999999999
export const maxNpiValue = 2147483647
export const nonLatinChar = /^[A-Za-z0-9\\!"#$%&’()*+,./:;<=>?@[\]^_{|}~\-'`\s]+$/g
export const onlyWhitespaceChar = /^(?!\s+$)/g

function validateType(value, parent, field) {
  const modalMapping = {
    doctor: 'Doctor',
    facility: 'Facility',
  }
  const fieldNameMapping = {
    phone_numbers: 'phone',
    fax_numbers: 'fax',
    emails: 'email',
    addresses: 'address',
  }
  const typeNameMapping = {
    LOC: 'local',
    COR: 'corporate',
    OFF: 'legal',
  }

  let data = [
    {
      type: 'LOC',
      indexes: [],
    },
    {
      type: 'COR',
      indexes: [],
    },
    {
      type: 'OFF',
      indexes: [],
    },
  ]
  const items = parent[field]

  items.forEach((item, index) => {
    const needItem = data.find(dataItem => dataItem.type === item.type)
    if (needItem) {
      needItem.indexes.push(index)
    }
  })

  data = data.map(item => ({
    ...item,
    indexes: [...item.indexes.slice(1)],
  }))

  const errors = []
  data.forEach(({ type, indexes }) => {
    indexes.forEach(index => {
      let msg = `Contacts cannot include more than one ${typeNameMapping[type]} ${fieldNameMapping[field]}`
      if (field === 'addresses') {
        msg = `${modalMapping[parent.type]} cannot have more than one ${
          typeNameMapping[type]
        } address`
      }
      errors.push(
        new ValidationError(msg, value[index], `${field}[${index}].type`)
      )
    })
  })

  if (errors.length === 0) {
    return true
  }

  return new ValidationError(errors)
}

export const email = string()
  .matches(nonLatinChar, 'Email cannot include non-latin characters')
  .email('Please enter a valid email address')
  .required('Please enter an email address')

export const password = string()
  .min(8, 'Passwords must contain at least ${min} characters')
  .max(128, 'Passwords must contain less or equal ${max} characters')
  .matches(
    // eslint-disable-next-line
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&’()*+,\]\[./:;<=>?@^_`{|}~])[A-Za-z \d!"#$%&’()*+,\]\[./:;<=>?@^_`{|}\\\-'~]+$/,
    {
      message:
        'Passwords must contain Latin characters, at least one special character, one number, and a variation of uppercase and lowercase letters',
    }
  )
  .required('Please enter a password')

export const firstname = string()
  .required('Please enter a First Name')
  .min(2, 'First Name must be at least ${min} characters')
  .max(64, 'First Name cannot include more than ${max} characters')
  .matches(/^[A-Za-z\-`'\s]+$/, {
    message:
      "First Name cannot include any numbers, non-latin or special characters except -'`",
  })

export const searchfirstname = string()
  .min(1, 'First Name must be at least ${min} characters')
  .max(128, 'First Name cannot include more than ${max} characters')
  .matches(nonLatinChar, 'First Name cannot include non-latin characters')

export const lastname = string()
  .required('Please enter a Last Name')
  .min(2, 'Last Name must be at least ${min} characters')
  .max(64, 'Last Name cannot include more than ${max} characters')
  .matches(/^[A-Za-z\-`'\s]+$/, {
    message:
      "Last Name cannot include any numbers, non-latin or special characters except -'`",
  })

export const facilityName = string()
  .min(1, 'Facility Name must be at least ${min} characters')
  .max(128, 'Facility Name cannot include more than ${max} characters')
  .matches(nonLatinChar, 'Facility Name cannot include non-latin characters')

export const numberField = number()
  .positive('Must be a positive number')
  .integer('Must include only digits')
  .typeError('Must include only digits')

export const cmsID = number()
  .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
  .negative('Must be a negative number')
  .integer('Must include only digits')
  .typeError('Must include only digits')

export const siteID = string()
  .matches(/^[0-9]+$/, {
    message: 'Site ID must include only digits',
  })
  .max(8, 'Site ID cant be more than ${max} digits')

export const phoneNumber = string()
  .matches(/^[0-9]+$/, {
    message: 'Phone number must include only digits',
  })
  .length(10, 'Phone number must be equal ${length} digits; please correct')

export const phoneExtension = string()
  .matches(/^[0-9]+$/, {
    message: 'Phone extension must include only digits',
  })
  .max(6, 'Phone extension cant be more than ${length} digits; please correct')

export const zipCode = string()
  .required('Please enter a zip code')
  .matches(/^[0-9]+$/, 'Zip Code must include only digits')
  .length(5, 'Zip Code should include 5 digits')
  .nullable()

export const searchZipCode = string().nullable()

export const npi = number()
  // .max(maxNpiValue, 'Ensure this value is less than or equal to ${max}')
  .nullable()
  .integer('NPI must include only digits')
  .typeError('NPI must include only digits')
  .positive('NPI must be a positive number')
  .test('length', 'NPI should be 10 digits long', val => {
    const length = val ? `${val}`.length : 0
    return length === 0 || length === 10
  })

export const city = string()
  .max(255, 'City cannot include more than ${max} characters')
  .matches(nonLatinChar, 'City cannot include non-latin characters')
  .required('Please enter a city')
  .nullable()

export const address_line_1 = string()
  .nullable()
  .max(255, 'Address Line 1 cannot include more than ${max} characters')
  .matches(nonLatinChar, 'Address Line 1 cannot include  non-latin  characters')
  .matches(onlyWhitespaceChar, 'Address Line 1 cannot contain only whitespace')

export const address_line_2 = string()
  .nullable()
  .max(255, 'Address Line 2 cannot include more than ${max} characters')
  .matches(nonLatinChar, 'Address Line 2 cannot include  non-latin  characters')

export const fax = string()
  .matches(/^[0-9]+$/, {
    message: 'Fax number must include only digits',
  })
  .length(10, 'Fax number must be equal ${length} digits; please correct.')

export const addresses = array()
  .of(
    object().shape({
      zip_code: zipCode,
      city,
      state: string().nullable().required('Please select a state'),
      address_line_1: address_line_1.required('Please enter an Address Line 1'),
      address_line_2,
    })
  ) // eslint-disable-next-line
  .test('uniqueType', '', function (value) {
    return validateType(value, this.parent, 'addresses')
  })

export const other_npis = array()
  .of(npi.required('Additional NPI required'))
  // eslint-disable-next-line
  .test('uniqueNpi', '', function (value) {
    const data = value
    const errors = []
    data.forEach((additionalNpi, index) => {
      const isRepeat =
        [...data, this.parent.npi]
          .filter(Boolean)
          .filter(item => item === additionalNpi).length > 1

      if (isRepeat) {
        errors.push(
          new ValidationError(
            'Additional NPI must be unique',
            data[index],
            `other_npis[${index}]`
          )
        )
      }
    })

    return new ValidationError(errors)
  })

export const phone_numbers = array()
  .of(
    object().shape({
      number: phoneNumber.required('Please enter a Phone Number'),
      type: string(),
      phone_extension: phoneExtension.nullable(),
    })
  )
  // eslint-disable-next-line
  .test('uniqueType', '', function (value) {
    return validateType(value, this.parent, 'phone_numbers')
  })

export const fax_numbers = array()
  .of(
    object().shape({
      number: fax.required('Please enter a Fax Number'),
      type: string(),
    })
  )
  // eslint-disable-next-line
  .test('uniqueType', '', function (value) {
    return validateType(value, this.parent, 'fax_numbers')
  })

export const emails = array()
  .of(
    object().shape({
      address: email,
      type: string(),
    })
  )
  // eslint-disable-next-line
  .test('uniqueType', '', function (value) {
    return validateType(value, this.parent, 'emails')
  })

export const supervisors = array().of(
  object().shape({
    cms_id: cmsID.required('Please enter Supervisor CMS ID'),
  })
)
