import React, { useEffect, useState, useRef } from 'react'
import { InputNumber, Space, Button } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const EditCell = ({ value, onSubmit }) => {
  const refEditableInput = useRef(null)
  const refEditableWrapper = useRef(null)
  const [isEdit, setIsEdit] = useState(false)
  const [changedValue, setChangedValue] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (refEditableInput.current && isEdit) {
      refEditableInput.current.focus()
    }
  }, [isEdit])

  useEffect(() => {
    let onClick = () => null
    if (refEditableWrapper.current) {
      const ref = refEditableWrapper.current

      onClick = ({ target }) => !ref?.contains(target) && setIsEdit(false)
      document.addEventListener('click', onClick)
    }

    return () => document.removeEventListener('click', onClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refEditableWrapper.current])

  return (
    <div ref={refEditableWrapper}>
      {isEdit && (
        <div className="editable-field" style={{ position: 'relative' }}>
          <InputNumber
            ref={refEditableInput}
            type="number"
            size="small"
            min={0}
            max={2147483647}
            defaultValue={value}
            onChange={number => {
              setChangedValue(number)
            }}
            onFocus={() => {
              setChangedValue(value)
            }}
          />
          <div
            className="save-options"
            style={{
              position: 'absolute',
              bottom: '-38px',
              background: 'white',
              right: '0',
              zIndex: 1,
              boxShadow: '0 3px 6px rgba(111,111,111,0.2)',
              borderRadius: '0 0 3px 3px',
              padding: '6px',
            }}
          >
            <Space>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                size="small"
                disabled={isSubmitting}
                onClick={async () => {
                  try {
                    if (value !== changedValue) {
                      setIsSubmitting(true)
                      await onSubmit(changedValue)
                    }
                    setIsSubmitting(false)
                    setIsEdit(false)
                  } catch (error) {
                    setIsSubmitting(false)
                  }
                }}
              />
              <Button
                icon={<CloseOutlined />}
                size="small"
                onClick={() => setIsEdit(false)}
              />
            </Space>
          </div>
        </div>
      )}
      {/* Show data as clickable cell */}
      <Button
        style={{
          width: '100%',
          height: '24px',
          padding: '1px 7px',
          textAlign: 'center',
          visibility: 'visible',
          display: !isEdit ? 'block' : 'none',
        }}
        type="button"
        onClick={() => {
          setIsEdit(true)
        }}
      >
        {value}
      </Button>
    </div>
  )
}

EditCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func.isRequired,
}
EditCell.defaultProps = {
  value: null,
}
export default EditCell
