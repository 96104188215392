import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Spin } from 'antd'
import Table from '../../../components/Tables/SearchTable'

import {
  searchFacilityProfiles,
  selectFacilityProfiles,
  resetState as resetFacilitiesState,
} from '../../../../state/modules/facilities'

import useDispatchHttp from '../../../../hooks/dispatchHttpHandler'

import {
  DEFAULT_FACILITY_PROFILE_PAGE_SIZE,
  DEFAULT_FACILITY_PROFILE_PAGE_SIZE_OPTIONS,
  FACILITY_PROFILE,
} from '../../../../constants'
import MainLayout from '../../../layouts/MainLayout'
import SearchLayout from '../../../layouts/SearchLayout'
import SearchPageLayout from '../../../layouts/SearchPageLayout'
import { facilityProfilesColumns } from '../../../../data'
import { getHighlightFields } from '../../exceptions/helpers/getHighlightFields'
import { useUser } from '../../../../providers/UserProvider'

const ProfilesList = () => {
  const { isUserActionAllowed } = useUser()
  const [formValues, setFormValues] = useState({})

  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const allFacilityProfiles = useSelector(selectFacilityProfiles)
  const { count: facilityDoctorsCount } = useSelector(
    state => state.facilities.facility_profiles
  )

  const loadingStatus = useSelector(state => state.facilities.status)

  const handleSearch = useCallback(
    values => {
      dispatchHttp(searchFacilityProfiles({ data: values }))
      setFormValues(values)
    },
    [dispatchHttp, setFormValues]
  )

  useEffect(
    () => () => {
      dispatch(resetFacilitiesState())
    },
    [dispatch]
  )

  const formattedData = data =>
    data.map(item => ({
      ...item.mrd_facility,
      id: item.id,
      name: item.provider_name,
      addresses: item.provider_addresses,
      fax_numbers: item.provider_faxes,
      phone_numbers: item.provider_phones,
      main_retriever_data: item.main_retriever,
      is_sole_proprietor: item.sole_proprietor_doctor,
    }))

  const highlightedFields = getHighlightFields({ primary_search: formValues }, [
    formattedData(allFacilityProfiles),
  ])

  return (
    <MainLayout>
      <Spin spinning={false}>
        <SearchLayout
          handleSearch={handleSearch}
          handleReset={() => setFormValues({})}
          loadInitData
          searchRequest={handleSearch}
          isFacilityProfile
        >
          <Row gutter={40} />
          <SearchPageLayout
            title={
              <>
                Facility Profiles List
                {facilityDoctorsCount ? (
                  <span className="badge">{facilityDoctorsCount}</span>
                ) : null}
              </>
            }
          />
          <Table
            columns={facilityProfilesColumns.filter(
              item =>
                !item.permission ||
                (item.permission && isUserActionAllowed(item.permission))
            )}
            onLinkClick={() => dispatch(resetFacilitiesState())}
            onLoadData={searchFacilityProfiles}
            onFormatData={data => formattedData(data)}
            totalData={allFacilityProfiles}
            totalCount={facilityDoctorsCount}
            loadingStatus={loadingStatus}
            entity={FACILITY_PROFILE}
            formData={formValues}
            pageSizeOptions={DEFAULT_FACILITY_PROFILE_PAGE_SIZE_OPTIONS}
            pageSize={DEFAULT_FACILITY_PROFILE_PAGE_SIZE}
            primarySearch={formValues}
            highlightedFields={highlightedFields}
          />
        </SearchLayout>
      </Spin>
    </MainLayout>
  )
}

export default ProfilesList
