import React, { useEffect, useCallback } from 'react'
import { Row, Col, Typography } from 'antd'
import PropTypes from 'prop-types'

import SearchForm from '../../components/Forms/SearchForm'
import Breadcrumb from '../../components/Breadcrumbs'

const SearchLayout = ({
  children,
  handleSearch,
  handleReset,
  breadcrumbs,
  title,
  initialFormValues,
  searchSuggestion,
  allowRevert,
  loadInitData,
  searchRequest,
  isHideSearch,
  isFacilityProfile,
}) => {
  const handleInitialLoadData = useCallback(
    async values => {
      handleReset(values)
      searchRequest(values)
    },
    [handleReset, searchRequest]
  )

  useEffect(() => {
    if (loadInitData) {
      searchRequest(initialFormValues)
    }
  }, [initialFormValues, loadInitData, searchRequest])

  return (
    <Row justify="center">
      {!isHideSearch && (
        <Col xxl={5} xl={6} lg={7}>
          <div className="search-layout__sidebar">
            <div className="search-layout__form-wrapper">
              <SearchForm
                onSubmit={handleSearch}
                onReset={handleInitialLoadData}
                initialValues={initialFormValues}
                searchSuggestion={searchSuggestion}
                allowRevert={allowRevert}
                isFacilityProfile={isFacilityProfile}
              />
            </div>
          </div>
        </Col>
      )}

      <Col xxl={19} xl={18} lg={17}>
        {(breadcrumbs || title) && (
          <div className="search-layout__header">
            {breadcrumbs && <Breadcrumb crumbs={breadcrumbs} />}
            <Typography.Title level={3}>{title} </Typography.Title>
          </div>
        )}
        <div className={!isHideSearch ? 'search-layout__content' : null}>
          {children}
        </div>
      </Col>
    </Row>
  )
}

SearchLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  searchRequest: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  initialFormValues: PropTypes.shape({}),
  searchSuggestion: PropTypes.shape({}),
  allowRevert: PropTypes.bool,
  loadInitData: PropTypes.bool,
  isHideSearch: PropTypes.bool,
  isFacilityProfile: PropTypes.bool,
}

SearchLayout.defaultProps = {
  children: null,
  handleReset: null,
  breadcrumbs: null,
  title: '',
  searchSuggestion: {},
  allowRevert: false,
  initialFormValues: {},
  loadInitData: false,
  isHideSearch: false,
  isFacilityProfile: false,
}

export default SearchLayout
