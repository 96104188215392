import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { fetchAllUsers, createUser, updateUser, fetchUserById } from './actions'

import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'

export const usersAdapter = createEntityAdapter()

const initialState = usersAdapter.getInitialState({
  status: IDLE_STATUS,
})

const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchAllUsers.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      if (payload.results) {
        // with pagination
        usersAdapter.setAll(state, payload.results)
        state.count = payload.count
      } else {
        // without pagination
        usersAdapter.setAll(state, payload)
        state.count = payload.length
      }
    })
    builder.addCase(fetchAllUsers.rejected, (state, { payload, error }) => {
      state.status = FAILURE_STATUS
      state.error = payload || error.message
    })
    builder.addCase(fetchUserById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchUserById.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      usersAdapter.addOne(state, payload)
    })
    builder.addCase(createUser.fulfilled, usersAdapter.upsertOne)
    builder.addCase(updateUser.fulfilled, usersAdapter.updateOne)
  },
})

const { reducer } = usersSlice

export default reducer
