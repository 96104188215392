import Api from './index'
import { API_URL } from '../constants'

class InsuranceCompanies extends Api {
  getInsuranceCompanyListing(id, params) {
    return this.get('/', { params })
  }

  getInsuranceCompanyById(id) {
    return this.get(`${id}`)
  }

  updateWomba(id, data) {
    return this.patch(`/update_womba/${id}/`, { data })
  }
}

export default new InsuranceCompanies({
  baseURL: `${API_URL}insurance_companies/`,
})
