import { assignmentCopyCompanyAdapter } from './reducer'
import { LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const {
  selectAll: selectAllAssignmentCopyCompanies,
} = assignmentCopyCompanyAdapter.getSelectors(
  state => state.assignmentCopyCompanies
)

export const {
  selectById: selectAssignmentCopyCompanyById,
} = assignmentCopyCompanyAdapter.getSelectors(
  state => state.assignmentCopyCompanies
)

export const isAssignmentCopyCompaniesFetching = state =>
  state.assignmentCopyCompanies.status === LOADING_STATUS &&
  state.assignmentCopyCompanies.status !== SUCCESS_STATUS
