import React from 'react'
import { Row, Col, Divider } from 'antd'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import TextareaField from '../../../shared/TextareaField'

export const Notes = ({ title }) => (
  <>
    <Divider orientation="left" className="form-divider">
      {title}
    </Divider>
    <Row gutter={12}>
      <Col span={24}>
        <Field
          component={TextareaField}
          rows={4}
          maxlength="1024"
          name="notes"
          placeholder="Add notes here"
        />
      </Col>
    </Row>
  </>
)

Notes.propTypes = {
  title: PropTypes.string,
}

Notes.defaultProps = {
  title: '',
}
