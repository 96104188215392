import queryString from 'query-string'

const routesNames = {
  USER_ACTIVITY_LOG: '/user-activity-log',
  FACILITY: '/facility',
  CLUSTERS: '/clusters',
  HEALTH_NETWORKS: '/health-networks',
  CASES: '/cases',
  USERS: '/users',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  FORBIDDEN: '/forbidden',
}

export const routePaths = {
  openUserActivityHistory: user_id =>
    queryString.stringifyUrl({
      url: routesNames.USER_ACTIVITY_LOG,
      query: {
        user_id,
      },
    }),
  openClusterById: id => `${routesNames.CLUSTERS}/${id}`,
  openFacilityById: id => `${routesNames.FACILITY}/${id}`,
  openEntityHistory: (entity, id, user_id, edited, selectedEntityFilter) =>
    `${routesNames.USER_ACTIVITY_LOG}/${entity}/${id}${
      user_id ? `?user_id=${user_id}` : `?edited=${edited}`
    }&entity_filter=${selectedEntityFilter}`,
  openCaseById: id => `${routesNames.CASES}/${id}`,
  openUsersList: () => routesNames.USERS,
  openForgotPassword: () => routesNames.FORGOT_PASSWORD,
  openLogin: () => routesNames.LOGIN,
  openForbidden: () => routesNames.FORBIDDEN,
  openHealthNetworkById: id => `${routesNames.HEALTH_NETWORKS}/${id}`,
}
