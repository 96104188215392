import React, { useCallback } from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const InputMaskField = ({
  field: { ...field },
  form: { touched, errors, setFieldValue },
  label,
  error,
  maskOptions,
  ...rest
}) => {
  const { name } = field
  const inputMask = createNumberMask(maskOptions)

  const handleBlur = useCallback(
    ({ target: { value } }) => {
      setFieldValue(name, Number(value.replace(/\$|\s+/g, '')))
    },
    [name, setFieldValue]
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setFieldValue(name, value)
    },
    [name, setFieldValue]
  )

  return (
    <Form.Item
      label={label}
      validateStatus={
        (errors[name] && touched[name]) || error ? 'error' : 'success'
      }
      help={
        (errors[name] && touched[name]) || error ? errors[name] || error : null
      }
    >
      <MaskedInput
        mask={inputMask}
        render={ref => (
          <Input
            {...rest}
            {...field}
            ref={input => ref(input && input.input)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )}
      />
    </Form.Item>
  )
}

InputMaskField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.bool),
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
    ]),
    errors: PropTypes.objectOf(PropTypes.string),
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  error: PropTypes.string,
  maskOptions: PropTypes.shape({}),
}

InputMaskField.defaultProps = {
  field: PropTypes.shape({
    value: '',
    onChange: () => null,
    onBlur: () => null,
  }),
  label: '',
  error: '',
  maskOptions: {},
}

export default InputMaskField
