/* eslint-disable no-template-curly-in-string */
import { object, array, number, string } from 'yup'

export const relateDoctorFacility = object().shape({
  id: number()
    .positive('Must be a positive number')
    .nullable()
    .required('Please enter an ID')
    .typeError('Must be a number'),
})

export const relateDoctorFacilityVendor = object().shape({
  organization_name: string()
    .max(256, 'Organization Name cannot include more than ${max} characters')
    .nullable()
    .required('Please enter an Organization Name'),
  organization_id: string()
    .max(256, 'Organization ID cannot include more than ${max} characters')
    .nullable()
    .required('Please enter an Organization ID'),
  id: number()
    .positive('Must be a positive number')
    .nullable()
    .required('Please enter an ID')
    .typeError('Must be a number'),
})

export const editRelateDoctorFacilityVendor = object().shape({
  organization_name: string()
    .max(256, 'Organization Name cannot include more than ${max} characters')
    .nullable()
    .required('Please enter an Organization Name'),
  organization_id: string()
    .max(256, 'Organization ID cannot include more than ${max} characters')
    .nullable()
    .required('Please enter an Organization ID'),
})

export const relateVendorToDoctorFacility = object().shape({
  vendors: array().of(
    object().shape({
      organization_name: string()
        .max(256, 'Сan not include more than ${max} chars')
        .nullable()
        .required('The field is required'),
      organization_id: string()
        .max(256, 'Сan not include more than ${max} chars')
        .nullable()
        .required('The field is required'),
    })
  ),
})
