import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Card,
  Row,
  Col,
  Typography,
  Skeleton,
  Button,
  Space,
  Modal,
} from 'antd'
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons'
import DetailPageLayout from '../../layouts/DetailPageLayout'

import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import {
  fetchHealthNetworkById,
  selectHealthNetworkById,
  deleteHealthNetwork,
  updateHealthNetwork,
  removeHealthNetworkFacility,
  addHealthNetworkFacility,
  checkHealthNetworkFacility,
  removeHealthNetworkDoctor,
  addHealthNetworkDoctor,
  fetchHealthNetworkFacilities,
  fetchHealthNetworkDoctors,
  fetchHealthNetworkSoleProprietors,
  selectAllHealthNetworksFacilities,
  selectAllHealthNetworksDoctors,
  selectAllHealthNetworksSoleProprietors,
} from '../../../state/modules/healthNetworks'
import { capitalizeEveryWord } from '../../../utils/helpers'
import { FACILITY, DOCTOR, LOADING_STATUS } from '../../../constants'
import { useUser } from '../../../providers/UserProvider'
import Table from '../../components/Tables/SearchTable/SearchTable.container'
import RelateDoctorFacilityModal from '../../components/Modals/RelateDoctorFacilityModal/RelateDoctorFacilityModal'
import { facilityTableColumns, doctorTableColumns } from '../../../data'
import CreateHealthNetworkModal from '../../components/Modals/CreateHealthNetworkModal'
import showConfirmDialog from '../../components/Modals/DeleteConfirm'

const HealthNetwork = () => {
  const [isHealthNetworkRemoved, setHealthNetworkRemoved] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const dispatchHttp = useDispatchHttp()
  const healthNetworkData = useSelector(state =>
    selectHealthNetworkById(state, id)
  )
  const loadingStatus = useSelector(state => state.healthNetworks.status)
  const relatedDoctors = useSelector(selectAllHealthNetworksDoctors)
  const relatedSoleProprietors = useSelector(
    selectAllHealthNetworksSoleProprietors
  )
  const relatedFacilities = useSelector(selectAllHealthNetworksFacilities)
  const doctorsLoadingStatus = useSelector(
    state => state.healthNetworks.related_doctors.status
  )
  const soleProprietorsLoadingStatus = useSelector(
    state => state.healthNetworks.related_sole_proprietors.status
  )
  const facilitiesLoadingStatus = useSelector(
    state => state.healthNetworks.related_facilities.status
  )
  const doctorsCount = useSelector(
    state => state.healthNetworks.related_doctors.count
  )
  const soleProprietorsCount = useSelector(
    state => state.healthNetworks.related_sole_proprietors.count
  )
  const facilitiesCount = useSelector(
    state => state.healthNetworks.related_facilities.count
  )
  const { isUserActionAllowed } = useUser()

  const breadcrumbs = [
    { name: 'Health Networks', path: '/health-networks' },
    {
      name: capitalizeEveryWord(`${healthNetworkData?.name}`),
      path: `/health-networks/${id}/`,
    },
  ]

  const handleRemoveHealthNetwork = async () => {
    showConfirmDialog({
      title: `Do you want to delete ${healthNetworkData.name}?`,
      data: id,
      handleConfirm: async healthNetworkId => {
        try {
          setHealthNetworkRemoved(true)
          await dispatchHttp(
            deleteHealthNetwork(healthNetworkId),
            'Health Network has been successfully removed '
          )
          history.replace('/health-networks')
        } catch (e) {
          setHealthNetworkRemoved(false)
        }
      },
    })
  }

  const handleUpdateHealthNetwork = async data => {
    try {
      await dispatchHttp(
        updateHealthNetwork({ id, data: { name: data.name.trim() } }),
        'Health Network updated successfully'
      )
      dispatchHttp(fetchHealthNetworkById(id))
    } catch (e) {
      setHealthNetworkRemoved(false)
      throw new Error(e)
    }
  }

  const relateFacility = async data =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      const { health_network: healthNetwork } = await dispatchHttp(
        checkHealthNetworkFacility({
          jaia_id: data.id,
        })
      )
      if (healthNetwork) {
        const content = `Are you sure that you want to relate facility '${data.id}' 
      to Health Network ${healthNetworkData.name}, since it belongs to ${healthNetwork.name}?`
        const title = `Relate Facility Warning`
        Modal.confirm({
          title: <Typography.Title level={3}>{title}</Typography.Title>,
          icon: null,
          okText: 'Relate',
          okType: 'danger',
          okButtonProps: {
            danger: true,
            type: 'primary',
          },
          content,
          async onOk() {
            await dispatchHttp(
              addHealthNetworkFacility({ id, data: { jaia_id: data.id } }),
              'Facility has been successfully added to health network'
            )
            resolve()
            dispatchHttp(fetchHealthNetworkFacilities({ id }))
            dispatchHttp(fetchHealthNetworkDoctors({ id }))
            dispatchHttp(fetchHealthNetworkSoleProprietors({ id }))
          },
        })

        return
      }

      await dispatchHttp(
        addHealthNetworkFacility({ id, data: { jaia_id: data.id } }),
        'Facility has been successfully added to health network'
      )
      resolve()
      dispatchHttp(fetchHealthNetworkFacilities({ id }))
      dispatchHttp(fetchHealthNetworkDoctors({ id }))
      dispatchHttp(fetchHealthNetworkSoleProprietors({ id }))
    })

  const removeFacilityRelation = async data => {
    await dispatchHttp(
      removeHealthNetworkFacility({ id, data: { jaia_id: data.id } }),
      'Facility has been successfully removed from health network'
    )
    dispatchHttp(fetchHealthNetworkFacilities({ id }))
    dispatchHttp(fetchHealthNetworkDoctors({ id }))
    dispatchHttp(fetchHealthNetworkSoleProprietors({ id }))
  }

  const relateDoctor = async data => {
    await dispatchHttp(
      addHealthNetworkDoctor({ id, data: { jaia_id: data.id } }),
      'Doctor has been successfully added to health network'
    )
    dispatchHttp(fetchHealthNetworkSoleProprietors({ id }))
  }

  const removeDoctorRelation = async data => {
    await dispatchHttp(
      removeHealthNetworkDoctor({ id, data: { jaia_id: data.id } }),
      'Doctor has been successfully removed from health network'
    )
    dispatchHttp(fetchHealthNetworkSoleProprietors({ id }))
  }

  useEffect(() => {
    dispatchHttp(fetchHealthNetworkFacilities({ id }))
  }, [dispatchHttp, id])

  useEffect(() => {
    dispatchHttp(fetchHealthNetworkDoctors({ id }))
  }, [dispatchHttp, id])

  useEffect(() => {
    dispatchHttp(fetchHealthNetworkSoleProprietors({ id }))
  }, [dispatchHttp, id])

  useEffect(() => {
    if (!healthNetworkData && !isHealthNetworkRemoved) {
      dispatchHttp(fetchHealthNetworkById(id))
    }
  }, [healthNetworkData, dispatchHttp, id, isHealthNetworkRemoved])

  const facilityColumns = facilityTableColumns.filter(
    item => item.key !== 'health_network'
  )

  return (
    <DetailPageLayout breadcrumbs={breadcrumbs}>
      <Row>
        <Col span={24}>
          <Card>
            <Skeleton
              loading={loadingStatus === LOADING_STATUS}
              active
              paragraph={{ rows: 1 }}
            >
              <Row align="middle" justify="space-between">
                <Col span={16}>
                  <Typography.Title level={2} ellipsis style={{ margin: 0 }}>
                    {capitalizeEveryWord(healthNetworkData?.name)}
                  </Typography.Title>
                </Col>{' '}
                <Col>
                  {isUserActionAllowed('change_healthnetwork') && (
                    <CreateHealthNetworkModal
                      title="Update Health Network"
                      onSubmit={handleUpdateHealthNetwork}
                      submitButtonText="Update"
                      initialValues={{ name: healthNetworkData?.name }}
                      trigger={
                        <Button
                          ghost
                          type="primary"
                          style={{ marginRight: '12px' }}
                        >
                          Update
                        </Button>
                      }
                    />
                  )}
                  {isUserActionAllowed('delete_healthnetwork') && (
                    <Button onClick={handleRemoveHealthNetwork} danger>
                      Remove
                      <DeleteOutlined />
                    </Button>
                  )}
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
        <Col span={24}>
          <Table
            tableTitle={
              <Row justify="space-between" align="middle">
                <Col>Facilities {facilitiesCount}</Col>
                {isUserActionAllowed('add_facilities') && (
                  <Col>
                    <RelateDoctorFacilityModal
                      title="Relate Facility"
                      entity="Facility"
                      onSubmit={relateFacility}
                      closeAfterSubmit
                      trigger={
                        <Button type="text" size="large">
                          <Typography.Text type="secondary">
                            <Space>
                              <LinkOutlined /> Relate Facility
                            </Space>
                          </Typography.Text>
                        </Button>
                      }
                    />
                  </Col>
                )}
              </Row>
            }
            columns={
              isUserActionAllowed('view_doctor')
                ? facilityColumns
                : facilityColumns.map(item => {
                    if (item.key === 'related_doctors') {
                      return { ...item, link: null }
                    }
                    return { ...item }
                  })
            }
            totalData={relatedFacilities}
            totalCount={facilitiesCount}
            onLoadData={data =>
              fetchHealthNetworkFacilities({
                ...data,
                id,
              })
            }
            hideTotalCount
            entity={FACILITY}
            loadingStatus={facilitiesLoadingStatus}
            rowDeletion={
              isUserActionAllowed('remove_facilities') && {
                tooltip: 'Remove Relation',
                confirmMsg:
                  'Are you sure you want to remove the facility relation?',
                okText: 'Yes',
                onConfirm: removeFacilityRelation,
              }
            }
          />

          <Table
            tableTitle={
              <Row justify="space-between" align="middle">
                <Col>Sole Proprietor Doctors {soleProprietorsCount}</Col>
                {isUserActionAllowed('add_doctors') && (
                  <Col>
                    <RelateDoctorFacilityModal
                      title="Relate Doctor"
                      entity="Doctor"
                      onSubmit={relateDoctor}
                      closeAfterSubmit
                      trigger={
                        <Button type="text" size="large">
                          <Typography.Text type="secondary">
                            <Space>
                              <LinkOutlined /> Relate Doctor
                            </Space>
                          </Typography.Text>
                        </Button>
                      }
                    />
                  </Col>
                )}
              </Row>
            }
            columns={
              isUserActionAllowed('view_facility')
                ? doctorTableColumns
                : doctorTableColumns.map(item => {
                    if (item.key === 'related_facilities') {
                      return { ...item, link: null }
                    }
                    return { ...item }
                  })
            }
            totalData={relatedSoleProprietors}
            totalCount={soleProprietorsCount}
            onLoadData={data =>
              fetchHealthNetworkSoleProprietors({
                ...data,
                id,
              })
            }
            hideTotalCount
            entity={DOCTOR}
            loadingStatus={soleProprietorsLoadingStatus}
            rowDeletion={
              isUserActionAllowed('remove_doctors') && {
                tooltip: 'Remove Relation',
                confirmMsg:
                  'Are you sure you want to remove the doctor relation?',
                okText: 'Yes',
                onConfirm: removeDoctorRelation,
              }
            }
          />

          <Table
            tableTitle={
              <Row justify="space-between" align="middle">
                <Col>Doctors {doctorsCount}</Col>
              </Row>
            }
            columns={
              isUserActionAllowed('view_facility')
                ? doctorTableColumns
                : doctorTableColumns.map(item => {
                    if (item.key === 'related_facilities') {
                      return { ...item, link: null }
                    }
                    return { ...item }
                  })
            }
            totalData={relatedDoctors}
            totalCount={doctorsCount}
            onLoadData={data =>
              fetchHealthNetworkDoctors({
                ...data,
                id,
              })
            }
            hideTotalCount
            entity={DOCTOR}
            loadingStatus={doctorsLoadingStatus}
          />
        </Col>
      </Row>
    </DetailPageLayout>
  )
}

export default HealthNetwork
