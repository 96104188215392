import { mergerAdapter } from './reducer'

export const {
  selectAll: selectAllMergers,
  selectById: selectMergerById,
} = mergerAdapter.getSelectors(state => state.mergers.mergers)

export const {
  selectAll: selectAllDoctorRelatedMergers,
} = mergerAdapter.getSelectors(state => state.mergers.relatedDoctors)

export const {
  selectAll: selectAllFacilityRelatedMergers,
} = mergerAdapter.getSelectors(state => state.mergers.relatedFacilities)
