import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getActivityLogs, getActivityLogById } from './actions'
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const activityLogAdapter = createEntityAdapter()
const initialState = activityLogAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const clusterSlice = createSlice({
  name: 'clusters',
  initialState,
  extraReducers: builder => {
    builder.addCase(getActivityLogs.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getActivityLogs.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        activityLogAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(getActivityLogById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getActivityLogById.fulfilled, (state, { payload }) => {
      activityLogAdapter.upsertOne(state, payload)
      state.status = SUCCESS_STATUS
    })
  },
})

const { reducer } = clusterSlice

export default reducer
