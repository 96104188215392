import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { notification } from 'antd'
import {
  confirmLogin,
  getUserPermissions,
  setUserDataLoaded,
} from '../../../state/modules/auth'
import FormWrapper from '../../components/Forms/AuthForms/FormWrapper'
import ConfirmCodeForm from '../../components/Forms/AuthForms/ConfirmCodeForm'
import { useUser } from '../../../providers/UserProvider'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { routes } from '../../../providers/routes'

const ConfirmCode = () => {
  const dispatch = useDispatch()
  const dispatchHttp = useDispatchHttp()
  const location = useLocation()
  const history = useHistory()
  const { setCurrentUser, isUserActionAllowed, setUserPermissions } = useUser()
  const { from } = location.state || { from: { pathname: '/', search: '' } }

  const getRedirectRoute = () => {
    let isRouteAllowed = false
    if (from?.pathname && from?.pathname !== '/forbidden') {
      const route = routes.find(item => item?.path === from?.pathname)
      isRouteAllowed =
        !route?.permission || isUserActionAllowed(route?.permission)
    }

    return isRouteAllowed ? `${from.pathname}${from.search}` : '/'
  }

  const handleSubmit = async ({ code }) => {
    const { search } = location
    const otpToken = new URLSearchParams(search).get('otp_token')
    try {
      const user = await dispatch(
        confirmLogin({
          otpPassword: code,
          otpToken,
        })
      )
      const permissions = await dispatchHttp(getUserPermissions())
      setCurrentUser(user)
      setUserPermissions(permissions)
      dispatch(setUserDataLoaded(true))

      history.replace(getRedirectRoute())
    } catch (error) {
      const [errorKey] = Object.keys(error?.response?.data)
      const errorMessage =
        error?.response?.data[errorKey] || 'Authorization error.'
      notification.error({ message: errorMessage })
    }
  }

  return (
    <FormWrapper
      title="Enter confirmation code"
      form={<ConfirmCodeForm onSubmit={handleSubmit} />}
    />
  )
}

export default ConfirmCode
