import React, { useState } from 'react'
import { Input, Form, Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const UploadField = ({
  field: { ...field },
  form: { errors, touched, setFieldValue, setFieldTouched },
  label,
  error,
  size,
  ...props
}) => {
  const [fileName, setFileName] = useState('')
  const [defaultFileList, setDefaultFileList] = useState([])
  const { name } = field

  const handleChange = ({ file }) => {
    setFieldValue(name, file)
    setFieldTouched(name, true, false)
    setFileName(file.name)
  }

  const beforeUpload = (file, fileList) => {
    setDefaultFileList(fileList)

    return false
  }

  return (
    <Form.Item
      label={label}
      validateStatus={
        (errors[name] && touched[name]) || error ? 'error' : 'success'
      }
      help={
        (errors[name] && touched[name]) || error ? errors[name] || error : null
      }
    >
      <Upload
        className="upload-field"
        onChange={handleChange}
        maxCount={1}
        showUploadList={false}
        fileList={defaultFileList}
        beforeUpload={beforeUpload}
      >
        <Input
          {...props}
          size={size}
          value={fileName}
          readOnly
          className="upload-field__input"
          suffix={
            <Button
              icon={<UploadOutlined style={{ color: '#1890FF' }} />}
              size="small"
              type="link"
              className="upload-field__button"
            >
              Add New
            </Button>
          }
        />
      </Upload>
    </Form.Item>
  )
}

UploadField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
    ]),
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['password', 'text', 'search', 'email']),
  size: PropTypes.oneOf(['large', 'small', 'medium']),
  error: PropTypes.string,
}

UploadField.defaultProps = {
  field: PropTypes.shape({
    value: '',
    onChange: () => null,
    onBlur: () => null,
  }),
  label: '',
  type: 'text',
  size: 'medium',
  error: '',
}

export default UploadField
