import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { initialDoctorValues } from '../../../data'
import MedicalEntityModal from '../Modals/MedicalEntityModal'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import CreateDoctorForm from '../Forms/DoctorForm/Form.container'
import { createUpdateDoctorSchema } from '../../../schema/createUpdateDoctor'
import { createDoctor } from '../../../state/modules/doctors'
import { formatPhoneNumbers } from '../../../utils/helpers'

const initialDoctorCreateValues = {
  ...initialDoctorValues,
  phone_numbers: initialDoctorValues.phone_numbers.map(item => ({
    ...item,
    verified: true,
  })),
}

const CreateDoctorModal = ({
  isCreateNewDoctorEnabled,
  currentSearch,
  handleRelateDoctor,
}) => {
  const dispatchHttp = useDispatchHttp()
  const [createItemValues, setCreateItemValues] = useState({})

  useEffect(() => {
    const info = {}
    const isFeedbackLoop = currentSearch?.suggestion_type === 'contact_form'
    const addresses = currentSearch?.all_addresses || []
    if (addresses.length) {
      info.addresses = [{ ...currentSearch.primary_search, type: 'LOC' }]
    }

    if (currentSearch?.primary_search?.phone) {
      info.phone_numbers = [
        {
          number: currentSearch?.primary_search?.phone,
          type: 'LOC',
          phone_extension: '',
          verified: true,
        },
      ]
    }

    if (currentSearch?.primary_search?.fax) {
      info.fax_numbers = [
        {
          number: currentSearch?.primary_search?.fax,
          type: 'LOC',
          verified: true,
        },
      ]
    }

    if (
      currentSearch?.suggestion_type === 'facility' ||
      currentSearch?.suggestion_type === 'mrd_facility' ||
      currentSearch?.suggestion_type === 'treatment_facility' ||
      (isFeedbackLoop && currentSearch?.form_type === 'facility') ||
      (isFeedbackLoop && currentSearch?.form_type === 'treatment')
    ) {
      info.name = currentSearch?.primary_search?.last_name || ''
    }

    if (
      currentSearch?.suggestion_type === 'doctor' ||
      (isFeedbackLoop && currentSearch?.form_type === 'doctor')
    ) {
      info.first_name = currentSearch?.primary_search?.first_name || ''
      info.last_name = currentSearch?.primary_search?.last_name || ''
    }
    setCreateItemValues(info)
  }, [currentSearch])

  const handleCreateDoctor = async (_, data) => {
    const addresses = data?.addresses.map(address => ({
      city: address.city,
      state: address.state,
      zip_code: address.zip_code,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      type: address.type,
    }))
    const { type, ...rest } = data

    const resp = await dispatchHttp(
      createDoctor({
        ...rest,
        phone_numbers: formatPhoneNumbers(data.phone_numbers),
        fax_numbers: formatPhoneNumbers(data.fax_numbers),
        addresses,
      }),
      'Doctor has been successfully created'
    )

    await handleRelateDoctor(resp.id)
  }

  return (
    <MedicalEntityModal
      title="Create New Doctor"
      trigger={
        <Button ghost type="primary" disabled={!isCreateNewDoctorEnabled}>
          Create New Doctor
        </Button>
      }
      form={
        <CreateDoctorForm
          initialValues={{
            ...initialDoctorCreateValues,
            ...createItemValues,
          }}
          onSubmit={handleCreateDoctor}
          validationSchema={createUpdateDoctorSchema}
          action="create"
          submitButtonText="Create"
        />
      }
    />
  )
}

export default CreateDoctorModal
