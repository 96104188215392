import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, useLocation } from 'react-router-dom'

import AuthLayout from '../../layouts/AuthLayout'

export const PublicRoute = ({ component: Component, title, ...rest }) => {
  const { token } = useSelector(state => state.auth)
  const location = useLocation()

  useEffect(() => {
    document.title = title || 'Jaia Search'
  }, [title])

  return (
    <Route
      {...rest}
      render={props => {
        const { from } = location.state || {}
        return token && !from ? (
          <Redirect to="/" />
        ) : (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        )
      }}
    />
  )
}
