import React from 'react'
import { Typography, Modal } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const showConfirmDialog = ({
  title,
  data,
  handleConfirm,
  okText = 'Delete',
  status,
}) => {
  Modal.confirm({
    title: <Typography.Title level={3}>{title}</Typography.Title>,
    icon: <QuestionCircleOutlined style={{ color: '#46BFD1' }} />,
    okText,
    okType: 'danger',
    cancelText: 'Cancel',
    centered: true,
    width: 534,
    maskStyle: {
      background: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(10px)',
    },
    confirmLoading: true,
    okButtonProps: {
      danger: true,
      type: 'primary',
    },
    onOk() {
      handleConfirm(data, status)
    },
  })
}

export default showConfirmDialog
