import React, { useState, useEffect } from 'react'
import { Typography, Modal, Form, Button, Row, Col, Select } from 'antd'
import { Formik, FieldArray, Field } from 'formik'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { skipOnImportOptions } from '../../../../data/selectOptions'
import { NO_SKIP_ON_IMPORT_VENDOR_LIST } from '../../../../constants'
import TextField from '../../../shared/TextField'
import FormObserver from '../../../shared/FormObserver/FormObserver'
import { relateVendorToDoctorFacility } from '../../../../schema/relateDoctorFacility'

const RelateVendorModal = ({
  trigger,
  onSubmit,
  onClose,
  title,
  initialData,
  vendorsList,
  closeAfterSubmit,
  submitButtonText,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [options, setOptions] = useState([])
  const [vendorOptions, setVendorOptions] = useState([])

  const handleOpenModal = e => {
    e.preventDefault()
    setIsModalVisible(!isModalVisible)
  }

  const getVendorOptions = data =>
    options.map(option => {
      const selectedVendor = data.find(
        item =>
          item.vendor__name === option.label || item.label === option.label
      )

      option.disabled = !!selectedVendor

      return option
    })

  const filterVendorOption = data => {
    const filteredOptions = getVendorOptions(data)
    setVendorOptions(filteredOptions)
  }

  const getVendorsWithoutName = data => data.filter(item => !item.vendor__name)

  useEffect(() => {
    const preparedOptions = vendorsList.map(vendor => ({
      label: vendor.name,
      value: vendor.id,
      disabled: true,
    }))
    setOptions(preparedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorsList])

  useEffect(() => {
    filterVendorOption(initialData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: handleOpenModal,
      })}
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={false}
        destroyOnClose
        centered
        onCancel={() => setIsModalVisible(false)}
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(10px)',
        }}
        width="794px"
      >
        <Typography.Title level={3}>{title}</Typography.Title>
        <Formik
          initialValues={{ vendors: [...initialData] }}
          onSubmit={async (...data) => {
            try {
              await onSubmit(...data)
              if (closeAfterSubmit) {
                setIsModalVisible(false)
                onClose()
              }
            } catch (err) {
              /* empty */
            }
          }}
          validationSchema={relateVendorToDoctorFacility}
        >
          {({ handleSubmit, values, setFieldValue, initialValues, errors }) => (
            <div className="form__fields-array">
              <Form
                onFinish={handleSubmit}
                initialValues={initialValues}
                layout="vertical"
              >
                <FormObserver
                  onChange={data => filterVendorOption(data.vendors)}
                />
                <FieldArray
                  name="vendors"
                  render={({ name, push, remove }) => (
                    <>
                      {values?.vendors.map((field, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={index} align="top" style={{ gap: '10px' }}>
                          <Col style={{ marginBottom: '12px', width: '130px' }}>
                            <div className="ant-col ant-form-item-label">
                              <label
                                htmlFor={`${name}[${index}].vendor_id`}
                                title="Vendor"
                              >
                                Vendor
                              </label>
                            </div>
                            <div>
                              <Select
                                placeholder="Select Vendor"
                                value={field.vendor_id || undefined}
                                style={{ width: 130 }}
                                name={`${name}[${index}].vendor_id`}
                                options={vendorOptions}
                                onChange={(value, data) => {
                                  setFieldValue(
                                    `${name}[${index}].vendor_id`,
                                    value
                                  )
                                  setFieldValue(
                                    `${name}[${index}].vendor__name`,
                                    data.label
                                  )
                                }}
                                disabled={field.id}
                              />
                            </div>
                          </Col>
                          <Col style={{ width: '226px' }}>
                            <Field
                              component={TextField}
                              placeholder="Type Here"
                              name={`${name}[${index}].organization_name`}
                              label="Organization Name"
                              error={
                                errors &&
                                errors[name] &&
                                errors[name][index] &&
                                errors[name][index].organization_name
                              }
                              required
                            />
                          </Col>
                          <Col style={{ width: '226px' }}>
                            <Field
                              component={TextField}
                              placeholder="Type Here"
                              name={`${name}[${index}].organization_id`}
                              label="Organization ID"
                              error={
                                errors &&
                                errors[name] &&
                                errors[name][index] &&
                                errors[name][index].organization_id
                              }
                              required
                            />
                          </Col>
                          {!NO_SKIP_ON_IMPORT_VENDOR_LIST.includes(
                            field.vendor__name
                          ) && (
                            <Col style={{ marginBottom: '12px' }}>
                              <div className="ant-col ant-form-item-label">
                                <label
                                  htmlFor={`${name}[${index}].skip_on_import`}
                                  title="Skip On Import"
                                >
                                  Skip On Import
                                </label>
                              </div>
                              <div>
                                <Select
                                  value={field.skip_on_import}
                                  style={{ width: 88 }}
                                  name={`${name}[${index}].skip_on_import`}
                                  options={skipOnImportOptions}
                                  onChange={value => {
                                    setFieldValue(
                                      `${name}[${index}].skip_on_import`,
                                      value
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                          )}
                          <Col
                            style={{ marginLeft: 'auto', marginTop: '24px' }}
                          >
                            <Form.Item>
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  remove(index)
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                      {values?.vendors?.length < vendorOptions?.length && (
                        <Form.Item
                          style={{ marginTop: '20px', marginBottom: 0 }}
                        >
                          <Button
                            type="dashed"
                            onClick={() => {
                              push({
                                skip_on_import: false,
                                organization_name: '',
                                organization_id: '',
                                id: '',
                                vendor_id: '',
                              })
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Vendor
                          </Button>
                        </Form.Item>
                      )}
                    </>
                  )}
                />
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginTop: '20px' }}
                >
                  <Col>
                    <Button onClick={handleOpenModal}>Close window</Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        (values?.vendors.length > 0 &&
                          values?.vendors.length ===
                            getVendorsWithoutName(values?.vendors).length) ||
                        (errors && errors.vendors && errors.vendors.length)
                      }
                    >
                      {submitButtonText || 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default RelateVendorModal
