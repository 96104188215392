import React from 'react'
import { notification } from 'antd'

import FormWrapper from '../../components/Forms/AuthForms/FormWrapper'
import ForgotPasswordForm from '../../components/Forms/AuthForms/ForgotPasswordForm'
import { forgotPassword } from '../../../state/modules/auth'

const ForgotPassword = () => {
  const handleSubmit = values => {
    forgotPassword(values)
      .then(() => {
        notification.success({
          message:
            'An email has been sent to the address you have provided. Please follow the link in the email to complete your password reset request. ',
        })
      })
      .catch(error => {
        notification.error({
          message: error.message,
        })
      })
  }

  return (
    <FormWrapper
      title="Enter your E&#8209;mail"
      subtitle={
        <>
          Please enter your email address for access to the Jaia Profile
          Service.
        </>
      }
      form={<ForgotPasswordForm onSubmit={handleSubmit} />}
    />
  )
}

export default ForgotPassword
