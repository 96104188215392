import React from 'react'
import PropTypes from 'prop-types'

import { Layout } from 'antd'

import Header from '../../components/Header'
import SubHeader from '../../components/SubHeader'

const MainLayout = ({ children, withSubHeader, subHeaderProps }) => (
  <Layout>
    <Header />
    {withSubHeader && <SubHeader {...subHeaderProps} />}
    {children}
  </Layout>
)

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  withSubHeader: PropTypes.bool,
  subHeaderProps: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    button: PropTypes.element,
  }),
}

MainLayout.defaultProps = {
  children: null,
  withSubHeader: false,
  subHeaderProps: {
    title: '',
    subTitle: '',
    button: null,
  },
}

export default MainLayout
