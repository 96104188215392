import { healthNetworkAdapter } from './reducer'

export const {
  selectAll: selectAllHealthNetworks,
  selectById: selectHealthNetworkById,
} = healthNetworkAdapter.getSelectors(state => state.healthNetworks)

export const {
  selectAll: selectAllHealthNetworksDoctors,
} = healthNetworkAdapter.getSelectors(
  state => state.healthNetworks.related_doctors
)

export const {
  selectAll: selectAllHealthNetworksSoleProprietors,
} = healthNetworkAdapter.getSelectors(
  state => state.healthNetworks.related_sole_proprietors
)

export const {
  selectAll: selectAllHealthNetworksFacilities,
} = healthNetworkAdapter.getSelectors(
  state => state.healthNetworks.related_facilities
)
