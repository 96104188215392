import { object } from 'yup'
import {
  emails,
  phone_numbers,
  addresses,
  fax_numbers,
  npi,
  facilityName,
  other_npis,
} from './fields'

export const updateCreateFacilitySchema = object().shape({
  name: facilityName.required('Please enter a Facility Name').nullable(),
  emails,
  phone_numbers,
  addresses,
  fax_numbers,
  npi,
  other_npis,
})
export const updateCreateRelatedFacilityProfileSchema = object().shape({
  name: facilityName.required('Please enter a Facility Name').nullable(),
  emails,
  phone_numbers,
  addresses,
  fax_numbers,
  npi,
})
