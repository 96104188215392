import React from 'react'
import { Form, Button } from 'antd'
import { Formik, Field } from 'formik'
import { LockOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import TextField from '../../../shared/TextField'
import { confirmCodeSchema } from '../../../../schema/auth'

const ConfirmCodeForm = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={confirmCodeSchema}
    initialValues={{ code: '' }}
  >
    {({ handleSubmit, isSubmitting }) => (
      <Form onFinish={handleSubmit}>
        <Field
          component={TextField}
          name="code"
          placeholder="Confirmation code"
          type="text"
          icon={<LockOutlined className="form-input-icon" />}
        />
        <Form.Item>
          <Link to="/login" className="forgot-password-link">
            Back to Log in page
          </Link>
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            size="large"
            loading={isSubmitting}
          >
            Confirm
          </Button>
        </Form.Item>
      </Form>
    )}
  </Formik>
)

ConfirmCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ConfirmCodeForm
