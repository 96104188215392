import React from 'react'
import PropTypes from 'prop-types'

import FormWrapper from '../MedicalFormWrapper'
import Form from './Form'

const FacilityForm = ({
  initialValues,
  onClose,
  onSubmit,
  validationSchema,
  entityId,
  ...formProps
}) => (
  <FormWrapper
    initialValues={initialValues}
    onClose={onClose}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    id={entityId}
    submitButtonText="Upload"
  >
    <Form {...formProps} />
  </FormWrapper>
)

FacilityForm.propTypes = {
  initialValues: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape({}),
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

FacilityForm.defaultProps = {
  onClose: () => null,
  initialValues: {},
  onSubmit: () => null,
  validationSchema: null,
  entityId: '',
}

export default FacilityForm
