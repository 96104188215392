export const transformSearchValues = options => {
  const values = Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(options).filter(([_, v]) => v != null)
  )

  if (values.last_name) {
    values.facility_name = values.last_name
  }

  const { last_name, ...rest } = values
  return rest
}

export const transformSearchCandidateId = options => {
  let values = { ...options }
  if (values?.candidate_ids?.length) {
    const gaia_id = values.candidate_ids.join(',')
    values = { gaia_id }
  }

  return values
}
