import React from 'react'

import { FacilityMain, Addresses, Contact, AdditionalNpi } from '../FormFields'

const FormComponent = ({ errorMessage, formData, type, action, original }) => (
  <>
    <FacilityMain title="Main Info" original={original} />
    <AdditionalNpi
      title="Additional NPIs"
      values={formData.other_npis}
      errorMessage={errorMessage}
    />
    <Addresses
      values={formData}
      errorMessage={errorMessage}
      type={type}
      action={action}
      original={original}
    />
    <Contact
      values={formData}
      errorMessage={errorMessage}
      title="Contacts"
      type={type}
      action={action}
      original={original}
    />
  </>
)

export default FormComponent
