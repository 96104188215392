import React from 'react'
import { Button, Row, Col, Form } from 'antd'
import PropTypes from 'prop-types'
import { Formik, getIn } from 'formik'

import { removeEmptyValues } from '../../../../utils/functions'

const FormWrapper = ({
  initialValues,
  initialTouched,
  onClose,
  onSubmit,
  validationSchema,
  children,
  id,
  submitButtonText,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    initialTouched={initialTouched}
    onSubmit={async values => {
      try {
        await onSubmit(id, removeEmptyValues(values))
        await onClose()
      } catch (err) {
        /* empty */
      }
    }}
    validationSchema={validationSchema}
  >
    {({ values, handleSubmit, errors, touched, isSubmitting }) => {
      const errorMessage = name => {
        const error = getIn(errors, name)
        const touch = getIn(touched, name)

        return touch && error ? error : null
      }

      return (
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          className="medical-form-wrapper"
        >
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              errorMessage,
              initialValues,
              formData: values,
            })
          )}

          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: '10px' }}
          >
            <Col>
              <Button onClick={onClose}>Close window</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {submitButtonText}
              </Button>
            </Col>
          </Row>
        </Form>
      )
    }}
  </Formik>
)

FormWrapper.propTypes = {
  initialValues: PropTypes.shape({}),
  initialTouched: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  submitButtonText: PropTypes.string,
}

FormWrapper.defaultProps = {
  onClose: () => null,
  initialValues: {},
  initialTouched: {},
  onSubmit: () => null,
  validationSchema: null,
  submitButtonText: 'Save Changes',
}

export default FormWrapper
