import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Space, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'

const TableLink = ({
  route,
  avatar,
  text,
  icon,
  onLinkClick,
  linkData,
  navigate = true,
}) => {
  if (navigate) {
    return (
      <Link to={{ pathname: route, data: linkData }} onClick={onLinkClick}>
        <Space>
          {avatar && (
            <Avatar
              className="search-table__user-avatar"
              size={26}
              icon={
                <UserOutlined className="search-table__user-avatar--icon" />
              }
            />
          )}
          {text}
          {icon && icon}
        </Space>
      </Link>
    )
  }
  return (
    <Typography.Link>
      <Space>
        {avatar && (
          <Avatar
            className="search-table__user-avatar"
            size={26}
            icon={<UserOutlined className="search-table__user-avatar--icon" />}
          />
        )}
        {text}
        {icon && icon}
      </Space>
    </Typography.Link>
  )
}

export default TableLink
