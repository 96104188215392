import React from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import { capitalizeEveryWord } from '../../../utils/helpers'

const EditorCell = ({ editor, path, isUserActionAllowed }) => {
  if (editor && editor.id) {
    return isUserActionAllowed('view_user') ? (
      <Link to={`/${path}/${editor.id}/`}>
        {capitalizeEveryWord(`${editor?.firstname} ${editor?.lastname}`)}
      </Link>
    ) : (
      <Typography.Text type="secondary">
        {capitalizeEveryWord(`${editor?.firstname} ${editor?.lastname}`)}
      </Typography.Text>
    )
  }

  if (editor && !editor.id) {
    return (
      <Typography.Text type="secondary">
        {capitalizeEveryWord(editor?.first_name)}
      </Typography.Text>
    )
  }

  return <Typography.Text type="secondary">No info</Typography.Text>
}

export default EditorCell
