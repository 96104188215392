import Api from './index'
import { API_URL } from '../constants'

class CopyCompany extends Api {
  getCopyCompanies(id, params) {
    return this.get('/', { params })
  }
}

export default new CopyCompany({
  baseURL: `${API_URL}copy_companies/`,
})
