import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getCopyCompanies, resetState } from './actions'
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const copyCompaniesAdapter = createEntityAdapter()
const initialState = copyCompaniesAdapter.getInitialState({
  data: [],
  status: IDLE_STATUS,
  count: 0,
})

const copyCompaniesSlice = createSlice({
  name: 'copyCompanies',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      copyCompaniesAdapter.removeAll(state)
    })
    builder.addCase(getCopyCompanies.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getCopyCompanies.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        // skip normalization via copyCompaniesAdapter as results may contain items with the same id several times
        state.data = results
        state.status = SUCCESS_STATUS
      }
    )
  },
})

const { reducer } = copyCompaniesSlice

export default reducer
