import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Form } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { FieldArray, Field, useFormikContext } from 'formik'

import ToggleField from '../ToggleField'
import TextField from '../TextField'
import SelectField from '../SelectField'
import { Label } from '../../components/Forms/FormFields/Label'

const ArrayField = ({
  values,
  fieldsName,
  label,
  fieldKey,
  renderError,
  selectOptions,
  minLengthFields,
  original,
  action,
}) => {
  const { setFieldTouched } = useFormikContext()

  return (
    <div className="form__fields-array">
      <FieldArray
        name={fieldsName}
        render={({ name, push, remove }) => (
          <>
            {values.map((field, index) => {
              const origin = original[index]
              return (
                <Row key={`${fieldKey}_${index + 1}`} gutter={12}>
                  <Col flex={12}>
                    <Field
                      component={TextField}
                      label={
                        <Label
                          label={`${label} ${index + 1}`}
                          original={origin && origin[fieldKey]}
                        />
                      }
                      size="medium"
                      name={`${name}[${index}].${fieldKey}`}
                      placeholder="Type Here"
                      error={renderError(`${name}[${index}].${fieldKey}`)}
                      required
                    />
                  </Col>
                  {fieldsName === 'phone_numbers' && (
                    <Col span={4}>
                      <Field
                        component={TextField}
                        label={
                          <Label
                            label="Ext."
                            original={origin && origin.phone_extension}
                          />
                        }
                        maxLength="6"
                        size="medium"
                        name={`${name}[${index}].phone_extension`}
                        placeholder="Type Here"
                        error={renderError(`${name}[${index}].phone_extension`)}
                      />
                    </Col>
                  )}
                  <Col span={6}>
                    <Field
                      component={SelectField}
                      label={
                        <Label label="Select type" original={origin?.type} />
                      }
                      size="medium"
                      name={`${name}[${index}].type`}
                      error={renderError(`${name}[${index}].type`)}
                      placeholder="Select Here"
                      options={selectOptions}
                    />
                  </Col>
                  {(fieldsName === 'phone_numbers' ||
                    fieldsName === 'fax_numbers') &&
                    field.type === 'LOC' && (
                      <Col span={2}>
                        <Field
                          component={ToggleField}
                          label={
                            <Label
                              label="Verified"
                              original={origin && origin.verified}
                            />
                          }
                          size="medium"
                          name={`${name}[${index}].verified`}
                          error={renderError(`${name}[${index}].verified`)}
                        />
                      </Col>
                    )}
                  <Col>
                    <Form.Item label>
                      <Button
                        disabled={minLengthFields === index + 1}
                        icon={<DeleteOutlined />}
                        onClick={() => remove(index)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  push({
                    [fieldKey]: '',
                    type: 'LOC',
                    verified: action === 'create',
                  })
                  setFieldTouched(`${fieldsName}[${values.length}].type`, true)
                }}
                block
                icon={<PlusOutlined />}
              >
                Add {label}
              </Button>
            </Form.Item>
          </>
        )}
      />
    </div>
  )
}

ArrayField.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  original: PropTypes.arrayOf(PropTypes.object),
  fieldsName: PropTypes.string,
  label: PropTypes.string,
  fieldKey: PropTypes.string,
  renderError: PropTypes.func,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  action: PropTypes.string,
  minLengthFields: PropTypes.number,
}

ArrayField.defaultProps = {
  values: [],
  original: {},
  fieldsName: '',
  label: '',
  fieldKey: '',
  renderError: () => null,
  selectOptions: [{ label: '', value: '' }],
  action: '',
  minLengthFields: null,
}

export default ArrayField
