import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'

const FormWrapper = ({ title, subtitle, form }) => (
  <div className="login-form" data-testid="form-wrapper">
    <Typography.Title className="login-form__title">{title}</Typography.Title>
    {subtitle && (
      <Typography.Paragraph className="login-form__subtitle">
        {subtitle}
      </Typography.Paragraph>
    )}
    {form}
  </div>
)

FormWrapper.propTypes = {
  form: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

FormWrapper.defaultProps = {
  title: 'Log in to your Account',
  subtitle: null,
}

export default FormWrapper
