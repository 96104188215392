import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getAgencies, resetState } from './actions'
import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'

export const AgencyAdapter = createEntityAdapter()
const initialState = AgencyAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const Agencieslice = createSlice({
  name: 'agencies',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      AgencyAdapter.removeAll(state)
    })
    builder.addCase(getAgencies.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getAgencies.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        AgencyAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(getAgencies.rejected, state => {
      state.status = FAILURE_STATUS
    })
  },
})

const { reducer } = Agencieslice

export default reducer
