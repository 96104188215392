import UsersTables from '../../pages/users/UsersTables'
import UserDetails from '../../pages/users/UserDetails'
import AddNewUser from '../../pages/users/AddNewUser'
import ActivityLogList from '../../pages/users/ActivityLogList'
import ActivityLogDetail from '../../pages/users/ActivityLogDetail'
import Vendors from '../../pages/users/Vendors'
import VendorDetail from '../../pages/users/VendorDetail'
import Search from '../../pages/search/Search'
import Facility from '../../pages/facilities/Facility'
import Doctor from '../../pages/doctors/Doctor'
import Clusters from '../../pages/cluster/Clusters'
import Cluster from '../../pages/cluster/Cluster'
import HealthNetworks from '../../pages/healthNetworks/HealthNetworks'
import HealthNetwork from '../../pages/healthNetworks/HealthNetwork'
import Exceptions from '../../pages/exceptions/Exceptions'
import Exception from '../../pages/exceptions/Exception'
import FacilityProfiles from '../../pages/facilities/profile/Profiles'
import FacilityProfile from '../../pages/facilities/profile/Profile'
import FacilityProfileMergers from '../../pages/facilities/merger/Mergers'
import FacilityProfileMerger from '../../pages/facilities/merger/Merger'
import Retrievers from '../../pages/facilities/retriever/Retrievers'
import HistoryLog from '../../pages/exceptions/HistoryLog'
import ImportLogs from '../../pages/importLogs/ImportLogs'
import ImportLogDetails from '../../pages/importLogs/ImportLogDetails'
import WeeklyNpeesUpdatesDetails from '../../pages/importLogs/WeeklyNpeesUpdatesDetails'
import PhoneLogsDetails from '../../pages/importLogs/PhoneLogsDetails'
import NotFound from '../../pages/notFound/NotFound'
import RolesTables from '../../pages/roles/RolesTables'
import RoleDetails from '../../pages/roles/RoleDetails'
import Reports from '../../pages/reports/Reports'
import Forbidden from '../../pages/forbidden/Forbidden'
import Organizations from '../../pages/organizations/Organizations'
import RetrieversList from '../../pages/retrieversList/RetrieversList'
import AssignmentCopyCompany from '../../pages/assignmentCopyCompany/AssignmentCopyCompanyTables'
import AssignmentCopyCompanyDetails from '../../pages/assignmentCopyCompany/AssignmentCopyCompanyDetails'

export default [
  {
    path: '/users',
    name: 'Users',
    component: UsersTables,
    permission: 'view_user',
    title: 'Users',
  },
  {
    path: '/users/add-new-user',
    name: 'Add New User',
    component: AddNewUser,
    permission: 'add_user',
    title: 'New User',
  },
  {
    path: '/users/:id',
    name: 'User Details',
    component: UserDetails,
    permission: 'view_user',
    title: 'User Details',
  },
  {
    path: '/user-activity-log',
    name: 'User Activity Log List',
    component: ActivityLogList,
    permission: ['view_version', 'view_history_case'],
    title: 'User Activity Log List',
  },
  {
    path: '/user-activity-log/:entity/:instance_id',
    name: 'User Activity Log',
    component: ActivityLogDetail,
    permission: 'view_version',
    title: 'User Activity Log',
  },
  {
    path: '/vendors',
    name: 'Vendors',
    component: Vendors,
    permission: 'can_manage_vendor_relations',
    title: 'Vendors',
  },
  {
    path: '/vendors/:id',
    name: 'Vendor',
    component: VendorDetail,
    permission: 'can_manage_vendor_relations',
    title: 'Vendor',
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    permission: ['view_doctor', 'view_facility'],
    title: 'Search',
  },
  {
    path: '/facility/:id',
    name: 'Facility',
    component: Facility,
    permission: 'view_facility',
    title: 'Facility',
  },
  {
    path: '/doctor/:id',
    name: 'Doctor',
    component: Doctor,
    permission: 'view_doctor',
    title: 'Doctor',
  },
  {
    path: '/clusters',
    name: 'Clusters',
    component: Clusters,
    permission: 'view_facilitycluster',
    title: 'Clusters',
  },
  {
    path: '/clusters/:id',
    name: 'Cluster',
    component: Cluster,
    permission: 'view_facilitycluster',
    title: 'Cluster Details',
  },
  {
    path: '/health-networks',
    name: 'Health Networks',
    component: HealthNetworks,
    permission: 'view_healthnetwork',
    title: 'Health Networks',
  },
  {
    path: '/health-networks/:id',
    name: 'Health Network',
    component: HealthNetwork,
    permission: 'view_healthnetwork',
    title: 'Health Network',
  },
  {
    path: '/cases',
    name: 'Cases',
    component: Exceptions,
    permission: 'view_case',
    title: 'Cases',
  },
  {
    path: '/cases/:id',
    name: 'Case',
    component: Exception,
    permission: 'view_case',
    title: 'Case Details',
  },
  {
    path: '/cases/:id/history',
    name: 'Exception History',
    component: HistoryLog,
    permission: 'view_history_case',
    title: 'Exception History',
  },
  {
    path: '/facility-profiles',
    name: 'Facility Profiles',
    component: FacilityProfiles,
    permission: 'view_facilityprofile',
    title: 'Facility Profiles',
  },
  {
    path: '/facility-profiles/:id',
    name: 'Facility Profile',
    component: FacilityProfile,
    permission: 'view_facilityprofile',
    title: 'Facility Profile',
  },
  {
    path: '/facility-profile-mergers',
    name: 'Facility Profile Mergers',
    component: FacilityProfileMergers,
    permission: 'view_merger',
    title: 'Facility Profile Mergers',
  },
  {
    path: '/facility-profile-mergers/:id',
    name: 'Facility Profile Merger',
    component: FacilityProfileMerger,
    permission: 'view_merger',
    title: 'Facility Profile Merger',
  },
  {
    path: '/facility-profiles/:id/retrievers',
    name: 'Past Retrievers',
    component: Retrievers,
    permission: 'view_retriever',
    title: 'Past Retrievers',
  },
  {
    path: '/data-sync-log',
    name: 'Import Logs',
    component: ImportLogs,
    permission: 'view_importlog',
    title: 'Import Logs',
  },
  {
    path: '/data-sync-log/:id',
    name: 'Import Logs Details',
    component: ImportLogDetails,
    permission: 'view_importlog',
    title: 'Import Logs Details',
  },
  {
    path: '/data-sync-log-weekly-npees-updates/:id',
    name: 'Weekly NPEES Updates Logs Details',
    component: WeeklyNpeesUpdatesDetails,
    permission: 'view_importlog',
    title: 'Weekly NPEES Updates Logs Details',
  },
  {
    path: '/phone-logs/:id',
    name: 'Phone Logs Details',
    component: PhoneLogsDetails,
    permission: 'view_importlog',
    title: 'Phone Logs Details',
  },
  {
    path: '/not-found',
    name: 'Not Found',
    component: NotFound,
    title: 'Not Found',
  },
  {
    path: '/roles',
    name: 'Roles',
    component: RolesTables,
    permission: 'view_group',
    title: 'Roles',
  },
  {
    path: '/roles/add-new-role',
    name: 'Add New Role',
    component: RoleDetails,
    permission: 'add_group',
    title: 'Add New Role',
  },
  {
    path: '/roles/:roleId',
    name: 'Edit Role',
    component: RoleDetails,
    permission: 'view_group',
    title: 'Edit Role',
  },
  {
    path: '/reports/',
    name: 'Reports',
    component: Reports,
    permission: 'view_report',
    title: 'Reports',
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
    title: 'Forbidden',
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: Organizations,
    title: 'Organizations',
    permission: ['view_agency', 'view_insurancecompany'],
  },
  {
    path: '/copy-company-assignment',
    name: 'Copy Company Assignment',
    component: AssignmentCopyCompany,
    permission: 'view_assignmentcopycompany',
    title: 'Copy Company Assignment',
  },
  {
    path: '/copy-company-assignment/add-new-cc-assignment',
    name: 'Add New Copy Company Assignment',
    component: AssignmentCopyCompanyDetails,
    permission: 'add_assignmentcopycompany',
    title: 'Add New Copy Company Assignment',
  },
  {
    path: '/copy-company-assignment/:ccAssignmentId',
    name: 'Edit Copy Company Assignment',
    component: AssignmentCopyCompanyDetails,
    permission: 'view_assignmentcopycompany',
    title: 'Edit Copy Company Assignment',
  },
  {
    path: '/retrievers-list',
    name: 'Retrievers',
    component: RetrieversList,
    permission: 'manage_retrievers',
    title: 'Retrievers',
  },
]
