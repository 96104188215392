import React, { useCallback, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { notification } from 'antd'
import { StatusCodes, getReasonPhrase } from 'http-status-codes'

import FormWrapper from '../../components/Forms/AuthForms/FormWrapper'
import ResetPasswordForm from '../../components/Forms/AuthForms/ResetPasswordForm'
import {
  resetPassword,
  verifyResetPasswordToken,
} from '../../../state/modules/auth'
import { routePaths } from '../../../utils/routes'

const ResetPassword = () => {
  const { search } = useLocation()
  const history = useHistory()
  const token = new URLSearchParams(search).get('token')

  const verifyToken = useCallback(async () => {
    try {
      await verifyResetPasswordToken(token)
    } catch (err) {
      notification.error({
        message: 'Reset password session has been expired',
      })
      history.push(routePaths.openForgotPassword())
    }
  }, [token, history])

  useEffect(() => {
    verifyToken()
  }, [verifyToken])

  const handleSubmit = useCallback(
    ({ password }) => {
      resetPassword({ password, token })
        .then(data => {
          if (data.status === getReasonPhrase(StatusCodes.OK)) {
            notification.success({
              message: 'Password has been successfully changed',
            })
            history.push(routePaths.openLogin())
          }
        })
        .catch(error => {
          const [errorKey] = Object.keys(error?.response?.data)
          const errorMessage =
            error?.response?.data[errorKey] || 'Reset password error.'
          notification.error({
            message: errorMessage,
          })
        })
    },
    [history, token]
  )

  return (
    <FormWrapper
      title="Enter a new Password"
      form={<ResetPasswordForm onSubmit={handleSubmit} />}
    />
  )
}

export default ResetPassword
