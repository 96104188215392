export const MODEL_NAMES = {
  FACILITY: 'Facility',
  FACILITY_PROFILE: 'FacilityProfile',
  AUTH_FORM: 'AuthorizationForm',
  DOCTOR: 'Doctor',
  FACILITY_CLUSTER: 'FacilityCluster',
  DOCTOR_TO_FACILITY: 'Doctor2Facility',
  GAIA_USER: 'User',
  GROUP: 'Group',
  HEALTH_NETWORK: 'HealthNetwork',
  INSURANCE_COMPANY: 'InsuranceCompany',
  AGENCY: 'Agency',
  ASSIGNMENT_COPY_COMPANY: 'AssignmentCopyCompany',
  VENDOR: 'Vendor',
  FACILITY_VENDOR: 'FacilityVendor',
  DOCTOR_VENDOR: 'DoctorVendor',
  RETRIEVER: 'Retriever',
}
