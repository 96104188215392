import React from 'react'
import { Button, Modal } from 'antd'

const RelateNotesModal = ({ visible, title, handleClose, notes }) => (
  <Modal
    visible={visible}
    title={title}
    onCancel={handleClose}
    footer={[
      <Button key="back" onClick={handleClose}>
        OK
      </Button>,
    ]}
  >
    {notes && notes?.map(note => <p key={note}>{note}</p>)}
  </Modal>
)

export default RelateNotesModal
