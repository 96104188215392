import React, { useEffect, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { List } from 'antd'

import PageLayout from '../../layouts/DetailPageLayout'

import {
  getActivityLogs,
  selectActivityLogsCount,
  isActivityLogsFetching,
  selectAllActivityLogs,
} from '../../../state/modules/activityLogs'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import ActivityLogFilters from '../../components/ActivityLogFilters'
import ActivityLogCard from '../../components/ActivityLogCard'
import {
  validateSearchParams,
  getActivityLogNamesFromPath,
} from '../../../utils/functions'

const ActivityLogDetail = () => {
  const dispatchHttp = useDispatchHttp()
  const { instance_id, entity } = useParams()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const userId = query.get('user_id')
  const editedValue = query.get('edited')
  const entityFilter = query.get('entity_filter')
  const activityLogs = useSelector(selectAllActivityLogs)
  const isFetchingActivityLogs = useSelector(isActivityLogsFetching)
  const count = useSelector(selectActivityLogsCount)
  const paramValue = userId ? { user_id: +userId } : { edited: editedValue }
  const [searchParams, setSearchParams] = useState({
    ...paramValue,
    instance_id,
    entity,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const breadcrumbs = [
    {
      name: 'Activity Log',
      path: '/user-activity-log',
    },
    {
      name: 'Entities',
      path: `/user-activity-log${
        searchParams.user_id
          ? `?user_id=${searchParams.user_id}`
          : `?edited=${searchParams.edited}`
      }&entity=${entityFilter}&tab=entities`,
    },
    {
      name: `${getActivityLogNamesFromPath(entity) || ''} #${instance_id}`,
      path: `/user-activity-log/${entity}/${instance_id}/`,
    },
  ]

  useEffect(() => {
    const searchTerms = searchParams.user_id
      ? { user_id: searchParams.user_id }
      : { edited: searchParams.edited }

    dispatchHttp(
      getActivityLogs({
        instance_id,
        entity,
        ...validateSearchParams(searchTerms),
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp, instance_id, entity])

  const updateSearchParam = useCallback(
    params => {
      // exclude user_id and edited keys
      const {
        user_id: paramUserId,
        edited,
        ...preparedSearchParams
      } = searchParams
      // We can have only one user_id or edited value so if one of this params is present we clear them from the state
      const newSearchParams =
        params.user_id || params.edited ? preparedSearchParams : searchParams
      const data = {
        ...newSearchParams,
        ...params,
      }

      setSearchParams(data)
      dispatchHttp(
        getActivityLogs({
          ...validateSearchParams(data),
          instance_id,
          entity,
        })
      )
      setCurrentPage(1)
      setPageSize(10)
    },
    [searchParams, dispatchHttp, instance_id, entity]
  )

  const handlePaginate = (page, newPageSize) => {
    dispatchHttp(
      getActivityLogs({
        ...validateSearchParams(searchParams),
        page,
        pageSize: newPageSize,
      })
    )
    setCurrentPage(page)
    setPageSize(newPageSize)
  }

  const renderItem = useCallback(data => <ActivityLogCard data={data} />, [])

  return (
    <PageLayout title="Activity Log" breadcrumbs={breadcrumbs}>
      <ActivityLogFilters
        searchParams={searchParams}
        updateSearchParam={updateSearchParam}
      />
      <List
        grid={{ gutter: 24, column: 1 }}
        dataSource={activityLogs}
        renderItem={renderItem}
        pagination={{
          hideOnSinglePage: activityLogs.length === 0,
          total: count,
          onChange: handlePaginate,
          current: currentPage,
          pageSize,
        }}
        loading={isFetchingActivityLogs}
      />
    </PageLayout>
  )
}

export default ActivityLogDetail
