import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import ActivityLogCasesService from '../../../services/ActivityLogCasesService'

export const getActivityLogCases = createAsyncThunk(
  'activityLogCases/getList',
  async data => {
    const response = await getPagination(
      data,
      ActivityLogCasesService.getActivityLogCases.bind(ActivityLogCasesService)
    )

    return response
  }
)
