import React from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useUser } from '../../../providers/UserProvider'

const ActivityLogEditedBy = ({ name, id }) => {
  const { isUserActionAllowed } = useUser()

  if (id && isUserActionAllowed('view_user')) {
    return <Link to={`/users/${id}/`}>{name}</Link>
  }

  return <Typography.Text>{name}</Typography.Text>
}

export default ActivityLogEditedBy
