import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import AssignmentCopyCompanyService from '../../../services/AssignmentCopyCompanyService'
import { getPagination } from '../../../utils/functions'

export const fetchAllAssignmentCopyCompanies = createAsyncThunk(
  'assignmentCopyCompanies/fetchAll',
  async (data = {}) => {
    const { skipPagination, ...rest } = data
    if (skipPagination) {
      return AssignmentCopyCompanyService.getAssignmentCopyCompanies(rest)
    }

    return getPagination(
      rest,
      AssignmentCopyCompanyService.getAssignmentCopyCompanies.bind(
        AssignmentCopyCompanyService
      )
    )
  }
)

export const fetchAssignmentCopyCompanyById = createAsyncThunk(
  'assignmentCopyCompanies/fetchById',
  async id => AssignmentCopyCompanyService.getAssignmentCopyCompanyById(id)
)

export const createAssignmentCopyCompany = createAsyncThunk(
  'assignmentCopyCompanies/createOne',
  async data => AssignmentCopyCompanyService.createAssignmentCopyCompany(data)
)

export const deleteAssignmentCopyCompany = createAsyncThunk(
  'assignmentCopyCompanies/deleteOne',
  async data => AssignmentCopyCompanyService.deleteAssignmentCopyCompany(data)
)

export const updateAssignmentCopyCompany = createAsyncThunk(
  'assignmentCopyCompanies/updateOne',
  async ({ id, ...rest }) =>
    AssignmentCopyCompanyService.updateAssignmentCopyCompany(id, rest)
)

export const resetState = createAction('assignmentCopyCompanies/resetState')
