import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import VendorService from '../../../services/VendorService'

export const getVendors = createAsyncThunk('vendor/getVendors', data => {
  const { skipPagination, ...rest } = data

  if (skipPagination) {
    return VendorService.getVendorList(rest)
  }

  return getPagination(rest, VendorService.getVendorList.bind(VendorService))
})

export const fetchVendorById = createAsyncThunk(
  'vendor/fetchVendorById',
  async id => {
    const response = await VendorService.getVendorById(id)

    return response
  }
)

export const updateVendor = createAsyncThunk(
  'vendor/updateVendor',
  async ({ id, data }) => {
    const response = await VendorService.updateVendor(id, data)

    return response
  }
)

export const resetState = createAction('vendor/resetState')
