import React, { memo, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table, Space, Button, Radio, Popconfirm, Tooltip } from 'antd'
import {
  SwapOutlined,
  EditOutlined,
  DeleteOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'

import MedicalEntityModal from '../../Modals/MedicalEntityModal'

import {
  DOCTOR,
  FACILITY,
  FACILITY_PROFILE,
  INSURANCE_COMPANY,
  AGENCIES,
  EXCEPTION,
  DEFAULT_PAGE_SIZE,
  CLUSTER,
  VENDORS,
  RETRIEVERS,
} from '../../../../constants'

const TableComponent = ({
  className,
  columns,
  pagination,
  error,
  tableProps,
  onChange,
  entity,
  editForm,
  rowSelection,
  customRowSelection,
  rowDeletion,
  pageSizeOptions,
  rowClassName,
  onRow,
  rowKey,
  scroll,
  formData,
}) => {
  const [selectedRow, setSelectedRow] = useState([])
  const defaultPagination = useMemo(
    () => ({
      hideOnSinglePage: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      disabled: error,
      defaultPageSize: DEFAULT_PAGE_SIZE,
      size: 'default',
      pageSizeOptions,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, onChange]
  )

  const paginationOptions = pagination
    ? { ...pagination, ...defaultPagination }
    : defaultPagination

  const rowKeyValue = () => {
    if (rowKey) return rowKey
    return record => record.id
  }

  const resetSelectedRow = () => {
    if (!selectedRow.length) return
    setSelectedRow([])
  }

  useEffect(() => {
    resetSelectedRow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  return (
    <Table
      className={className}
      size="small"
      pagination={paginationOptions}
      rowKey={rowKeyValue()}
      scroll={scroll}
      {...tableProps}
      rowSelection={rowSelection}
      rowClassName={rowClassName}
      onRow={onRow}
      onChange={(paginationData, filters, sorter) => {
        onChange(paginationData.current, paginationData.pageSize, sorter)
        resetSelectedRow()
      }}
    >
      {columns.map(({ title, dataIndex, key, ...rest }, index) => {
        const preparedColumn = (colTitle, colDataIndex, colKey, colRest) => (
          <Table.Column
            title={
              colRest.sorter ? (
                <Space size={6}>
                  {colTitle}
                  {pagination.sorter?.columnKey === colKey &&
                    pagination.sorter?.order === 'ascend' && (
                      <SortAscendingOutlined style={{ fontSize: '16px' }} />
                    )}

                  {pagination.sorter?.columnKey === colKey &&
                    pagination.sorter?.order === 'descend' && (
                      <SortDescendingOutlined style={{ fontSize: '16px' }} />
                    )}

                  {(pagination.sorter?.columnKey !== colKey ||
                    !pagination.sorter?.order) && <SwapOutlined rotate={90} />}
                </Space>
              ) : (
                colTitle
              )
            }
            dataIndex={colDataIndex}
            key={colKey}
            {...colRest}
            sortOrder={
              pagination.sorter?.columnKey === colKey &&
              pagination.sorter?.order
            }
          />
        )
        if (rest.children) {
          const children = rest.children.map(
            ({
              title: childTitle,
              dataIndex: childDataIndex,
              key: childKey,
              ...childRest
            }) =>
              preparedColumn(childTitle, childDataIndex, childKey, childRest)
          )

          return (
            <Table.ColumnGroup
              // eslint-disable-next-line react/no-array-index-key
              key={`${title}-${index}`}
              title={title}
              {...rest}
            >
              {children}
            </Table.ColumnGroup>
          )
        }

        return preparedColumn(title, dataIndex, key, rest)
      })}
      {!!editForm && (
        <Table.Column
          width={50}
          render={(text, data) => (
            <MedicalEntityModal
              title={entity === FACILITY ? 'Edit Facility' : 'Edit Doctor'}
              trigger={
                <Button
                  type="text"
                  icon={
                    <Tooltip title="Edit">
                      <EditOutlined
                        style={{ color: '#BFBFBF', fontSize: 18 }}
                      />
                    </Tooltip>
                  }
                />
              }
              form={React.cloneElement(editForm, {
                initialValues: data,
                entityId: data.id,
              })}
            />
          )}
        />
      )}
      {!!customRowSelection && (
        <Table.Column
          align="center"
          width={60}
          title={customRowSelection.columnTitle}
          render={(text, data) => {
            const key = data.id
            const checked = selectedRow.includes(key)
            const defaultChecked =
              data[customRowSelection.key] && !selectedRow.length
                ? data[customRowSelection.key]
                : checked

            const handleChange = ({ target: { value } }) => {
              if (!checked) {
                setSelectedRow([value])
              }

              if (customRowSelection.onSelect) {
                customRowSelection.onSelect(value)
              }
            }

            return (
              <Radio
                name={key}
                checked={defaultChecked}
                onChange={handleChange}
                value={key}
                disabled={!customRowSelection.disabled}
              />
            )
          }}
        />
      )}
      {!!rowDeletion && (
        <Table.Column
          width={50}
          align="center"
          render={(_, record) => {
            if (rowDeletion?.hideRule && rowDeletion?.hideRule(record)) {
              return null
            }
            return (
              <Popconfirm
                okText={rowDeletion.okText}
                title={rowDeletion.confirmMsg}
                overlayStyle={{ width: 250 }}
                onConfirm={() =>
                  rowDeletion.onConfirm({ id: record.id, record })
                }
              >
                <Tooltip title={rowDeletion.tooltip}>
                  <DeleteOutlined style={{ color: '#BFBFBF', fontSize: 18 }} />
                </Tooltip>
              </Popconfirm>
            )
          }}
        />
      )}
    </Table>
  )
}

TableComponent.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      title: PropTypes.string,
      avatar: PropTypes.bool,
      link: PropTypes.bool,
      render: PropTypes.func,
      sorter: PropTypes.func,
    })
  ).isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    pageSize: PropTypes.number,
    ordering: PropTypes.string,
    sorter: PropTypes.shape({
      columnKey: PropTypes.string,
      field: PropTypes.string,
      order: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  }),
  error: PropTypes.bool,
  onChange: PropTypes.func,
  tableProps: PropTypes.shape({
    loading: PropTypes.bool,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    bordered: PropTypes.bool,
  }),
  entity: PropTypes.oneOf([
    DOCTOR,
    FACILITY,
    FACILITY_PROFILE,
    INSURANCE_COMPANY,
    AGENCIES,
    EXCEPTION,
    CLUSTER,
    VENDORS,
    RETRIEVERS,
  ]),
  editForm: PropTypes.element,
  customRowSelection: PropTypes.shape({
    key: PropTypes.string,
    columnTitle: PropTypes.string,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  rowDeletion: PropTypes.shape({
    tooltip: PropTypes.string,
    okText: PropTypes.string,
    confirmMsg: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    hideRule: PropTypes.func,
  }),
  rowSelection: PropTypes.shape({
    type: PropTypes.oneOf(['radio', 'checkbox']),
    onChange: PropTypes.func,
  }),
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  onRow: PropTypes.func,
  rowClassName: PropTypes.func,
  className: PropTypes.string,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  scroll: PropTypes.shape({}),
  formData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
}

TableComponent.defaultProps = {
  pagination: PropTypes.shape({
    current: 1,
    pageSize: 10,
  }),
  error: false,
  onChange: PropTypes.func,
  tableProps: PropTypes.shape({
    loading: false,
    onChange: () => null,
    bordered: false,
  }),
  rowSelection: null,
  entity: FACILITY,
  editForm: null,
  customRowSelection: null,
  rowDeletion: null,
  pageSizeOptions: null,
  onRow: null,
  rowClassName: null,
  className: '',
  rowKey: null,
  scroll: null,
  formData: {},
}

export default memo(TableComponent)
