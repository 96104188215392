import React from 'react'
import { Divider } from 'antd'

import ArrayField from '../../../shared/ArrayField'
import {
  phoneOptions,
  faxOptions,
  emailsOptions,
} from '../../../../data/selectOptions'

export const Contact = ({
  values,
  errorMessage,
  title,
  type,
  original,
  action,
}) => {
  let filteredPhoneOptions = phoneOptions

  if (type === 'doctor') {
    filteredPhoneOptions = filteredPhoneOptions.filter(
      ({ value }) => value !== 'OFF'
    )
  }

  return (
    <>
      <Divider orientation="left" className="form-divider">
        {title}
      </Divider>
      <ArrayField
        fieldsName="phone_numbers"
        label="Phone Number"
        fieldKey="number"
        action={action}
        values={values.phone_numbers}
        original={original?.phone_numbers}
        renderError={errorMessage}
        selectOptions={filteredPhoneOptions}
        minLengthFields={1}
      />
      <Divider />

      <ArrayField
        fieldsName="emails"
        label="E-mail"
        fieldKey="address"
        values={values.emails}
        original={original?.emails}
        renderError={errorMessage}
        selectOptions={emailsOptions}
      />
      <Divider />

      <ArrayField
        fieldsName="fax_numbers"
        label="Fax"
        fieldKey="number"
        action={action}
        values={values.fax_numbers}
        original={original?.fax_numbers}
        renderError={errorMessage}
        selectOptions={faxOptions}
      />
    </>
  )
}
