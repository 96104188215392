import React, { useEffect, useMemo, useCallback, memo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spin, Typography, Row, Col, Space, Button } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

import InfoCard from '../components/InfoCard'
import FacilityProfileCard from '../components/FacilityProfileCard'
import FacilityForm from '../components/Forms/FacilityForm'
import DoctorForm from '../components/Forms/DoctorForm'

import SearchTable from '../components/Tables/SearchTable/index'
import MedicalEntityModal from '../components/Modals/MedicalEntityModal'
import showConfirmDialog from '../components/Modals/DeleteConfirm'
import RelateDoctorModal from '../components/Modals/RelateDoctorFacilityModal'
import RelateVendorModal from '../components/Modals/RelateVendorModal/RelateVendorModal'
import { formatPhoneNumbers } from '../../utils/helpers'

import {
  LOADING_STATUS,
  DOCTOR,
  FACILITY,
  NO_SKIP_ON_IMPORT_VENDOR_LIST,
} from '../../constants'
import {
  selectFacilityById,
  fetchFacilityById,
  updateFacility,
  getRelatedFacilities,
  selectRelatedFacilities,
  getRelatedDoctors,
  selectRelatedDoctors,
  deleteFacility,
  fetchFacilityProfileById,
  selectFacilityProfileById,
  linkDoctor,
  unlinkDoctor,
  selectRelatedFacilityProfiles,
  getRelatedFacilityProfileFacilities,
} from '../../state/modules/facilities'

import { getVendors, selectAllVendors } from '../../state/modules/vendors'
import {
  addVendorFacility,
  removeVendorFacility,
  updateVendorFacility,
} from '../../state/modules/vendorFacilities'

import { createMerger } from '../../state/modules/mergers'

import { updateDoctor } from '../../state/modules/doctors'
import useDispatchHttp from '../../hooks/dispatchHttpHandler'
import {
  updateCreateFacilitySchema,
  updateCreateRelatedFacilityProfileSchema,
} from '../../schema/updateCreateFacility'
import { createUpdateDoctorSchema } from '../../schema/createUpdateDoctor'

import {
  facilityTableColumns,
  doctorTableColumns,
  initialFacilityValues,
} from '../../data'
import { routePaths } from '../../utils/routes'
import { useUser } from '../../providers/UserProvider'

const Facility = ({
  id,
  relateFacility,
  relateTreatmentFacility,
  removeRelation,
  removeTreatmentRelation,
  hasRelation,
  hasRelationTreatmentFacility,
  tableRef,
  isMrdCase,
  exceptionId,
  isActionDisabled,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { isUserActionAllowed } = useUser()
  const { id: currentUserID } = useSelector(state => state.auth)
  const [userID, setUserID] = useState(null)
  const [isFacilityRemoved, setFacilityRemoved] = useState(false)
  const [isFacilityLoading, setFacilityLoading] = useState(false)
  const [isRelatedFacilitiesLoading, setRelatedFacilitiesLoading] = useState(
    false
  )
  const [isRelatedDoctorsLoading, setRelatedDoctorsLoading] = useState(false)
  const [isFacilityProfileLoading, setFacilityProfileLoading] = useState(false)
  const [
    isRelatedFacilityProfilesLoading,
    setRelatedFacilityProfilesLoading,
  ] = useState(false)
  const { status } = useSelector(state => state.facilities)
  const facilityData = useSelector(state => selectFacilityById(state, id))
  const vendorsData = useSelector(selectAllVendors)
  const allRelatedFacilities = useSelector(selectRelatedFacilities)
  const allRelatedDoctors = useSelector(selectRelatedDoctors)
  const allRelatedFacilityProfiles = useSelector(selectRelatedFacilityProfiles)
  const { count: doctorsCount } = useSelector(
    state => state.facilities.related_doctors
  )
  const { count: facilityCount } = useSelector(
    state => state.facilities.related_facilities
  )
  const { count: relatedFacilityProfilesCount } = useSelector(
    state => state.facilities.related_facility_profiles
  )

  const facilityProfileData = useSelector(state =>
    selectFacilityProfileById(state, facilityData?.facility_profile)
  )
  const dispatchHttp = useDispatchHttp()

  const handleFacilityUpdate = useCallback(
    async (facilityId, data) => {
      await dispatchHttp(
        updateFacility({
          facilityId,
          data: {
            ...data,
            phone_numbers: formatPhoneNumbers(data.phone_numbers),
            fax_numbers: formatPhoneNumbers(data.fax_numbers),
            npi: data.npi || null,
            taxonomy_group_name: data.taxonomy_group_name || null,
          },
        }),
        'Facility has been successfully updated'
      )
      await dispatchHttp(fetchFacilityById(id))
      await dispatchHttp(getRelatedFacilities({ data: id }))
    },
    [dispatchHttp, id]
  )
  const handleRelatedFacilityProfileUpdate = useCallback(
    async (facilityId, data) => {
      await dispatchHttp(
        updateFacility({
          facilityId,
          data,
        }),
        'Related Facility Profile has been successfully updated'
      )
      await dispatchHttp(
        getRelatedFacilityProfileFacilities({
          data: facilityData?.facility_profile,
          exclude_id: id,
        })
      )
    },
    [dispatchHttp, id, facilityData?.facility_profile]
  )

  const handleDoctorUpdate = useCallback(
    async (doctorId, data) => {
      await dispatchHttp(
        updateDoctor({
          id: doctorId,
          data,
        }),
        'Doctor has been successfully updated'
      )
      await dispatchHttp(getRelatedDoctors({ data: id }))
    },
    [dispatchHttp, id]
  )

  const handleDelete = useCallback(
    async facilityId => {
      try {
        setFacilityRemoved(true)
        await dispatchHttp(
          deleteFacility(facilityId),
          'Facility has been successfully deleted'
        )

        history.push('/search')
      } catch (e) {
        setFacilityRemoved(false)
      }
    },

    [dispatchHttp, history]
  )

  const handleCreateMerger = useCallback(
    async facilityId => {
      const resp = await dispatchHttp(
        createMerger({
          mrd_facility: facilityId,
        }),
        'Facility Profile Merger successfully created'
      )

      if (isUserActionAllowed('view_merger')) {
        history.push(`/facility-profile-mergers/${resp.id}`)
      }
    },
    // eslint-disable-next-line
    [dispatchHttp, history]
  )

  const handleLinkDoctor = useCallback(
    async data => {
      await dispatchHttp(
        linkDoctor({ id, data }),
        'Doctor has been successfully related'
      )
      await dispatchHttp(getRelatedDoctors({ data: id }))
    },
    [dispatchHttp, id]
  )

  const handleUnlinkDoctor = useCallback(
    async data => {
      await dispatchHttp(
        unlinkDoctor({ id, data }),
        'Relation has been successfully removed'
      )
      await dispatchHttp(getRelatedDoctors({ data: id }))
    },
    [dispatchHttp, id]
  )

  const callGetFacilityById = useCallback(async () => {
    setFacilityLoading(true)
    await dispatchHttp(fetchFacilityById(id))
    setFacilityLoading(false)
  }, [dispatchHttp, id])

  const removeVendorRelation = async data => {
    await dispatchHttp(
      removeVendorFacility(data.id),
      'Facility has been successfully removed from the vendor'
    )
  }

  const addVendorRelation = async data => {
    let preparedData = {
      facility_id: id,
      vendor_id: data.vendor_id,
      organization_id: data.organization_id,
      organization_name: data.organization_name,
    }

    if (!NO_SKIP_ON_IMPORT_VENDOR_LIST.includes(data.vendor__name)) {
      preparedData = {
        ...preparedData,
        skip_on_import: data.skip_on_import,
      }
    }

    await dispatchHttp(
      addVendorFacility({ data: preparedData }),
      'Facility has been successfully added to a vendor'
    )
  }

  const updateVendorRelation = async data => {
    let updatedData = {
      vendor_id: id,
      organization_id: data.organization_id,
      organization_name: data.organization_name,
    }

    if (!NO_SKIP_ON_IMPORT_VENDOR_LIST.includes(data.vendor__name)) {
      updatedData = {
        ...updatedData,
        skip_on_import: data.skip_on_import,
      }
    }

    dispatchHttp(
      updateVendorFacility({ id: data.id, data: updatedData }),
      'Facility relation updated successfully'
    )
  }

  const preparedDataToSendRequests = data => {
    const dataToDelete = facilityData.vendor_relations
      .filter(relation => {
        const isRelationExistInUpdatedData = data.vendors.some(
          element => element.id === relation.id
        )

        return !isRelationExistInUpdatedData
      })
      .map(element => ({ ...element, action: 'delete' }))

    const dataToAdd = data.vendors
      .filter(element => !element.id)
      .map(element => ({ ...element, action: 'add' }))

    const dataToUpdate = data.vendors
      .filter(relation =>
        facilityData.vendor_relations.find(
          element =>
            (element.id &&
              element.id === relation.id &&
              element.skip_on_import !== relation.skip_on_import) ||
            (element.id &&
              element.id === relation.id &&
              element.organization_id !== relation.organization_id) ||
            (element.id &&
              element.id === relation.id &&
              element.organization_name !== relation.organization_name)
        )
      )
      .map(element => ({ ...element, action: 'update' }))

    const dataToSendRequests = { dataToDelete, dataToAdd, dataToUpdate }
    return dataToSendRequests
  }

  const handleVendorRelation = useCallback(
    async data => {
      const preparedData = preparedDataToSendRequests(data)
      await Promise.all(
        preparedData.dataToDelete.map(async vendorRelation => {
          await removeVendorRelation(vendorRelation)
        })
      )

      await Promise.all(
        preparedData.dataToUpdate.map(async vendorRelation => {
          await updateVendorRelation(vendorRelation)
        })
      )

      await Promise.all(
        preparedData.dataToAdd.map(async vendorRelation => {
          await addVendorRelation(vendorRelation)
        })
      )

      callGetFacilityById()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchHttp, id, facilityData?.vendor_relations]
  )

  const openHistory = useCallback(() => {
    history.push(
      routePaths.openEntityHistory(
        'facility',
        facilityData?.id,
        userID,
        'all',
        'facility'
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityData, history])

  const initialValues = {
    ...initialFacilityValues,
    ...facilityData,
  }
  const initialTouched = {
    addresses: initialValues.addresses.map(() => ({
      type: true,
    })),
    phone_numbers: initialValues.phone_numbers.map(() => ({
      type: true,
    })),
    fax_numbers: initialValues.fax_numbers.map(() => ({
      type: true,
    })),
    emails: initialValues.emails.map(() => ({
      type: true,
    })),
  }

  const menuOptions = useMemo(
    () => [
      {
        content: (
          <MedicalEntityModal
            title="Edit Facility"
            form={
              <FacilityForm
                initialValues={initialValues}
                initialTouched={initialTouched}
                onSubmit={handleFacilityUpdate}
                validationSchema={updateCreateFacilitySchema}
                action="edit"
                entityId={id}
              />
            }
            trigger={<div>Edit</div>}
          />
        ),
        hide: !isUserActionAllowed('change_facility'),
        key: 'edit',
      },
      {
        content: (
          <div
            onClick={openHistory}
            role="button"
            onKeyPress={openHistory}
            tabIndex="0"
          >
            View History
          </div>
        ),
        key: 'history',
        hide: !isUserActionAllowed('view_version'),
      },
      {
        content: (
          <RelateVendorModal
            title="Manage Vendors"
            initialData={facilityData?.vendor_relations || []}
            vendorsList={vendorsData}
            onSubmit={data => handleVendorRelation(data)}
            closeAfterSubmit
            trigger={<div>Manage Vendors</div>}
          />
        ),
        hide: !isUserActionAllowed('can_manage_vendor_relations'),
        key: 'relate-vendor',
      },
      {
        content: 'Create Facility Profile Merger',
        key: 'merger',
        hide: !isUserActionAllowed('add_merger'),
        onClick: () => handleCreateMerger(id),
      },
      {
        content: 'Remove',
        key: 'remove',
        hide: !isUserActionAllowed('delete_facility'),
        danger: true,
        onClick: () =>
          showConfirmDialog({
            title: `Do you want to delete ${facilityData.name}?`,
            data: id,
            handleConfirm: handleDelete,
          }),
      },
    ],
    // eslint-disable-next-line
    [
      facilityData,
      vendorsData,
      handleDelete,
      handleFacilityUpdate,
      handleCreateMerger,
      id,
      openHistory,
      isUserActionAllowed,
    ]
  )

  const callGetVendors = useCallback(async () => {
    await dispatchHttp(getVendors({ skipPagination: true }))
  }, [dispatchHttp])

  const callGetRelatedFacilities = useCallback(async () => {
    setRelatedFacilitiesLoading(true)
    await dispatchHttp(getRelatedFacilities({ data: id }))
    setRelatedFacilitiesLoading(false)
  }, [dispatchHttp, id])

  const callGetRelatedDoctors = useCallback(async () => {
    setRelatedDoctorsLoading(true)
    await dispatchHttp(getRelatedDoctors({ data: id }))
    setRelatedDoctorsLoading(false)
  }, [dispatchHttp, id])

  const callGetFacilityProfileById = useCallback(async () => {
    setFacilityProfileLoading(true)
    await dispatchHttp(fetchFacilityProfileById(facilityData?.facility_profile))
    setFacilityProfileLoading(false)
  }, [dispatchHttp, facilityData?.facility_profile])

  const callGetRelatedFacilityProfiles = useCallback(async () => {
    setRelatedFacilityProfilesLoading(true)
    await dispatchHttp(
      getRelatedFacilityProfileFacilities({
        data: facilityData?.facility_profile,
        exclude_id: id,
      })
    )
    setRelatedFacilityProfilesLoading(false)
  }, [dispatchHttp, id, facilityData?.facility_profile])

  useEffect(() => {
    if (!facilityData && !isFacilityRemoved) {
      callGetFacilityById()
    }
  }, [callGetFacilityById, facilityData, isFacilityRemoved])

  useEffect(() => {
    callGetVendors()
  }, [callGetVendors])

  useEffect(() => {
    callGetRelatedFacilities()
  }, [callGetRelatedFacilities])

  useEffect(() => {
    if (isUserActionAllowed('view_doctor')) {
      callGetRelatedDoctors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetRelatedDoctors])

  useEffect(() => {
    if (
      facilityData?.facility_profile &&
      isUserActionAllowed('view_facilityprofile')
    ) {
      callGetFacilityProfileById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetFacilityProfileById, facilityData?.facility_profile])

  useEffect(() => {
    if (facilityData?.facility_profile) {
      callGetRelatedFacilityProfiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetRelatedFacilityProfiles, facilityData?.facility_profile])

  const openClusterById = () => {
    history.push(`/clusters/${facilityData?.cluster}/`)
  }

  const facilityColumns = isUserActionAllowed('view_doctor')
    ? facilityTableColumns
    : facilityTableColumns.map(item => {
        if (item.key === 'related_doctors') {
          return { ...item, link: null }
        }
        return { ...item }
      })

  useEffect(() => {
    if (
      location?.data?.autoscroll &&
      !isFacilityLoading &&
      !isRelatedFacilitiesLoading &&
      !isRelatedFacilityProfilesLoading &&
      !isRelatedDoctorsLoading &&
      !isFacilityProfileLoading &&
      tableRef?.current
    ) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [
    location,
    isFacilityLoading,
    isRelatedFacilitiesLoading,
    isRelatedFacilityProfilesLoading,
    isRelatedDoctorsLoading,
    isFacilityProfileLoading,
    tableRef,
  ])

  useEffect(() => {
    if (!isUserActionAllowed('view_user')) {
      setUserID(currentUserID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp])

  return (
    <Spin size="large" spinning={status === LOADING_STATUS}>
      <InfoCard
        data={facilityData}
        loading={status === LOADING_STATUS}
        menu={menuOptions}
        onRelateException={relateFacility}
        onRelateTreatmentFacility={relateTreatmentFacility}
        onRemoveRelation={removeRelation}
        onRemoveTreatmentRelation={removeTreatmentRelation}
        hasRelation={hasRelation}
        hasRelationTreatmentFacility={hasRelationTreatmentFacility}
        isMrdCase={isMrdCase}
        exceptionId={exceptionId}
        isActionDisabled={isActionDisabled}
        hideMenuOption={menuOptions.filter(item => !item.hide)}
      />
      {isUserActionAllowed('view_facilityprofile') &&
        facilityData?.facility_profile && (
          <FacilityProfileCard data={facilityProfileData} loading={status} />
        )}
      {isUserActionAllowed('view_facility') &&
        allRelatedFacilityProfiles.length > 0 && (
          <SearchTable
            onLoadData={getRelatedFacilityProfileFacilities}
            tableTitle="Related to Facility Profile"
            columns={facilityColumns}
            totalData={allRelatedFacilityProfiles}
            formData={facilityData?.facility_profile}
            loadingStatus={status}
            totalCount={relatedFacilityProfilesCount}
            excludeId={id}
            editForm={
              isUserActionAllowed('change_facility') && (
                <FacilityForm
                  onSubmit={handleRelatedFacilityProfileUpdate}
                  validationSchema={updateCreateRelatedFacilityProfileSchema}
                />
              )
            }
          />
        )}

      {facilityData?.cluster && (
        <SearchTable
          onLoadData={getRelatedFacilities}
          tableTitle="Related Administrative Facilities"
          columns={facilityColumns}
          totalData={allRelatedFacilities}
          totalCount={facilityCount}
          formData={id}
          entity={FACILITY}
          loadingStatus={status}
          editForm={
            isUserActionAllowed('change_facility') && (
              <FacilityForm
                onSubmit={handleFacilityUpdate}
                validationSchema={updateCreateFacilitySchema}
              />
            )
          }
          titleAction={
            isUserActionAllowed('view_facilitycluster') && {
              text: 'View Cluster',
              action: openClusterById,
              icon: <LinkOutlined />,
              hide: !facilityData?.cluster,
            }
          }
        />
      )}
      {isUserActionAllowed('view_doctor') && (
        <SearchTable
          tableRef={tableRef}
          onLoadData={getRelatedDoctors}
          tableTitle={
            <Row justify="space-between" align="middle">
              <Col>Related Doctors {!!doctorsCount && doctorsCount}</Col>
              {isUserActionAllowed('manage_relations_facility') && (
                <Col>
                  <RelateDoctorModal
                    title="Relate Doctor to Facility"
                    entity={DOCTOR}
                    onSubmit={handleLinkDoctor}
                    closeAfterSubmit
                    trigger={
                      <Button type="text" size="large">
                        <Typography.Text type="secondary">
                          <Space>
                            <LinkOutlined /> Relate Doctor
                          </Space>
                        </Typography.Text>
                      </Button>
                    }
                  />
                </Col>
              )}
            </Row>
          }
          columns={doctorTableColumns}
          totalData={allRelatedDoctors}
          formData={id}
          totalCount={doctorsCount}
          hideTotalCount
          entity={DOCTOR}
          loadingStatus={status}
          editForm={
            isUserActionAllowed('change_doctor') && (
              <DoctorForm
                onSubmit={handleDoctorUpdate}
                validationSchema={createUpdateDoctorSchema}
                action="edit"
              />
            )
          }
          rowDeletion={
            isUserActionAllowed('manage_relations_facility') && {
              tooltip: 'Remove Relation',
              confirmMsg: 'Are you sure you want to remove the relation?',
              okText: 'Yes',
              onConfirm: handleUnlinkDoctor,
            }
          }
        />
      )}
    </Spin>
  )
}

export default memo(Facility)
