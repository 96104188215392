import React from 'react'
import { Form, Select } from 'antd'
import PropTypes from 'prop-types'
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons'

// eslint-disable-next-line react/display-name
const FieldWrapper = Field => ({
  form: { touched, errors, setFieldValue, setFieldTouched },
  suffix,
  size,
  field,
  form,
  label,
  options,
  error,
  allowClear,
  multiple,
  required,
  ...props
}) => {
  const { name, value } = field
  const { loading, disabled } = props

  const handleChange = data => {
    setFieldValue(name, data)
  }

  const handleClear = () => {
    setFieldValue(name, null)
  }

  const handleBlur = () => setFieldTouched(name, true)

  return (
    <Form.Item
      label={label}
      validateStatus={
        (errors[name] && touched[name]) || error ? 'error' : 'success'
      }
      help={
        (errors[name] && touched[name]) || error ? errors[name] || error : null
      }
      required={required}
    >
      <Field
        {...props}
        value={loading ? 'Loading...' : value}
        options={options}
        size={size}
        allowClear={!loading && !suffix && allowClear}
        suffixIcon={
          loading ? (
            <LoadingOutlined />
          ) : (
            suffix || <CaretDownOutlined className="select-icon--default" />
          )
        }
        onChange={handleChange}
        onClear={handleClear}
        onBlur={handleBlur}
        multiple={multiple}
        mode={multiple && 'multiple'}
        loading={loading}
        disabled={loading || disabled}
      />
    </Form.Item>
  )
}

const SelectField = FieldWrapper(Select)
SelectField.displayName = 'SelectField'

SelectField.propTypes = {
  icon: PropTypes.element,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.bool),
      PropTypes.objectOf(PropTypes.array),
      PropTypes.array,
    ]),
    errors: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    ),
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  type: PropTypes.oneOf(['password', 'text', 'search', 'email']),
  size: PropTypes.oneOf(['large', 'small', 'medium']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
  required: PropTypes.bool,
}

SelectField.defaultProps = {
  icon: null,
  field: PropTypes.shape({
    value: '',
    onChange: () => null,
    onBlur: () => null,
  }),
  label: '',
  type: 'text',
  size: 'medium',
  required: false,
}

export default SelectField
