export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_FACILITY_PROFILE_PAGE_SIZE = 15

export const DEFAULT_PAGE_SIZE_OPTIONS = ['10', '20', '50', '100']
export const DEFAULT_FACILITY_PROFILE_PAGE_SIZE_OPTIONS = [
  '15',
  '20',
  '50',
  '100',
]

export const DEFAULT_MERGERS_PAGE_SIZE_OPTIONS = [
  '10',
  '20',
  '50',
  '100',
  '3000',
]
