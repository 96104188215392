import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import HealthNetworkService from '../../../services/HealthNetworkService'

export const getHealthNetworks = createAsyncThunk(
  'healthNetwork/getHealthNetworks',
  async data => {
    const response = await getPagination(
      data,
      HealthNetworkService.getHealthNetworkList.bind(HealthNetworkService)
    )

    return { ...response }
  }
)

export const fetchHealthNetworkById = createAsyncThunk(
  'healthNetwork/fetchHealthNetworkById',
  async id => {
    const response = await HealthNetworkService.getHealthNetworkById(id)

    return response
  }
)

export const fetchHealthNetworkFacilities = createAsyncThunk(
  'healthNetwork/fetchHealthNetworkFacilities',
  async data => {
    const response = await getPagination(
      data,
      HealthNetworkService.getHealthNetworkFacilities.bind(HealthNetworkService)
    )

    return { ...response }
  }
)

export const fetchHealthNetworkDoctors = createAsyncThunk(
  'healthNetwork/fetchHealthNetworkDoctors',
  async data => {
    const response = await getPagination(
      data,
      HealthNetworkService.getHealthNetworkDoctors.bind(HealthNetworkService)
    )

    return { ...response }
  }
)

export const fetchHealthNetworkSoleProprietors = createAsyncThunk(
  'healthNetwork/fetchHealthNetworkSoleProprietors',
  async data => {
    const response = await getPagination(
      data,
      HealthNetworkService.getHealthNetworkSoleProprietors.bind(
        HealthNetworkService
      )
    )

    return { ...response }
  }
)

export const createHealthNetwork = createAsyncThunk(
  'healthNetwork/createHealthNetwork',
  data => {
    const response = HealthNetworkService.createHealthNetwork(data)

    return response
  }
)

export const deleteHealthNetwork = createAsyncThunk(
  'healthNetwork/deleteHealthNetwork',
  async id => {
    await HealthNetworkService.removeHealthNetwork(id)

    return id
  }
)

export const updateHealthNetwork = createAsyncThunk(
  'healthNetwork/updateHealthNetwork',
  async ({ id, data }) => {
    const response = await HealthNetworkService.updateHealthNetwork(id, data)

    return response
  }
)

export const removeHealthNetworkFacility = createAsyncThunk(
  'healthNetwork/removeHealthNetworkFacility',
  async ({ id, data }) => {
    const response = await HealthNetworkService.removeHealthNetworkFacility(
      id,
      data
    )

    return response
  }
)

export const addHealthNetworkFacility = createAsyncThunk(
  'healthNetwork/addHealthNetworkFacility',
  async ({ id, data }) => {
    const response = await HealthNetworkService.addHealthNetworkFacility(
      id,
      data
    )

    return response
  }
)

export const checkHealthNetworkFacility = createAsyncThunk(
  'healthNetwork/checkHealthNetworkFacility',
  async data => {
    const response = await HealthNetworkService.checkHealthNetworkFacility(data)

    return response
  }
)

export const removeHealthNetworkDoctor = createAsyncThunk(
  'healthNetwork/removeHealthNetworkDoctor',
  async ({ id, data }) => {
    const response = await HealthNetworkService.removeHealthNetworkDoctor(
      id,
      data
    )

    return response
  }
)

export const addHealthNetworkDoctor = createAsyncThunk(
  'healthNetwork/addHealthNetworkDoctor',
  async ({ id, data }) => {
    const response = await HealthNetworkService.addHealthNetworkDoctor(id, data)

    return response
  }
)

export const resetState = createAction('healthNetwork/resetState')
