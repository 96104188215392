import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import CreateFacilityForm from '../Forms/FacilityForm/Form.container'
import { initialFacilityValues } from '../../../data'
import { updateCreateFacilitySchema } from '../../../schema/updateCreateFacility'
import MedicalEntityModal from '../Modals/MedicalEntityModal'
import { createFacility } from '../../../state/modules/facilities'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { formatPhoneNumbers } from '../../../utils/helpers'

const initialFacilityCreateValues = {
  ...initialFacilityValues,
  phone_numbers: initialFacilityValues.phone_numbers.map(item => ({
    ...item,
    verified: true,
  })),
}

const CreateFacilityModal = ({
  isMrdCase,
  isCreateNewFacilityEnabled,
  currentSearch,
  handleRelateFacility,
  hasRelatedFacility,
  hasRelatedTreatmentFacility,
}) => {
  const dispatchHttp = useDispatchHttp()
  const [createItemValues, setCreateItemValues] = useState({})

  useEffect(() => {
    const info = {}
    const isFeedbackLoop = currentSearch?.suggestion_type === 'contact_form'
    const addresses = currentSearch?.all_addresses || []
    if (addresses.length) {
      info.addresses = [{ ...currentSearch.primary_search, type: 'LOC' }]
    }

    if (currentSearch?.primary_search?.phone) {
      info.phone_numbers = [
        {
          number: currentSearch?.primary_search?.phone,
          type: 'LOC',
          phone_extension: '',
          verified: true,
        },
      ]
    }

    if (currentSearch?.primary_search?.fax) {
      info.fax_numbers = [
        {
          number: currentSearch?.primary_search?.fax,
          type: 'LOC',
          verified: true,
        },
      ]
    }

    if (
      currentSearch?.suggestion_type === 'facility' ||
      currentSearch?.suggestion_type === 'mrd_facility' ||
      currentSearch?.suggestion_type === 'treatment_facility' ||
      (isFeedbackLoop && currentSearch?.form_type === 'facility') ||
      (isFeedbackLoop && currentSearch?.form_type === 'treatment')
    ) {
      info.name = currentSearch?.primary_search?.last_name || ''
    }

    if (
      currentSearch?.suggestion_type === 'doctor' ||
      (isFeedbackLoop && currentSearch?.form_type === 'doctor')
    ) {
      info.first_name = currentSearch?.primary_search?.first_name || ''
      info.last_name = currentSearch?.primary_search?.last_name || ''
    }

    setCreateItemValues(info)
  }, [currentSearch])

  const handleCreateFacility = async (_, data) => {
    const isFeedbackLoop = currentSearch?.suggestion_type === 'contact_form'
    const addresses = data?.addresses.map(address => ({
      city: address.city,
      state: address.state,
      zip_code: address.zip_code,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      type: address.type,
    }))
    const { type, ...rest } = data

    const resp = await dispatchHttp(
      createFacility({
        ...rest,
        addresses,
        phone_numbers: formatPhoneNumbers(data.phone_numbers),
        fax_numbers: formatPhoneNumbers(data.fax_numbers),
        reprocess_cases: false,
      }),
      'Facility has been successfully created'
    )

    let facilityType = 'mrd'
    if (isMrdCase) {
      if (hasRelatedFacility && !hasRelatedTreatmentFacility) {
        facilityType = 'treatment'
      }

      if (
        (!hasRelatedFacility &&
          !hasRelatedTreatmentFacility &&
          currentSearch?.suggestion_type === 'treatment_facility') ||
        (!hasRelatedFacility &&
          !hasRelatedTreatmentFacility &&
          isFeedbackLoop &&
          currentSearch?.form_type === 'treatment')
      ) {
        facilityType = 'treatment'
      }
    }
    await handleRelateFacility(resp.id, facilityType)
  }
  return (
    <MedicalEntityModal
      title={
        // eslint-disable-next-line no-nested-ternary
        !isMrdCase
          ? 'Create Facility'
          : currentSearch?.suggestion_type === 'mrd_facility' ||
            (currentSearch?.suggestion_type === 'contact_form' &&
              currentSearch?.form_type === 'facility')
          ? 'Create MRD Facility'
          : 'Create Treatment Facility'
      }
      trigger={
        <Button ghost type="primary" disabled={!isCreateNewFacilityEnabled}>
          Create New Facility
        </Button>
      }
      form={
        <CreateFacilityForm
          initialValues={{
            ...initialFacilityCreateValues,
            ...createItemValues,
          }}
          onSubmit={handleCreateFacility}
          validationSchema={updateCreateFacilitySchema}
          action="create"
          submitButtonText="Create"
        />
      }
    />
  )
}

export default CreateFacilityModal
