import React from 'react'
import { Row, Col, Divider, Form, Button } from 'antd'
import { Field, FieldArray, FastField } from 'formik'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import TextField from '../../../shared/TextField'
import SelectField from '../../../shared/SelectField'
import { Label } from './Label'

import { stateOptions, faxOptions } from '../../../../data'

export const Addresses = ({ errorMessage, values, original }) => {
  const addressesCount = values.addresses.length

  return (
    <FieldArray
      name="addresses"
      render={({ name, push, remove }) => (
        <>
          <Divider orientation="left" className="form-divider">
            Address
          </Divider>
          {values.addresses.map((field, index) => {
            const fieldName = `addresses[${index}]`
            const disableDelete = addressesCount <= 1
            const origin = original?.addresses[index]

            return (
              <>
                {index > 0 ? (
                  <Divider
                    style={{ marginTop: '12px', marginBottom: '12px' }}
                  />
                ) : null}
                <Row gutter={12}>
                  <Col span={12}>
                    <FastField
                      component={SelectField}
                      label={
                        <Label label="Select type" original={origin?.type} />
                      }
                      name={`${name}[${index}].type`}
                      error={errorMessage(`${name}[${index}].type`)}
                      options={faxOptions}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={8}>
                    <Field
                      component={SelectField}
                      label={<Label label="State" original={origin?.state} />}
                      size="medium"
                      name={`${fieldName}.state`}
                      placeholder="Select Here"
                      options={stateOptions}
                      allowClear
                      error={errorMessage(`${fieldName}.state`)}
                      required
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      component={TextField}
                      label={<Label label="City" original={origin?.city} />}
                      size="medium"
                      name={`${fieldName}.city`}
                      placeholder="Type Here"
                      error={errorMessage(`${fieldName}.city`)}
                      required
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      component={TextField}
                      label={
                        <Label label="Zip Code" original={origin?.zip_code} />
                      }
                      size="medium"
                      name={`${fieldName}.zip_code`}
                      placeholder="Type Here"
                      error={errorMessage(`${fieldName}.zip_code`)}
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <Field
                      component={TextField}
                      label={
                        <Label
                          label="Address Line 1"
                          original={origin?.address_line_1}
                        />
                      }
                      size="medium"
                      name={`${fieldName}.address_line_1`}
                      placeholder="Type Here"
                      error={errorMessage(`${fieldName}.address_line_1`)}
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <Field
                      component={TextField}
                      label={
                        <Label
                          label="Address Line 2"
                          original={origin?.address_line_2}
                        />
                      }
                      size="medium"
                      name={`${fieldName}.address_line_2`}
                      placeholder="Type Here"
                      error={errorMessage(`${fieldName}.address_line_2`)}
                    />
                  </Col>
                </Row>
                {addressesCount > 1 ? (
                  <Row justify="end">
                    <Col>
                      <Button
                        disabled={disableDelete}
                        icon={<DeleteOutlined />}
                        onClick={() => remove(index)}
                      />
                    </Col>
                  </Row>
                ) : null}
              </>
            )
          })}

          <Form.Item style={{ marginTop: '12px' }}>
            <Button
              type="dashed"
              onClick={() => {
                push({
                  address_line_1: '',
                  city: '',
                  state: null,
                  type: 'ADD',
                  zip_code: '',
                })
              }}
              block
              icon={<PlusOutlined />}
            >
              Add Additional Address
            </Button>
          </Form.Item>
        </>
      )}
    />
  )
}
