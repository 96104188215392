export const DATE_FORMAT = {
  default: 'ddd, MMM D, YYYY',
  day_month_year: 'DD/MM/YYYY',
  month_day_year: 'MM/DD/YYYY',
  year_month_day: 'YYYY-MM-DD',
}

export const TIME_FORMAT = {
  half: 'h:mm a',
  full: 'HH:mm',
}
