import React from 'react'
import { useHistory } from 'react-router-dom'
import { Result, Button } from 'antd'
import { useUser } from '../../../providers/UserProvider'
import { logoutUser } from '../../../state/modules/auth'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

const NotFound = () => {
  const history = useHistory()
  const dispatchHttp = useDispatchHttp()
  const { getAllowedRoute } = useUser()
  const handleLogout = () => {
    dispatchHttp(logoutUser())
  }

  const allowedRoute = getAllowedRoute()
  const subTitle = allowedRoute
    ? "Sorry, you don't have access to view this page"
    : "Sorry, you don't have any permissions"

  return (
    <div className="error-layout">
      <Result
        status="403"
        title="403"
        subTitle={subTitle}
        extra={
          allowedRoute ? (
            <Button type="primary" onClick={() => history.push('/')}>
              Back Home
            </Button>
          ) : (
            <Button type="primary" onClick={handleLogout}>
              Logout
            </Button>
          )
        }
      />
    </div>
  )
}
export default NotFound
