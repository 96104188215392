import { createSlice } from '@reduxjs/toolkit'
import {
  resetState,
  searchCaseDetailsFacilities,
  searchCaseDetailsDoctors,
} from './actions'
import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'
import { facilityAdapter as facility } from '../facilities/reducer'
import { doctorAdapter as doctor } from '../doctors/reducer'

export const adapters = {
  facility,
  doctor,
}

const initialState = {
  facility: facility.getInitialState({
    count: 0,
  }),
  doctor: doctor.getInitialState({ count: 0 }),
  facilityStatus: IDLE_STATUS,
  doctorStatus: IDLE_STATUS,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.facility = facility.getInitialState({
        count: 0,
      })
      state.doctor = doctor.getInitialState({ count: 0 })
      state.facilityStatus = IDLE_STATUS
      state.doctorStatus = IDLE_STATUS
    })

    builder.addCase(searchCaseDetailsFacilities.pending, state => {
      state.facilityStatus = LOADING_STATUS
    })
    builder.addCase(
      searchCaseDetailsFacilities.fulfilled,
      (state, { payload }) => {
        state.facility.count = payload.count
        facility.setAll(state.facility, payload.results)
        state.facilityStatus = SUCCESS_STATUS
      }
    )
    builder.addCase(searchCaseDetailsFacilities.rejected, state => {
      state.facilityStatus = FAILURE_STATUS
    })

    builder.addCase(searchCaseDetailsDoctors.pending, state => {
      state.doctorStatus = LOADING_STATUS
    })
    builder.addCase(
      searchCaseDetailsDoctors.fulfilled,
      (state, { payload }) => {
        state.doctor.count = payload.count
        doctor.setAll(state.doctor, payload.results)
        state.doctorStatus = SUCCESS_STATUS
      }
    )
    builder.addCase(searchCaseDetailsDoctors.rejected, state => {
      state.doctorStatus = FAILURE_STATUS
    })
  },
})

const { reducer } = searchSlice

export default reducer
