import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  Form,
  Radio,
  DatePicker,
  Spin,
  Button,
  Popover,
  Badge,
  Select,
  Input,
} from 'antd'
import { FilterOutlined, CloseOutlined } from '@ant-design/icons'
import { Field, Formik } from 'formik'
import moment from 'moment'
import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import useDebounce from '../../../hooks/useDebounce'

import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import {
  getExceptionList,
  selectAllExceptions,
  resetState as resetExceptionsState,
} from '../../../state/modules/exceptions'

import { DATE_FORMAT, EXCEPTION, EXCEPTION_MAPPING } from '../../../constants'
import { exceptionsColumns } from '../../../data'
import { cmsFilterSchema } from '../../../schema/cmsFilter'
import NumberField from '../../shared/NumberField'

const Exceptions = ({ location }) => {
  const [searchParams, setSearchParams] = useState({
    status: 4,
    sort: '-importance',
    ...(location?.filter || {}),
  })
  const [isFilterVisible, setFilterVisible] = useState(false)
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const exceptionsList = useSelector(selectAllExceptions)
  const { count, status } = useSelector(state => state.exceptions)

  const handleSort = ({ target: { value, name } }) => {
    const sort = { [name]: value }

    setSearchParams(state => ({ ...state, ...sort }))
    dispatchHttp(getExceptionList({ ...searchParams, ...sort }))
  }

  const handleFilterByCompleted = value => {
    const { is_completed, ...rest } = searchParams
    const data = { ...rest }

    if (value === 'completed') data.is_completed = true
    if (value === 'uncompleted') data.is_completed = false
    if (value) setSearchParams(data)
    dispatchHttp(getExceptionList(data))
  }

  const handleFilterByReportedByRetriever = value => {
    const { reported_by_retriever, ...rest } = searchParams
    const data = { ...rest }

    if (value === 'reported_by_retriever') data.reported_by_retriever = true
    if (value === 'not_reported_by_retriever')
      data.reported_by_retriever = false
    if (value) setSearchParams(data)
    dispatchHttp(getExceptionList(data))
  }

  const handleFilterByReopened = value => {
    const { reopened, ...rest } = searchParams
    const data = { ...rest }

    if (value === 'reopened') data.reopened = true
    if (value === 'not_reopened') data.reopened = false
    if (value) setSearchParams(data)
    dispatchHttp(getExceptionList(data))
  }

  const handleFilterByInternal = value => {
    const { is_internal_retriever, ...rest } = searchParams
    const data = { ...rest }

    if (value === 'internal') data.is_internal_retriever = true
    if (value === 'not_internal') data.is_internal_retriever = false
    if (value) setSearchParams(data)
    dispatchHttp(getExceptionList(data))
  }

  const handleFilterByStatus = (value, option) => {
    const { key } = option
    setSearchParams(state => ({ ...state, status: key }))
    dispatchHttp(getExceptionList({ ...searchParams, status: key }))
  }

  const handleChangeCmsId = useDebounce(e => {
    const { value } = e.target
    const { cms_id, ...rest } = searchParams
    const data = { ...rest }
    if (value) {
      data.cms_id = value
    }
    setSearchParams(data)
    dispatchHttp(getExceptionList(data))
  }, 300)

  useEffect(() => {
    dispatchHttp(getExceptionList({ ...searchParams }))
    // eslint-disable-next-line
  }, [dispatchHttp])

  useEffect(
    () => () => {
      dispatch(resetExceptionsState())
    },
    [dispatch]
  )

  const filtersAppliedCount = () =>
    Object.entries(searchParams).filter(data => {
      const [key, value] = data
      return (key === 'facility_profile__id' || key === 'date_range') && value
    }).length

  const onSubmitFilters = data => {
    setSearchParams(state => ({ ...state, ...data }))
    dispatchHttp(getExceptionList({ ...searchParams, ...data }))
    setFilterVisible(false)
  }

  const onResetFilters = form => {
    const { cms_id, date_range, facility_profile__id, ...rest } = searchParams
    if (filtersAppliedCount()) {
      setSearchParams(rest)
      dispatchHttp(getExceptionList({ ...rest }))
    } else {
      form.setFieldValue('date_range', null)
      form.resetForm()
    }
    setFilterVisible(false)
  }

  const getDateRangeValues = values => {
    if (values.date_range) {
      return [
        moment(values?.date_range?.split(',')[0]),
        moment(values?.date_range?.split(',')[1]),
      ]
    }

    return []
  }

  const filters = (
    <Form
      layout="inline"
      className="sort-form"
      component="div"
      initialValues={location.filter}
      size="small"
    >
      <Row gutter={[16, 32]}>
        <Col>
          <Form.Item label="CMS Case ID" name="cms_id" size="small">
            <Input
              onChange={handleChangeCmsId}
              type="number"
              style={{ width: 100 }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Status" name="status" size="small">
            <Select
              defaultValue={searchParams.status}
              style={{ width: 140 }}
              onSelect={handleFilterByStatus}
            >
              {Object.values(EXCEPTION_MAPPING).map(({ name, index }) => (
                <Select.Option value={index} key={index}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="CMS Completed" name="completed" size="small">
            <Select
              defaultValue="all"
              style={{ width: 60 }}
              onChange={handleFilterByCompleted}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="completed">Yes</Select.Option>
              <Select.Option value="uncompleted">No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="Reported By Retriever"
            name="reported_by_retriever"
            size="small"
          >
            <Select
              defaultValue="all"
              style={{ width: 60 }}
              onChange={handleFilterByReportedByRetriever}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="reported_by_retriever">Yes</Select.Option>
              <Select.Option value="not_reported_by_retriever">
                No
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Reopened" name="reopened" size="small">
            <Select
              defaultValue="all"
              style={{ width: 60 }}
              onChange={handleFilterByReopened}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="reopened">Yes</Select.Option>
              <Select.Option value="not_reopened">No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Internal Case" name="internal" size="small">
            <Select
              defaultValue="all"
              style={{ width: 60 }}
              onChange={handleFilterByInternal}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="internal">Yes</Select.Option>
              <Select.Option value="not_internal">No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item wrapperCol={{ offset: 2, span: 24 }}>
            <Radio.Group
              defaultValue="-importance"
              layout="inline"
              name="sort"
              onChange={handleSort}
            >
              <Radio.Button value="-importance">Important First</Radio.Button>
              <Radio.Button value="-created_at">New First</Radio.Button>
              <Radio.Button value="created_at">Old First</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col>
          <Popover
            overlayClassName="cms-filter"
            content={
              <Row>
                <Formik
                  initialValues={{
                    facility_profile__id: searchParams.facility_profile__id,
                    cms_id: searchParams.cms_id,
                    date_range: searchParams.date_range,
                  }}
                  onSubmit={onSubmitFilters}
                  validationSchema={cmsFilterSchema}
                  enableReinitialize
                >
                  {({ handleSubmit, values, isSubmitting, dirty }) => (
                    <Form onFinish={handleSubmit}>
                      <Row gutter={24} className="filter-item">
                        <Col span={24}>
                          <Field
                            size="small"
                            name="date_range"
                            render={({ form }) => (
                              <DatePicker.RangePicker
                                placeholder={['Start Date', 'End Date']}
                                value={getDateRangeValues(values)}
                                format={DATE_FORMAT.year_month_day}
                                onChange={(dates, datesString) => {
                                  const date_range = datesString
                                    .filter(date => !!date)
                                    .toString()
                                  form.setFieldValue('date_range', date_range)
                                }}
                              />
                            )}
                          />
                        </Col>
                      </Row>

                      <Row gutter={24} className="filter-item">
                        <Col span={24}>
                          <Field
                            component={NumberField}
                            label="Facility Profile ID"
                            size="small"
                            name="facility_profile__id"
                          />
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={18}>
                          <Button
                            type="primary"
                            block
                            htmlType="submit"
                            disabled={!dirty}
                            loading={isSubmitting}
                            size="default"
                          >
                            Apply
                          </Button>
                        </Col>
                        <Col span={6}>
                          <Field
                            render={({ form }) => (
                              <Button
                                icon={<CloseOutlined />}
                                danger
                                block
                                onClick={() => {
                                  onResetFilters(form)
                                }}
                                disabled={
                                  !Object.values(values).filter(Boolean).length
                                }
                                size="default"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Row>
            }
            trigger="click"
            visible={isFilterVisible}
            placement="bottomRight"
            onVisibleChange={setFilterVisible}
          >
            <Badge
              count={filtersAppliedCount()}
              size="small"
              style={{
                marginRight: '6px',
              }}
            >
              <Button
                icon={<FilterOutlined />}
                style={{
                  marginRight: '6px',
                }}
              />
            </Badge>
          </Popover>
        </Col>
      </Row>
    </Form>
  )

  return (
    <Spin spinning={false}>
      <PageLayout
        title={
          <>
            CMS Cases
            <span className="badge">{count}</span>
          </>
        }
      >
        <Row
          gutter={[16, 32]}
          style={{
            background: '#fff',
            padding: '14px 12px',
            justifyContent: 'flex-end',
          }}
        >
          {filters}
        </Row>
        <Row gutter={[16, 32]} className="exceptions-table">
          <Table
            onLoadData={getExceptionList}
            columns={exceptionsColumns}
            linkData={{ sort: searchParams?.sort }}
            entity={EXCEPTION}
            totalData={exceptionsList}
            totalCount={count}
            loadingStatus={status}
            formData={searchParams}
          />
        </Row>
      </PageLayout>
    </Spin>
  )
}

export default Exceptions
