import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import RetrieverService from '../../../services/RetrieverService'

export const getRetrievers = createAsyncThunk(
  'facilities/getRetrievers',
  data =>
    getPagination(data, RetrieverService.getRetrievers.bind(RetrieverService))
)

export const getRetrieversTimeZones = createAsyncThunk(
  'retrievers/getRetrieversTimeZones',
  data => {
    const response = RetrieverService.getRetrieversTimeZones(data)

    return response
  }
)

export const updateRetriever = createAsyncThunk(
  'retrievers/updateRetriever',
  async ({ id, data }) => {
    const response = await RetrieverService.updateRetriever(id, data)

    return response
  }
)

export const resetState = createAction('retrievers/resetState')
