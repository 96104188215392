import React, { useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import DetailPageLayout from '../../layouts/DetailPageLayout'
import Doctor from '../../templates/Doctor'

import { selectDoctorById } from '../../../state/modules/doctors'
import { capitalizeEveryWord } from '../../../utils/helpers'

const DoctorPage = () => {
  const { id } = useParams()
  const doctorData = useSelector(state => selectDoctorById(state, id))

  const breadcrumbs = useMemo(
    () => [
      { name: 'Search', path: '/search' },
      {
        name: capitalizeEveryWord(
          `${doctorData?.first_name} ${doctorData?.last_name}`
        ),
        path: `/doctor/${id}/`,
      },
    ],
    [doctorData, id]
  )
  const tableRef = useRef(null)

  return (
    <DetailPageLayout breadcrumbs={breadcrumbs}>
      <Doctor id={id} tableRef={tableRef} />
    </DetailPageLayout>
  )
}

export default DoctorPage
