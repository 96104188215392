import Api from './index'
import { API_URL } from '../constants'

class Doctor extends Api {
  getDoctorById(id) {
    return this.get(`${id}/`)
  }

  updateDoctor(id, data) {
    return this.put(`${id}/`, { data })
  }

  createDoctor(data) {
    return this.post('/', { data })
  }

  deleteDoctor(id) {
    return this.del(`${id}/`)
  }

  getRelatedFacilities(id, params) {
    return this.get(`${id}/facilities/`, { params })
  }

  linkFacility(id, data) {
    return this.post(`${id}/link-facility/`, {
      data,
      skipNotFoundRedirect: true,
    })
  }

  unlinkFacility(id, data) {
    return this.del(`${id}/unlink-facility/`, { data })
  }
}

export default new Doctor({
  baseURL: `${API_URL}doctors/`,
})
