import React, { useRef, useState, useCallback } from 'react'
import { Row, Col, Button, Space, Typography } from 'antd'
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons'
import Table from '../Tables/SearchTable'
import { FACILITY } from '../../../constants'
import RelateExceptionModal from '../Modals/RelateExceptionModal'
import Facility from '../../templates/Facility'
import { useUser } from '../../../providers/UserProvider'
import showConfirmDialog from '../Modals/DeleteConfirm'

const ExceptionFacilityTable = ({
  id,
  type,
  data,
  onLoadData,
  isMrdCase,
  disabledRemove,
  handleRemoveRelation,
  loadingStatus,
  title,
  tableTitle,
  totalCount,
  handleRelateFacility,
  hasRelatedTreatmentFacility,
  hasRelatedFacility,
  exceptionData,
  dataCaseInformation,
  caseInformationId,
  primarySearch,
  highlightedFields,
  facilityColumns,
  formData,
}) => {
  const [isVisibleModal, setVisibleModal] = useState(false)
  const [isAutoScrollModal, setAutoScrollModal] = useState(false)
  const [modalId, setModalId] = useState(false)
  const { isUserActionAllowed } = useUser()

  const removeRelationHandler = useCallback(
    relationData =>
      showConfirmDialog({
        title: 'Are you sure you want to remove relation?',
        data: relationData,
        handleConfirm: payload => {
          setAutoScrollModal(false)
          setVisibleModal(false)
          handleRemoveRelation(payload)
        },
        okText: 'Remove Relation',
      }),
    [handleRemoveRelation]
  )

  const columns = facilityColumns.map(item => ({
    ...item,
    onCell: record => ({
      onClick: e => {
        if (e.target.closest('.redirect')) return
        if (item.key === 'name') {
          setVisibleModal(true)
          setModalId(record.id)
        }

        if (item.key === 'related_doctors') {
          setVisibleModal(true)
          setModalId(record.id)
          setAutoScrollModal(true)
        }
      },
    }),
  }))

  const tableRef = useRef(null)
  if (!data || data.length === 0) {
    return null
  }

  return (
    <>
      <RelateExceptionModal
        useClearData
        data={exceptionData}
        isVisibleModal={isVisibleModal}
        isAutoScrollModal={isAutoScrollModal}
        scrollRef={tableRef}
        onCloseModal={() => {
          setAutoScrollModal(false)
          setVisibleModal(false)
        }}
        dataCaseInformation={dataCaseInformation}
        caseInformationId={caseInformationId}
        id={modalId}
      >
        <Facility
          tableRef={tableRef}
          isActionDisabled={disabledRemove}
          relateFacility={facilityId => {
            setAutoScrollModal(false)
            setVisibleModal(false)
            handleRelateFacility(facilityId, 'mrd')
          }}
          relateTreatmentFacility={facilityId => {
            setAutoScrollModal(false)
            setVisibleModal(false)
            handleRelateFacility(facilityId, 'treatment')
          }}
          removeRelation={() => {
            removeRelationHandler({ facility: hasRelatedFacility, type: 'mrd' })
          }}
          removeTreatmentRelation={() => {
            removeRelationHandler({
              facility: hasRelatedTreatmentFacility,
              type: 'treatment',
            })
          }}
          hasRelation={hasRelatedFacility}
          hasRelationTreatmentFacility={hasRelatedTreatmentFacility}
          isMrdCase={isMrdCase}
          exceptionId={id}
        />
      </RelateExceptionModal>

      <Table
        tableTitle={
          tableTitle || (
            <Row justify="space-between" align="middle">
              <Col>
                <LinkOutlined className="search-table__title--icon" />
                {title}
              </Col>
              {isUserActionAllowed('manage_relations_case') && (
                <Col>
                  <Button
                    type="text"
                    size="large"
                    disabled={disabledRemove}
                    onClick={() => {
                      const facilityMapping = {
                        mrd: hasRelatedFacility,
                        treatment: hasRelatedTreatmentFacility,
                      }

                      removeRelationHandler({
                        facility: facilityMapping[type],
                        type,
                      })
                    }}
                  >
                    <Typography.Text
                      type={disabledRemove ? 'secondary' : 'danger'}
                    >
                      <Space>
                        <DeleteOutlined />
                        Remove Relation
                      </Space>
                    </Typography.Text>
                  </Button>
                </Col>
              )}
            </Row>
          )
        }
        columns={columns}
        entity={FACILITY}
        totalData={data}
        totalCount={totalCount}
        loadingStatus={loadingStatus}
        primarySearch={primarySearch}
        highlightedFields={highlightedFields}
        formData={formData}
        onLoadData={onLoadData}
      />
    </>
  )
}

export default ExceptionFacilityTable
