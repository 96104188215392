import React, { useState, useEffect, useCallback } from 'react'
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd'
import { Formik } from 'formik'
import { SearchOutlined } from '@ant-design/icons'
import { specialAuthorizationColumns } from '../../../../data'
import useDispatchHttp from '../../../../hooks/dispatchHttpHandler'
import { getSpecialAuth } from '../../../../state/modules/facilities'
import Table from '../../Tables/SearchTable'

const SpecialAuthorizationModal = ({
  form,
  trigger,
  width,
  facilityId,
  ...props
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [specialAuth, setSpecialAuth] = useState([])
  const [count, setCount] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({
    facility_profile: facilityId,
  })
  // eslint-disable-next-line no-unused-vars
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  })
  const dispatchHttp = useDispatchHttp()

  const handleOpenModal = e => {
    if (e) e.preventDefault()

    setIsModalVisible(!isModalVisible)
  }

  const handleKeyPress = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault()
    }
  }

  const getSpecialAuthorizations = useCallback(async () => {
    try {
      setLoading(true)
      const resp = await dispatchHttp(
        getSpecialAuth({
          ...searchParams,
        })
      )

      setSpecialAuth(resp.results)
      setCount(resp.count)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }, [searchParams, setLoading, setSpecialAuth, setCount, dispatchHttp])
  useEffect(() => {
    if (!isModalVisible) {
      setSpecialAuth([])
      setCount(0)
      setLoading(false)
      setSearchParams({
        facility_profile: facilityId,
      })
    }
    // eslint-disable-next-line
  }, [isModalVisible])

  useEffect(() => {
    if (facilityId && isModalVisible) {
      getSpecialAuthorizations()
    }
    // eslint-disable-next-line
  }, [dispatchHttp, facilityId, isModalVisible])

  const handleSearch = useCallback(
    async e => {
      try {
        setLoading(true)
        setSearchParams(state => ({ ...state, search: e.target.value }))
        const resp = await dispatchHttp(
          getSpecialAuth({
            ...searchParams,
            search: e.target.value,
          })
        )
        setSpecialAuth(resp.results)
        setCount(resp.count)
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    },
    [dispatchHttp, searchParams]
  )

  return (
    <>
      {trigger &&
        React.cloneElement(trigger, {
          onClick: handleOpenModal,
        })}
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={false}
        centered
        width={width}
        destroyOnClose
        onCancel={() => setIsModalVisible(false)}
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(10px)',
        }}
        {...props}
      >
        <Typography.Title level={3}>Special Authorizations</Typography.Title>
        <Formik>
          {({ values, handleSubmit }) => (
            <Form
              onFinish={handleSubmit}
              initialValues={values}
              onKeyDown={handleKeyPress}
            >
              <Form.Item>
                <Input
                  prefix={
                    <Button
                      type="link"
                      size="small"
                      icon={<SearchOutlined />}
                    />
                  }
                  placeholder="Insurance, Policy Type"
                  onPressEnter={handleSearch}
                />
              </Form.Item>
              <Table
                columns={specialAuthorizationColumns}
                totalData={specialAuth}
                onLoadData={getSpecialAuth}
                formData={searchParams}
                loading={isLoading}
                totalCount={count}
                onPageChange={setPagination}
              />
              <Row justify="space-between">
                <Col>
                  <Button onClick={() => setIsModalVisible(false)}>
                    Close window
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default SpecialAuthorizationModal
