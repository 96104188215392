import React, { useEffect, useCallback, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Card, Row, Col, Typography, Space, Skeleton, Tag, Button } from 'antd'
import { StarOutlined, LinkOutlined, DeleteOutlined } from '@ant-design/icons'
import DetailPageLayout from '../../layouts/DetailPageLayout'
import ProfileCard from '../../components/ProfileCard'
import Table from '../../components/Tables/SearchTable'

import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import {
  fetchClusterById,
  selectClusterById,
  updateCluster,
  deleteCluster,
  getClusterFacilities,
  selectAllClusterFacilities,
} from '../../../state/modules/clusters'
import {
  addFacilityToCluster,
  removeFacilityFromCluster,
} from '../../../state/modules/facilities'
import { capitalizeEveryWord } from '../../../utils/helpers'
import { LOADING_STATUS, FACILITY } from '../../../constants'
import { clusterTableColumns, facilityTableColumns } from '../../../data'
import RelateFacilityModal from '../../components/Modals/RelateDoctorFacilityModal'
import { useUser } from '../../../providers/UserProvider'
import showConfirmDialog from '../../components/Modals/DeleteConfirm'

const Cluster = () => {
  const [isClusterRemoved, setClusterRemoved] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const dispatchHttp = useDispatchHttp()
  const clusterData = useSelector(state => selectClusterById(state, id))
  const clusterFacilities = useSelector(selectAllClusterFacilities)
  const clusterFacilitiesCount = useSelector(
    state => state.clusters.facilities.count
  )
  const loadingStatus = useSelector(state => state.clusters.status)
  const { isUserActionAllowed } = useUser()

  const breadcrumbs = [
    { name: 'Clusters', path: '/clusters' },
    {
      name: capitalizeEveryWord(`${clusterData?.name}`),
      path: `/cluster/${id}/`,
    },
  ]

  const handleClusterUpdate = useCallback(
    async clusterId => {
      await dispatchHttp(
        updateCluster({
          id,
          data: { headquarter_id: clusterId },
        }),
        'Cluster has been successfully updated'
      )

      dispatchHttp(getClusterFacilities({ id }))
    },
    [dispatchHttp, id]
  )

  const relateFacility = async data => {
    await dispatchHttp(
      addFacilityToCluster({
        id: data.id,
        data: {
          id: clusterData.id,
        },
      }),
      'Facility has been successfully added to cluster'
    )

    dispatchHttp(getClusterFacilities({ id }))
  }

  const unlinkFacilityFromCluster = async data => {
    await dispatchHttp(
      removeFacilityFromCluster(data.id),
      'Facility has been successfully removed from cluster'
    )
    dispatchHttp(getClusterFacilities({ id }))
  }

  const handleRemoveCluster = async () => {
    showConfirmDialog({
      title: `Do you want to delete ${clusterData.name}?`,
      data: id,
      handleConfirm: async clusterId => {
        try {
          setClusterRemoved(true)
          await dispatchHttp(
            deleteCluster(clusterId),
            'Cluster has been successfully removed '
          )
          history.replace('/clusters')
        } catch (e) {
          setClusterRemoved(false)
        }
      },
    })
  }

  useEffect(() => {
    if (!clusterData && !isClusterRemoved) {
      dispatchHttp(fetchClusterById(id))
    }
  }, [clusterData, dispatchHttp, id, isClusterRemoved])

  useEffect(() => {
    dispatchHttp(getClusterFacilities({ id }))
  }, [dispatchHttp, id])

  return (
    <DetailPageLayout breadcrumbs={breadcrumbs}>
      <Row>
        <Col span={24}>
          <Card>
            <Skeleton
              loading={loadingStatus === LOADING_STATUS}
              active
              paragraph={{ rows: 1 }}
            >
              <Row align="middle" justify="space-between">
                <Col span={20}>
                  <Typography.Title level={2} ellipsis style={{ margin: 0 }}>
                    {capitalizeEveryWord(clusterData?.name)}
                  </Typography.Title>
                </Col>{' '}
                {isUserActionAllowed('delete_facilitycluster') && (
                  <Col>
                    <Button onClick={handleRemoveCluster} danger>
                      Remove
                      <DeleteOutlined />
                    </Button>
                  </Col>
                )}
              </Row>
            </Skeleton>
          </Card>
        </Col>
        <Col span={24}>
          <ProfileCard
            title={
              <Space size={16}>
                Main Facility
                <Tag color="processing" icon={<StarOutlined />}>
                  Headquarter
                </Tag>
              </Space>
            }
            style={{
              headerColor: '#E6F7FF',
              bodyColor: '#003A8C',
            }}
          >
            <Table
              columns={clusterTableColumns}
              totalData={[clusterData?.headquarter]}
              loadingStatus={loadingStatus}
              entity={FACILITY}
            />
          </ProfileCard>

          <Table
            tableTitle={
              <Row justify="space-between" align="middle">
                <Col>Facilities {clusterFacilitiesCount}</Col>
                {isUserActionAllowed('manage_relations_facilitycluster') && (
                  <Col>
                    <RelateFacilityModal
                      title="Relate Facility"
                      entity="Facility"
                      onSubmit={relateFacility}
                      closeAfterSubmit
                      trigger={
                        <Button type="text" size="large">
                          <Typography.Text type="secondary">
                            <Space>
                              <LinkOutlined /> Relate Facility
                            </Space>
                          </Typography.Text>
                        </Button>
                      }
                    />
                  </Col>
                )}
              </Row>
            }
            columns={
              isUserActionAllowed('view_doctor')
                ? facilityTableColumns
                : facilityTableColumns.map(item => {
                    if (item.key === 'related_doctors') {
                      return { ...item, link: null }
                    }
                    return { ...item }
                  })
            }
            totalData={clusterFacilities}
            totalCount={clusterFacilitiesCount}
            hideTotalCount
            onLoadData={data =>
              getClusterFacilities({
                ...data,
                id,
              })
            }
            entity={FACILITY}
            loadingStatus={loadingStatus}
            customRowSelection={{
              columnTitle: 'Headquarter',
              key: 'is_headquarter',
              onSelect: handleClusterUpdate,
              disabled: isUserActionAllowed(
                'manage_headquarter_facilitycluster'
              ),
            }}
            rowDeletion={
              isUserActionAllowed('manage_relations_facilitycluster') && {
                tooltip: 'Remove Relation',
                confirmMsg: 'Are you sure you want to remove the relation?',
                okText: 'Yes',
                onConfirm: unlinkFacilityFromCluster,
                hideRule: item => item.is_headquarter,
              }
            }
          />
        </Col>
      </Row>
    </DetailPageLayout>
  )
}

export default Cluster
