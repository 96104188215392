import React from 'react'
import { Row, Col, Divider } from 'antd'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import TextField from '../../../shared/TextField'

export const Vendors = ({ title }) => (
  <>
    <Divider orientation="left" className="form-divider">
      {title}
    </Divider>
    <Row gutter={12}>
      <Col span={12}>
        <Field
          component={TextField}
          label="EMR Vendor"
          maxlength="256"
          size="medium"
          name="emr_vendor_name"
          placeholder="Type Here"
        />
      </Col>
      <Col span={12}>
        <Field
          component={TextField}
          maxlength="256"
          label="EMR Data Source"
          size="medium"
          name="emr_data_source"
          placeholder="Type Here"
        />
      </Col>
    </Row>
  </>
)

Vendors.propTypes = {
  title: PropTypes.string,
}

Vendors.defaultProps = {
  title: '',
}
