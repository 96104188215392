import React from 'react'

import { Row, Col, Divider } from 'antd'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import TextField from '../../../shared/TextField'
// import NumberField from '../../../shared/NumberField'
// import SelectField from '../../../shared/SelectField'
// import DatePickerField from '../../../shared/DatePickerField'
//
// import { DATE_FORMAT } from '../../../../constants'
// import { communicationOptions, paymentOptions } from '../../../../data'

// eslint-disable-next-line
export const Overview = ({ title, values }) => (
  <>
    <Divider orientation="left" className="form-divider">
      {title}
    </Divider>
    <Row gutter={24}>
      <Col span={24}>
        <Field
          component={TextField}
          label="Provider Name"
          size="medium"
          name="provider_name"
          placeholder="Type Here"
          disabled
        />
      </Col>
    </Row>
    {/* <Row gutter={12}> */}
    {/*  <Col span={12}> */}
    {/*    <Field */}
    {/*      component={SelectField} */}
    {/*      label="Communication Method" */}
    {/*      size="medium" */}
    {/*      name="communication_method" */}
    {/*      placeholder="Select Here" */}
    {/*      options={communicationOptions} */}
    {/*      disabled */}
    {/*    /> */}
    {/*  </Col> */}
    {/*  <Col span={12}> */}
    {/*    <Field */}
    {/*      component={SelectField} */}
    {/*      label="Payment Method" */}
    {/*      size="medium" */}
    {/*      name="payment_type" */}
    {/*      placeholder="Select Here" */}
    {/*      options={paymentOptions} */}
    {/*      disabled */}
    {/*    /> */}
    {/*  </Col> */}
    {/* </Row> */}
    {/* <Row gutter={12}> */}
    {/*  {!!values.last_retriever && ( */}
    {/*    <Col flex={1}> */}
    {/*      <Field */}
    {/*        component={TextField} */}
    {/*        label="Past Retriever" */}
    {/*        size="medium" */}
    {/*        name="last_retriever" */}
    {/*        placeholder="Select Here" */}
    {/*        disabled */}
    {/*      /> */}
    {/*    </Col> */}
    {/*  )} */}
    {/*  {!!values.last_case_date && ( */}
    {/*    <Col flex={1}> */}
    {/*      <Field */}
    {/*        component={DatePickerField} */}
    {/*        label="Last Case Date" */}
    {/*        size="medium" */}
    {/*        name="last_case_date" */}
    {/*        placeholder="Select Date" */}
    {/*        format={DATE_FORMAT.year_month_day} */}
    {/*        disabled */}
    {/*      /> */}
    {/*    </Col> */}
    {/*  )} */}
    {/*  {!!values.case_count && ( */}
    {/*    <Col flex={1}> */}
    {/*      <Field */}
    {/*        component={NumberField} */}
    {/*        label="Case Count" */}
    {/*        size="medium" */}
    {/*        name="case_count" */}
    {/*        placeholder="Type Here" */}
    {/*        disabled */}
    {/*      /> */}
    {/*    </Col> */}
    {/*  )} */}
    {/* </Row> */}
  </>
)

Overview.propTypes = {
  title: PropTypes.string,
  values: PropTypes.shape({
    last_retriever: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        fullname: PropTypes.string,
        cms_id: PropTypes.string,
      }),
    ]),
    last_case_date: PropTypes.string,
    case_count: PropTypes.number,
    provider_name: PropTypes.string,
  }),
}

Overview.defaultProps = {
  title: '',
  values: {},
}
