/* eslint-disable no-template-curly-in-string */
import { object, mixed } from 'yup'
import { FILE_SIZE, SUPPORTED_FORMATS } from '../constants'

export const createAuthFormSchema = object().shape({
  attachment: mixed()
    .required('Attachment is required')
    .test(
      'fileSize',
      'File too large',
      value => value && value.size <= FILE_SIZE
    )
    .test(
      'fileFormat',
      'Unsupported Format. Only files with the "pdf" extensions are allowed',
      value => value && SUPPORTED_FORMATS.includes(value.type)
    ),
})
