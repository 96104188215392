export const routes = [
  {
    path: '/cases',
    name: 'Cases',
    permission: 'view_case',
  },
  {
    path: '/search',
    name: 'Search',
    permission: ['view_doctor', 'view_facility'],
  },
  {
    path: '/facility-profiles',
    name: 'Facility Profiles',
    permission: 'view_facilityprofile',
  },
  {
    path: '/clusters',
    name: 'Clusters',
    permission: 'view_facilitycluster',
  },
  {
    path: '/users',
    name: 'Users',
    permission: 'view_user',
  },
  {
    path: '/user-activity-log',
    name: 'User Activity Log List',
    permission: ['view_version', 'view_history_case'],
  },
  {
    path: '/data-sync-log',
    name: 'Import Logs',
    permission: 'view_importlog',
  },
  {
    path: '/roles',
    name: 'Roles',
    permission: 'view_group',
  },
  {
    path: '/reports',
    name: 'Reports',
    permission: 'view_report',
  },
  {
    path: '/organizations',
    name: 'Organizations',
    permission: ['view_agency', 'view_insurancecompany'],
  },
  {
    path: '/copy-company-assignment',
    name: 'Assignment Copy Companies',
    permission: 'view_assignmentcopycompany',
  },
  {
    path: '/vendors',
    name: 'Vendors',
    permission: 'can_manage_vendor_relations',
  },
  {
    path: '/retrievers-list',
    name: 'Retrievers',
    permission: 'manage_retrievers',
  },
]
