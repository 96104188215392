import Api from './index'
import { API_URL } from '../constants'

class Role extends Api {
  createRole(data) {
    return this.post('groups/', { data })
  }

  updateRole(id, data) {
    return this.put(`/groups/${id}/`, { data })
  }

  deleteRole(id) {
    return this.del(`/groups/${id}/`)
  }

  getRoles(id, params) {
    return this.get('/groups/', { params })
  }

  getRoleById(id) {
    return this.get(`groups/${id}/`)
  }

  getRolePermissions() {
    return this.get(`permissions/`)
  }
}

export default new Role({
  baseURL: `${API_URL}accounts/`,
})
