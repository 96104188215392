import React from 'react'
import { Form, Row, Col, Divider } from 'antd'
import { Field } from 'formik'

import TextField from '../../../shared/TextField'
import ToggleField from '../../../shared/ToggleField'
import NumberField from '../../../shared/NumberField'
import { Label } from './Label'

export const DoctorMain = ({ title, original }) => (
  <>
    <Divider orientation="left" className="form-divider">
      {title}
    </Divider>
    <Form.Item>
      <label className="user-form__label--switch">Sole Proprietor</label>
      <Field name="is_sole_proprietor" component={ToggleField} noStyle />
    </Form.Item>
    <Row gutter={16}>
      <Col span={12}>
        <Field
          component={TextField}
          size="medium"
          name="first_name"
          label={<Label label="First Name" original={original?.first_name} />}
          placeholder="Type Here"
          required
        />
      </Col>

      <Col span={12}>
        <Field
          component={TextField}
          size="medium"
          name="last_name"
          label={<Label label="Last Name" original={original?.last_name} />}
          placeholder="Type Here"
          required
        />
      </Col>

      <Col span={12}>
        <Field
          component={TextField}
          label="Taxonomy"
          size="medium"
          name="taxonomy_group_name"
          placeholder="Type Here"
        />
      </Col>
      <Col span={12}>
        <Field
          component={NumberField}
          label="NPI"
          size="medium"
          name="npi"
          placeholder="Type Here"
        />
      </Col>
    </Row>
  </>
)
