import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  getImportLogsList,
  getImportLogById,
  getWeeklyNpeesUpdatesList,
  getWeeklyNpeesUpdatesById,
  getExtraImportLogsList,
  getExtraImportLogById,
  getExtraImportLogsTypes,
} from './actions'
import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../../../constants'

export const importLogsAdapter = createEntityAdapter()
const initialState = importLogsAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
  types: importLogsAdapter.getInitialState({ count: 0 }),
})

const retrieverSlice = createSlice({
  name: 'importLogs',
  initialState,
  extraReducers: builder => {
    builder.addCase(getImportLogsList.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getImportLogsList.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        importLogsAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
        state.types = importLogsAdapter.getInitialState({
          count: 0,
        })
      }
    )
    builder.addCase(getImportLogsList.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(getImportLogById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getImportLogById.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      importLogsAdapter.upsertOne(state, payload)
    })
    builder.addCase(getImportLogById.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(getWeeklyNpeesUpdatesList.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getWeeklyNpeesUpdatesList.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        importLogsAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(getWeeklyNpeesUpdatesList.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(getWeeklyNpeesUpdatesById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getWeeklyNpeesUpdatesById.fulfilled,
      (state, { payload }) => {
        state.status = SUCCESS_STATUS
        importLogsAdapter.upsertOne(state, payload)
      }
    )
    builder.addCase(getWeeklyNpeesUpdatesById.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(getExtraImportLogsList.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getExtraImportLogsList.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        importLogsAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(getExtraImportLogsList.rejected, state => {
      state.status = FAILURE_STATUS
    })

    builder.addCase(getExtraImportLogById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getExtraImportLogById.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      importLogsAdapter.upsertOne(state, payload)
    })
    builder.addCase(getExtraImportLogById.rejected, state => {
      state.status = FAILURE_STATUS
    })

    builder.addCase(getExtraImportLogsTypes.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getExtraImportLogsTypes.fulfilled, (state, { payload }) => {
      importLogsAdapter.setAll(state.types, payload)
      state.status = SUCCESS_STATUS
    })
  },
})

const { reducer } = retrieverSlice

export default reducer
