import { object, number } from 'yup'

import {
  city,
  address_line_1,
  address_line_2,
  facilityName,
  searchfirstname,
  searchZipCode,
} from './fields'

export const searchSchema = object().shape({
  cms_id: number()
    .negative('Must be a negative number')
    .integer('Must include only digits')
    .typeError('Must include only digits'),
  zip_code: searchZipCode,
  city: city.notRequired(),
  address_line_1,
  address_line_2,
  first_name: searchfirstname,
  facility_name: facilityName,
})
