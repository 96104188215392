/* eslint-disable no-template-curly-in-string */
import { object, string } from 'yup'
import { nonLatinChar } from './fields'

export const updateCreateHealthNetworkSchema = object().shape({
  name: string()
    .trim()
    .min(1, 'Health Network must be at least ${min} characters')
    .max(128, 'Health Network cannot include more than ${max} characters')
    .matches(nonLatinChar, 'Health Network cannot include non-latin characters')
    .required('Please enter Health Network Name'),
})
