import React, { useCallback } from 'react'
import { List, Button, Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import FormListItem from '../../FormListItem'
import MedicalEntityModal from '../../Modals/MedicalEntityModal'
import AuthForm from '../CreateAuthForm'

import { createAuthFormSchema } from '../../../../schema/createAuthForm'
import { useUser } from '../../../../providers/UserProvider'

const AuthFormsList = ({ initialValues, onSubmit, onClose, canDelete }) => {
  const { isUserActionAllowed } = useUser()
  const renderItem = useCallback(
    item => (
      <FormListItem
        canDelete={canDelete}
        key={item.id}
        form={initialValues.form}
        editor={item.editor}
        facilityId={initialValues.id}
        {...item}
      />
    ),
    [initialValues.form, initialValues.id, canDelete]
  )

  return (
    <div className="form-list">
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <MedicalEntityModal
            title="Create Special Authorization Form"
            className="set-retriever-modal"
            width={580}
            trigger={
              isUserActionAllowed('add_authorizationform') && (
                <Button
                  type="primary"
                  ghost
                  block
                  icon={<PlusOutlined className="form-list__add-icon" />}
                >
                  Add New Form
                </Button>
              )
            }
            form={
              <AuthForm
                validationSchema={createAuthFormSchema}
                onSubmit={onSubmit}
                initialValues={{
                  special_auth: 0,
                  e_signature: 0,
                  attachment: null,
                }}
              />
            }
          />
        </Col>
        <Col span={24}>
          <List
            dataSource={initialValues.history_forms}
            renderItem={renderItem}
            pagination={{
              hideOnSinglePage: initialValues.history_forms.length === 0,
              className: 'left',
              pageSize: 7,
              total: initialValues.history_forms.length,
            }}
          />
        </Col>
        <Col>
          <Button onClick={onClose}>Close window</Button>
        </Col>
      </Row>
    </div>
  )
}

export default AuthFormsList
