import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getReports, resetState } from './actions'
import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../../../constants'

export const reportsAdapter = createEntityAdapter()
const initialState = reportsAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      reportsAdapter.removeAll(state)
    })
    builder.addCase(getReports.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getReports.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        reportsAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(getReports.rejected, state => {
      state.status = FAILURE_STATUS
    })
  },
})

const { reducer } = reportsSlice

export default reducer
