/* eslint-disable no-template-curly-in-string */
import { object, string } from 'yup'

import {
  firstname,
  lastname,
  addresses,
  phone_numbers,
  emails,
  fax_numbers,
  npi,
  nonLatinChar,
} from './fields'

export const createUpdateDoctorSchema = object().shape({
  first_name: firstname,
  last_name: lastname,
  phone_numbers,
  addresses,
  fax_numbers,
  emails,
  npi,
  taxonomy_group_name: string()
    .nullable()
    .max(255, 'Taxonomy cannot include more than ${max} characters')
    .matches(nonLatinChar, 'Taxonomy cannot include non-latin characters'),
})
