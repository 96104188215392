import { LOADING_STATUS } from '../../../constants'
import { activityLogAdapter } from './reducer'

export const {
  selectAll: selectAllActivityLogs,
  selectById: selectActivityLogById,
} = activityLogAdapter.getSelectors(state => state.activityLogs)

export const selectActivityLogsCount = state => state.activityLogs.count

export const isActivityLogsFetching = state =>
  state.activityLogs.status === LOADING_STATUS
