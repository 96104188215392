import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Tooltip,
  Typography,
  Button,
  Modal,
  Tabs as Tab,
} from 'antd'
import { LinkOutlined, EditOutlined } from '@ant-design/icons'
import DetailPageLayout from '../../layouts/DetailPageLayout'
import Tabs from '../../components/Tabs'

import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import useDebounce from '../../../hooks/useDebounce'
import TextField from '../../shared/TextField'
import {
  relateDoctorFacilityVendor,
  editRelateDoctorFacilityVendor,
} from '../../../schema/relateDoctorFacility'
import {
  fetchVendorById,
  selectVendorById,
} from '../../../state/modules/vendors'

import {
  getVendorFacilities,
  selectAllVendorFacilities,
  addVendorFacility,
  removeVendorFacility,
  updateVendorFacility,
  resetState as resetFacilitiesState,
} from '../../../state/modules/vendorFacilities'

import {
  getVendorDoctors,
  selectAllVendorDoctors,
  addVendorDoctor,
  removeVendorDoctor,
  updateVendorDoctor,
  resetState as resetDoctorsState,
} from '../../../state/modules/vendorDoctors'
import {
  capitalizeEveryWord,
  capitalizeFirsLetter,
} from '../../../utils/helpers'
import {
  DOCTOR,
  FACILITY,
  NO_SKIP_ON_IMPORT_VENDOR_LIST,
} from '../../../constants'
import Table from '../../components/Tables/SearchTable'
import {
  vendorFacilityTableColumns,
  vendorDoctorTableColumns,
} from '../../../data'
import { validateSearchParams } from '../../../utils/functions'
import { skipOnImportOptions } from '../../../data/selectOptions'

const VendorDetail = () => {
  const { id } = useParams()
  const dispatchHttp = useDispatchHttp()
  const [activeTab, setActiveTab] = useState(0)
  const vendorData = useSelector(state => selectVendorById(state, id))
  const relatedDoctors = useSelector(selectAllVendorDoctors)
  const relatedFacilities = useSelector(selectAllVendorFacilities)
  const doctorsLoadingStatus = useSelector(state => state.vendorDoctors.status)
  const facilitiesLoadingStatus = useSelector(
    state => state.vendorFacilities.status
  )
  const doctorsCount = useSelector(state => state.vendorDoctors.count)
  const facilitiesCount = useSelector(state => state.vendorFacilities.count)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalRelateVisible, setIsModalRelateVisible] = useState(false)
  const [skipOnImport, setSkipOnImport] = useState(false)
  const [organizationName, setOrganizationName] = useState('')
  const [organizationID, setOrganizationID] = useState('')
  const [relationID, setRelationID] = useState(false)
  const [relateModalData, setRelateModalData] = useState({})
  const [modalTitle, setModalTitle] = useState('')
  const [form] = Form.useForm()
  const [vendorPagination, setVendorPagination] = useState({
    page: 1,
    pageSize: 10,
  })
  const [searchParams, setSearchParams] = useState({})
  const [isSkipOnImportVendor, setIsSkipOnImportVendor] = useState()

  const breadcrumbs = [
    { name: 'Vendors', path: '/vendors' },
    {
      name: capitalizeEveryWord(`${vendorData?.name}`),
      path: `/vendors/${id}/`,
    },
  ]

  const setModalData = text => {
    setRelationID(text?.relationId)
    setSkipOnImport(text?.skip_on_import)
    setOrganizationName(text?.organization_name)
    setOrganizationID(text?.organization_id)
    setModalTitle(`Edit ${text.name}`)
  }

  const handleOpenModal = e => {
    if (e) e.preventDefault()
    setIsModalVisible(!isModalVisible)
  }

  const handleOpenModalRelate = e => {
    if (e) e.preventDefault()
    setIsModalRelateVisible(!isModalRelateVisible)
  }

  const renderEditColumn = text => (
    <>
      <Button
        type="text"
        onClick={() => {
          setModalData(text)
          handleOpenModal()
        }}
        icon={
          <Tooltip title="Edit">
            <EditOutlined style={{ color: '#BFBFBF', fontSize: 18 }} />
          </Tooltip>
        }
      />
    </>
  )

  const handleChangeSearch = useDebounce(e => {
    const { value } = e.target
    const requestData = {
      id,
      ...validateSearchParams(searchParams),
      search: value.trim(),
    }
    setSearchParams({ ...searchParams, search: value.trim() })

    if (activeTab === 0) {
      dispatchHttp(getVendorFacilities(requestData))
    } else if (activeTab === 1) {
      dispatchHttp(getVendorDoctors(requestData))
    }
  }, 500)

  const handleChangeSkipOnImportFilter = state => {
    const data = state.toString()
    const requestData = {
      id,
      ...searchParams,
      ...validateSearchParams({ skip_on_import: data }),
    }
    setSearchParams({ ...searchParams, skip_on_import: data })

    if (activeTab === 0) {
      dispatchHttp(getVendorFacilities(requestData))
    } else if (activeTab === 1) {
      dispatchHttp(getVendorDoctors(requestData))
    }
  }

  const relateFacility = async data => {
    let preparedData = {
      organization_name: data.organization_name,
      organization_id: data.organization_id,
      facility_id: data.id,
      vendor_id: id,
    }

    if (isSkipOnImportVendor)
      preparedData = {
        ...preparedData,
        skip_on_import: data.skip_on_import,
      }
    await dispatchHttp(
      addVendorFacility({ data: preparedData }),
      'Facility has been successfully added to a vendor'
    )

    dispatchHttp(
      getVendorFacilities({ ...validateSearchParams(searchParams), id })
    )
  }

  const relateDoctor = async data => {
    let preparedData = {
      organization_name: data.organization_name,
      organization_id: data.organization_id,
      doctor_id: data.id,
      vendor_id: id,
    }

    if (isSkipOnImportVendor)
      preparedData = {
        ...preparedData,
        skip_on_import: data.skip_on_import,
      }
    await dispatchHttp(
      addVendorDoctor({ data: preparedData }),
      'Doctor has been successfully added to a vendor'
    )

    dispatchHttp(
      getVendorDoctors({ ...validateSearchParams(searchParams), id })
    )
  }

  const removeFacilityRelation = async data => {
    await dispatchHttp(
      removeVendorFacility(data.record.relationId),
      'Facility has been successfully removed from the vendor'
    )

    dispatchHttp(
      getVendorFacilities({ ...validateSearchParams(searchParams), id })
    )
  }

  const getColumns = columns => {
    const formattedTableColumns = columns.filter(item => {
      if (!isSkipOnImportVendor) {
        return item.key !== 'skip_on_import'
      }

      return item
    })

    if (isSkipOnImportVendor) {
      formattedTableColumns.push({
        title: '',
        key: 'id',
        align: 'center',
        width: 50,
        render: (_, col, text) => renderEditColumn(text),
      })
    }

    return formattedTableColumns
  }

  const removeDoctorRelation = async data => {
    await dispatchHttp(
      removeVendorDoctor(data.record.relationId),
      'Doctor has been successfully removed from the vendor'
    )
    dispatchHttp(getVendorDoctors({ id }))
  }

  const updateFacility = async data =>
    dispatchHttp(
      updateVendorFacility(data),
      'Facility relation updated successfully'
    )

  const updateDoctor = async data =>
    dispatchHttp(
      updateVendorDoctor(data),
      'Doctor relation updated successfully'
    )

  const onSubmit = async data => {
    const updatedData = {
      organization_name: data.organization_name,
      organization_id: data.organization_id,
      skip_on_import: data.skip_on_import,
      vendor_id: id,
    }

    if (activeTab === 0) {
      await updateFacility({ id: relationID, data: updatedData })
      dispatchHttp(
        getVendorFacilities({ ...validateSearchParams(searchParams), id })
      )
    } else if (activeTab === 1) {
      await updateDoctor({ id: relationID, data: updatedData })
      dispatchHttp(
        getVendorDoctors({ ...validateSearchParams(searchParams), id })
      )
    }
    handleOpenModal()
  }

  const formattedDataFacility = data =>
    data.map(item => ({
      relationId: item.id,
      skip_on_import: item.skip_on_import,
      organization_id: item.organization_id,
      organization_name: item.organization_name,
      id: item.facility.id,
      name: item.facility.name,
      addresses: item.facility.addresses,
      fax_numbers: item.facility.fax_numbers,
      phone_numbers: item.facility.phone_numbers,
      related_doctors: item.facility.related_doctors,
      facility_profile: item.facility.facility_profile,
    }))

  const formattedDataDoctor = data =>
    data.map(item => ({
      relationId: item.id,
      skip_on_import: item.skip_on_import,
      organization_id: item.organization_id,
      organization_name: item.organization_name,
      id: item.doctor.id,
      first_name: item.doctor.first_name,
      last_name: item.doctor.last_name,
      other_names: item.doctor.other_names,
      addresses: item.doctor.addresses,
      fax_numbers: item.doctor.fax_numbers,
      phone_numbers: item.doctor.phone_numbers,
      related_facilities: item.doctor.related_facilities,
      facility_profile: item.doctor.facility_profile,
    }))

  useEffect(() => {
    if (!vendorData) {
      dispatchHttp(fetchVendorById(id))
    } else {
      setIsSkipOnImportVendor(
        !NO_SKIP_ON_IMPORT_VENDOR_LIST.includes(vendorData.name)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData, dispatchHttp])

  useEffect(() => {
    form?.resetFields()
    if (activeTab === 0) {
      dispatchHttp(getVendorFacilities({ id }))
    } else if (activeTab === 1) {
      dispatchHttp(getVendorDoctors({ id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp, activeTab])

  useEffect(
    () => () => {
      dispatchHttp(resetFacilitiesState())
      dispatchHttp(resetDoctorsState())
    },
    [dispatchHttp]
  )

  return (
    <DetailPageLayout breadcrumbs={breadcrumbs}>
      <Row>
        <Col span={24}>
          <Modal
            visible={isModalRelateVisible}
            footer={null}
            closable={false}
            destroyOnClose
            centered
            onCancel={() => setIsModalRelateVisible(false)}
            maskStyle={{
              background: 'rgba(0, 0, 0, 0.6)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <Typography.Title level={3}>
              {relateModalData?.title}
            </Typography.Title>
            <Formik
              initialValues={{
                id: null,
                skip_on_import: false,
                organization_name: null,
                organization_id: null,
              }}
              onSubmit={async (...data) => {
                try {
                  await relateModalData?.onSubmit(...data)
                  setIsModalRelateVisible(false)
                } catch (err) {
                  /* empty */
                }
              }}
              validationSchema={relateDoctorFacilityVendor}
            >
              {({ handleSubmit, initialValues, values, setValues }) => (
                <Form
                  onFinish={handleSubmit}
                  layout="vertical"
                  initialValues={initialValues}
                >
                  <Field
                    component={TextField}
                    placeholder="Type Here"
                    name="id"
                    label={`${capitalizeFirsLetter(
                      relateModalData?.entity
                    )} ID`}
                    required
                  />
                  <Field
                    component={TextField}
                    placeholder="Type Here"
                    name="organization_name"
                    label="Organization Name"
                    required
                  />
                  <Field
                    component={TextField}
                    placeholder="Type Here"
                    name="organization_id"
                    label="Organization ID"
                    required
                  />
                  {isSkipOnImportVendor && (
                    <Form.Item
                      label="Skip On Import"
                      name="skip_on_import"
                      size="small"
                      defaultValue="false"
                      style={{ width: 100 }}
                    >
                      <Select
                        defaultValue={initialValues.skip_on_import}
                        style={{ width: 120 }}
                        name="skip_on_import"
                        options={skipOnImportOptions}
                        onChange={e => {
                          setValues({ ...values, skip_on_import: e })
                        }}
                      />
                    </Form.Item>
                  )}
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Button onClick={handleOpenModalRelate}>
                        Close window
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        Relate
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal>
          <Modal
            visible={isModalVisible}
            footer={null}
            closable={false}
            centered
            destroyOnClose
            onCancel={handleOpenModal}
            maskStyle={{
              background: 'rgba(0, 0, 0, 0.6)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <Typography.Title level={3}>{modalTitle}</Typography.Title>
            <Formik
              initialValues={{
                skip_on_import: skipOnImport,
                organization_name: organizationName,
                organization_id: organizationID,
              }}
              onSubmit={onSubmit}
              enableReinitialize
              validationSchema={editRelateDoctorFacilityVendor}
            >
              {({ handleSubmit, setValues, initialValues, dirty, values }) => (
                <Form
                  initialValues={initialValues}
                  onFinish={handleSubmit}
                  className="organization-form"
                  layout="vertical"
                >
                  <Field
                    component={TextField}
                    placeholder="Type Here"
                    name="organization_name"
                    label="Organization Name"
                    required
                  />
                  <Field
                    component={TextField}
                    placeholder="Type Here"
                    name="organization_id"
                    label="Organization ID"
                    required
                  />
                  <Field
                    label="Skip On Import"
                    size="medium"
                    name="skip_on_import"
                    placeholder="Select Here"
                    options={skipOnImportOptions}
                  >
                    {() => (
                      <Form.Item label="Skip On Import">
                        <Select
                          defaultValue={initialValues.skip_on_import}
                          style={{ width: 80 }}
                          name="skip_on_import"
                          options={skipOnImportOptions}
                          onChange={value => {
                            setValues({ ...values, skip_on_import: value })
                          }}
                        />
                      </Form.Item>
                    )}
                  </Field>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ paddingTop: '10px' }}
                  >
                    <Col>
                      <Button onClick={handleOpenModal}>Close window</Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!dirty}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal>
          <Tabs
            fullWidth
            theme="dark"
            tabsProps={{
              size: 'small',
              tabBarGutter: 24,
              onChange: key => {
                setSearchParams({
                  ...searchParams,
                  search: '',
                  skip_on_import: 'all',
                })
                setActiveTab(+key)
              },
              activeKey: `${activeTab}`,
            }}
          >
            <Tab.TabPane tab="Facilities" key="0">
              <Row
                gutter={[16, 32]}
                style={{
                  background: '#fff',
                  padding: '14px 12px',
                  justifyContent: 'flex-end',
                  margin: 0,
                }}
              >
                <Form
                  form={form}
                  layout="inline"
                  className="sort-form"
                  component="div"
                  initialValues={{ search: '', skipOnImport: 'all' }}
                  size="small"
                >
                  <Row>
                    {isSkipOnImportVendor && (
                      <Col style={{ marginRight: '10px' }}>
                        <Form.Item
                          label="Skip On Import"
                          name="skipOnImport"
                          size="small"
                        >
                          <Select
                            style={{ width: 80 }}
                            onChange={handleChangeSkipOnImportFilter}
                          >
                            <Select.Option value="all">All</Select.Option>
                            {skipOnImportOptions.map(option => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col>
                      <Form.Item label="Search" name="search" size="small">
                        <Input
                          onChange={handleChangeSearch}
                          type="text"
                          className="search-input"
                          placeholder="NPI, Jaia ID, Organization Name, Organization ID"
                          style={{ width: 300 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        ghost
                        type="primary"
                        style={{ marginLeft: '12px' }}
                        onClick={() => {
                          setRelateModalData({
                            title: 'Relate Facility',
                            entity: 'Facility',
                            onSubmit: relateFacility,
                          })
                          handleOpenModalRelate()
                        }}
                      >
                        <LinkOutlined /> Relate Facility
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Table
                columns={getColumns(vendorFacilityTableColumns)}
                totalData={relatedFacilities}
                totalCount={facilitiesCount}
                onLoadData={data =>
                  getVendorFacilities({
                    ...data,
                    id,
                  })
                }
                formData={validateSearchParams(searchParams)}
                onFormatData={data => formattedDataFacility(data)}
                hideTotalCount
                rowKey="relationId"
                entity={FACILITY}
                loadingStatus={facilitiesLoadingStatus}
                rowDeletion={{
                  tooltip: 'Remove Relation',
                  confirmMsg:
                    'Are you sure you want to remove the facility relation?',
                  okText: 'Yes',
                  onConfirm: removeFacilityRelation,
                }}
                onPageChange={setVendorPagination}
                pagination={vendorPagination}
              />
            </Tab.TabPane>

            <Tab.TabPane tab="Doctors" key="1">
              <Row
                gutter={[16, 32]}
                style={{
                  background: '#fff',
                  padding: '14px 12px',
                  justifyContent: 'flex-end',
                  margin: 0,
                }}
              >
                <Form
                  form={form}
                  layout="inline"
                  className="sort-form"
                  component="div"
                  initialValues={{ search: '', skipOnImport: 'all' }}
                  size="small"
                >
                  <Row>
                    {isSkipOnImportVendor && (
                      <Col style={{ marginRight: '10px' }}>
                        <Form.Item
                          label="Skip On Import"
                          name="skipOnImport"
                          size="small"
                        >
                          <Select
                            style={{ width: 80 }}
                            onChange={handleChangeSkipOnImportFilter}
                          >
                            <Select.Option value="all">All</Select.Option>
                            {skipOnImportOptions.map(option => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col>
                      <Form.Item label="Search" name="search" size="small">
                        <Input
                          onChange={handleChangeSearch}
                          type="text"
                          className="search-input"
                          placeholder="NPI, Jaia ID, Organization Name, Organization ID"
                          style={{ width: 300 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        ghost
                        type="primary"
                        style={{ marginLeft: '12px' }}
                        onClick={() => {
                          setRelateModalData({
                            title: 'Relate Doctor',
                            entity: 'Doctor',
                            onSubmit: relateDoctor,
                          })
                          handleOpenModalRelate()
                        }}
                      >
                        <LinkOutlined /> Relate Doctor
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Table
                columns={getColumns(vendorDoctorTableColumns)}
                totalData={relatedDoctors}
                totalCount={doctorsCount}
                onLoadData={data =>
                  getVendorDoctors({
                    ...data,
                    id,
                  })
                }
                formData={validateSearchParams(searchParams)}
                onFormatData={data => formattedDataDoctor(data)}
                hideTotalCount
                rowKey="relationId"
                entity={DOCTOR}
                loadingStatus={doctorsLoadingStatus}
                rowDeletion={{
                  tooltip: 'Remove Relation',
                  confirmMsg:
                    'Are you sure you want to remove the doctor relation?',
                  okText: 'Yes',
                  onConfirm: removeDoctorRelation,
                }}
                onPageChange={setVendorPagination}
              />
            </Tab.TabPane>
          </Tabs>
        </Col>
      </Row>
    </DetailPageLayout>
  )
}

export default VendorDetail
