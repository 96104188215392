import React from 'react'
import { Tooltip } from 'antd'

export const Label = ({ label, original }) => (
  <span
    style={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {label} &nbsp;
    {original && (
      <Tooltip title={`Original: ${original}`}>
        <span style={{ background: '#f7d1cf' }}>
          Original: <span style={{ fontWeight: 'bold' }}>{original}</span>
        </span>
      </Tooltip>
    )}
  </span>
)
