import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { List, Col } from 'antd'

import ActivityLogLayout from '../../layouts/ActivityLogLayout'
import ExceptionLogCard from '../../components/ExceptionLogCard'
import { LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import {
  fetchExceptionHistoryLog,
  selectExceptionHistoryLogs,
} from '../../../state/modules/exceptions'
import { isExceptionShouldBeSkipped } from '../../../utils/helpers'

const HistoryLog = () => {
  const { id } = useParams()
  const dispatchHttp = useDispatchHttp()
  const logData = useSelector(selectExceptionHistoryLogs)
  const { status } = useSelector(state => state.exceptions)
  const { count } = useSelector(state => state.exceptions.history_logs)

  const breadcrumbs = [
    { name: 'CMS Cases', path: '/cases' },
    { name: `CMS Case #${id}`, path: `/cases/${id}/` },
    { name: 'CMS Case History', path: `/cases/${id}/history/` },
  ]
  const renderItem = data => {
    const changes = data.changes.new
    const isOnlyOneChange = Object.keys(changes).length === 1

    if (isOnlyOneChange && isExceptionShouldBeSkipped(changes)) return null

    return (
      <ExceptionLogCard
        data={data}
        isMrd={logData && logData[0].is_mrd_case}
        id={logData && logData[0].case}
      />
    )
  }

  const handleListChange = useCallback(
    (page, pageSize) => {
      dispatchHttp(fetchExceptionHistoryLog({ page, pageSize, id }))
    },
    [dispatchHttp, id]
  )

  useEffect(() => {
    dispatchHttp(fetchExceptionHistoryLog({ id }))
  }, [dispatchHttp, id])

  return (
    <ActivityLogLayout title="CMS Case History" breadcrumbs={breadcrumbs}>
      <Col span={24}>
        <List
          grid={{ gutter: 24, column: 1 }}
          dataSource={logData}
          renderItem={renderItem}
          pagination={{
            hideOnSinglePage: true,
            onChange: handleListChange,
            total: count,
          }}
          loading={status === LOADING_STATUS && status !== SUCCESS_STATUS}
        />
      </Col>
    </ActivityLogLayout>
  )
}

export default HistoryLog
