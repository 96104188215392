import Api from './index'
import { API_URL } from '../constants'

class ActivityLogCases extends Api {
  getActivityLogCases(data, params) {
    return this.get('', { data, params })
  }
}

export default new ActivityLogCases({
  baseURL: `${API_URL}activity/case-activity-log/`,
})
