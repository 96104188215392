import React, { Component } from 'react'
import { ErrorContent } from './ErrorContent'

class ErrorBoundary extends Component {
  constructor() {
    super()
    this.state = {
      hasError: false,
    }
    this.resetHandler = this.resetHandler.bind(this)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  resetHandler() {
    this.setState({ hasError: false })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? <ErrorContent reset={this.resetHandler} /> : children
  }
}

export default ErrorBoundary
