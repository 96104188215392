import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getVendorFacilities, resetState } from './actions'
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const vendorFacilityAdapter = createEntityAdapter()
const initialState = vendorFacilityAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const vendorFacilitySlice = createSlice({
  name: 'vendorFacilities',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      vendorFacilityAdapter.removeAll(state)
    })
    builder.addCase(getVendorFacilities.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getVendorFacilities.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        vendorFacilityAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
  },
})

const { reducer } = vendorFacilitySlice

export default reducer
