import React from 'react'
import { Field } from 'formik'

import UploadField from '../../../shared/UploadField'

export const Forms = ({ errorMessage }) => (
  <>
    <Field
      component={UploadField}
      label="Special Authorization Form"
      name="attachment"
      placeholder="Upload file"
      error={errorMessage('attachment')}
    />
  </>
)
