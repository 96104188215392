export * from './medicalEntity'
export * from './errorMessages'
export * from './addressType'
export * from './dateTimeFormats'
export * from './requestStatuses'
export * from './attachments'
export * from './importLogsStatuses'
export * from './models'
export * from './case'
export * from './pageSizeOptions'
export * from './activityLogEntities'
export * from './permissionMap'
export * from './reportStatuses'
export * from './exceptionTypes'

export const API_URL = process.env.REACT_APP_API_URL
