import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getVendors, fetchVendorById, resetState } from './actions'
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const vendorAdapter = createEntityAdapter()
const initialState = vendorAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const vendorSlice = createSlice({
  name: 'vendors',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      vendorAdapter.removeAll(state)
    })
    builder.addCase(getVendors.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getVendors.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      if (payload.results) {
        // with pagination
        vendorAdapter.setAll(state, payload.results)
        state.count = payload.count
      } else {
        // without pagination
        vendorAdapter.setAll(state, payload)
        state.count = payload.length
      }
    })
    builder.addCase(fetchVendorById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchVendorById.fulfilled, (state, { payload }) => {
      vendorAdapter.upsertOne(state, payload)
      state.status = SUCCESS_STATUS
    })
  },
})

const { reducer } = vendorSlice

export default reducer
