export function getResolveState(
  selectedGoals,
  isMrdCase,
  hasRelatedDoctor,
  hasRelatedFacility,
  hasRelatedTreatmentFacility
) {
  let isDisable = true
  if (!selectedGoals.length) {
    return isDisable
  }

  if (!isMrdCase) {
    if (hasRelatedDoctor || hasRelatedFacility) {
      isDisable = false
    }

    return isDisable
  }

  const sortedSelectedGoals = selectedGoals.sort().join('_')

  const mapping = {
    find_doctor: {
      [[false, false, false].join('_')]: true,
    },
    find_mrd_facility: {
      [[false, false, false].join('_')]: true,
      [[false, true, false].join('_')]: true,
    },
    find_treatment_facility: {
      [[false, false, false].join('_')]: true,
      [[false, false, true].join('_')]: true,
    },
    find_doctor_find_mrd_facility: {
      [[false, false, false].join('_')]: true,
      [[false, true, false].join('_')]: true,
    },
    find_doctor_find_treatment_facility: {
      [[false, false, false].join('_')]: true,
    },
    find_doctor_find_mrd_facility_find_treatment_facility: {
      [[false, false, false].join('_')]: true,
      [[true, false, true].join('_')]: true,
    },
    find_mrd_facility_find_treatment_facility: {
      [[false, false, false].join('_')]: true,
      [[false, true, false].join('_')]: true,
      [[false, false, true].join('_')]: true,
    },
  }

  const relationString = [
    !!hasRelatedDoctor,
    !!hasRelatedTreatmentFacility,
    !!hasRelatedFacility,
  ].join('_')

  isDisable = !!mapping[sortedSelectedGoals][relationString]

  return isDisable
}

export function getConfirmationDialogState(
  selectedGoals,
  isMrdCase,
  hasRelatedDoctor,
  hasRelatedFacility,
  hasRelatedTreatmentFacility
) {
  let isShow = false
  const sortedSelectedGoals = selectedGoals.sort().join('_')

  if (!selectedGoals.length) {
    return isShow
  }

  if (!isMrdCase) {
    const mapping = {
      find_doctor: {
        [[true, false].join('_')]: true,
      },
      find_facility: {
        [[true, false].join('_')]: true,
      },
      find_doctor_find_facility: {
        [[true, false].join('_')]: true,
      },
    }

    const relationString = [!!hasRelatedDoctor, !!hasRelatedFacility].join('_')
    isShow = !!mapping[sortedSelectedGoals][relationString]
    return isShow
  }

  const mapping = {
    find_doctor: {
      [[true, false, false].join('_')]: true,
    },
    find_mrd_facility: {
      [[true, false, false].join('_')]: true,
    },
    find_treatment_facility: {
      [[true, false, false].join('_')]: true,
    },
    find_doctor_find_mrd_facility: {
      [[true, false, false].join('_')]: true,
    },
    find_doctor_find_treatment_facility: {
      [[true, false, false].join('_')]: true,
    },
    find_doctor_find_mrd_facility_find_treatment_facility: {
      [[true, false, false].join('_')]: true,
    },
    find_mrd_facility_find_treatment_facility: {
      [[true, false, false].join('_')]: true,
    },
  }

  const relationString = [
    !!hasRelatedDoctor,
    !!hasRelatedTreatmentFacility,
    !!hasRelatedFacility,
  ].join('_')
  isShow = !!mapping[sortedSelectedGoals][relationString]

  return isShow
}
