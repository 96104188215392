import Api from './index'
import { API_URL } from '../constants'

class HealthNetworks extends Api {
  getHealthNetworkList(id, params) {
    return this.get('/', { params })
  }

  getHealthNetworkById(id) {
    return this.get(`${id}/`)
  }

  getHealthNetworkFacilities(_, params) {
    const { id, ...rest } = params
    return this.get(`${id}/providers/facilities/`, { params: rest })
  }

  getHealthNetworkDoctors(_, params) {
    const { id, ...rest } = params
    return this.get(`${id}/providers/doctors/`, { params: rest })
  }

  getHealthNetworkSoleProprietors(_, params) {
    const { id, ...rest } = params
    return this.get(`${id}/providers/sole_proprietors/`, { params: rest })
  }

  createHealthNetwork(data) {
    return this.post(`/`, { data })
  }

  removeHealthNetwork(id) {
    return this.del(`${id}/`)
  }

  updateHealthNetwork(id, data) {
    return this.put(`${id}/`, { data })
  }

  removeHealthNetworkFacility(id, data) {
    return this.post(`${id}/remove_facility/`, { data })
  }

  addHealthNetworkFacility(id, data) {
    return this.post(`${id}/add_facility/`, { data })
  }

  checkHealthNetworkFacility(data) {
    return this.post(`/facility_in_health_network/`, { data })
  }

  removeHealthNetworkDoctor(id, data) {
    return this.post(`${id}/remove_doctor/`, { data })
  }

  addHealthNetworkDoctor(id, data) {
    return this.post(`${id}/add_doctor/`, { data })
  }
}

export default new HealthNetworks({
  baseURL: `${API_URL}health_network/`,
})
