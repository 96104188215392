import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useUser } from '../../../providers/UserProvider'

const DefaultRoute = () => {
  const { token } = useSelector(state => state.auth)
  const { getAllowedRoute } = useUser()

  if (token) {
    return <Redirect to={getAllowedRoute()?.path || '/forbidden'} />
  }

  return <Redirect to="/login" />
}

export default DefaultRoute
