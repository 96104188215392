import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import UserService from '../../../services/UserService'

export const fetchAllUsers = createAsyncThunk(
  'users/fetchAll',
  async (data = {}) => {
    const { skipPagination, ...rest } = data
    if (skipPagination) {
      return UserService.getUsers(rest)
    }
    return getPagination(rest, UserService.getUsers.bind(UserService))
  }
)

export const fetchUserById = createAsyncThunk('users/fetchById', async id => {
  const response = await UserService.getUserById(id)

  return response
})

export const createUser = createAsyncThunk(
  'users/createOne',
  async ({ data }) => {
    const response = await UserService.createUser(data)

    return response
  }
)

export const updateUser = createAsyncThunk(
  'users/updateOne',
  async ({ id, data }) => {
    const response = await UserService.updateUser(id, data)

    return { id, changes: response }
  }
)
