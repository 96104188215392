import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Row, Col, Card, Form } from 'antd'
import { Field, Formik } from 'formik'
import MainLayout from '../../layouts/MainLayout'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import TextField from '../../shared/TextField'
import CheckboxField from '../../shared/CheckboxField'
import { assignCopyCompanySchema } from '../../../schema/assignCopyCompany'
import {
  createAssignmentCopyCompany,
  fetchAssignmentCopyCompanyById,
  selectAssignmentCopyCompanyById,
  updateAssignmentCopyCompany,
} from '../../../state/modules/assignmentCopyCompanies'
import { useUser } from '../../../providers/UserProvider'

const AssignmentCopyCompanyDetails = () => {
  const history = useHistory()
  const { ccAssignmentId } = useParams()
  const { isUserActionAllowed } = useUser()
  const assignmentCopyCompany = useSelector(state =>
    selectAssignmentCopyCompanyById(state, ccAssignmentId)
  )
  const isEditPage = !!ccAssignmentId
  const [initialValues, setInitialValues] = useState({
    cms_id: '',
    phone_number: '',
    is_enabled: false,
    site_id: '',
  })

  const dispatchHttp = useDispatchHttp()

  const breadcrumbsAddRolePage = [
    { name: 'Copy Company Assignments', path: '/copy-company-assignment' },
    {
      name: `Add Assignment`,
    },
  ]

  const breadcrumbsEditRolePage = [
    { name: 'Copy Company Assignments', path: '/copy-company-assignment' },
    {
      name: `Edit Assignment: ${assignmentCopyCompany?.copy_company?.name}`,
    },
  ]

  const onUpdate = useCallback(
    data => {
      const { cms_id: _, ...formattedData } = data
      return dispatchHttp(
        updateAssignmentCopyCompany({
          id: ccAssignmentId,
          copy_company_cms_id: data.cms_id,
          ...formattedData,
        }),
        'Copy Company Assignment has been successfully updated'
      )
    },
    [dispatchHttp, ccAssignmentId]
  )

  const onCreate = useCallback(
    data => {
      const { cms_id: _, ...formattedData } = data
      return dispatchHttp(
        createAssignmentCopyCompany({
          copy_company_cms_id: data.cms_id,
          ...formattedData,
        }),
        'Copy Company Assignment has been successfully added'
      )
    },
    [dispatchHttp]
  )

  const onSubmit = useCallback(
    async data => {
      if (isEditPage) {
        await onUpdate(data)
      } else {
        await onCreate(data)
      }
      history.push('/copy-company-assignment')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isEditPage,
      updateAssignmentCopyCompany,
      createAssignmentCopyCompany,
      history,
    ]
  )

  useEffect(() => {
    if (assignmentCopyCompany) {
      setInitialValues({
        cms_id: assignmentCopyCompany?.copy_company.cms_id,
        phone_number: assignmentCopyCompany.phone_number,
        is_enabled: assignmentCopyCompany.is_enabled,
        site_id: assignmentCopyCompany.site_id,
      })
    }
  }, [assignmentCopyCompany])

  useEffect(() => {
    if (+ccAssignmentId) {
      dispatchHttp(fetchAssignmentCopyCompanyById(ccAssignmentId))
    }
  }, [dispatchHttp, ccAssignmentId])

  return (
    <MainLayout
      withSubHeader
      subHeaderProps={{
        breadcrumb: isEditPage
          ? breadcrumbsEditRolePage
          : breadcrumbsAddRolePage,
      }}
    >
      <Row justify="center">
        <Card className="user-info-card">
          <Col>
            <Formik
              initialValues={initialValues}
              validationSchema={assignCopyCompanySchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ handleSubmit, dirty, isSubmitting }) => (
                <Form
                  onFinish={handleSubmit}
                  className="assignment-copy-company-form"
                  layout="vertical"
                >
                  <Field
                    component={TextField}
                    label="Copy Company CMS ID"
                    size="medium"
                    name="cms_id"
                    required
                  />
                  <Field
                    component={TextField}
                    label="Phone Number"
                    size="medium"
                    name="phone_number"
                    required
                  />
                  <Field
                    component={TextField}
                    label="Site ID"
                    size="medium"
                    name="site_id"
                    required
                  />
                  <Field
                    name="is_enabled"
                    label="Enabled"
                    component={CheckboxField}
                  />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ paddingTop: '30px' }}
                  >
                    <Col>
                      <Button
                        onClick={() => {
                          history.push('/copy-company-assignment')
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      {isUserActionAllowed('change_assignmentcopycompany') &&
                        isEditPage && (
                          <Button
                            type="primary"
                            ghost
                            htmlType="submit"
                            disabled={!dirty}
                            loading={isSubmitting}
                          >
                            Edit
                          </Button>
                        )}
                      {!isEditPage && (
                        <Button
                          type="primary"
                          ghost
                          htmlType="submit"
                          disabled={!dirty}
                          loading={isSubmitting}
                        >
                          Create
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Card>
      </Row>
    </MainLayout>
  )
}

export default AssignmentCopyCompanyDetails
