import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import ActivityLogService from '../../../services/ActivityLogService'

export const getActivityLogs = createAsyncThunk(
  'activityLogs/getList',
  async data => {
    const response = await getPagination(
      data,
      ActivityLogService.getActivityLogs.bind(ActivityLogService)
    )

    return response
  }
)

export const getActivityLogById = createAsyncThunk(
  'activityLogs/getById',
  async id => {
    const response = await ActivityLogService.getActivityLogById(id)

    return response
  }
)
