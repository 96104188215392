import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { List, Row, Col, Button, Card, Divider, Form, Typography } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchFormById } from '../../../state/modules/facilities'

import { useToggle } from '../../../hooks/useToggle'
import ActivityLogDataItem from './ActivityLogDataItem'
import ActivityLogAuthor from './ActivityLogAuthor'
import {
  mapActivityLogChanges,
  mapActivityLogChangesForTable,
  formatDateTime,
} from '../../../utils/helpers'
import {
  downloadFileByUrl,
  getModelPath,
  getModelNameForPath,
} from '../../../utils/functions'
import { routePaths } from '../../../utils/routes'
import { ACTIVITY_LOG_ENTITIES, MODEL_NAMES } from '../../../constants'
import { useUser } from '../../../providers/UserProvider'
import SearchTable from '../Tables/SearchTable'
import {
  activityLogUsersColumns,
  activityLogFacilityColumns,
} from '../../../data'

const activityLogTablesMap = {
  [MODEL_NAMES.GAIA_USER]: activityLogUsersColumns,
  [MODEL_NAMES.FACILITY]: activityLogFacilityColumns,
}

const ActivityLogCard = ({
  data: {
    changes,
    model_name,
    name,
    author,
    created_at,
    action,
    instance_id,
  } = {},
  userId,
  edited,
  selectedEntityFilter,
}) => {
  const { entity } = useParams()
  const dispatch = useDispatch()
  const [open, setOpen] = useToggle(false)
  const { isUserActionAllowed } = useUser()

  const handleOpenModel = () => {
    if (model_name === MODEL_NAMES.AUTH_FORM) {
      dispatch(fetchFormById({ formId: instance_id }))
        .then(unwrapResult)
        .then(resp => {
          downloadFileByUrl(resp.changes.link, name)
        })
    } else {
      const modelViewPath = getModelPath(model_name)

      if (modelViewPath) {
        window.open(`/${modelViewPath}/${instance_id}`, '_blank')
      }
    }
  }

  const isNameAction =
    model_name !== MODEL_NAMES.DOCTOR_TO_FACILITY &&
    model_name !== MODEL_NAMES.INSURANCE_COMPANY &&
    model_name !== MODEL_NAMES.AGENCY &&
    model_name !== MODEL_NAMES.VENDOR &&
    model_name !== MODEL_NAMES.FACILITY_VENDOR &&
    model_name !== MODEL_NAMES.DOCTOR_VENDOR &&
    model_name !== MODEL_NAMES.RETRIEVER
  const showInstanceID =
    model_name !== MODEL_NAMES.FACILITY_VENDOR &&
    model_name !== MODEL_NAMES.DOCTOR_VENDOR

  const showInTable =
    model_name === MODEL_NAMES.FACILITY || model_name === MODEL_NAMES.GAIA_USER
  const entityName = `${name || ''} ${showInstanceID ? `#${instance_id}` : ''}`
  const isInstaceFilter = !!entity

  return (
    <List.Item>
      <Card className="activity-log-card">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {isInstaceFilter ? (
            <Typography.Text className="activity-log-card__title">
              {entityName}
            </Typography.Text>
          ) : (
            <Link
              to={routePaths.openEntityHistory(
                getModelNameForPath(model_name),
                instance_id,
                userId,
                edited,
                selectedEntityFilter
              )}
            >
              <Typography.Link className="activity-log-card__title">
                {entityName}
              </Typography.Link>
            </Link>
          )}
          <Typography.Paragraph
            type="secondary"
            // className="exception-log-card__text"
            style={{ marginBottom: 0 }}
          >
            by{' '}
            <span>
              <ActivityLogAuthor id={author.id} name={author.name} />
            </span>{' '}
            {formatDateTime(created_at)}
          </Typography.Paragraph>
        </div>
        <Row align="middle" justify="space-between">
          <Col flex="1 0">
            <div className="activity-log-card__reason">
              {isNameAction &&
              isUserActionAllowed(ACTIVITY_LOG_ENTITIES[model_name]) ? (
                <Typography.Link onClick={handleOpenModel}>
                  {model_name}
                </Typography.Link>
              ) : (
                model_name
              )}
              {`: ${action}`}
            </div>
          </Col>
          <Col>
            {action !== 'Delete' && (
              <Button
                type="link"
                onClick={setOpen}
                style={{ padding: 0, height: '22px' }}
              >
                {open ? 'Hide' : 'Show More'}{' '}
                {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              </Button>
            )}
          </Col>
        </Row>
        <div className={cx('activity-log-card__bottom', { open })}>
          <Divider />
          <Form layout="vertical">
            {(() => {
              if (action === 'Create' && showInTable) {
                return (
                  <SearchTable
                    className="activityLogCardTable"
                    columns={activityLogTablesMap[model_name]}
                    totalData={[mapActivityLogChangesForTable(changes)]}
                    pagination={false}
                    rowKey="id"
                  />
                )
              }

              return mapActivityLogChanges(
                changes
              ).map(([key, value], index) => (
                <ActivityLogDataItem
                  fieldKey={key}
                  fieldValue={value}
                  number={index + 1}
                  key={`row-${index + 1}`}
                  action={action}
                />
              ))
            })()}
          </Form>
        </div>
      </Card>
    </List.Item>
  )
}

export default ActivityLogCard
