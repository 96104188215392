import React, { memo } from 'react'
import { Row, Col, Typography } from 'antd'
import PropTypes from 'prop-types'

import MainLayout from '../MainLayout'
import Breadcrumb from '../../components/Breadcrumbs'

const DetailPageLayout = ({ children, breadcrumbs, title, extraButtons }) => (
  <MainLayout>
    <div className="detail-page-layout">
      {title && (
        <div className="detail-page-layout__header">
          <Row justify="center">
            <Col style={{ padding: '0 12px', flex: '0 0 100%' }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Typography.Title level={3}>{title}</Typography.Title>
                </Col>
                <Col>{extraButtons}</Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
      <Row justify="center">
        <Col style={{ padding: '0 12px', flex: '0 0 100%' }}>
          {breadcrumbs.length ? (
            <div className="detail-page-layout__breadcrumbs">
              <Breadcrumb crumbs={breadcrumbs} />
            </div>
          ) : null}
          {children}
        </Col>
      </Row>
    </div>
  </MainLayout>
)

DetailPageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, path: PropTypes.string })
  ),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  extraButtons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

DetailPageLayout.defaultProps = {
  children: null,
  breadcrumbs: [],
  title: '',
  extraButtons: null,
}

export default memo(DetailPageLayout)
