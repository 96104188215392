import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getActivityLogCases } from './actions'
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const activityLogCaseAdapter = createEntityAdapter()
const initialState = activityLogCaseAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const activityLogCaseSlice = createSlice({
  name: 'activityLogCases',
  initialState,
  extraReducers: builder => {
    builder.addCase(getActivityLogCases.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getActivityLogCases.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        activityLogCaseAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
  },
})

const { reducer } = activityLogCaseSlice

export default reducer
