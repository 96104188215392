export const ACTIVITY_LOG_ENTITIES = {
  Case: 'view_case',
  Facility: 'view_facility',
  Doctor: 'view_doctor',
  FacilityProfile: 'view_facilityprofile',
  AuthorizationForm: 'download_authorizationform',
  FacilityCluster: 'view_facilitycluster',
  HealthNetwork: 'view_healthnetwork',
  User: 'view_user',
  InsuranceCompany: 'insurance_company',
  Agency: 'agency',
  AssignmentCopyCompany: 'assignment_copy_company',
}
