import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getInsuranceCompanies, resetState } from './actions'
import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'

export const InsuranceCompanyAdapter = createEntityAdapter()
const initialState = InsuranceCompanyAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const InsuranceCompanieslice = createSlice({
  name: 'insuranceCompanies',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      InsuranceCompanyAdapter.removeAll(state)
    })
    builder.addCase(getInsuranceCompanies.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getInsuranceCompanies.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        InsuranceCompanyAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(getInsuranceCompanies.rejected, state => {
      state.status = FAILURE_STATUS
    })
  },
})

const { reducer } = InsuranceCompanieslice

export default reducer
