import React, { memo, useState, useEffect } from 'react'
import {
  Card,
  Avatar,
  Skeleton,
  Tag,
  Row,
  Col,
  Menu,
  Dropdown,
  Typography,
  Button,
  Space,
  Tooltip,
} from 'antd'
import {
  MoreOutlined,
  StarOutlined,
  CheckOutlined,
  UserOutlined,
  LinkOutlined,
  BookFilled,
  DeleteOutlined,
  PartitionOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
/* eslint-disable */

import { capitalizeEveryWord, formatPhoneNumber } from '../../../utils/helpers'
import { addressType, FACILITY, DOCTOR } from '../../../constants'

import FacilityLogo from '../../../assets/facility-logo.svg'
import CopyView from '../CopyView'
import { useUser } from '../../../providers/UserProvider'
import { uniqBy } from '../../../utils/helpers'

const MAX_DISPLAYED_OTHER_NAMES_COUNT = 20
const MAX_DISPLAYED_OTHER_NPIS_COUNT = 20

const InfoCard = ({
  data,
  loading,
  entity,
  menu,
  onRelateException,
  onRelateTreatmentFacility,
  onRemoveRelation,
  onRemoveTreatmentRelation,
  hasRelation,
  hasRelationTreatmentFacility,
  isMrdCase,
  exceptionId,
  isActionDisabled,
  hideMenuOption,
}) => {
  const [displayOtherNames, setDisplayOtherNames] = useState(false)
  const [displayOtherNpis, setDisplayOtherNpis] = useState(false)
  const [
    disabledSkipOnImportVendorRelations,
    setDisabledSkipOnImportVendorRelations,
  ] = useState([])
  const { isUserActionAllowed } = useUser()

  const {
    name: entityName,
    is_headquarter,
    health_network,
    npi,
    taxonomy_group_name,
    addresses,
    phone_numbers,
    emails,
    fax_numbers,
    other_names,
    is_sole_proprietor,
    first_name,
    last_name,
    id,
    is_mrd,
    other_npis,
    vendor_relations,
  } = data

  const allNpis = [npi].concat(other_npis).filter(Boolean)

  const isCurrentRelated = hasRelation === data.id
  const isCurrentTreatmentFacilityRelated =
    hasRelationTreatmentFacility === data.id

  const canRelateFacility =
    !hasRelationTreatmentFacility ||
    (hasRelationTreatmentFacility && hasRelationTreatmentFacility !== data.id)
  const canRelateTreatmentFacility =
    !hasRelation || (hasRelation && hasRelation !== data.id)

  useEffect(() => {
    setDisabledSkipOnImportVendorRelations(
      vendor_relations?.filter(vendor => !vendor.skip_on_import)
    )
  }, [vendor_relations])

  return (
    <div className="info-card">
      <Card bordered={false}>
        {loading ? (
          <Skeleton
            loading={loading}
            avatar={{ size: 90, shape: 'square' }}
            active
            paragraph={{ rows: 4 }}
          />
        ) : (
          <>
            <div className="info-card__header">
              <Row justify="space-between">
                <Col xxl={24} lg={24}>
                  <Row gutter={24} wrap={false}>
                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      {entity === DOCTOR ? (
                        <Avatar
                          className="search-table__user-avatar"
                          size={90}
                          icon={
                            <UserOutlined className="search-table__user-avatar--icon" />
                          }
                        />
                      ) : (
                        <Avatar src={FacilityLogo} size={90} shape="square" />
                      )}
                    </Col>
                    <Col flex={1}>
                      <div
                        className="info-card__title"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {entityName
                          ? capitalizeEveryWord(entityName)
                          : capitalizeEveryWord(`${first_name} ${last_name}`)}
                        {isUserActionAllowed('manage_relations_case') && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginBottom: 10,
                            }}
                          >
                            {entity === 'doctor' &&
                              onRelateException &&
                              exceptionId &&
                              !isActionDisabled && (
                                <Col pull={0}>
                                  {hasRelation && isCurrentRelated ? (
                                    <Button
                                      type="text"
                                      size="large"
                                      onClick={onRemoveRelation}
                                    >
                                      <Typography.Text type="danger">
                                        <Space>
                                          <DeleteOutlined />
                                          Remove Relation
                                        </Space>
                                      </Typography.Text>
                                    </Button>
                                  ) : !hasRelation ? (
                                    <Button
                                      icon={<LinkOutlined />}
                                      onClick={() => onRelateException(id)}
                                    >
                                      Relate Doctor to Case {exceptionId}
                                    </Button>
                                  ) : null}
                                </Col>
                              )}

                            {entity === 'facility' &&
                              onRelateException &&
                              exceptionId &&
                              !isActionDisabled && (
                                <Col pull={0}>
                                  {hasRelation && isCurrentRelated ? (
                                    <Button
                                      type="text"
                                      size="large"
                                      onClick={onRemoveRelation}
                                    >
                                      <Typography.Text type="danger">
                                        <Space>
                                          <DeleteOutlined />
                                          Remove Relation
                                        </Space>
                                      </Typography.Text>
                                    </Button>
                                  ) : !hasRelation && canRelateFacility ? (
                                    <Button
                                      icon={<LinkOutlined />}
                                      onClick={() => onRelateException(id)}
                                    >
                                      {isMrdCase
                                        ? `Relate MRD Facility to Case ${exceptionId}`
                                        : `Relate Facility to Case ${exceptionId}`}
                                    </Button>
                                  ) : null}
                                </Col>
                              )}

                            {isMrdCase &&
                              onRelateTreatmentFacility &&
                              exceptionId &&
                              !isActionDisabled && (
                                <Col pull={0}>
                                  {hasRelationTreatmentFacility &&
                                  isCurrentTreatmentFacilityRelated ? (
                                    <Button
                                      type="text"
                                      size="large"
                                      onClick={onRemoveTreatmentRelation}
                                    >
                                      <Typography.Text type="danger">
                                        <Space>
                                          <DeleteOutlined />
                                          Remove Relation
                                        </Space>
                                      </Typography.Text>
                                    </Button>
                                  ) : !hasRelationTreatmentFacility &&
                                    canRelateTreatmentFacility ? (
                                    <Button
                                      icon={<LinkOutlined />}
                                      onClick={() =>
                                        onRelateTreatmentFacility(id)
                                      }
                                    >
                                      Relate Treatment Facility to Case{' '}
                                      {exceptionId}
                                    </Button>
                                  ) : null}
                                </Col>
                              )}
                          </div>
                        )}

                        {!onRelateException &&
                          !onRelateTreatmentFacility &&
                          !!hideMenuOption.length && (
                            <Col>
                              <Dropdown.Button
                                icon={<MoreOutlined />}
                                trigger="click"
                                overlay={
                                  <Menu>
                                    {menu.map(
                                      ({ content, key, hide, ...props }) => {
                                        if (hide) {
                                          return null
                                        }

                                        return (
                                          <Menu.Item key={key} {...props}>
                                            {content}
                                          </Menu.Item>
                                        )
                                      }
                                    )}
                                  </Menu>
                                }
                              />
                            </Col>
                          )}
                      </div>
                      {!!other_names.length && (
                        <div
                          className="info-card__title--sub"
                          style={{ width: '95%' }}
                        >
                          {other_names
                            .slice(
                              0,
                              displayOtherNames
                                ? other_names.length
                                : MAX_DISPLAYED_OTHER_NAMES_COUNT
                            )
                            .map(({ name }) => capitalizeEveryWord(name))
                            .join(', ')}
                          {other_names.length >
                            MAX_DISPLAYED_OTHER_NAMES_COUNT &&
                            (displayOtherNames ? (
                              <span
                                role="button"
                                tabIndex={0}
                                onClick={() =>
                                  setDisplayOtherNames(!displayOtherNames)
                                }
                                className="info-card__button-collapse"
                              >
                                See Less...
                              </span>
                            ) : (
                              <span
                                role="button"
                                tabIndex={0}
                                onClick={() =>
                                  setDisplayOtherNames(!displayOtherNames)
                                }
                                className="info-card__button-collapse"
                              >
                                See More...
                              </span>
                            ))}
                        </div>
                      )}
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexWrap: 'wrap',
                        }}
                      >
                        <Col
                          className="info-card__id"
                          style={{ marginTop: '2px' }}
                        >
                          <CopyView title={`Jaia ID: ${id}`} value={id} />
                        </Col>
                        {health_network?.map(item => (
                          <Col style={{ marginTop: '2px' }} key={item.id}>
                            <Link
                              to={`/health-networks/${item.id}`}
                              target="_blank"
                            >
                              <Tag
                                color="processing"
                                icon={<PartitionOutlined />}
                              >
                                {item.name}
                              </Tag>
                            </Link>
                          </Col>
                        ))}
                        {is_headquarter && (
                          <Col style={{ marginTop: '2px' }}>
                            <Tag color="processing" icon={<StarOutlined />}>
                              Facility Cluster Headquarter
                            </Tag>
                          </Col>
                        )}
                        {is_mrd && (
                          <Col style={{ marginTop: '2px' }}>
                            <Tag color="processing" icon={<BookFilled />}>
                              MRD
                            </Tag>
                          </Col>
                        )}
                        {taxonomy_group_name && (
                          <Col style={{ marginTop: '2px' }}>
                            <Tag className="info-card__tag">
                              <Typography.Paragraph>
                                <strong>Taxonomy: </strong>
                                {taxonomy_group_name}
                              </Typography.Paragraph>
                            </Tag>
                          </Col>
                        )}
                        {allNpis
                          .slice(
                            0,
                            displayOtherNpis
                              ? allNpis.length
                              : MAX_DISPLAYED_OTHER_NPIS_COUNT
                          )
                          .map(item => (
                            <Col style={{ marginTop: '2px' }}>
                              <Tag key={item} style={{ marginBottom: 10 }}>
                                <strong>NPI:</strong>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://npiregistry.cms.hhs.gov/provider-view/${item}`}
                                >
                                  {' '}
                                  {item}
                                </a>
                              </Tag>
                            </Col>
                          ))}
                        {other_npis?.length > MAX_DISPLAYED_OTHER_NPIS_COUNT &&
                          (displayOtherNpis ? (
                            <span
                              role="button"
                              tabIndex={0}
                              onClick={() =>
                                setDisplayOtherNpis(!displayOtherNpis)
                              }
                              className="info-card__button-collapse"
                            >
                              See Less...
                            </span>
                          ) : (
                            <span
                              role="button"
                              tabIndex={0}
                              onClick={() =>
                                setDisplayOtherNpis(!displayOtherNpis)
                              }
                              className="info-card__button-collapse"
                            >
                              See More...
                            </span>
                          ))}

                        {is_sole_proprietor && (
                          <Col flex={1}>
                            <Tag color="processing" icon={<CheckOutlined />}>
                              Sole Proprietor
                            </Tag>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="info-card__body">
              {addresses.map(
                (
                  {
                    type,
                    state,
                    city,
                    address_line_1,
                    address_line_2,
                    zip_code,
                    timezone,
                  },
                  index
                ) => (
                  <div className="info-card__row" key={`address_${index + 1}`}>
                    <div className="info-card__row--name">
                      {addressType[type]} Address{' '}
                      {timezone && (
                        <Tag style={{ marginLeft: '4px' }}>
                          <ClockCircleOutlined /> {timezone}
                        </Tag>
                      )}
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">State</div>
                      <div className="info-card__col--value">{state}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">City</div>
                      <div className="info-card__col--value">
                        {capitalizeEveryWord(city)}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Zip Code</div>
                      <div className="info-card__col--value">{zip_code}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">
                        Address Line 1
                      </div>
                      <div className="info-card__col--value">
                        {capitalizeEveryWord(address_line_1)}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">
                        Address Line 2
                      </div>
                      <div className="info-card__col--value">
                        {capitalizeEveryWord(address_line_2)}
                      </div>
                    </div>
                  </div>
                )
              )}

              <div className="info-card__row">
                <div className="info-card__row--name">Contacts</div>
                {phone_numbers.map(
                  ({ number, type, phone_extension, verified }, index) => (
                    <div className="info-card__col" key={`number_${index + 1}`}>
                      <div className="info-card__col--label">
                        Phone Number {addressType[type]}{' '}
                        {verified ? (
                          <Tooltip
                            title="Contact is verified"
                            placement="topRight"
                          >
                            <CheckOutlined style={{ color: '#1890ff' }} />
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="info-card__col--value">
                        {formatPhoneNumber(number, phone_extension)}
                      </div>
                    </div>
                  )
                )}
                {emails.map(({ address, type }, index) => (
                  <div className="info-card__col" key={`email_${index + 1}`}>
                    <div className="info-card__col--label">
                      E-mail {addressType[type]}
                    </div>
                    <div className="info-card__col--value">{address}</div>
                  </div>
                ))}

                {fax_numbers.map(({ number, type, verified }, index) => (
                  <div className="info-card__col" key={`fax_${index + 1}`}>
                    <div className="info-card__col--label">
                      Fax {addressType[type]}{' '}
                      {verified ? (
                        <Tooltip
                          title="Contact is verified"
                          placement="topRight"
                        >
                          <CheckOutlined style={{ color: '#1890ff' }} />
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="info-card__col--value">
                      {formatPhoneNumber(number)}
                    </div>
                  </div>
                ))}
              </div>
              {vendor_relations &&
                vendor_relations.length > 0 &&
                disabledSkipOnImportVendorRelations?.length > 0 && (
                  <div className="info-card__row">
                    <div className="info-card__row--name">Vendors</div>
                    {uniqBy(
                      disabledSkipOnImportVendorRelations,
                      'vendor_id'
                    ).map(({ vendor__name }, index) => (
                      <div
                        className="info-card__col"
                        key={`vendor_${index + 1}`}
                      >
                        <div className="info-card__col--value">
                          {vendor__name}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

InfoCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    is_headquarter: PropTypes.bool,
    npi: PropTypes.number,
    taxonomy_group_name: PropTypes.string,
    addresses: PropTypes.arrayOf(PropTypes.object),
    phone_numbers: PropTypes.arrayOf(PropTypes.object),
    emails: PropTypes.arrayOf(PropTypes.object),
    fax_numbers: PropTypes.arrayOf(PropTypes.object),
    other_names: PropTypes.arrayOf(PropTypes.object),
    is_sole_proprietor: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    id: PropTypes.number,
    cluster: PropTypes.number,
    is_mrd: PropTypes.bool,
    other_npis: PropTypes.arrayOf(PropTypes.number),
  }),
  loading: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    })
  ),
  entity: PropTypes.oneOf([DOCTOR, FACILITY]),
  onRelateException: PropTypes.func,
  exceptionId: PropTypes.string,
  hasRelation: PropTypes.bool,
  hasRelationTreatmentFacility: PropTypes.bool,
  onRelateTreatmentFacility: PropTypes.func,
  onRemoveRelation: PropTypes.func,
  onRemoveTreatmentRelation: PropTypes.func,
  isMrdCase: PropTypes.bool,
  isActionDisabled: PropTypes.bool,
}

InfoCard.defaultProps = {
  data: {
    name: '',
    headquarter_of_cluster: false,
    npi: 0,
    taxonomy_group_name: '',
    addresses: [],
    phone_numbers: [],
    emails: [],
    fax_numbers: [],
    other_names: [],
    is_sole_proprietor: false,
    first_name: '',
    last_name: '',
    cluster: null,
    other_npis: [],
  },
  loading: false,
  menu: [],
  entity: FACILITY,
  onRelateException: null,
  exceptionId: '',
  hasRelation: false,
  hasRelationTreatmentFacility: false,
  onRelateTreatmentFacility: null,
  onRemoveRelation: null,
  onRemoveTreatmentRelation: null,
  isMrdCase: false,
  isActionDisabled: false,
}

export default memo(InfoCard)
