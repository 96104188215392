import React, { useCallback } from 'react'
import { Checkbox } from 'antd'
import PropTypes from 'prop-types'

const ToggleField = ({
  field,
  form: { touched, errors, setFieldTouched, ...form },
  label,
}) => {
  const onChangeHandle = useCallback(
    value => {
      form.setFieldValue(field.name, value)
    },
    [field.name, form]
  )

  return (
    <Checkbox onChange={onChangeHandle} {...field} checked={field.value}>
      {label}
    </Checkbox>
  )
}

ToggleField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.objectOf(PropTypes.bool),
    errors: PropTypes.objectOf(PropTypes.string),
    setFieldTouched: PropTypes.func,
  }).isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf(['large', 'small', 'medium']),
}

ToggleField.defaultProps = {
  field: PropTypes.shape({
    value: false,
    onChange: () => null,
    onBlur: () => null,
  }),
  label: '',
  size: 'large',
}

export default ToggleField
