import Api from './index'
import { API_URL } from '../constants'

class Search extends Api {
  getSearchIndexes() {
    return this.get('indexes/')
  }

  searchRequest({ data }) {
    return this.post('search/', { data })
  }

  searchDoctors({ data }) {
    return this.post('search/doctors/', { data })
  }

  searchFacilities({ data }) {
    return this.post('search/facilities/', { data })
  }

  searchFacilityProfiles({ data }) {
    return this.post('search/facility_profiles/', { data })
  }
}

export default new Search({
  baseURL: API_URL,
})
