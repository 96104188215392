import React from 'react'
import { Link } from 'react-router-dom'

export const ErrorContent = ({ reset }) => (
  <div className="error-content">
    <div>Oops... Something went wrong...</div>
    <Link to="/" onClick={reset}>
      Go to Home Screen
    </Link>
  </div>
)
