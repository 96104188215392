import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Typography, Tag } from 'antd'
import {
  selectImportLogById,
  isFetchingImportLogs,
  getImportLogById,
  getImportLogFile,
} from '../../../state/modules/importLogs'
import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { mapImportLogsStatusColor } from '../../../utils/helpers'

const RenderImportLogTableTitle = file => {
  const { id } = useParams()
  const dispatch = useDispatch()

  return (
    <Typography.Link
      onClick={() => {
        dispatch(getImportLogFile(id))
      }}
    >
      {file.file_name}
    </Typography.Link>
  )
}

const RenderImportLogTableStatus = status => (
  <Tag color={mapImportLogsStatusColor(status)}>{status}</Tag>
)
const detailColumns = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Date | Time',
    key: 'started_at',
    date: true,
  },
  {
    title: 'Title',
    key: 'cms_file',
    render: RenderImportLogTableTitle,
  },
  {
    title: 'Status',
    key: 'status',
    render: RenderImportLogTableStatus,
  },
]
const statisticColumns = [
  {
    title: 'Total Cases',
    key: 'total_cases_in_file',
  },
  {
    title: 'On Review',
    key: 'total_cases_on_review',
  },
  {
    title: 'Mapped',
    key: 'total_cases_mapped',
  },
  {
    title: 'Rejected',
    key: 'total_cases_rejected',
  },
  {
    title: 'Success',
    key: 'total_cases_success',
  },
  {
    title: 'Warnings',
    key: 'total_exceptions',
  },
  {
    title: 'Reprocessed',
    key: 'total_cases_reprocessed',
  },
  {
    title: 'Skipped',
    key: 'total_cases_skipped',
  },
  {
    title: 'Created',
    key: 'total_cases_created',
  },
  {
    title: 'CMR',
    key: 'cmr',
  },
]

const caseItems = {
  0: [
    'total_cases_in_file',
    'total_cases_on_review',
    'total_cases_mapped',
    'total_cases_success',
    'total_cases_rejected',
    'total_exceptions',
    'total_cases_skipped',
  ],
  1: [
    'total_cases_in_file',
    'total_cases_on_review',
    'total_cases_mapped',
    'total_cases_success',
    'total_cases_rejected',
    'total_exceptions',
    'total_cases_skipped',
  ],
  2: [
    'total_cases_in_file',
    'total_cases_skipped',
    'total_cases_reprocessed',
    'total_cases_created',
    'total_cases_success',
    'total_cases_rejected',
    'total_exceptions',
  ],
}

const ImportLogDetails = () => {
  const dispatchHttp = useDispatchHttp()
  const { id } = useParams()
  const [columns, setColumns] = useState(statisticColumns)
  const importLog = useSelector(state => selectImportLogById(state, id))
  const loading = useSelector(isFetchingImportLogs)
  const title = importLog?.cms_file?.file_name
  const breadcrumbs = [
    { name: 'Import Log', path: '/data-sync-log' },
    {
      name: title,
      path: `/data-sync-log/${importLog?.id}`,
    },
  ]

  useEffect(() => {
    dispatchHttp(getImportLogById(id))
  }, [dispatchHttp, id])

  useEffect(() => {
    if (importLog?.case_type) {
      setColumns(
        caseItems[importLog.case_type].map(column =>
          statisticColumns.find(({ key }) => key === column)
        )
      )
    }
  }, [importLog?.case_type])

  return (
    <PageLayout breadcrumbs={breadcrumbs} title={title}>
      <Card style={{ width: '100%' }}>
        <Table
          columns={detailColumns}
          tableTitle="Import Details"
          loading={loading}
          totalData={[importLog]}
        />
        <Table
          columns={columns}
          tableTitle="Import Statistics"
          loading={loading}
          totalData={[importLog]}
        />
      </Card>
      <Card loading={loading}>
        <Typography.Paragraph>
          <pre>{importLog?.process_logs}</pre>
        </Typography.Paragraph>
      </Card>
    </PageLayout>
  )
}

export default ImportLogDetails
