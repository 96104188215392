import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import VendorFacilityService from '../../../services/VendorFacilityService'

export const getVendorFacilities = createAsyncThunk(
  'vendorFacility/getVendorFacilities',
  async data => {
    const response = await getPagination(
      data,
      VendorFacilityService.getVendorFacilities.bind(VendorFacilityService)
    )

    return { ...response }
  }
)

export const addVendorFacility = createAsyncThunk(
  'vendorFacility/addVendorFacility',
  async ({ data }) => {
    const response = await VendorFacilityService.addVendorFacility(data)

    return response
  }
)

export const removeVendorFacility = createAsyncThunk(
  'vendorFacility/removeVendorFacility',
  async id => {
    await VendorFacilityService.removeVendorFacility(id)

    return id
  }
)

export const updateVendorFacility = createAsyncThunk(
  'vendorFacility/updateVendorFacility',
  async ({ id, data }) => {
    const response = await VendorFacilityService.updateVendorFacility(id, data)

    return response
  }
)

export const resetState = createAction('vendorFacility/resetState')
