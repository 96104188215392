import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

const FormObserver = ({ onChange }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    onChange(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return null
}

FormObserver.propTypes = {
  onChange: PropTypes.func,
}

FormObserver.defaultProps = {
  onChange: () => null,
}

export default FormObserver
