/* eslint-disable class-methods-use-this */
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { uniq } from '../utils/helpers'
import history from '../history'

const mapErrors = errors => {
  let errorMsg = null

  if (typeof errors === 'string') return errors

  errorMsg = Object.values(errors).map(error => {
    if (error.constructor === Object) {
      return uniq(
        Object.values(error)
          .map(item => uniq(Object.values(item).flat()))
          .flat()
      )
    }

    return error.toString()
  })

  return errorMsg.toString()
}

class Api {
  constructor({ baseURL }) {
    this.instance = axios.create({ baseURL })
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
    this.instance.interceptors.response.use(
      ({ data }) => data,
      this.handleError
    )
  }

  handleRequest(response) {
    const params = response
    const userData = localStorage.getItem('user')

    if (userData) {
      const { token } = JSON.parse(userData)
      params.headers.common.Authorization = `Token ${token}`
    }

    return params
  }

  handleError(error) {
    const { config } = error
    const skipNotFoundRedirect = config?.skipNotFoundRedirect
    const method = config?.method
    if (error.response) {
      const { data, status, statusText } = error.response

      if (status >= StatusCodes.INTERNAL_SERVER_ERROR) {
        return Promise.reject(statusText)
      }

      if (
        status === StatusCodes.NOT_FOUND &&
        method === 'get' &&
        !skipNotFoundRedirect
      ) {
        history.push('/not-found')
      }

      error.message = data ? mapErrors(data) : statusText

      return Promise.reject(error)
    }

    return Promise.reject(error.message)
  }

  makeRequest(method, url, options = {}) {
    const headers = { ...this.instance.defaults.headers, ...options.headers }

    return this.instance({
      ...options,
      method,
      url,
      headers,
    })
  }

  get(url, data) {
    return this.makeRequest('get', url, data)
  }

  post(url, data) {
    return this.makeRequest('post', url, data)
  }

  put(url, data) {
    return this.makeRequest('put', url, data)
  }

  patch(url, data) {
    return this.makeRequest('patch', url, data)
  }

  del(url, data) {
    return this.makeRequest('delete', url, data)
  }
}

export default Api
