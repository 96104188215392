import React from 'react'
import { Button } from 'antd'
import { initialFacilityValues } from '../../../data'
import { updateCreateFacilitySchema } from '../../../schema/updateCreateFacility'
import MedicalEntityModal from '../Modals/MedicalEntityModal'
import FacilityForm from '../Forms/FacilityForm/Form.container'

const buttonMapping = {
  facility: 'Edit Facility',
  mrd: 'Edit MRD Facility',
  treatment: 'Edit Treatment Facility',
}

const titleMapping = {
  facility: 'Apply feedback loop changes for Facility',
  mrd: 'Apply feedback loop changes for MRD Facility',
  treatment: 'Apply feedback loop changes for Treatment Facility',
}

const EditFacilityModal = ({
  editItemValues,
  originalValues,
  facilityType,
  facilityData,
  handleUpdateFacility,
}) => {
  const buttonTitle = buttonMapping[facilityType]
  const title = titleMapping[facilityType]

  return (
    <MedicalEntityModal
      title={title}
      trigger={
        <Button ghost type="primary">
          {buttonTitle}
        </Button>
      }
      form={
        <FacilityForm
          initialValues={{
            ...initialFacilityValues,
            ...editItemValues,
          }}
          original={originalValues}
          onSubmit={handleUpdateFacility}
          validationSchema={updateCreateFacilitySchema}
          action="edit"
          entityId={facilityData?.id}
        />
      }
    />
  )
}

export default EditFacilityModal
