import React from 'react'
import { Col, Row, Typography } from 'antd'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumbs'

const SearchPageLayout = ({ children, breadcrumbs, title, extraButtons }) => (
  <div className="detail-page-layout">
    {title && (
      <div className="detail-page-layout__header">
        <Row justify="center">
          <Col xxl={20} xl={22} span={22}>
            <Row justify="space-between" align="middle">
              <Col>
                <Typography.Title level={3}>{title}</Typography.Title>
              </Col>
              <Col>{extraButtons}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    )}
    <Row justify="center">
      <Col xxl={20} xl={22} span={22}>
        {breadcrumbs && (
          <div className="detail-page-layout__breadcrumbs">
            <Breadcrumb crumbs={breadcrumbs} />
          </div>
        )}
        {children}
      </Col>
    </Row>
  </div>
)

export default SearchPageLayout
