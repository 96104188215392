import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  List,
  Row,
  Spin,
  Tabs as Tab,
  Col,
  Tag,
  Space,
} from 'antd'
import PageLayout from '../../layouts/DetailPageLayout'

import { LOADING_STATUS } from '../../../constants'
import {
  getReports,
  selectAllReports,
  generateCaseAssignmentReport,
  generateFeedbackLoopReport,
  downloadReport,
  deleteReport,
  resetState as resetReportsState,
} from '../../../state/modules/reports'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import { useUser } from '../../../providers/UserProvider'
import CreateReportModal from '../../components/Modals/CreateReportModal/CreateReportModal'
import Tabs from '../../components/Tabs/Tabs'
import { formatDateTime, mapReportsStatusColor } from '../../../utils/helpers'

const tabs = [
  { name: 'Case Assignment', key: 0 },
  { name: 'Feedback Loop', key: 1 },
]
const tabsMapping = {
  0: 'case_assignment',
  1: 'feedback_loop',
}

const infoFields = {
  start_date: 'Start date',
  end_date: 'End date',
  internal_case: 'Internal Case',
}

const Clusters = () => {
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const activeTabRef = useRef(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [activeTab, setActiveTab] = useState(0)
  const [isCreateReportModalVisible, setCreateReportModalVisible] = useState(
    false
  )
  const reportsList = useSelector(selectAllReports)
  const { count } = useSelector(state => state.reports)
  const loadingStatus = useSelector(state => state.reports.status)
  const { isUserActionAllowed } = useUser()

  useEffect(() => {
    dispatchHttp(getReports({ type: tabsMapping[activeTab] }))
  }, [dispatchHttp, activeTab])

  useEffect(() => {
    activeTabRef.current = activeTab
  }, [activeTab])

  const handlePageChange = useCallback(
    (page, pageSize) => {
      setCurrentPage(page)
      dispatchHttp(getReports({ type: tabsMapping[activeTab], page, pageSize }))
    },
    [dispatchHttp, activeTab]
  )

  useEffect(
    () => () => {
      dispatch(resetReportsState())
    },
    [dispatch]
  )

  const handleGenerateReport = useCallback(
    async data => {
      const { report_type } = data
      if (report_type === 'case_assignment') {
        const { date_range = [] } = data
        const [startDate] = date_range
        const [, endDate] = date_range

        await dispatchHttp(
          generateCaseAssignmentReport({
            start_date: startDate?.format('YYYY-MM-DD'),
            end_date: endDate?.format('YYYY-MM-DD'),
          }),
          'Request to generate report was sent'
        )
      }

      if (report_type === 'feedback_loop') {
        const internalMapping = {
          all: null,
          internal: true,
          not_internal: false,
        }
        const { date_range = [] } = data
        const [startDate] = date_range
        const [, endDate] = date_range
        const { internal_retriever } = data

        await dispatchHttp(
          generateFeedbackLoopReport({
            start_date: startDate?.format('YYYY-MM-DD'),
            end_date: endDate?.format('YYYY-MM-DD'),
            internal_retriever: internalMapping[internal_retriever],
          }),
          'Request to generate report was sent'
        )
      }
      setCreateReportModalVisible(false)
      setCurrentPage(1)
      await dispatchHttp(
        getReports({ type: tabsMapping[activeTab], page: 1, pageSize: 10 })
      )
    },
    [dispatchHttp, activeTab]
  )

  const handleDeleteReport = useCallback(
    async id => {
      await dispatchHttp(deleteReport(id), 'Report successfully deleted')
      setCurrentPage(1)
      await dispatchHttp(
        getReports({
          type: tabsMapping[activeTabRef.current],
          page: 1,
          pageSize: 10,
        })
      )
    },
    [dispatchHttp, activeTabRef]
  )

  const isGenerateAllowed =
    isUserActionAllowed('generate_case_assignment') ||
    isUserActionAllowed('generate_feedback_loop')

  const reportInfo = (title, value) => (
    <div className="import_log_card__count_info" key={title}>
      <span className="import_log_card__count_info__title">{title}</span>
      <span className="import_log_card__count_info__value">{value}</span>
    </div>
  )

  const renderItem = useCallback(
    item => (
      <List.Item key={item.id}>
        <Card style={{ width: '100%' }}>
          <Row align="middle">
            <Col span={18}>
              <Row>
                <div className="import_log_card__title">
                  <span className="import_log_card__title_id">#{item.id}</span>{' '}
                  {formatDateTime(item.created_at)}
                </div>
                <Tag
                  color={mapReportsStatusColor(item.status)}
                  style={{
                    margin: '5px 0',
                  }}
                >
                  {item.status}
                </Tag>
              </Row>
              <Row align="middle">
                {Object.keys(infoFields)
                  .filter(
                    key => item.report_parameters && item.report_parameters[key]
                  )
                  .map(key =>
                    reportInfo(infoFields[key], item.report_parameters[key])
                  )}
              </Row>
            </Col>
            <Col
              span={6}
              style={{
                textAlign: 'right',
              }}
            >
              {item.status === 'Generated' && (
                <Space>
                  <Button
                    onClick={() => {
                      dispatch(downloadReport(item.id, item?.filename))
                    }}
                  >
                    Download
                  </Button>
                  {isUserActionAllowed('delete_report') && (
                    <Button
                      danger
                      onClick={() => {
                        handleDeleteReport(item.id)
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Space>
              )}
            </Col>
          </Row>
        </Card>
      </List.Item>
    ),
    // eslint-disable-next-line
    [dispatch, handleDeleteReport]
  )

  return (
    <Spin spinning={false}>
      <PageLayout
        title={
          <>
            Reports
            <span className="badge">{count}</span>
          </>
        }
        extraButtons={
          isGenerateAllowed ? (
            <Button onClick={() => setCreateReportModalVisible(true)}>
              Create Report
            </Button>
          ) : (
            <></>
          )
        }
      >
        <CreateReportModal
          title="Create Report"
          visible={isCreateReportModalVisible}
          handleClose={() => setCreateReportModalVisible(false)}
          handleGenerate={data => handleGenerateReport(data)}
        />
        <Tabs
          fullWidth
          theme="dark"
          tabsProps={{
            size: 'small',
            tabBarGutter: 24,
            onChange: key => {
              setCurrentPage(1)
              setActiveTab(key)
            },
            activeKey: `${activeTab}`,
          }}
        >
          {tabs.map(({ key, name }) => (
            <Tab.TabPane tab={name} key={key}>
              <List
                dataSource={reportsList}
                renderItem={renderItem}
                pagination={{
                  current: currentPage,
                  onChange: handlePageChange,
                  total: count,
                  hideOnSinglePage: true,
                }}
                loading={loadingStatus === LOADING_STATUS}
              />
            </Tab.TabPane>
          ))}
        </Tabs>
      </PageLayout>
    </Spin>
  )
}

export default Clusters
