import { combineReducers } from 'redux'

import auth from './auth'
import users from './users'
import facilities from './facilities'
import mergers from './mergers'
import doctors from './doctors'
import exceptions from './exceptions'
import search from './search'
import retrievers from './retrievers'
import clusters from './clusters'
import importLogs from './importLogs'
import activityLogs from './activityLogs'
import activityLogCases from './activityLogCases'
import roles from './roles'
import copyCompanies from './copyCompanies'
import reports from './reports'
import healthNetworks from './healthNetworks'
import vendors from './vendors'
import vendorFacilities from './vendorFacilities'
import vendorDoctors from './vendorDoctors'
import insuranceCompanies from './insuranceCompanies'
import agencies from './agencies'
import assignmentCopyCompanies from './assignmentCopyCompanies'

export default combineReducers({
  auth,
  users,
  facilities,
  mergers,
  doctors,
  exceptions,
  search,
  retrievers,
  clusters,
  importLogs,
  activityLogs,
  activityLogCases,
  roles,
  copyCompanies,
  reports,
  healthNetworks,
  vendors,
  vendorFacilities,
  vendorDoctors,
  insuranceCompanies,
  agencies,
  assignmentCopyCompanies,
})
