import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  getClusters,
  createCluster,
  deleteCluster,
  fetchClusterById,
  updateCluster,
  getClusterFacilities,
  resetState,
} from './actions'
import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../../../constants'

export const clusterAdapter = createEntityAdapter()
const initialState = clusterAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
  facilities: clusterAdapter.getInitialState({ count: 0 }),
})

const clusterSlice = createSlice({
  name: 'clusters',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      state.facilities = clusterAdapter.getInitialState({ count: 0 })
      clusterAdapter.removeAll(state)
    })
    builder.addCase(getClusters.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getClusters.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        clusterAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(fetchClusterById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchClusterById.fulfilled, (state, { payload }) => {
      clusterAdapter.upsertOne(state, payload)
      state.status = SUCCESS_STATUS
    })
    builder.addCase(
      getClusterFacilities.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.status = SUCCESS_STATUS
        state.facilities.count = count
        clusterAdapter.setAll(state.facilities, results)
      }
    )
    builder.addCase(getClusterFacilities.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getClusterFacilities.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(createCluster.fulfilled, clusterAdapter.upsertOne)
    builder.addCase(deleteCluster.fulfilled, clusterAdapter.removeOne)
    builder.addCase(updateCluster.fulfilled, clusterAdapter.updateOne)
  },
})

const { reducer } = clusterSlice

export default reducer
