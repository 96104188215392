import Api from './index'
import { API_URL } from '../constants'

class ExtraImportLogs extends Api {
  getExtraImportLogsList(data, params) {
    return this.get('', { data, params })
  }

  getExtraImportLogById(id) {
    return this.get(id)
  }

  getExtraImportLogFileUrl(id) {
    return this.get(`${id}/retrieve_link/`)
  }

  getExtraImportLogsTypes(params) {
    return this.get('/import_types', { params })
  }
}

export default new ExtraImportLogs({
  baseURL: `${API_URL}extra_import_logs/`,
})
