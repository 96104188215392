import { getName } from '../../../../utils/helpers'

export function getHighlightFields(currentSearch, tablesData) {
  const state = []
  const city = []
  const fax = []
  const phone = []
  const addressLine1 = []
  const addressLine2 = []
  const zip = []
  const name = []

  const getField = (arr, field) =>
    arr
      .filter(Boolean)
      .find(
        item =>
          currentSearch?.primary_search[field] &&
          item
            .toLowerCase()
            .trim()
            .indexOf(
              currentSearch?.primary_search[field].toLowerCase().trim()
            ) !== -1
      )

  tablesData.filter(Boolean).forEach(tableData => {
    tableData.filter(Boolean).forEach(item => {
      name.push(getName(item))
      item?.addresses?.forEach(address => {
        state.push(address.state)
        city.push(address.city)
        addressLine1.push(address.address_line_1)
        addressLine2.push(address.address_line_2)
        zip.push(address.zip_code)
      })
      item?.fax_numbers?.forEach(faxItem => {
        fax.push(faxItem.number)
      })
      item?.phone_numbers?.forEach(phoneItem => {
        phone.push(phoneItem.number)
      })
    })
  })

  return {
    fax: getField(fax, 'fax'),
    city: getField(city, 'city'),
    state: getField(state, 'state'),
    phone: getField(phone, 'phone'),
    address_line_1: getField(addressLine1, 'address_line_1'),
    address_line_2: getField(addressLine2, 'address_line_2'),
    zip_code: getField(zip, 'zip_code'),
    facility_name: getField(name, 'facility_name'),
    first_name: getField(name, 'first_name'),
    last_name: getField(name, 'last_name'),
  }
}
