import Api from './index'
import { API_URL } from '../constants'

class VendorDoctor extends Api {
  getVendorDoctors(_, params) {
    const { id, ...rest } = params
    return this.get(`/?vendor_id=${id}`, { params: rest })
  }

  addVendorDoctor(data) {
    return this.post('/', { data })
  }

  removeVendorDoctor(id) {
    return this.del(`${id}/`)
  }

  updateVendorDoctor(id, data) {
    return this.put(`${id}/`, { data })
  }
}

export default new VendorDoctor({
  baseURL: `${API_URL}doctor-vendor/`,
})
