import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Card, Typography, Tag, Spin } from 'antd'
import {
  selectImportLogById,
  isFetchingImportLogs,
  getWeeklyNpeesUpdatesById,
} from '../../../state/modules/importLogs'
import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { mapImportLogsStatusColor } from '../../../utils/helpers'

const RenderImportLogTableTitle = fileUrl => {
  const fileNameParts = fileUrl?.split('/') || []
  const title = fileNameParts[fileNameParts.length - 1] || 'Title'

  return <Typography.Link href={fileUrl}>{title}</Typography.Link>
}

const RenderImportLogTableStatus = status => (
  <Tag color={mapImportLogsStatusColor(status)}>{status}</Tag>
)

const columns = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Date | Time',
    key: 'created_at',
    date: true,
  },
  {
    title: 'Title',
    key: 'file_url',
    render: RenderImportLogTableTitle,
  },
  {
    title: 'Status',
    key: 'status',
    render: RenderImportLogTableStatus,
  },
  {
    title: 'Total Doctors Imported',
    key: 'total_doctors_imported',
  },
  {
    title: 'Total Facilities Imported',
    key: 'total_facilities_imported',
  },
]

const WeeklyNpeesUpdatesDetails = () => {
  const dispatchHttp = useDispatchHttp()
  const { id } = useParams()
  const importLog = useSelector(state => selectImportLogById(state, id))
  const loading = useSelector(isFetchingImportLogs)
  const fileNameParts = importLog?.file_url?.split('/') || []
  const title = fileNameParts[fileNameParts.length - 1] || 'Title'
  const breadcrumbs = [
    { name: 'Import Log', path: '/data-sync-log' },
    {
      name: title,
      path: `/data-sync-log-weekly-npees-updates/${importLog?.id}`,
    },
  ]

  useEffect(() => {
    dispatchHttp(getWeeklyNpeesUpdatesById(id))
  }, [dispatchHttp, id])

  return (
    <PageLayout breadcrumbs={breadcrumbs} title={title}>
      <Spin spinning={loading}>
        <Card style={{ width: '100%' }} className="weekly-npees-updates">
          <Table
            columns={columns}
            tableTitle="Import Log"
            loading={loading}
            totalData={[importLog]}
          />
        </Card>
      </Spin>
    </PageLayout>
  )
}

export default WeeklyNpeesUpdatesDetails
