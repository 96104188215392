import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Row, Col, Switch, Radio } from 'antd'
import { Field } from 'formik'
import TextField from '../../../shared/TextField'

const CollapsibleCardProfile = ({ onSetFieldValue, data, values, isOpen }) => {
  const [open, setOpen] = useState(isOpen)

  const handleCardVisibility = checked => {
    setOpen(checked)
  }

  useEffect(() => {
    handleCardVisibility(isOpen)
  }, [isOpen])

  return (
    <div
      className={cx('search-form__card search-form__card--collapsible', {
        'search-form__card--compact': !open,
      })}
    >
      {!open && (
        <Row justify="space-between">
          <Col>
            <div className="ant-form-item-label">
              <label>Advanced Search</label>
            </div>
          </Col>
          <Col>
            <Switch onChange={handleCardVisibility} defaultChecked={open} />
          </Col>
        </Row>
      )}
      {open && (
        <>
          <Row justify="space-between">
            <Col>
              <div className="ant-form-item-label">
                <label>Advanced Search</label>
              </div>
            </Col>
            <Col>
              <Switch onChange={handleCardVisibility} defaultChecked={open} />
            </Col>
          </Row>
          <>
            <Field
              component={TextField}
              name="main_retriever_cms_id"
              placeholder="Type Here"
              label="Retriever CMS ID"
            />
            <div className="ant-form-item">
              <Radio.Group
                initialValue={data.auto_detect_main_retriever}
                name="auto_detect_main_retriever"
                onChange={({ target: { value, name } }) => {
                  onSetFieldValue(name, value)
                }}
                value={values.auto_detect_main_retriever}
                buttonStyle="solid"
              >
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="true">AD Enabled</Radio.Button>
                <Radio.Button value="false">AD Disabled</Radio.Button>
              </Radio.Group>
            </div>
            <Row gutter={4}>
              <Col span={12}>
                <Field
                  component={TextField}
                  name="copy_company_cms_id"
                  placeholder="Type Here"
                  label="Copy Company CMS ID"
                />
              </Col>
              <Col span={12}>
                <Field
                  component={TextField}
                  name="copy_company_site_id"
                  placeholder="Type Here"
                  label="Site ID"
                />
              </Col>
            </Row>
            <div className="ant-form-item">
              <Radio.Group
                initialValue={data.auto_detect_copy_company}
                name="auto_detect_copy_company"
                onChange={({ target: { value, name } }) => {
                  onSetFieldValue(name, value)
                }}
                value={values.auto_detect_copy_company}
                buttonStyle="solid"
              >
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="true">AD Enabled</Radio.Button>
                <Radio.Button value="false">AD Disabled</Radio.Button>
              </Radio.Group>
            </div>
            <div className="ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label title="Communication Method">Communication Method</label>
              </div>
              <Radio.Group
                initialValue={data.auto_detect_communication_method}
                name="auto_detect_communication_method"
                onChange={({ target: { value, name } }) => {
                  onSetFieldValue(name, value)
                }}
                value={values.auto_detect_communication_method}
                buttonStyle="solid"
              >
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="true">AD Enabled</Radio.Button>
                <Radio.Button value="false">AD Disabled</Radio.Button>
              </Radio.Group>
            </div>
          </>
        </>
      )}
    </div>
  )
}

export default CollapsibleCardProfile
