import Login from '../../pages/auth/Login'
import ForgotPassword from '../../pages/auth/ForgotPassword'
import ResetPassword from '../../pages/auth/ResetPassword'
import ConfirmCode from '../../pages/auth/ConfirmCode'

export default [
  { path: '/login', name: 'Login', component: Login, title: 'Login' },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    title: 'Forgot Password',
  },
  {
    path: '/reset_password',
    name: 'Reset Password',
    component: ResetPassword,
    title: 'Reset Password',
  },
  {
    path: '/confirm_code',
    name: 'Confirm Code',
    component: ConfirmCode,
    title: 'Confirm Code',
  },
]
