import Api from './index'
import { API_URL } from '../constants'

class Reports extends Api {
  getReportsList(id, params) {
    return this.get('/', { params })
  }

  generateCaseAssignmentReport(data) {
    return this.post('/generate_case_assignment/', { data })
  }

  generateFeedbackLoopReport(data) {
    return this.post('/generate_feedback_loop/', { data })
  }

  getReportFileUrl(id) {
    return this.get(`/${id}/retrieve_link/`)
  }

  deleteReport(id) {
    return this.del(`/${id}/`)
  }
}

export default new Reports({
  baseURL: `${API_URL}reports/`,
})
