const hotkeysFieldsMapping = {
  KeyX: ({ fax }) => fax && { fax },
  KeyN: ({ first_name, last_name }) =>
    (first_name || last_name) && {
      first_name: first_name || null,
      last_name: last_name || null,
    },
  KeyP: ({ phone }) => phone && { phone },
  KeyM: ({ candidate_ids }) => candidate_ids?.length && { candidate_ids },
  KeyA: ({ address_line_1, city, state, zip_code }) =>
    (address_line_1 || city || state || zip_code) && {
      address_line_1: address_line_1 || null,
      city: city || null,
      state: state || null,
      zip_code: zip_code || null,
    },
}

const missedFieldsMapping = {
  KeyX: () => 'primary fax is',
  KeyN: type => {
    if (type !== 'doctor') {
      return 'Facility Name is'
    }

    return 'First and Last names are'
  },
  KeyP: () => 'primary phone is',
  KeyM: () => 'Jaia top matches are',
  KeyA: () => 'Primary address is',
}

export const getHotkeysSearch = (hotKey, search, type) =>
  hotkeysFieldsMapping[hotKey](search) || {
    missedField: missedFieldsMapping[hotKey](type),
  }
