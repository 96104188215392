import Api from './index'
import { API_URL } from '../constants'

class Retriever extends Api {
  getRetrievers(id, params) {
    return this.get('/', { params })
  }

  getRetrieversTimeZones(params) {
    return this.get('/retriever_timezones/', { params })
  }

  updateRetriever(id, data) {
    return this.patch(`/${id}/`, { data })
  }
}

export default new Retriever({
  baseURL: `${API_URL}retrievers/`,
})
