import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Col, Form, Input, Row, Spin } from 'antd'
import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import CreateHealthNetworkModal from '../../components/Modals/CreateHealthNetworkModal'

import {
  getHealthNetworks,
  selectAllHealthNetworks,
  createHealthNetwork,
  resetState as resetClustersState,
} from '../../../state/modules/healthNetworks'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import { HEALTH_NETWORK } from '../../../constants'
import { healthNetworkTableColumns } from '../../../data'
import { routePaths } from '../../../utils/routes'
import { useUser } from '../../../providers/UserProvider'
import useDebounce from '../../../hooks/useDebounce'

const HealthNetworks = () => {
  const history = useHistory()
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useState({})
  const healthNetworksList = useSelector(selectAllHealthNetworks)
  const { count } = useSelector(state => state.healthNetworks)
  const loadingStatus = useSelector(state => state.healthNetworks.status)
  const { isUserActionAllowed } = useUser()

  const handleHealthNetworkCreate = async ({ name }) => {
    const result = await dispatchHttp(
      createHealthNetwork({ name: name.trim() }),
      'Health Network has been successfully created'
    )

    history.push(routePaths.openHealthNetworkById(result.id))
  }

  const handleChangeName = useDebounce(e => {
    const { value } = e.target
    setSearchParams({ ...searchParams, health_network_name: value.trim() })
    dispatchHttp(getHealthNetworks({ health_network_name: value.trim() }))
  }, 300)

  useEffect(() => {
    dispatchHttp(getHealthNetworks({}))
  }, [dispatchHttp])

  useEffect(
    () => () => {
      dispatch(resetClustersState())
    },
    [dispatch]
  )

  return (
    <Spin spinning={false}>
      <PageLayout
        title={
          <>
            Health Networks List
            <span className="badge">{count}</span>
          </>
        }
        extraButtons={
          isUserActionAllowed('add_healthnetwork') && (
            <CreateHealthNetworkModal
              title="Create new Health Network"
              onSubmit={handleHealthNetworkCreate}
              submitButtonText="Create"
              initialValues={{ name: '' }}
              trigger={
                <Button ghost type="primary">
                  Create New Health Network
                </Button>
              }
            />
          )
        }
      >
        <Row
          gutter={[16, 32]}
          style={{
            background: '#fff',
            padding: '14px 12px',
            justifyContent: 'flex-end',
            margin: 0,
          }}
        >
          <Form
            layout="inline"
            className="sort-form"
            component="div"
            initialValues={{ name: '' }}
            size="small"
          >
            <Row>
              <Col>
                <Form.Item label="Health Network Name" name="name" size="small">
                  <Input
                    onChange={handleChangeName}
                    type="text"
                    style={{ width: 200 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
        <Table
          columns={healthNetworkTableColumns}
          onLoadData={getHealthNetworks}
          formData={searchParams}
          totalCount={count}
          totalData={healthNetworksList}
          loadingStatus={loadingStatus}
          entity={HEALTH_NETWORK}
        />
      </PageLayout>
    </Spin>
  )
}

export default HealthNetworks
