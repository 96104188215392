import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import VendorDoctorService from '../../../services/VendorDoctorService'

export const getVendorDoctors = createAsyncThunk(
  'vendorDoctor/getVendorDoctors',
  async data => {
    const response = await getPagination(
      data,
      VendorDoctorService.getVendorDoctors.bind(VendorDoctorService)
    )

    return { ...response }
  }
)

export const addVendorDoctor = createAsyncThunk(
  'vendorDoctor/addVendorDoctor',
  async ({ data }) => {
    const response = await VendorDoctorService.addVendorDoctor(data)

    return response
  }
)

export const removeVendorDoctor = createAsyncThunk(
  'vendorDoctor/removeVendorDoctor',
  async id => {
    await VendorDoctorService.removeVendorDoctor(id)

    return id
  }
)

export const updateVendorDoctor = createAsyncThunk(
  'vendorDoctor/updateVendorDoctor',
  async ({ id, data }) => {
    const response = await VendorDoctorService.updateVendorDoctor(id, data)

    return response
  }
)

export const resetState = createAction('vendorDoctor/resetState')
