import React from 'react'
import PropTypes from 'prop-types'

import FormWrapper from '../MedicalFormWrapper'
import Form from './Form'

const DoctorForm = ({
  initialValues,
  initialTouched,
  onClose,
  onSubmit,
  validationSchema,
  entityId,
  ...formProps
}) => (
  <FormWrapper
    initialValues={initialValues}
    initialTouched={initialTouched}
    onClose={onClose}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    id={entityId}
    {...formProps}
  >
    <Form {...formProps} type="doctor" />
  </FormWrapper>
)

DoctorForm.propTypes = {
  initialValues: PropTypes.shape({}),
  initialTouched: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape({}),
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

DoctorForm.defaultProps = {
  onClose: () => null,
  initialValues: {},
  initialTouched: {},
  onSubmit: () => null,
  validationSchema: null,
  entityId: '',
}

export default DoctorForm
