import { object } from 'yup'

import { email, password, firstname, lastname, supervisors } from './fields'

export const createUserSchema = object().shape({
  email,
  password,
  firstname,
  lastname,
  supervisors,
})
