import React from 'react'
import { Button, Col, Divider, Form, Row, Collapse } from 'antd'

import { Field, FieldArray } from 'formik'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import NumberField from '../../../shared/NumberField'

const { Panel } = Collapse

export const AdditionalNpi = ({ values, errorMessage, title }) => (
  <>
    <Collapse
      ghost
      defaultActiveKey={[values.length ? '1' : null]}
      className="additional-npi-collapse"
    >
      <Panel
        header={
          <Divider
            orientation="left"
            className="form-divider"
            style={{ margin: 0 }}
          >
            {title}
          </Divider>
        }
        key="1"
      >
        <div className="form__fields-array">
          <FieldArray
            name="other_npis"
            render={({ name, push, remove }) => (
              <>
                {values.map((field, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row key={index} gutter={12}>
                    <Col flex={4}>
                      <Field
                        component={NumberField}
                        label={`Additional NPI ${index + 1}`}
                        size="medium"
                        name={`${name}[${index}]`}
                        placeholder="Type Here"
                        error={errorMessage(`${name}[${index}]`)}
                      />
                    </Col>
                    <Col>
                      <Form.Item label>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => remove(index)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      push('')
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Additional NPI
                  </Button>
                </Form.Item>
              </>
            )}
          />
        </div>
      </Panel>
    </Collapse>
  </>
)
