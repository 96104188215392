import React from 'react'
import { Button } from 'antd'
import { initialDoctorValues } from '../../../data'
import MedicalEntityModal from '../Modals/MedicalEntityModal'
import { createUpdateDoctorSchema } from '../../../schema/createUpdateDoctor'
import DoctorForm from '../Forms/DoctorForm'

const EditDoctorModal = ({
  editItemValues,
  originalValues,
  doctorData,
  handleUpdateDoctor,
}) => (
  <MedicalEntityModal
    title="Apply feedback loop changes for Doctor"
    trigger={
      <Button ghost type="primary">
        Edit Doctor
      </Button>
    }
    form={
      <DoctorForm
        initialValues={{
          ...initialDoctorValues,
          ...editItemValues,
        }}
        original={originalValues}
        onSubmit={handleUpdateDoctor}
        validationSchema={createUpdateDoctorSchema}
        action="edit"
        entityId={doctorData?.id}
      />
    }
  />
)

export default EditDoctorModal
