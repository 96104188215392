import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import SearchService from '../../../services/SearchService'

export const searchCaseDetailsDoctors = createAsyncThunk(
  'search/searchCaseDetailsDoctors',
  async ({ data = {}, page = 1, pageSize = 10 } = {}) => {
    const response = await SearchService.searchDoctors({
      data: { page, results_per_page: pageSize, ...data },
    })
    return response
  }
)

export const searchCaseDetailsFacilities = createAsyncThunk(
  'search/searchCaseDetailsFacilities',
  async ({ data = {}, page = 1, pageSize = 10 } = {}) => {
    const response = await SearchService.searchFacilities({
      data: { page, results_per_page: pageSize, ...data },
    })
    return response
  }
)

export const resetState = createAction('search/resetState')
