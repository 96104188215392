import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  getMergersList,
  fetchMergerById,
  getDoctorMergerProviders,
  resetState,
  getFacilityMergerProviders,
} from './actions'

import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'

export const mergerAdapter = createEntityAdapter()
const initialState = mergerAdapter.getInitialState({
  status: IDLE_STATUS,
  mergers: mergerAdapter.getInitialState({ count: 0 }),
  relatedDoctors: mergerAdapter.getInitialState({ count: 0 }),
  relatedFacilities: mergerAdapter.getInitialState({ count: 0 }),
})

const mergersSlice = createSlice({
  name: 'mergers',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.mergers = mergerAdapter.getInitialState({ count: 0 })
      state.relatedDoctors = mergerAdapter.getInitialState({ count: 0 })
      state.relatedFacilities = mergerAdapter.getInitialState({ count: 0 })
      state.status = IDLE_STATUS
      mergerAdapter.removeAll(state)
    })
    builder.addCase(getMergersList.pending, state => {
      state.mergers.status = LOADING_STATUS
    })
    builder.addCase(
      getMergersList.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.mergers.status = SUCCESS_STATUS
        state.mergers.count = count
        mergerAdapter.setAll(state.mergers, results)
      }
    )
    builder.addCase(getMergersList.rejected, state => {
      state.mergers.status = FAILURE_STATUS
    })
    builder.addCase(fetchMergerById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchMergerById.fulfilled, (state, { payload }) => {
      mergerAdapter.upsertOne(state.mergers, payload)
      state.status = SUCCESS_STATUS
    })
    builder.addCase(fetchMergerById.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(getDoctorMergerProviders.pending, state => {
      state.relatedDoctors.status = LOADING_STATUS
    })
    builder.addCase(
      getDoctorMergerProviders.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.relatedDoctors.status = SUCCESS_STATUS
        state.relatedDoctors.count = count
        mergerAdapter.setAll(state.relatedDoctors, results)
      }
    )
    builder.addCase(getDoctorMergerProviders.rejected, state => {
      state.relatedDoctors.status = FAILURE_STATUS
    })
    builder.addCase(getFacilityMergerProviders.pending, state => {
      state.relatedFacilities.status = LOADING_STATUS
    })
    builder.addCase(
      getFacilityMergerProviders.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.relatedFacilities.status = SUCCESS_STATUS
        state.relatedFacilities.count = count
        mergerAdapter.setAll(state.relatedFacilities, results)
      }
    )
    builder.addCase(getFacilityMergerProviders.rejected, state => {
      state.relatedFacilities.status = FAILURE_STATUS
    })
  },
})

const { reducer } = mergersSlice

export default reducer
