import React from 'react'
import PropTypes from 'prop-types'

import { Row, Col, Layout } from 'antd'

const AuthLayout = ({ children }) => (
  <Layout className="auth-layout">
    <Layout.Content className="auth-layout__content">
      <Col flex={1}>
        <Row align="middle" justify="center">
          <Col xxl={{ span: 15 }} xl={13}>
            <div className="auth-layout__img" />
          </Col>
          <Col xxl={{ span: 9 }} xl={11}>
            {children}
          </Col>
        </Row>
      </Col>
    </Layout.Content>
  </Layout>
)

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

AuthLayout.defaultProps = {
  children: null,
}

export default AuthLayout
