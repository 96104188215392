import Api from './index'
import { API_URL } from '../constants'

class Clusters extends Api {
  getClustersList(id, params) {
    return this.get('/', { params })
  }

  getClusterFacilities(_, params) {
    const { id, ...rest } = params
    return this.get(`/${id}/facilities/`, { params: rest })
  }

  getClusterById(id) {
    return this.get(`${id}/`)
  }

  createFacilityCluster(data) {
    return this.post('/', { data })
  }

  removeFacilityCluster(id) {
    return this.del(`${id}/`)
  }

  updateFacilityCluster(id, data) {
    return this.put(`${id}/`, { data })
  }
}

export default new Clusters({
  baseURL: `${API_URL}facility_clusters/`,
})
