import Api from './index'
import { API_URL } from '../constants'

class WeeklyNpeesUpdates extends Api {
  getWeeklyNpeesUpdatesList(data, params) {
    return this.get('', { data, params })
  }

  getWeeklyNpeesUpdatesById(id) {
    return this.get(id)
  }
}

export default new WeeklyNpeesUpdates({
  baseURL: `${API_URL}nppes_weekly_update/`,
})
