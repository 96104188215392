import { rolesAdapter } from './reducer'
import { LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const { selectAll: selectAllRoles } = rolesAdapter.getSelectors(
  state => state.roles
)

export const { selectById: selectRoleById } = rolesAdapter.getSelectors(
  state => state.roles
)

export const isRolesFetching = state =>
  state.roles.status === LOADING_STATUS && state.roles.status !== SUCCESS_STATUS

export const isRolesFetched = state =>
  state.roles.status !== LOADING_STATUS && state.roles.status === SUCCESS_STATUS
