import React from 'react'

// eslint-disable-next-line
import { Overview, Schedule, Finance, Vendors, Notes } from '../FormFields'

// eslint-disable-next-line
const FormComponent = ({ formData }) => (
  <>
    <Overview title="Overview" values={formData} />

    <Vendors title="Vendors" />

    <Schedule title="Schedule" />

    {/* <Finance title="Finance" /> */}

    <Notes title="Notes" />
  </>
)

export default FormComponent
