import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  getHealthNetworks,
  createHealthNetwork,
  deleteHealthNetwork,
  fetchHealthNetworkById,
  fetchHealthNetworkFacilities,
  fetchHealthNetworkDoctors,
  fetchHealthNetworkSoleProprietors,
  updateHealthNetwork,
  resetState,
} from './actions'
import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'

export const healthNetworkAdapter = createEntityAdapter()
const initialState = healthNetworkAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
  related_facilities: healthNetworkAdapter.getInitialState({
    count: 0,
    status: IDLE_STATUS,
  }),
  related_doctors: healthNetworkAdapter.getInitialState({
    count: 0,
    status: IDLE_STATUS,
  }),
  related_sole_proprietors: healthNetworkAdapter.getInitialState({
    count: 0,
    status: IDLE_STATUS,
  }),
})

const healthNetworkSlice = createSlice({
  name: 'healthNetworks',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      state.related_facilities = healthNetworkAdapter.getInitialState({
        count: 0,
        status: IDLE_STATUS,
      })
      state.related_doctors = healthNetworkAdapter.getInitialState({
        count: 0,
        status: IDLE_STATUS,
      })
      state.related_sole_proprietors = healthNetworkAdapter.getInitialState({
        count: 0,
        status: IDLE_STATUS,
      })
      healthNetworkAdapter.removeAll(state)
    })
    builder.addCase(getHealthNetworks.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getHealthNetworks.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        healthNetworkAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(fetchHealthNetworkById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchHealthNetworkById.fulfilled, (state, { payload }) => {
      healthNetworkAdapter.upsertOne(state, payload)
      state.status = SUCCESS_STATUS
    })
    builder.addCase(
      createHealthNetwork.fulfilled,
      healthNetworkAdapter.upsertOne
    )
    builder.addCase(
      deleteHealthNetwork.fulfilled,
      healthNetworkAdapter.removeOne
    )
    builder.addCase(
      updateHealthNetwork.fulfilled,
      healthNetworkAdapter.updateOne
    )
    builder.addCase(
      fetchHealthNetworkDoctors.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.related_doctors.count = count
        state.related_doctors.status = SUCCESS_STATUS
        healthNetworkAdapter.setAll(state.related_doctors, results)
      }
    )
    builder.addCase(fetchHealthNetworkDoctors.pending, state => {
      state.related_doctors.status = LOADING_STATUS
    })
    builder.addCase(fetchHealthNetworkDoctors.rejected, state => {
      state.related_doctors.status = FAILURE_STATUS
    })
    builder.addCase(
      fetchHealthNetworkSoleProprietors.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.related_sole_proprietors.count = count
        state.related_sole_proprietors.status = SUCCESS_STATUS
        healthNetworkAdapter.setAll(state.related_sole_proprietors, results)
      }
    )
    builder.addCase(fetchHealthNetworkSoleProprietors.pending, state => {
      state.related_sole_proprietors.status = LOADING_STATUS
    })
    builder.addCase(fetchHealthNetworkSoleProprietors.rejected, state => {
      state.related_sole_proprietors.status = FAILURE_STATUS
    })
    builder.addCase(
      fetchHealthNetworkFacilities.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.related_facilities.count = count
        state.related_facilities.status = SUCCESS_STATUS
        healthNetworkAdapter.setAll(state.related_facilities, results)
      }
    )
    builder.addCase(fetchHealthNetworkFacilities.pending, state => {
      state.related_facilities.status = LOADING_STATUS
    })
    builder.addCase(fetchHealthNetworkFacilities.rejected, state => {
      state.related_facilities.status = FAILURE_STATUS
    })
  },
})

const { reducer } = healthNetworkSlice

export default reducer
