import Api from './index'
import { API_URL } from '../constants'

class Clusters extends Api {
  getActivityLogs(data, params) {
    return this.get('', { data, params })
  }

  getActivityLogById(id) {
    return this.get(id)
  }
}

export default new Clusters({
  baseURL: `${API_URL}activity/`,
})
