import { clusterAdapter } from './reducer'

export const {
  selectAll: selectAllClusters,
  selectById: selectClusterById,
} = clusterAdapter.getSelectors(state => state.clusters)

export const {
  selectAll: selectAllClusterFacilities,
} = clusterAdapter.getSelectors(state => state.clusters.facilities)
