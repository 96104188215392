import Api from './index'
import { API_URL } from '../constants'

class AssignmentCopyCompany extends Api {
  createAssignmentCopyCompany(data) {
    return this.post('assignment-copy-companies/', { data })
  }

  updateAssignmentCopyCompany(id, data) {
    return this.put(`/assignment-copy-companies/${id}/`, { data })
  }

  deleteAssignmentCopyCompany(id) {
    return this.del(`/assignment-copy-companies/${id}/`)
  }

  getAssignmentCopyCompanies(id, params) {
    return this.get('assignment-copy-companies/', { params })
  }

  getAssignmentCopyCompanyById(id) {
    return this.get(`assignment-copy-companies/${id}/`)
  }
}

export default new AssignmentCopyCompany({
  baseURL: API_URL,
})
