import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Space,
  Skeleton,
  Tooltip,
  Tag,
  Button,
  notification,
} from 'antd'
import {
  ClockCircleOutlined,
  FileOutlined,
  EditOutlined,
  StopOutlined,
  PartitionOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import DetailPageLayout from '../../../layouts/DetailPageLayout'
import ProfileCard from '../../../components/ProfileCard'
import MedicalEntityModal from '../../../components/Modals/MedicalEntityModal'
import SpecialAuthorizationModal from '../../../components/Modals/SpecialAuthorizationModal'
import UpdateFacilityForm from '../../../components/Forms/FacilityProfileForm'
import SetRetrieverForm from '../../../components/Forms/SetRetrieverForm'
import CommunicationMethodForm from '../../../components/Forms/CommunicationMethodForm'
import SetCopyCompanyForm from '../../../components/Forms/SetCopyCompanyForm'
import AuthFormsList from '../../../components/Forms/AuthFormsList'
import AuthForm from '../../../components/Forms/CreateAuthForm'
import FacilityProfileMRD from '../../../components/FacilityProfileMRD'
import { useUser } from '../../../../providers/UserProvider'
import { downloadFileByUrl } from '../../../../utils/functions'

import useDispatchHttp from '../../../../hooks/dispatchHttpHandler'
import {
  fetchFacilityProfileById,
  selectFacilityProfileById,
  updateFacilityProfile,
  setFacilityProfileCommunicationMethod,
  setFacilityProfileCommunicationMethodAutoDetection,
  setFacilityProfileRetriever,
  createAuthForm,
  fetchFormById,
  addFacilityToFacilityProfile,
  removeFacilityFromFacilityProfile,
  setMRDFacility,
  getRelatedFacilityProfileFacilities,
  selectRelatedFacilityProfiles,
  fetchFacilityById,
  setFacilityProfileCopyCompany,
  setFacilityProfileCopyCompanyAutoDetection,
  setFacilityProfileMainRetrieverAutoDetection,
  getFacilityProfileESignStat,
} from '../../../../state/modules/facilities'
import { LOADING_STATUS, DATE_FORMAT, DOCTOR } from '../../../../constants'
import {
  convertTextToValue,
  formatWorkingHours,
  formatWeekDays,
  formatDate,
} from '../../../../utils/helpers'
import { createFacilityProfileSchema } from '../../../../schema/createFacilityProfile'
import { createAuthFormSchema } from '../../../../schema/createAuthForm'
import {
  communicationOptions,
  doctorTableColumns,
  paymentOptions,
  postbillOptions,
} from '../../../../data'
import Table from '../../../components/Tables/SearchTable/SearchTable.container'
import {
  fetchDoctorById,
  selectDoctorById,
} from '../../../../state/modules/doctors'

const FacilityProfile = () => {
  const { isUserActionAllowed } = useUser()
  const { id } = useParams()
  const dispatchHttp = useDispatchHttp()
  const facilityData = useSelector(state =>
    selectFacilityProfileById(state, id)
  )
  const loadingStatus = useSelector(state => state.facilities.status)
  const treatment_facilities = useSelector(selectRelatedFacilityProfiles)
  const doctor = useSelector(state =>
    selectDoctorById(state, facilityData?.sole_proprietor_doctor)
  )
  const { count: relatedFacilityProfilesCount, page, pageSize } = useSelector(
    state => state.facilities.related_facility_profiles
  )
  const mrdFacility = facilityData?.mrd_facility
  const [eSignStats, setEsignStats] = useState(null)

  const breadcrumbs = useMemo(
    () => [
      { name: 'Facility Profiles List', path: '/facility-profiles' },
      {
        name: `${facilityData?.provider_name}`,
        path: `/facility-profiles/${id}/`,
      },
    ],
    [facilityData?.provider_name, id]
  )

  const initialValues = {
    communication_method: convertTextToValue(
      facilityData?.communication_method,
      communicationOptions
    ),
    payment_type: convertTextToValue(
      facilityData?.payment_type,
      paymentOptions
    ),
    postbill: convertTextToValue(facilityData?.postbill, postbillOptions),
    main_retriever: facilityData?.main_retriever?.id,
    last_retriever: facilityData?.last_retriever?.fullname,
    provider_name: facilityData?.provider_name,
    last_case_date: facilityData?.last_case_date,
    case_count: facilityData?.case_count,
    copy_company_name: facilityData?.copy_company_name,
    emr_vendor_name: facilityData?.emr_vendor_name,
    emr_data_source: facilityData?.emr_data_source,
    working_hours_start: facilityData?.working_hours_start || '09:00:00',
    working_hours_end: facilityData?.working_hours_end || '17:00:00',
    turn_around_min: facilityData?.turn_around_min,
    turn_around_max: facilityData?.turn_around_max,
    turn_around_avg: facilityData?.turn_around_avg,
    contact_name: facilityData?.contact_name,
    min_fee: facilityData?.min_fee,
    max_fee: facilityData?.max_fee,
    avg_facility_records_fee: facilityData?.avg_facility_records_fee,
    avg_fee: facilityData?.avg_fee,
    total_paid: facilityData?.total_paid,
    days_open: facilityData?.days_open,
    notes: facilityData?.notes,
    email: facilityData?.email,
  }

  const handleFacilityProfileUpdate = useCallback(
    async (facilityId, data) => {
      await dispatchHttp(
        updateFacilityProfile({
          facilityId,
          data: {
            days_open: data.days_open || [],
            email: data.email || '',
            contact_name: data.contact_name || '',
            emr_data_source: data.emr_data_source || '',
            emr_vendor_name: data.emr_vendor_name || '',
            notes: data.notes || '',
            working_hours_end: data.working_hours_end || null,
            working_hours_start: data.working_hours_start || null,
          },
        }),
        'Facility Profile has been successfully updated'
      )
      await dispatchHttp(fetchFacilityProfileById(id))
    },
    [dispatchHttp, id]
  )

  const handleSetMRDFacility = useCallback(
    async facility_id => {
      await dispatchHttp(
        setMRDFacility({
          id,
          data: { facility_id },
        }),
        'Facility Profile  has been successfully updated'
      )
      await dispatchHttp(fetchFacilityProfileById(id))
      await dispatchHttp(
        getRelatedFacilityProfileFacilities({
          data: id,
          page,
          pageSize,
        })
      )
    },

    [dispatchHttp, id, page, pageSize]
  )

  const handleSetRetriever = useCallback(
    async (facilityId, data, isAutoDetectionEnabled) => {
      if (
        data.main_retriever &&
        isUserActionAllowed('update_retrievers_facilityprofile')
      ) {
        await dispatchHttp(
          setFacilityProfileRetriever({
            facilityId,
            data,
          })
        )
      }

      if (isUserActionAllowed('update_main_retriever_auto_detection')) {
        await dispatchHttp(
          setFacilityProfileMainRetrieverAutoDetection({
            facilityId,
            data: {
              auto_detect_main_retriever: isAutoDetectionEnabled,
            },
          }),
          'Facility Profile has been successfully updated'
        )
      }

      await dispatchHttp(fetchFacilityProfileById(id))
    },
    // eslint-disable-next-line
    [dispatchHttp, id]
  )

  const handleChangeCommunicationMethod = useCallback(
    async (facilityId, communicationMethod, isAutoDetectionEnabled) => {
      const promises = [
        dispatchHttp(
          setFacilityProfileCommunicationMethodAutoDetection({
            facilityId,
            data: {
              auto_detect_communication_method: isAutoDetectionEnabled,
            },
          })
        ),
      ]

      if (!isAutoDetectionEnabled) {
        promises.push(
          dispatchHttp(
            setFacilityProfileCommunicationMethod({
              facilityId,
              data: {
                communication_method: communicationMethod,
              },
            })
          )
        )
      }

      await Promise.all(promises)
      notification.success({
        message: 'Facility Profile has been successfully updated',
      })

      await dispatchHttp(fetchFacilityProfileById(id))
    },
    // eslint-disable-next-line
    [dispatchHttp, id]
  )

  const handleSetCopyCompany = useCallback(
    async (facilityId, data, isAutoDetectionEnabled) => {
      if (
        data.selected_id &&
        isUserActionAllowed('update_copy_company_facilityprofile')
      ) {
        const siteId = data[`site_id_${data.selected_id}`]
        const [, copyCompanyId] = data.selected_id.split('_')
        await dispatchHttp(
          setFacilityProfileCopyCompany({
            facilityId,
            data: {
              copy_company_id: copyCompanyId,
              site_id: siteId || null,
            },
          })
        )
      }

      if (isUserActionAllowed('update_copy_company_auto_detection')) {
        await dispatchHttp(
          setFacilityProfileCopyCompanyAutoDetection({
            facilityId,
            data: {
              auto_detect_copy_company: isAutoDetectionEnabled,
            },
          }),
          'Facility Profile has been successfully updated'
        )
      }

      await dispatchHttp(fetchFacilityProfileById(id))
    },
    // eslint-disable-next-line
    [dispatchHttp, id]
  )

  const handleFormCreate = useCallback(
    async (_, data) => {
      await dispatchHttp(
        createAuthForm({ ...data, facility_profile_id: id }),
        'Authorization Form has been successfully created'
      )
      await dispatchHttp(fetchFacilityProfileById(id))
    },
    [dispatchHttp, id]
  )

  const unlinkFacilityFromFacilityProfile = async data => {
    await dispatchHttp(
      removeFacilityFromFacilityProfile({
        id,
        data: { facility_id: data.id },
      }),
      'Facility has been successfully removed from profile'
    )
    await dispatchHttp(fetchFacilityProfileById(id))
    await dispatchHttp(getRelatedFacilityProfileFacilities({ data: id }))
  }

  const relateFacilityProfile = async data => {
    await dispatchHttp(
      addFacilityToFacilityProfile({
        id,
        data: {
          facility_id: data.id,
        },
      }),
      'Facility has been successfully added to facility'
    )

    await dispatchHttp(fetchFacilityProfileById(id))
    await dispatchHttp(getRelatedFacilityProfileFacilities({ data: id }))
  }

  useEffect(() => {
    dispatchHttp(fetchFacilityProfileById(id))
  }, [dispatchHttp, id])

  useEffect(() => {
    if (
      facilityData &&
      !facilityData?.sole_proprietor_doctor &&
      isUserActionAllowed('view_facility')
    ) {
      dispatchHttp(getRelatedFacilityProfileFacilities({ data: id }))
    }
    // eslint-disable-next-line
  }, [dispatchHttp, id, facilityData?.id])

  useEffect(() => {
    if (
      facilityData?.sole_proprietor_doctor &&
      isUserActionAllowed('view_doctor')
    ) {
      dispatchHttp(fetchDoctorById(facilityData.sole_proprietor_doctor))
    }
    // eslint-disable-next-line
  }, [dispatchHttp, facilityData?.sole_proprietor_doctor])

  useEffect(() => {
    if (facilityData?.mrd_facility_id && isUserActionAllowed('view_facility')) {
      dispatchHttp(fetchFacilityById(facilityData.mrd_facility_id))
    }
    // eslint-disable-next-line
  }, [dispatchHttp, facilityData?.id])

  const getEsignStats = async facilityId => {
    const resp = await dispatchHttp(
      getFacilityProfileESignStat({
        facility_profile: facilityId,
      })
    )
    setEsignStats(resp)
  }
  useEffect(() => {
    if (facilityData?.id) {
      getEsignStats(facilityData?.id)
    }
    // eslint-disable-next-line
  }, [dispatchHttp, facilityData?.id])

  const downloadForm = useCallback(async () => {
    const data = await dispatchHttp(
      fetchFormById({ formId: facilityData?.form?.id, id })
    )

    downloadFileByUrl(data?.changes?.link)
  }, [dispatchHttp, facilityData, id])

  const isShowDownloadForm =
    facilityData?.form && isUserActionAllowed('download_authorizationform')
  const isShowCreateForm =
    !facilityData?.form && isUserActionAllowed('add_authorizationform')

  const isShowDivider =
    (isShowDownloadForm || isShowCreateForm) &&
    isUserActionAllowed('view_authorizationform')

  const isShowAuthFormSection =
    isShowDownloadForm ||
    isShowCreateForm ||
    isUserActionAllowed('view_authorizationform')

  const mainRetriever = facilityData?.main_retriever_definition?.contname ? (
    <Tooltip title="Click to set new Main Retriever">
      {facilityData?.main_retriever_definition?.fullname ? (
        <span>
          {facilityData?.main_retriever_definition?.fullname}
          <Divider type="vertical" className="profile-card__divider" />
        </span>
      ) : null}
      {facilityData?.main_retriever_definition?.contname}
    </Tooltip>
  ) : (
    facilityData?.main_retriever_definition?.fullname
  )

  const lastRetriever =
    facilityData?.last_retriever?.fullname ||
    facilityData?.last_retriever?.contname ? (
      <span>
        {facilityData?.last_retriever?.fullname ? (
          <span>
            {facilityData?.last_retriever?.fullname}
            <Divider type="vertical" className="profile-card__divider" />
          </span>
        ) : null}
        {facilityData?.last_retriever?.contname}
      </span>
    ) : (
      '-'
    )

  const primaryCopyCompany =
    facilityData?.copy_companies.find(item => item.is_primary) ||
    facilityData?.copy_companies[0]

  return (
    <DetailPageLayout breadcrumbs={breadcrumbs}>
      <Row>
        <Col span={24}>
          <Card>
            <Skeleton
              loading={loadingStatus === LOADING_STATUS}
              active
              paragraph={{ rows: 1 }}
            >
              <Row align="middle" justify="space-between">
                <Col span={20}>
                  <Row>
                    <Typography.Title level={2} style={{ margin: 0 }} ellipsis>
                      {facilityData?.provider_name}{' '}
                    </Typography.Title>
                  </Row>
                  <Row>
                    {facilityData?.is_internal && (
                      <Tag color="processing">Handled Internally</Tag>
                    )}
                    {facilityData?.health_networks?.map(item => (
                      <Col style={{ marginBottom: '2px' }} key={item.id}>
                        <Link
                          to={`/health-networks/${item.id}`}
                          target="_blank"
                        >
                          <Tag color="processing" icon={<PartitionOutlined />}>
                            {item.name}
                          </Tag>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {isUserActionAllowed('view_case') && (
                      <Col>
                        {' '}
                        <Link
                          to={{
                            pathname: '/cases',
                            filter: {
                              facility_profile__id: id,
                              status: 4,
                            },
                          }}
                        >
                          <Button type="primary" ghost>
                            View All Cases
                          </Button>
                        </Link>
                      </Col>
                    )}

                    <Col style={{ marginLeft: 10 }}>
                      <MedicalEntityModal
                        title="Edit Facility Profile"
                        trigger={
                          isUserActionAllowed('change_facilityprofile') ? (
                            <Button
                              type="primary"
                              ghost
                              icon={<EditOutlined />}
                            >
                              Edit
                            </Button>
                          ) : (
                            <></>
                          )
                        }
                        form={
                          <UpdateFacilityForm
                            initialValues={initialValues}
                            onSubmit={handleFacilityProfileUpdate}
                            validationSchema={createFacilityProfileSchema}
                            action="edit"
                            entityId={id}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
        {facilityData?.sole_proprietor_doctor &&
          isUserActionAllowed('view_doctor') && (
            <Col span={24}>
              <ProfileCard
                title={<Space size={16}>Provider</Space>}
                style={{
                  headerColor: '#E6F7FF',
                  bodyColor: '#003A8C',
                  padding: 20,
                }}
              >
                <Table
                  columns={doctorTableColumns.filter(
                    item =>
                      item.key !== 'facility_profile' &&
                      item.key !== 'related_facilities'
                  )}
                  totalData={[doctor]}
                  entity={DOCTOR}
                />
              </ProfileCard>
            </Col>
          )}
        <FacilityProfileMRD
          handleSetMRDFacility={handleSetMRDFacility}
          unlinkFacilityFromFacilityProfile={unlinkFacilityFromFacilityProfile}
          relateFacilityProfile={relateFacilityProfile}
          treatment_facilities={treatment_facilities}
          mrd_facility={isUserActionAllowed('view_facility') && mrdFacility}
          relatedFacilityProfilesCount={relatedFacilityProfilesCount}
          id={id}
          isUserActionAllowed={isUserActionAllowed}
        />
        <Col span={24}>
          <ProfileCard
            title="Overview"
            color="yellow"
            loading={loadingStatus}
            content={[
              isUserActionAllowed('view_retriever') && {
                label: 'Main Retriever',
                value: (
                  <>
                    <MedicalEntityModal
                      title="Set Main Retriever"
                      className="set-retriever-modal"
                      width={1200}
                      trigger={
                        <a href="/">
                          {!facilityData?.auto_detect_main_retriever && (
                            <StopOutlined style={{ marginRight: '4px' }} />
                          )}
                          {mainRetriever}
                        </a>
                      }
                      form={
                        <SetRetrieverForm
                          initialValues={{
                            ...initialValues,
                            id: facilityData?.id,
                          }}
                          isAutoDetection={
                            facilityData?.auto_detect_main_retriever
                          }
                          onSubmit={handleSetRetriever}
                          entityId={id}
                          isUserActionAllowed={isUserActionAllowed}
                        />
                      }
                    />
                  </>
                ),
              },
              isUserActionAllowed('view_retriever') && {
                label: 'Past Retrievers',
                value: (
                  <>
                    {lastRetriever}
                    <>
                      <Divider
                        type="vertical"
                        className="profile-card__divider"
                      />

                      <Link to={`/facility-profiles/${id}/retrievers/`}>
                        <Space size={6}>
                          <ClockCircleOutlined />
                          History
                        </Space>
                      </Link>
                    </>
                  </>
                ),
              },
              {
                label: 'Communication Method',
                value: (
                  <>
                    <MedicalEntityModal
                      title="Change Communication Method"
                      className="communication-method-modal"
                      width={400}
                      trigger={
                        isUserActionAllowed('change_facilityprofile') ? (
                          <a href="/">
                            {!facilityData?.auto_detect_communication_method && (
                              <StopOutlined style={{ marginRight: '4px' }} />
                            )}
                            {facilityData?.communication_method}
                          </a>
                        ) : (
                          <>
                            {!facilityData?.auto_detect_communication_method && (
                              <StopOutlined style={{ marginRight: '4px' }} />
                            )}
                            {facilityData?.communication_method}
                          </>
                        )
                      }
                      form={
                        <CommunicationMethodForm
                          initialValues={{
                            ...initialValues,
                            id: facilityData?.id,
                          }}
                          isAutoDetection={
                            facilityData?.auto_detect_communication_method
                          }
                          onSubmit={handleChangeCommunicationMethod}
                          entityId={id}
                        />
                      }
                    />
                  </>
                ),
              },
              {
                label: 'Payment Method',
                value: facilityData?.payment_type,
              },
              {
                label: 'Last Case Date',
                value: moment(facilityData?.last_case_date).isValid()
                  ? formatDate(
                      facilityData?.last_case_date,
                      DATE_FORMAT.month_day_year
                    )
                  : '-',
              },
              {
                label: 'Case Count',
                value: facilityData?.case_count,
              },
            ].filter(Boolean)}
          />
          <ProfileCard
            title="E-Signature"
            color="yellow"
            loading={loadingStatus}
            content={[
              {
                label: 'E-Signature Verification',
                value: facilityData?.e_signature,
              },
              {
                label: 'E-Signature Accepted',
                value: eSignStats?.e_signature_accepted_count,
              },
              {
                label: 'E-Signature Accepted With Conditions',
                value: eSignStats?.e_signature_accepted_with_conditions_count,
              },
              {
                label: 'E-Signature Not Approved',
                value: eSignStats?.e_signature_not_approved_count,
              },
            ]}
          />
          <ProfileCard
            title="Forms"
            color="orange"
            loading={loadingStatus}
            content={[
              {
                label: 'Special Authorization',
                value: (
                  <>
                    <SpecialAuthorizationModal
                      className="set-retriever-modal"
                      width={1200}
                      facilityId={facilityData?.id}
                      trigger={<a href="/">By Insurance Company</a>}
                    />
                  </>
                ),
              },
              {
                label: 'Special Authorization Form Requirement',
                value: facilityData?.special_auth,
              },
              {
                label: 'Special Authorization Form',
                authForm: true,
                value: (
                  <>
                    {isShowDownloadForm && (
                      <Typography.Link onClick={downloadForm}>
                        <Space size={6}>
                          <FileOutlined />
                          Download Form
                        </Space>
                      </Typography.Link>
                    )}
                    {isShowCreateForm && (
                      <MedicalEntityModal
                        title="Create Special Authorization Form"
                        className="set-retriever-modal"
                        width={580}
                        trigger={
                          <Typography.Link>
                            <Space size={6}>
                              <FileOutlined />
                              Add New Form
                            </Space>
                          </Typography.Link>
                        }
                        form={
                          <AuthForm
                            validationSchema={createAuthFormSchema}
                            onSubmit={handleFormCreate}
                            initialValues={{
                              attachment: null,
                            }}
                          />
                        }
                      />
                    )}
                    {isShowDivider && (
                      <Divider
                        type="vertical"
                        className="profile-card__divider"
                      />
                    )}
                    {isUserActionAllowed('view_authorizationform') && (
                      <MedicalEntityModal
                        title="Special Authorization Forms"
                        className="set-retriever-modal"
                        width={1200}
                        trigger={
                          <Typography.Link>
                            <Space size={6}>
                              <ClockCircleOutlined />
                              History
                            </Space>
                          </Typography.Link>
                        }
                        form={
                          <AuthFormsList
                            initialValues={{
                              ...initialValues,
                              form: facilityData?.form,
                              history_forms: facilityData?.history_forms,
                              id: facilityData?.id,
                            }}
                            canDelete={isUserActionAllowed(
                              'delete_authorizationform'
                            )}
                            onSubmit={handleFormCreate}
                          />
                        }
                      />
                    )}
                  </>
                ),
              },
            ].filter(
              item => !item.authForm || (item.authForm && isShowAuthFormSection)
            )}
          />
          <ProfileCard
            title="Vendors"
            color="green"
            loading={loadingStatus}
            content={[
              {
                label: 'Copy Company',
                value: isUserActionAllowed('view_copycompany') ? (
                  <MedicalEntityModal
                    title="Set Primary Copy Company"
                    className="set-retriever-modal"
                    width={1200}
                    trigger={
                      <a href="/">
                        {!facilityData?.auto_detect_copy_company && (
                          <StopOutlined style={{ marginRight: '4px' }} />
                        )}
                        {facilityData?.copy_companies_definition}
                      </a>
                    }
                    form={
                      <SetCopyCompanyForm
                        initialValues={{
                          copy_company_id: primaryCopyCompany?.id,
                          id: facilityData?.id,
                        }}
                        isAutoDetection={facilityData?.auto_detect_copy_company}
                        fetchFacilityProfileById={fetchFacilityProfileById}
                        onSubmit={handleSetCopyCompany}
                        entityId={id}
                        isUserActionAllowed={isUserActionAllowed}
                      />
                    }
                  />
                ) : (
                  <>
                    {!facilityData?.auto_detect_copy_company && (
                      <StopOutlined style={{ marginRight: '4px' }} />
                    )}
                    {primaryCopyCompany?.name ?? 'Set Copy Company'}
                  </>
                ),
              },
              {
                label: 'Copy Company Site ID',
                value: primaryCopyCompany?.site_id ?? '-',
              },
              {
                label: 'EMR Vendor',
                value: facilityData?.emr_vendor_name,
              },
              {
                label: 'EMR Data Source',
                value: facilityData?.emr_data_source,
              },
            ]}
          />
          <ProfileCard
            title="Schedule"
            color="blue"
            loading={loadingStatus}
            content={[
              {
                label: 'Days Open',
                value: formatWeekDays(facilityData?.days_open),
              },
              {
                label: 'Hours Open',
                value: formatWorkingHours(
                  facilityData?.working_hours_start,
                  facilityData?.working_hours_end
                ),
              },
              {
                label: 'Turn Around Time',
                value: (
                  <Space size={24}>
                    <span>
                      <Typography.Text type="secondary">Min </Typography.Text>
                      {facilityData?.turn_around_min} days
                    </span>
                    <span>
                      <Typography.Text type="secondary">Max </Typography.Text>
                      {facilityData?.turn_around_max} days
                    </span>
                    <span>
                      <Typography.Text type="secondary">Avg </Typography.Text>
                      {facilityData?.turn_around_avg} days
                    </span>
                  </Space>
                ),
              },
              {
                label: 'Contact Name of Medical Department',
                value: facilityData?.contact_name,
              },
              {
                label: 'Email',
                value: facilityData?.email,
              },
            ]}
          />
          <ProfileCard
            title="Finance"
            color="magenta"
            loading={loadingStatus}
            content={[
              {
                label: 'Min Fee',
                value: `$${facilityData?.min_fee}`,
              },
              {
                label: 'Max Fee',
                value: `$${facilityData?.max_fee}`,
              },
              {
                label: 'Average Facility Records Fee Per Page',
                value: `$${facilityData?.avg_facility_records_fee}`,
              },
              {
                label: 'Avg Fee',
                value: `$${facilityData?.avg_fee}`,
              },
              {
                label: 'Postbill',
                value: facilityData?.postbill,
              },
              {
                label: 'Total Paid',
                value: `$${facilityData?.total_paid}`,
              },
            ]}
          />
        </Col>
      </Row>
      {facilityData?.notes ? (
        <div style={{ marginBottom: '20px' }}>
          <Row>
            <Col span={24}>
              <Card title="Notes" className="full-width">
                <p
                  style={{
                    whiteSpace: 'pre-line',
                  }}
                >
                  {facilityData?.notes}
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}
    </DetailPageLayout>
  )
}

export default FacilityProfile
