/* eslint-disable react/no-danger */
import React from 'react'

export function highlightDataDifference(text, column, data) {
  if (!data.old) {
    return text
  }

  const oldValue = data.old[column.key]
  if (Array.isArray(text)) {
    const newValues = text.filter(item => !oldValue.includes(item))
    const otherValues = text.filter(item => !newValues.includes(item))
    const highlightValues = newValues.map(item => {
      const highlightedText = `<span class='highlight__green'>${item}</span>`
      return (
        <span
          key={item}
          dangerouslySetInnerHTML={{ __html: highlightedText }}
        />
      )
    })
    return [...highlightValues, ...otherValues]
  }

  if (text !== oldValue) {
    const highlightedText = `<span class='highlight__green'>${
      text || 'No info'
    }</span>`
    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
  }

  return text
}
