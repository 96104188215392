import React from 'react'
import classNames from 'classnames'

export const importLogCardInfoItemStatuses = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
}

const getImportLogCardInfoStatusStyles = type => {
  switch (type) {
    case importLogCardInfoItemStatuses.POSITIVE:
      return 'import_log_card__count_info__value_positive'
    case importLogCardInfoItemStatuses.NEGATIVE:
      return 'import_log_card__count_info__value_negative'

    default:
      return null
  }
}

const ImportLogCardInfoItem = ({ title, value, type }) => (
  <div className="import_log_card__count_info">
    <span className="import_log_card__count_info__title">{title}</span>
    <span
      className={classNames(
        'import_log_card__count_info__value',
        getImportLogCardInfoStatusStyles(type)
      )}
    >
      {value}
    </span>
  </div>
)

ImportLogCardInfoItem.STATUSES = importLogCardInfoItemStatuses

export default ImportLogCardInfoItem
