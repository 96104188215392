export function getFeedbackLoopOriginalFields(feedbackLoopChangedData) {
  const originalValues = feedbackLoopChangedData?.originalValues
  if (!originalValues) return null

  const { addresses } = originalValues
  const { fax_numbers } = originalValues
  const { phone_numbers } = originalValues
  return {
    ...originalValues,
    ...addresses[0],
    phone: phone_numbers[0]?.number,
    fax: fax_numbers[0]?.number,
  }
}
