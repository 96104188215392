/* eslint-disable react/display-name */
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  Fragment,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import {
  Space,
  Avatar,
  notification,
  Button,
  Row,
  Typography,
  Empty,
  Spin,
  Radio,
} from 'antd'

import Table from './SearchTable.component'
import {
  sortByKey,
  formatTableData,
  formatDateTime,
  highlightValue,
  boldLocationValue,
  oneStringValue,
  parseValues,
  addOriginalValue,
  getColumnIcon,
} from '../../../../utils/helpers'
import {
  IDLE_STATUS,
  LOADING_STATUS,
  FAILURE_STATUS,
  SUCCESS_STATUS,
  DEFAULT_PAGE_SIZE,
  DOCTOR,
  FACILITY,
  FACILITY_PROFILE,
  EXCEPTION,
  CLUSTER,
  DATE_FORMAT,
  DEFAULT_PAGE_SIZE_OPTIONS,
  CASE_SEARCH_OPTIONS,
  MERGER,
  INSURANCE_COMPANY,
  AGENCIES,
  VENDORS,
  RETRIEVERS,
} from '../../../../constants'
import { useUser } from '../../../../providers/UserProvider'

const renderLink = ({
  route,
  avatar,
  text,
  icon,
  onLinkClick,
  linkData,
  target = '_self',
}) => (
  <Link
    to={{ pathname: route, data: linkData }}
    onClick={onLinkClick}
    target={target}
  >
    <Space>
      {avatar && (
        <Avatar
          className="search-table__user-avatar"
          size={26}
          icon={<UserOutlined className="search-table__user-avatar--icon" />}
        />
      )}
      {text}
      {icon && icon}
    </Space>
  </Link>
)

const renderSearchOptions = (
  id,
  text,
  searchOptions = [],
  changeSearchOption,
  activeRowId,
  editFacilityButton,
  formType
) => {
  const searchOption = searchOptions?.length ? searchOptions[id - 1] : null
  const defaultValue = (searchOption?.find(option => option.active) || {}).key
  const isFeedbackLoop = text === 'contact_form'
  const key = [text, formType].filter(Boolean).join('_')

  return (
    <>
      <span className="search_title">{CASE_SEARCH_OPTIONS[key]}</span>
      {searchOption?.map(radio => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          key={`${text}${radio.key}`}
          onClick={e => {
            if (radio.active && id !== activeRowId) return
            e.stopPropagation()
          }}
        >
          <Radio
            disabled={searchOption?.length === 1}
            value={radio.key}
            onChange={event => {
              changeSearchOption(event.target.value, id)
            }}
            key={radio.key}
            checked={defaultValue === radio.key}
          >
            {radio.key === 'full_search' ? 'Full Search' : 'Top Candidates'}
          </Radio>
        </div>
      ))}

      {isFeedbackLoop && editFacilityButton}
    </>
  )
}

const SearchTable = ({
  className,
  columns,
  entity,
  onLoadData,
  tableTitle,
  onShowMore,
  totalData,
  totalCount,
  formData,
  loadingStatus,
  editForm,
  footer,
  rowSelection,
  customRowSelection,
  rowDeletion,
  titleAction,
  useClearData,
  tableRef,
  hideTotalCount,
  excludeId,
  pageSizeOptions,
  pageSize,
  changeSearchOption,
  searchOptions,
  primarySearch,
  highlightedFields,
  originalFields,
  onRow,
  rowClassName,
  onLinkClick,
  linkData,
  activeRowId,
  onFormatData,
  onPageChange,
  editFacility,
  rowKey,
  scroll,
}) => {
  const initialPaginationData = {
    current: 1,
    pageSize: pageSize || DEFAULT_PAGE_SIZE,
  }
  const prevFormData = useRef(null)
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState({
    data: [],
    pagination: initialPaginationData,
  })
  const [tableColumns, setTableColumns] = useState([])
  const { isUserActionAllowed } = useUser()

  useMemo(() => {
    const formattedColumns = columns.map(column => {
      const hasChildren = column.children
      let formattedColumn

      const preparedColumn = columnData => ({
        ...columnData,
        dataIndex: columnData.key,
        sorter: columnData.sorter
          ? (a, b) => sortByKey(a, b, columnData.key)
          : null,
        render: (text, data) => {
          const {
            link,
            target,
            icon,
            avatar,
            key,
            path,
            render,
            partialRender,
            date,
            highlightOptions,
            field,
            boldLocationType,
            oneString,
            width,
          } = columnData
          const {
            id,
            facility_profile,
            case_id_related_by_cms_id,
            form_type,
          } = data

          if (render) {
            return render(text, columnData, data, isUserActionAllowed)
          }

          if (partialRender) {
            // eslint-disable-next-line no-param-reassign
            text = partialRender(text, columnData, data)
          }

          if (key === 'facility_profile') {
            const route = `/facility-profiles/${facility_profile}/`
            if (isUserActionAllowed('view_facilityprofile')) {
              return facility_profile
                ? renderLink({ route, avatar, text })
                : 'No info'
            }
            return facility_profile ? text : 'No info'
          }

          if (key === 'insurance-company') {
            const route = `/insurance-company/${id}/`
            return facility_profile
              ? renderLink({ route, avatar, text })
              : 'No info'
          }

          if (key === 'links') {
            const encodedGoogleData = encodeURI(text.googleSearchStr)
            const { npi } = text
            // const encodedNpiData = encodeURI(text.npiSearchStr)

            return (
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.google.com/search?q=${encodedGoogleData}`}
                >
                  Google
                </a>
                <br />
                {text.npi && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://npiregistry.cms.hhs.gov/provider-view/${npi}`}
                  >
                    NPI Lookup
                  </a>
                )}
              </>
            )
          }

          if (key === 'suggestion_type') {
            const editFacilityButton =
              form_type && editFacility && editFacility[form_type]
            return renderSearchOptions(
              id,
              text,
              searchOptions,
              changeSearchOption,
              activeRowId,
              editFacilityButton,
              form_type
            )
          }

          if (key === 'cms_case_id' && case_id_related_by_cms_id) {
            const route = `/cases/${case_id_related_by_cms_id}/`
            return renderLink({
              route,
              text,
            })
          }

          if (highlightOptions) {
            // eslint-disable-next-line no-param-reassign
            text = highlightValue(
              primarySearch,
              text,
              highlightedFields,
              highlightOptions,
              field
            )
          }

          if (oneString) {
            // eslint-disable-next-line no-param-reassign
            text = oneStringValue(text, width)
          }

          if (form_type && originalFields && originalFields[form_type]) {
            const originalValue = originalFields[form_type][key]
            // eslint-disable-next-line no-param-reassign
            text = addOriginalValue(text, originalValue, width)
          }

          if (boldLocationType) {
            // eslint-disable-next-line no-param-reassign
            text = boldLocationValue(text, field)
          }

          // eslint-disable-next-line no-param-reassign
          text = parseValues(text, field)

          if (link) {
            const route = path
              ? `/${entity}/${id}/${path}`
              : `/${entity}/${id}/`
            const linkIcon = getColumnIcon(icon, data, entity)

            return renderLink({
              linkData,
              onLinkClick,
              route,
              avatar,
              text,
              target,
              icon: linkIcon,
            })
          }

          return text && Array.isArray(text) ? (
            text.map((val, index) => (
              <Fragment key={`key_${val}_${index + 1}`}>
                <span style={{ display: 'inline-block', width: `${width}px` }}>
                  {val || '-'}
                </span>
                <br />
              </Fragment>
            ))
          ) : (
            <Space>
              {text != null ? (
                <span style={{ display: 'inline-block', width: `${width}px` }}>
                  {date
                    ? formatDateTime(text, `${DATE_FORMAT.month_day_year} |`)
                    : text}
                  {icon && icon}
                </span>
              ) : (
                <Typography.Text type="secondary">
                  <span
                    style={{ display: 'inline-block', width: `${width}px` }}
                  >
                    No info
                  </span>
                </Typography.Text>
              )}
            </Space>
          )
        },
      })

      if (hasChildren) {
        const childrenData = column.children.map(child => preparedColumn(child))
        formattedColumn = {
          ...column,
          children: childrenData,
        }
      } else {
        formattedColumn = preparedColumn(column)
      }

      return formattedColumn
    })

    setTableColumns(formattedColumns)
  }, [
    columns,
    entity,
    changeSearchOption,
    searchOptions,
    primarySearch,
    highlightedFields,
    originalFields,
    isUserActionAllowed,
    onLinkClick,
    linkData,
    activeRowId,
    editFacility,
  ])
  const fetchData = useCallback(
    ({ page, pageSize: reqPageSize, data, ordering, sorter }) => {
      dispatch(
        onLoadData({
          pageSize: reqPageSize,
          page,
          data,
          exclude_id: excludeId,
          ordering,
          ...formData,
        })
      )
        .then(unwrapResult)
        .then(({ results, count }) => {
          let formattedTableData = results
          if (onFormatData) {
            formattedTableData = onFormatData(results)
          }

          if (!useClearData) {
            formattedTableData = formatTableData(formattedTableData)
          }

          setTableData({
            data: formattedTableData,
            pagination: {
              total: count,
              current: page,
              pageSize: reqPageSize,
              ordering,
              sorter,
            },
          })
        })
        .catch(({ message }) => {
          notification.error({ message })
        })
    },
    [dispatch, formData, onLoadData, useClearData, excludeId, onFormatData]
  )

  const handleTableChange = useCallback(
    (page, tablePageSize, sorter) => {
      let ordering = null

      if (sorter && sorter.order) {
        ordering =
          sorter.order === 'ascend' ? `-${sorter.columnKey}` : sorter.columnKey
      }

      fetchData({
        page,
        pageSize: tablePageSize,
        data: formData,
        ordering,
        sorter,
      })
      if (onPageChange) {
        onPageChange({
          page,
          pageSize: tablePageSize,
          ordering,
        })
      }
    },
    [fetchData, formData, onPageChange]
  )

  useEffect(() => {
    setTableData(prev => {
      let formattedTableData = totalData
      if (onFormatData) {
        formattedTableData = onFormatData(totalData)
      }

      if (!useClearData) {
        formattedTableData = formatTableData(formattedTableData)
      }

      return {
        data: formattedTableData,
        pagination: { ...prev.pagination, total: totalCount },
      }
    })
  }, [totalCount, totalData, useClearData, onFormatData])

  useEffect(() => {
    if (JSON.stringify(prevFormData.current) !== JSON.stringify(formData)) {
      let formattedTableData = totalData
      if (onFormatData) {
        formattedTableData = onFormatData(totalData)
      }

      if (!useClearData) {
        formattedTableData = formatTableData(formattedTableData)
      }

      setTableData({
        data: formattedTableData,
        pagination: { ...initialPaginationData, total: totalCount },
      })
      prevFormData.current = formData
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData,
    prevFormData,
    tableData.data,
    totalData,
    totalCount,
    useClearData,
  ])

  useEffect(() => {
    let formattedTableData = totalData
    if (onFormatData) {
      formattedTableData = onFormatData(totalData)
    }

    if (!useClearData) {
      formattedTableData = formatTableData(formattedTableData)
    }

    setTableData({
      data: formattedTableData,
      pagination: { ...initialPaginationData, total: totalCount },
    })
    prevFormData.current = formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount])

  return (
    <div
      ref={tableRef}
      className={cx('search-table', { 'search-table--compact': !!onShowMore })}
    >
      {tableTitle && (
        <div className="search-table__title">
          {tableTitle}{' '}
          {!!totalCount && (
            <span className="search-table__title--count">
              {hideTotalCount ? null : totalCount}
            </span>
          )}
          {titleAction && !titleAction.hide && (
            <Typography.Link
              className="search-table__title--action-text"
              onClick={titleAction.action}
            >
              {titleAction.text} {titleAction.icon}
            </Typography.Link>
          )}
        </div>
      )}
      {/* eslint-disable-next-line no-nested-ternary */}
      {tableData.data.length ? (
        <Table
          className={className}
          columns={tableColumns}
          pagination={tableData.pagination}
          error={loadingStatus === FAILURE_STATUS}
          onChange={onLoadData && handleTableChange}
          tableProps={{
            loading:
              loadingStatus === LOADING_STATUS &&
              loadingStatus !== SUCCESS_STATUS,
            dataSource: tableData.data,
            bordered: true,
          }}
          entity={entity}
          editForm={editForm}
          rowSelection={rowSelection}
          customRowSelection={customRowSelection}
          rowDeletion={rowDeletion}
          pageSizeOptions={pageSizeOptions || DEFAULT_PAGE_SIZE_OPTIONS}
          onRow={onRow}
          rowClassName={rowClassName}
          rowKey={rowKey}
          scroll={scroll}
          formData={formData}
        />
      ) : loadingStatus === LOADING_STATUS ? (
        <Empty description="" className="search-table__empty">
          <Spin
            spinning
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        </Empty>
      ) : (
        <Empty description="No results" className="search-table__empty" />
      )}
      {!!onShowMore && totalCount >= tableData.pagination.pageSize && (
        <Row justify="end">
          <Button type="primary" ghost onClick={onShowMore}>
            View {totalCount} Results
          </Button>
        </Row>
      )}
      {footer}
    </div>
  )
}

SearchTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  entity: PropTypes.oneOf([
    DOCTOR,
    FACILITY,
    FACILITY_PROFILE,
    INSURANCE_COMPANY,
    AGENCIES,
    EXCEPTION,
    CLUSTER,
    MERGER,
    VENDORS,
    RETRIEVERS,
  ]),
  onLoadData: PropTypes.func,
  tableTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onShowMore: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  totalData: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number,
  loadingStatus: PropTypes.oneOf([
    IDLE_STATUS,
    LOADING_STATUS,
    FAILURE_STATUS,
    SUCCESS_STATUS,
  ]),
  formData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  editForm: PropTypes.element,
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  tableRef: PropTypes.func,
  customRowSelection: PropTypes.shape({
    columnTitle: PropTypes.string,
    onSelect: PropTypes.func,
  }),
  rowSelection: PropTypes.shape({
    type: PropTypes.oneOf(['radio', 'checkbox']),
    onChange: PropTypes.func,
  }),
  rowDeletion: PropTypes.shape({
    tooltip: PropTypes.string,
    okText: PropTypes.string,
    confirmMsg: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }),
  titleAction: PropTypes.shape({
    action: PropTypes.func,
    text: PropTypes.string,
    icon: PropTypes.element,
    hide: PropTypes.bool,
  }),
  useClearData: PropTypes.bool,
  hideTotalCount: PropTypes.bool,
  excludeId: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  pageSize: PropTypes.number,
  changeSearchOption: PropTypes.func,
  searchOptions: PropTypes.arrayOf(PropTypes.array),
  primarySearch: PropTypes.shape({
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    city: PropTypes.string,
    fax: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
  }),
  highlightedFields: PropTypes.shape({
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    city: PropTypes.string,
    fax: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
  }),
  originalFields: PropTypes.shape({
    contact_form_mrd: PropTypes.shape({}),
    contact_form_treatment: PropTypes.shape({}),
  }),
  onRow: PropTypes.func,
  rowClassName: PropTypes.func,
  onLinkClick: PropTypes.func,
  onFormatData: PropTypes.func,
  linkData: PropTypes.shape({
    sort: PropTypes.string,
  }),
  activeRowId: PropTypes.number,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
  editFacility: PropTypes.shape({
    contact_form_mrd: PropTypes.shape({}),
    contact_form_treatment: PropTypes.shape({}),
  }),
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  scroll: PropTypes.shape({}),
}

SearchTable.defaultProps = {
  entity: FACILITY,
  onLoadData: null,
  tableTitle: null,
  totalCount: 0,
  onShowMore: false,
  loadingStatus: IDLE_STATUS,
  formData: {},
  editForm: null,
  footer: null,
  tableRef: null,
  customRowSelection: null,
  rowSelection: null,
  rowDeletion: null,
  titleAction: null,
  useClearData: false,
  hideTotalCount: false,
  excludeId: null,
  pageSizeOptions: null,
  pageSize: null,
  changeSearchOption: null,
  searchOptions: null,
  primarySearch: null,
  highlightedFields: null,
  originalFields: {
    contact_form_mrd: null,
    contact_form_treatment: null,
  },
  onRow: null,
  rowClassName: null,
  onLinkClick: null,
  onFormatData: null,
  linkData: null,
  activeRowId: null,
  onPageChange: null,
  className: '',
  editFacility: {
    contact_form_mrd: null,
    contact_form_treatment: null,
  },
  rowKey: null,
  scroll: null,
}

export default SearchTable
