import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Typography } from 'antd'

const MedicalEntityModal = ({ form, title, trigger, width, ...props }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleOpenModal = e => {
    if (e) e.preventDefault()

    setIsModalVisible(!isModalVisible)
  }

  return (
    <>
      {trigger &&
        React.cloneElement(trigger, {
          onClick: handleOpenModal,
        })}
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={false}
        centered
        width={width}
        destroyOnClose
        onCancel={() => setIsModalVisible(false)}
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(10px)',
        }}
        {...props}
      >
        <Typography.Title level={3}>{title}</Typography.Title>
        {React.cloneElement(form, { onClose: handleOpenModal })}
      </Modal>
    </>
  )
}

MedicalEntityModal.propTypes = {
  form: PropTypes.element.isRequired,
  title: PropTypes.string,
  trigger: PropTypes.element,
  width: PropTypes.number,
}

MedicalEntityModal.defaultProps = {
  title: '',
  width: 800,
  trigger: '',
}

export default MedicalEntityModal
