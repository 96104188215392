import React, { useState } from 'react'
import { Typography, Tooltip } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const CopyView = ({ title, value }) => {
  const [copied, setCopied] = useState(false)

  return (
    <CopyToClipboard
      text={value}
      onCopy={() => {
        setCopied(true)
      }}
    >
      <Tooltip
        onVisibleChange={() => {
          setCopied(false)
        }}
        title={copied ? 'Copied' : 'Copy'}
      >
        <Typography.Link>{title}</Typography.Link>
      </Tooltip>
    </CopyToClipboard>
  )
}

export default CopyView
