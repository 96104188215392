import React from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'

const { TextArea } = Input
// eslint-disable-next-line react/display-name
const FieldWrapper = Field => ({
  icon,
  size,
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
  label,
  type,
  error,
  suffix,
  required,
  ...props
}) => {
  const { name } = field

  const handleBlur = () => {
    setFieldTouched(name, true)
  }

  const handleChange = ({ target: { value } }) => {
    setFieldValue(name, value)
  }

  return (
    <Form.Item
      label={label}
      validateStatus={
        (errors[name] && touched[name]) || error ? 'error' : 'success'
      }
      help={
        (errors[name] && touched[name]) || error ? errors[name] || error : null
      }
      required={required}
    >
      <Field
        {...field}
        {...props}
        size={size}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Form.Item>
  )
}

const TextareaField = FieldWrapper(TextArea)
TextareaField.displayName = 'TextareaField'

TextareaField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
    ]),
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }).isRequired,
  required: PropTypes.bool,
}

TextareaField.defaultProps = {
  field: PropTypes.shape({
    value: '',
    onChange: () => null,
    onBlur: () => null,
  }),
  required: false,
}

export default TextareaField
