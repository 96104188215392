import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  getExceptionList,
  fetchExceptionById,
  fetchExceptionHistoryLog,
  changeExceptionStatus,
  resetState,
} from './actions'

import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../../../constants'

export const exceptionAdapter = createEntityAdapter()
const initialState = exceptionAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
  history_logs: exceptionAdapter.getInitialState({ count: 0 }),
})

const exceptionSlice = createSlice({
  name: 'exceptions',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.history_logs = exceptionAdapter.getInitialState({ count: 0 })
      state.count = 0
      state.status = IDLE_STATUS
      exceptionAdapter.removeAll(state)
    })
    builder.addCase(getExceptionList.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getExceptionList.fulfilled, (state, { payload }) => {
      state.count = payload.count || payload.length
      exceptionAdapter.setAll(state, payload.results || payload)
      state.status = SUCCESS_STATUS
    })
    builder.addCase(getExceptionList.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(fetchExceptionById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchExceptionById.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      exceptionAdapter.upsertOne(state, payload)
    })
    builder.addCase(changeExceptionStatus.fulfilled, exceptionAdapter.updateOne)
    builder.addCase(fetchExceptionHistoryLog.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      fetchExceptionHistoryLog.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.status = SUCCESS_STATUS
        state.history_logs.count = count
        exceptionAdapter.setAll(state.history_logs, results)
      }
    )
  },
})

const { reducer } = exceptionSlice

export default reducer
