import React from 'react'
import { Link } from 'react-router-dom'
import { List, Row, Col, Typography, Card, Tag } from 'antd'

import { mapStatusColor, formatDateTime } from '../../../utils/helpers'
import { useUser } from '../../../providers/UserProvider'
import { updateChanges } from '../../pages/exceptions/helpers/getCaseHistoryChanges'

const renderTag = (change, isUserActionAllowed) => {
  const isViewAllowed =
    change?.name === 'Facility'
      ? isUserActionAllowed('view_facility')
      : isUserActionAllowed('view_doctor')
  return (
    <Tag
      className="exception-log-card__tag"
      color={change.status && mapStatusColor(change.status)}
    >
      {change.name && (
        <span className="exception-log-card__tag-name">{change.name}:</span>
      )}
      {change.link && isViewAllowed ? (
        <a target="_blank" rel="noreferrer" href={change.link}>
          <span className="exception-log-card__tag-text link">
            {change.value}
          </span>
        </a>
      ) : (
        <span
          className="exception-log-card__tag-text"
          style={{ overflow: 'auto', width: '100%', whiteSpace: 'normal' }}
        >
          {change.value}
        </span>
      )}
    </Tag>
  )
}

const ExceptionLogCard = ({
  data: { changes, editor, created_at, cms_case_number } = {},
  isMrd,
  id,
  renderCmsID,
}) => {
  const updatedChanges = updateChanges(changes, id, isMrd)
  const { isUserActionAllowed } = useUser()
  return (
    <List.Item>
      <Card className="exception-log-card">
        <List itemLayout="vertical">
          <List.Item>
            <Row gutter={24}>
              <Col span={24}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '4px',
                  }}
                >
                  {renderCmsID && (
                    <Link
                      style={{
                        fontSize: '14px',
                      }}
                      to={`/cases/${id}/history/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      CMS ID: {cms_case_number}
                    </Link>
                  )}
                  <Typography.Paragraph
                    type="secondary"
                    className="exception-log-card__text"
                    style={{ marginLeft: `${renderCmsID ? 'auto' : 0}` }}
                  >
                    Edited By:{' '}
                    <span>
                      {editor.firstname} {editor.lastname}
                    </span>{' '}
                    {formatDateTime(created_at)}
                  </Typography.Paragraph>
                </div>
                {updatedChanges?.map(change => (
                  <div
                    key={change.title}
                    style={{
                      paddingBottom: '1px',
                    }}
                  >
                    <div
                      style={{
                        display: `${
                          change.type === 'search_suggestions' ||
                          change.type === 'feedback_loop'
                            ? 'block'
                            : 'flex'
                        }`,
                      }}
                    >
                      {change.title && (
                        <Typography.Title
                          level={4}
                          style={{
                            fontSize: '14px',
                            marginRight: '6px',
                          }}
                        >
                          {change.is_created && (
                            <span>
                              {`#${id} Case Created`}
                              <br />
                            </span>
                          )}
                          {change?.title}
                        </Typography.Title>
                      )}

                      {change?.items?.length &&
                        change?.items.map(item =>
                          renderTag(item, isUserActionAllowed)
                        )}
                      {change?.value && renderTag(change, isUserActionAllowed)}
                    </div>
                    {change.reason && (
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <Typography.Title
                          level={4}
                          style={{
                            fontSize: '14px',
                            marginRight: '6px',
                          }}
                        >
                          Reason:
                        </Typography.Title>
                        <span>{change.reason}</span>
                      </div>
                    )}
                  </div>
                ))}
              </Col>
            </Row>
          </List.Item>
        </List>
      </Card>
    </List.Item>
  )
}

export default ExceptionLogCard
