import { createAsyncThunk } from '@reduxjs/toolkit'

import RoleService from '../../../services/RoleService'
import { getPagination } from '../../../utils/functions'

export const fetchAllRoles = createAsyncThunk(
  'roles/fetchAll',
  async (data = {}) => {
    const { skipPagination, ...rest } = data
    if (skipPagination) {
      return RoleService.getRoles(rest)
    }

    return getPagination(rest, RoleService.getRoles.bind(RoleService))
  }
)
export const fetchRolePermissions = createAsyncThunk(
  'roles/fetchPermissions',
  async () => RoleService.getRolePermissions()
)

export const fetchRoleById = createAsyncThunk('roles/fetchById', async id =>
  RoleService.getRoleById(id)
)

export const createRole = createAsyncThunk('roles/createOne', async data =>
  RoleService.createRole(data)
)
export const deleteRole = createAsyncThunk('roles/deleteOne', async data =>
  RoleService.deleteRole(data)
)

export const updateRole = createAsyncThunk(
  'roles/updateOne',
  async ({ id, ...rest }) => RoleService.updateRole(id, rest)
)
