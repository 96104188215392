import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getRetrievers, getRetrieversTimeZones, resetState } from './actions'
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const retrieversAdapter = createEntityAdapter()
const initialState = retrieversAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
  timezones: retrieversAdapter.getInitialState({ count: 0 }),
})

const retrieverSlice = createSlice({
  name: 'retrievers',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      retrieversAdapter.removeAll(state)
      state.timezones = retrieversAdapter.getInitialState({ count: 0 })
    })
    builder.addCase(getRetrievers.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getRetrievers.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        retrieversAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(getRetrieversTimeZones.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getRetrieversTimeZones.fulfilled, (state, { payload }) => {
      retrieversAdapter.setAll(state.timezones, payload)
      state.status = SUCCESS_STATUS
    })
  },
})

const { reducer } = retrieverSlice

export default reducer
