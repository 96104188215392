import React from 'react'
import { Form, Button } from 'antd'
import { Formik, Field } from 'formik'
import { LockOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import TextField from '../../../shared/TextField'
import { resetPasswordSchema } from '../../../../schema/auth'

const ForgotPasswordForm = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={resetPasswordSchema}
    initialValues={{ password: '', password_confirmation: '' }}
  >
    {({ handleSubmit }) => (
      <Form onFinish={handleSubmit}>
        <Field
          component={TextField}
          name="password"
          placeholder="Password"
          type="password"
          icon={<LockOutlined className="form-input-icon" />}
        />
        <Field
          component={TextField}
          name="password_confirmation"
          placeholder="Password Confirmation"
          type="password"
          icon={<LockOutlined className="form-input-icon" />}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Confirm
          </Button>
        </Form.Item>
      </Form>
    )}
  </Formik>
)

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ForgotPasswordForm
