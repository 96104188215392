import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Spin,
  Col,
  Form,
  Row,
  Button,
  Tooltip,
  Modal,
  Typography,
  Select,
} from 'antd'
import { Formik, Field } from 'formik'
import { EditOutlined } from '@ant-design/icons'

import TextField from '../../shared/TextField'
import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'

import {
  getVendors,
  selectAllVendors,
  updateVendor,
  resetState as resetClustersState,
} from '../../../state/modules/vendors'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import { VENDORS } from '../../../constants'
import { vendorsTableColumns } from '../../../data'

const Vendors = () => {
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const vendorsList = useSelector(selectAllVendors)
  const { count } = useSelector(state => state.vendors)
  const loadingStatus = useSelector(state => state.vendors.status)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  })

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [name, setName] = useState('')
  const [vendorID, setVendorID] = useState('')

  const handleOpenModal = e => {
    if (e) e.preventDefault()
    setIsModalVisible(!isModalVisible)
  }

  const setModalData = text => {
    setIsDisabled(text.is_disabled)
    setName(text.name)
    setVendorID(text.id)
  }

  const renderEditColumn = text => (
    <>
      <Button
        type="text"
        onClick={() => {
          setModalData(text)
          handleOpenModal()
        }}
        icon={
          <Tooltip title="Edit">
            <EditOutlined style={{ color: '#BFBFBF', fontSize: 18 }} />
          </Tooltip>
        }
      />
    </>
  )

  const selectOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  const columns = [
    ...vendorsTableColumns,
    {
      title: '',
      key: 'id',
      align: 'center',
      width: 50,
      render: (_, col, text) => renderEditColumn(text),
    },
  ]

  const handleUpdateVendor = async ({ id, data }) =>
    dispatchHttp(updateVendor({ id, data }), 'Vendor updated successfully')

  const onSubmit = async ({ id, data }) => {
    const updatedData = {
      is_disabled: data.isDisabled,
    }

    await handleUpdateVendor({ id, data: updatedData })
    return dispatchHttp(getVendors({ ...pagination }))
  }

  useEffect(() => {
    dispatchHttp(getVendors({}))
  }, [dispatchHttp])

  useEffect(
    () => () => {
      dispatch(resetClustersState())
    },
    [dispatch]
  )

  return (
    <Spin spinning={false}>
      <PageLayout
        title={
          <>
            Vendors
            <span className="badge">{count}</span>
          </>
        }
      >
        <Modal
          visible={isModalVisible}
          footer={null}
          closable={false}
          centered
          destroyOnClose
          onCancel={handleOpenModal}
          maskStyle={{
            background: 'rgba(0, 0, 0, 0.6)',
            backdropFilter: 'blur(10px)',
          }}
          width={400}
        >
          <Typography.Title level={3}>Edit Vendor</Typography.Title>
          <Formik
            initialValues={{
              isDisabled,
              name,
            }}
            onSubmit={async (...data) => {
              try {
                await onSubmit({ data: data[0], id: vendorID })
                handleOpenModal()
              } catch (err) {
                /* empty */
              }
            }}
            enableReinitialize
          >
            {({ handleSubmit, values, setValues, initialValues, dirty }) => (
              <Form
                initialValues={initialValues}
                onFinish={handleSubmit}
                className="vendor-form"
                layout="vertical"
              >
                <Field
                  component={TextField}
                  label="Name"
                  name="name"
                  disabled
                />
                <Form.Item label="Disabled">
                  <Select
                    defaultValue={initialValues.isDisabled}
                    name="isDisabled"
                    options={selectOptions}
                    onChange={e => {
                      setValues({ ...values, isDisabled: e })
                    }}
                  />
                </Form.Item>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ paddingTop: '10px' }}
                >
                  <Col>
                    <Button onClick={handleOpenModal}>Close window</Button>
                  </Col>
                  <Col>
                    <Button type="primary" htmlType="submit" disabled={!dirty}>
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal>
        <Table
          columns={columns}
          onLoadData={getVendors}
          totalCount={count}
          totalData={vendorsList}
          loadingStatus={loadingStatus}
          entity={VENDORS}
          onPageChange={setPagination}
        />
      </PageLayout>
    </Spin>
  )
}

export default Vendors
