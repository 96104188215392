import React from 'react'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'

import { addressType } from '../constants'

export const phoneOptions = Object.entries(addressType).map(([value, key]) => ({
  label: key,
  value,
}))

export const faxOptions = phoneOptions.filter(({ value }) => value !== 'OFF')
export const emailsOptions = faxOptions.filter(({ value }) => value !== 'ADD')

export const weekDays = [
  { label: 'Monday', value: 0 },
  { label: 'Tuesday', value: 1 },
  { label: 'Wednesday', value: 2 },
  { label: 'Thursday', value: 3 },
  { label: 'Friday', value: 4 },
  { label: 'Saturday', value: 5 },
  { label: 'Sunday', value: 6 },
]

export const formRequirementOptions = [
  { label: 'Never', value: 0 },
  { label: 'Always', value: 1 },
  { label: 'Depending on the Insurance Company and Policy Type', value: 2 },
]

export const paymentOptions = [
  { label: 'Credit card', value: 0 },
  { label: 'Check', value: 1 },
  { label: 'Unknown', value: 2 },
]

export const signatureOptions = [
  { label: 'Accepts', value: 0 },
  { label: 'Not Accepts', value: 1 },
]

export const communicationOptions = [
  { label: 'Mail', value: 0 },
  { label: 'Fax', value: 1 },
  { label: 'Unknown', value: 2 },
  { label: 'Email', value: 3 },
  { label: 'Upload', value: 4 },
]

export const postbillOptions = [
  { label: 'Yes', value: 0 },
  { label: 'No', value: 1 },
  { label: 'Unknown', value: 2 },
]

export const sortOptions = [
  { label: 'Last Case Date', value: 'last_case_date' },
  { label: 'Name', value: 'fullname' },
  {
    label: (
      <>
        Number of cases <ArrowUpOutlined />
      </>
    ),
    value: 'total_cases',
  },
  {
    label: (
      <>
        Number of cases <ArrowDownOutlined />
      </>
    ),
    value: '-total_cases',
  },
]

export const stateOptions = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
  {
    label: 'Armed Forces Americas',
    value: 'AA',
  },
  {
    label: 'Armed Forces Europe',
    value: 'AE',
  },
  {
    label: 'Armed Forces Pacific',
    value: 'AP',
  },
]

export const wombaOptions = [
  { label: 'Unknown', value: 'UNKNOWN' },
  { label: 'On', value: 'ON' },
  { label: 'Off', value: 'OFF' },
  { label: 'Service Type Only', value: 'SERVICE TYPE ONLY' },
  { label: 'Targeted', value: 'TARGETED' },
]

export const communicationMethodOptions = [
  { label: 'Email', value: 3 },
  { label: 'Upload', value: 4 },
  { label: 'Mail', value: 0 },
  { label: 'Fax', value: 1 },
]

export const skipOnImportOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const retrieverStateOptions = [
  { label: 'Available', value: 'available' },
  { label: 'Overloaded', value: 'overloaded' },
  { label: 'Inactive', value: 'is_inactive' },
  { label: 'Forbidden', value: 'is_forbidden' },
  { label: 'Away', value: 'is_away' },
  { label: 'Friday Off', value: 'is_no_work_fridays' },
  { label: 'System', value: 'is_system_screen' },
  { label: 'Retired', value: 'is_retired' },
]
