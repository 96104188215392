import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Modal, Row, Col, Divider, Form, Input, Button } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FieldArray, Field, Formik } from 'formik'

import CheckboxField from '../../../shared/CheckboxField'

import { capitalizeEveryWord } from '../../../../utils/helpers'
import { CASE_SEARCH_OPTIONS } from '../../../../constants'

const CONTACT_FIELD = 3

const applyChangesDialog = ({ handleConfirm, data, onClose }) => {
  const handleCloseModal = () => {
    Modal.destroyAll()
  }

  const handleSubmitForm = async values => {
    const formattedValues = {}
    for (const [key, value] of Object.entries(values)) {
      const formatted = Object.values(
        value.filter(item => Object.values(item)[0])
      )
        .flat()
        .map(item => {
          const id = String(Object.keys(item))
          return {
            suggestion: id,
          }
        })

      if (formatted.length) {
        if (key === 'doctor') {
          formattedValues.doctor = formatted
        } else {
          formattedValues.facility = (formattedValues.facility || []).concat(
            formatted
          )
        }
      }
    }

    await handleConfirm(formattedValues)
    await handleCloseModal()
  }

  const mapInitialValues = () => {
    const values = {}

    Object.entries(data).forEach(([field, prop]) => {
      values[field] = prop.suggestions.map(({ id }) => ({ [id]: true }))
    })

    return values
  }

  Modal.confirm({
    title: (
      <Row gutter={18} align="middle">
        <Col>
          <QuestionCircleOutlined style={{ color: '#46BFD1', fontSize: 28 }} />
        </Col>
        <Col>
          <Typography.Title level={3}>Changes Have Been Made</Typography.Title>
          <Typography.Text strong type="secondary">
            Apply changes?
          </Typography.Text>
        </Col>
      </Row>
    ),
    icon: null,
    centered: true,
    width: 580,
    okButtonProps: {
      hidden: true,
    },
    cancelButtonProps: {
      hidden: true,
    },
    maskStyle: {
      background: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(10px)',
    },
    confirmLoading: true,

    afterClose: onClose,
    content: (
      <Formik
        initialValues={mapInitialValues()}
        onSubmit={handleSubmitForm}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            <div className="suggestion-list">
              {Object.entries(data).map(([fieldName, prop]) => {
                const { suggestions, provider } = prop
                return (
                  !!suggestions.length && (
                    <>
                      <span className="suggestion-list__item-title">
                        {CASE_SEARCH_OPTIONS[fieldName]}:
                      </span>
                      <span className="suggestion-list__item-name">
                        #{provider.id} {provider.name}
                      </span>

                      <FieldArray
                        key={fieldName}
                        name={fieldName}
                        render={({ name }) =>
                          suggestions.map(
                            ({ id, accept_data, message, field }, index) => (
                              <div key={id} className="suggestion-list__item">
                                <Field
                                  component={CheckboxField}
                                  name={`${name}[${index}].[${id}]`}
                                  label={message}
                                />
                                {Object.entries(accept_data).map(
                                  ([key, value]) =>
                                    !!value &&
                                    field === CONTACT_FIELD && (
                                      <Form.Item
                                        key={key}
                                        label={key
                                          .split('_')
                                          .map(capitalizeEveryWord)
                                          .join(' ')}
                                      >
                                        <Input
                                          className="suggestion-list__input"
                                          name={key}
                                          value={value}
                                          key={key}
                                          disabled
                                        />
                                      </Form.Item>
                                    )
                                )}
                              </div>
                            )
                          )
                        }
                      />
                      <Divider />
                    </>
                  )
                )
              })}
            </div>
            <Row justify="space-between">
              <Col>
                <Button onClick={handleCloseModal}>Discard Changes</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  Apply Changes
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    ),
  })
}

applyChangesDialog.propTypes = {
  handleConfirm: PropTypes.func,
  data: PropTypes.shape({
    facility: PropTypes.arrayOf(PropTypes.object),
    doctor: PropTypes.arrayOf(PropTypes.object),
  }),
  facilityData: PropTypes.shape({}),
  doctorData: PropTypes.shape({}),
  onClose: PropTypes.func,
}

applyChangesDialog.defaultProps = {
  handleConfirm: null,
  data: {
    facility: [],
    doctor: [],
  },
  facilityData: {},
  doctorData: {},
  onClose: null,
}

export default applyChangesDialog
