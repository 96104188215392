import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Space,
  Row,
  Col,
  Tooltip,
  Spin,
  Form,
  Select,
  Input,
} from 'antd'
import { useSelector } from 'react-redux'
import { PlusOutlined, HistoryOutlined, EditOutlined } from '@ant-design/icons'

import MainLayout from '../../layouts/MainLayout'
import Table from '../../components/Tables/SearchTable'
import useDebounce from '../../../hooks/useDebounce'

import {
  fetchAllUsers,
  selectAllUsers,
  isUsersFetching,
} from '../../../state/modules/users'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { useUser } from '../../../providers/UserProvider'
import { validateSearchParams } from '../../../utils/functions'

export const filterOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
]

const UsersTables = () => {
  const dispatchHttp = useDispatchHttp()
  const users = useSelector(selectAllUsers)
  const isLoading = useSelector(isUsersFetching)
  const count = useSelector(state => state.users.count)

  const { isUserActionAllowed } = useUser()
  const [form] = Form.useForm()
  const [searchParams, setSearchParams] = useState({ state: 'active' })

  const renderEditColumn = text => (
    <>
      <Link to={`/users/${text.id}`}>
        <Button
          type="text"
          icon={
            <Tooltip title="Edit">
              <EditOutlined style={{ color: '#BFBFBF', fontSize: 18 }} />
            </Tooltip>
          }
        />
      </Link>
    </>
  )

  const columns = [
    {
      title: 'First Name',
      key: 'firstname',
    },
    {
      title: 'Last Name',
      key: 'lastname',
    },
    {
      title: 'Role',
      key: 'groups',
      render: groups => groups[0]?.name || '-',
    },
    {
      title: 'Supervisor CMS ID',
      key: 'supervisors',
      render: supervisorsData => {
        const supervisors = supervisorsData?.map(item => +item.cms_id)
        return supervisors.join(', ') || '-'
      },
      align: 'center',
    },
    {
      title: 'Admin',
      key: 'is_superuser',
      render: data => (data ? 'Yes' : 'No'),
      align: 'center',
    },
    {
      title: 'Active',
      key: 'is_active',
      render: data => (data ? 'Yes' : 'No'),
      align: 'center',
    },
    ...(isUserActionAllowed('view_version')
      ? [
          {
            title: 'User Activity',
            key: 'id',
            align: 'center',
            width: 106,
            // eslint-disable-next-line react/display-name
            render: id => (
              <div>
                <Link to={`/user-activity-log?tab=entities&user_id=${id}`}>
                  <Space>
                    <HistoryOutlined />
                    Check
                  </Space>
                </Link>
              </div>
            ),
          },
        ]
      : []),
    ...(isUserActionAllowed('change_user')
      ? [
          {
            title: '',
            key: 'id',
            align: 'center',
            width: 50,
            render: (_, col, text) => renderEditColumn(text),
          },
        ]
      : []),
  ]

  const handleChangeFilter = state => {
    setSearchParams({ ...searchParams, state })

    dispatchHttp(
      fetchAllUsers({
        ...searchParams,
        ...validateSearchParams({ state }),
      })
    )
  }

  const handleChangeSearch = useDebounce(e => {
    const { value } = e.target
    setSearchParams({ ...searchParams, search: value.trim() })
    dispatchHttp(
      fetchAllUsers({
        ...validateSearchParams(searchParams),
        search: value.trim(),
      })
    )
  }, 500)

  useEffect(() => {
    dispatchHttp(
      fetchAllUsers({
        ...searchParams,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp])

  return (
    <MainLayout
      withSubHeader
      subHeaderProps={{
        title: 'Users',
        subTitle: 'Manage Users Here',
        button: isUserActionAllowed('add_user') && (
          <Link to="/users/add-new-user">
            <Button
              type="primary"
              icon={<PlusOutlined style={{ fontSize: '12px' }} />}
            >
              Add New User
            </Button>
          </Link>
        ),
      }}
    >
      <Spin spinning={isLoading}>
        <Row
          justify="center"
          style={{
            marginTop: '24px',
          }}
        >
          <Col xxl={16} sm={20}>
            <Row
              gutter={[16, 32]}
              style={{
                background: '#fff',
                padding: '14px 12px',
                justifyContent: 'flex-end',
                margin: 0,
              }}
            >
              <Form
                form={form}
                layout="inline"
                className="sort-form"
                component="div"
                initialValues={{ state: searchParams.state }}
                size="small"
              >
                <Row>
                  <Col>
                    <Form.Item label="Search" name="search" size="small">
                      <Input
                        onChange={handleChangeSearch}
                        type="text"
                        className="search-input"
                        placeholder="First Name, Last Name"
                        style={{ width: 200 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="State" name="state" size="small">
                      <Select
                        style={{ width: 150 }}
                        onChange={handleChangeFilter}
                      >
                        <Select.Option value="all">All</Select.Option>
                        {filterOptions.map(option => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Row>
            <Table
              columns={columns}
              onLoadData={fetchAllUsers}
              formData={validateSearchParams(searchParams)}
              totalCount={count}
              totalData={users}
            />
          </Col>
        </Row>
      </Spin>
    </MainLayout>
  )
}

export default UsersTables
