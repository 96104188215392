import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import PropTypes from 'prop-types'

const Breadcrumbs = ({ crumbs }) => {
  if (crumbs.length <= 1) {
    return null
  }

  return (
    <Breadcrumb>
      {crumbs.map(({ name, path }) => (
        <Breadcrumb.Item key={name}>
          <Link to={path}>{name}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, path: PropTypes.string })
  ),
}

Breadcrumbs.defaultProps = {
  crumbs: [],
}

export default memo(Breadcrumbs)
