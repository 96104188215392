export const PERMISSION_MAP = [
  {
    codename: 'add_user',
    group: 'user',
  },
  {
    codename: 'change_user',
    group: 'user',
  },
  {
    codename: 'view_user',
    group: 'user',
  },
  {
    codename: 'add_group',
    group: 'role',
  },
  {
    codename: 'change_group',
    group: 'role',
  },
  {
    codename: 'delete_group',
    group: 'role',
  },
  {
    codename: 'view_group',
    group: 'role',
  },
  {
    codename: 'view_permission',
    group: 'permissions',
  },
  {
    codename: 'view_importlog',
    group: 'import log',
  },
  {
    codename: 'add_doctor',
    group: 'doctor',
  },
  {
    codename: 'change_doctor',
    group: 'doctor',
  },
  {
    codename: 'delete_doctor',
    group: 'doctor',
  },
  {
    codename: 'view_doctor',
    group: 'doctor',
  },
  {
    codename: 'manage_relations_doctor',
    group: 'doctor',
  },
  {
    codename: 'add_facility',
    group: 'facility',
  },
  {
    codename: 'change_facility',
    group: 'facility',
  },
  {
    codename: 'delete_facility',
    group: 'facility',
  },
  {
    codename: 'view_facility',
    group: 'facility',
  },
  {
    codename: 'manage_relations_facility',
    group: 'facility',
  },
  {
    codename: 'add_facilitycluster',
    group: 'cluster',
  },
  {
    codename: 'delete_facilitycluster',
    group: 'cluster',
  },
  {
    codename: 'view_facilitycluster',
    group: 'cluster',
  },
  {
    codename: 'manage_headquarter_facilitycluster',
    group: 'cluster',
  },
  {
    codename: 'manage_relations_facilitycluster',
    group: 'cluster',
  },
  {
    codename: 'add_authorizationform',
    group: 'Facility Profile Authorization Form',
  },
  {
    codename: 'delete_authorizationform',
    group: 'Facility Profile Authorization Form',
  },
  {
    codename: 'view_authorizationform',
    group: 'Facility Profile Authorization Form',
  },
  {
    codename: 'download_authorizationform',
    group: 'Facility Profile Authorization Form',
  },
  {
    codename: 'change_facilityprofile',
    group: 'facility profile',
  },
  {
    codename: 'view_facilityprofile',
    group: 'facility profile',
  },
  {
    codename: 'update_retrievers_facilityprofile',
    group: 'facility profile',
  },
  {
    codename: 'manage_relations_facilityprofile',
    group: 'facility profile',
  },
  {
    codename: 'update_mrd_facilityprofile',
    group: 'facility profile',
  },
  {
    codename: 'update_copy_company_facilityprofile',
    group: 'facility profile',
  },
  {
    codename: 'update_copy_company_auto_detection',
    group: 'facility profile copy company',
  },
  {
    codename: 'view_copycompany',
    group: 'facility profile copy company',
  },
  {
    codename: 'update_main_retriever_auto_detection',
    group: 'facility profile retriever',
  },
  {
    codename: 'view_retriever',
    group: 'facility profile retriever',
  },
  {
    codename: 'view_case',
    group: 'case',
  },
  {
    codename: 'manage_relations_case',
    group: 'case',
  },
  {
    codename: 'reject_case',
    group: 'case',
  },
  {
    codename: 'resolve_case',
    group: 'case',
  },
  {
    codename: 'return_case_to_review',
    group: 'case',
  },
  {
    codename: 'update_goals_case',
    group: 'case',
  },
  {
    codename: 'view_history_case',
    group: 'case history',
  },
  {
    codename: 'view_version',
    group: 'activity log',
  },
  {
    codename: 'add_merger',
    group: 'merger',
  },
  {
    codename: 'delete_merger',
    group: 'merger',
  },
  {
    codename: 'view_merger',
    group: 'merger',
  },
  {
    codename: 'relate_provider_merger',
    group: 'merger',
  },
  {
    codename: 'run_merger',
    group: 'merger',
  },
  {
    codename: 'unrelate_provider_merger',
    group: 'merger',
  },
  {
    codename: 'delete_report',
    group: 'reports',
  },
  {
    codename: 'view_report',
    group: 'reports',
  },
  {
    codename: 'generate_case_assignment',
    group: 'reports',
  },
  {
    codename: 'generate_feedback_loop',
    group: 'reports',
  },
  {
    codename: 'add_doctors',
    group: 'health network',
  },
  {
    codename: 'add_facilities',
    group: 'health network',
  },
  {
    codename: 'add_healthnetwork',
    group: 'health network',
  },
  {
    codename: 'change_healthnetwork',
    group: 'health network',
  },
  {
    codename: 'delete_healthnetwork',
    group: 'health network',
  },
  {
    codename: 'remove_doctors',
    group: 'health network',
  },
  {
    codename: 'remove_facilities',
    group: 'health network',
  },
  {
    codename: 'view_healthnetwork',
    group: 'health network',
  },
  {
    codename: 'change_agency',
    group: 'agency',
  },
  {
    codename: 'view_agency',
    group: 'agency',
  },
  {
    codename: 'change_insurancecompany',
    group: 'insurance company',
  },
  {
    codename: 'view_insurancecompany',
    group: 'insurance company',
  },
  {
    codename: 'add_assignmentcopycompany',
    group: 'copy company assignments',
  },
  {
    codename: 'change_assignmentcopycompany',
    group: 'copy company assignments',
  },
  {
    codename: 'delete_assignmentcopycompany',
    group: 'copy company assignments',
  },
  {
    codename: 'view_assignmentcopycompany',
    group: 'copy company assignments',
  },
  {
    codename: 'can_manage_vendor_relations',
    group: 'vendors',
  },
  {
    codename: 'manage_retrievers',
    group: 'retrievers',
  },
]
