import React from 'react'
import { Row, Col, Divider } from 'antd'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import TextField from '../../../shared/TextField'
import NumberField from '../../../shared/NumberField'
import { Label } from './Label'

export const FacilityMain = ({ title, original }) => (
  <>
    <Divider orientation="left" className="form-divider">
      {title}
    </Divider>
    <Row gutter={12}>
      <Col span={12}>
        <Field
          component={TextField}
          label={<Label label="Facility Name" original={original?.name} />}
          size="medium"
          name="name"
          placeholder="Type Here"
          required
        />
      </Col>

      <Col span={12}>
        <Field
          component={NumberField}
          label={<Label label="NPI" original={original?.npi} />}
          size="medium"
          name="npi"
          placeholder="Type Here"
        />
      </Col>
    </Row>
  </>
)

FacilityMain.propTypes = {
  values: PropTypes.shape({}),
  original: PropTypes.shape({
    name: PropTypes.string,
    npi: PropTypes.string,
  }),
  title: PropTypes.string,
}

FacilityMain.defaultProps = {
  values: {},
  original: {
    name: '',
    npi: '',
  },
  title: '',
}
