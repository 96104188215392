import React, { useState } from 'react'
import { Typography, Modal, Form, Button, Row, Col } from 'antd'
import { Formik, Field } from 'formik'

import NumberField from '../../../shared/NumberField'
import { relateDoctorFacility } from '../../../../schema/relateDoctorFacility'
import { capitalizeFirsLetter } from '../../../../utils/helpers'

const RelateDoctorFacilityModal = ({
  trigger,
  onSubmit,
  title,
  entity,
  closeAfterSubmit,
  submitButtonText,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleOpenModal = e => {
    e.preventDefault()
    setIsModalVisible(!isModalVisible)
  }

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: handleOpenModal,
      })}
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={false}
        destroyOnClose
        centered
        onCancel={() => setIsModalVisible(false)}
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Typography.Title level={3}>{title}</Typography.Title>
        <Formik
          initialValues={{ id: null }}
          onSubmit={async (...data) => {
            try {
              await onSubmit(...data)

              if (closeAfterSubmit) {
                setIsModalVisible(false)
              }
            } catch (err) {
              /* empty */
            }
          }}
          validationSchema={relateDoctorFacility}
        >
          {({ handleSubmit }) => (
            <Form onFinish={handleSubmit} layout="vertical">
              <Field
                component={NumberField}
                placeholder="Type Here"
                name="id"
                label={`${capitalizeFirsLetter(entity)} ID`}
              />
              <Row justify="space-between" align="middle">
                <Col>
                  <Button onClick={handleOpenModal}>Close window</Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    {submitButtonText || 'Relate'}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default RelateDoctorFacilityModal
