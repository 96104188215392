import Api from './index'
import { API_URL } from '../constants'

class Vendors extends Api {
  getVendorList(id, params) {
    return this.get('/', { params })
  }

  getVendorById(id) {
    return this.get(`${id}/`)
  }

  updateVendor(id, data) {
    return this.patch(`${id}/`, { data })
  }
}

export default new Vendors({
  baseURL: `${API_URL}vendors/`,
})
