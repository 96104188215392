import React from 'react'
import { Form, InputNumber } from 'antd'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/display-name
const FieldWrapper = Field => ({
  icon,
  size,
  field,
  form: { errors, touched, setFieldValue },
  label,
  error,
  ...props
}) => {
  const { name } = field

  return (
    <Form.Item
      label={label}
      validateStatus={
        (errors[name] && touched[name]) || error ? 'error' : 'success'
      }
      help={
        (errors[name] && touched[name]) || error ? errors[name] || error : null
      }
    >
      <Field
        {...field}
        {...props}
        prefix={icon}
        size={size}
        onChange={value => setFieldValue(name, value)}
      />
    </Form.Item>
  )
}

const NumberField = FieldWrapper(InputNumber)
NumberField.displayName = 'NumberField'

NumberField.propTypes = {
  icon: PropTypes.element,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
    ]),
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['large', 'small', 'medium']),
}

NumberField.defaultProps = {
  icon: null,
  field: PropTypes.shape({
    value: '',
    onChange: () => null,
    onBlur: () => null,
  }),
  label: '',
  size: 'large',
}

export default NumberField
