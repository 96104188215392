/* eslint-disable no-template-curly-in-string */
import { object, string } from 'yup'
import { nonLatinChar } from './fields'

export const createFacilityProfileSchema = object().shape(
  {
    working_hours_end: string()
      .nullable()
      .when('working_hours_start', {
        is: workingHoursStart => workingHoursStart,
        then: string().nullable().required('The field is required'),
        otherwise: string().nullable(),
      }),
    working_hours_start: string()
      .nullable()
      .when('working_hours_end', {
        is: workingHoursEnd => workingHoursEnd,
        then: string().nullable().required('The field is required'),
        otherwise: string().nullable(),
      }),
    email: string()
      .nullable()
      .matches(nonLatinChar, 'Email cannot include non-latin characters')
      .email('Please enter a valid email address'),
  },
  [['working_hours_end', 'working_hours_start']]
)
