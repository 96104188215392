export const FACILITY = 'facility'
export const DOCTOR = 'doctor'
export const FACILITY_PROFILE = 'facility-profiles'
export const MERGER = 'facility-profile-mergers'
export const EXCEPTION = 'cases'
export const CLUSTER = 'clusters'
export const HEALTH_NETWORK = 'health-networks'
export const VENDORS = 'vendors'
export const INSURANCE_COMPANY = 'insurance-company'
export const AGENCIES = 'agencies'
export const RETRIEVERS = 'retrievers'

export const EXCEPTION_STATUSES = {
  REVIEW: 'Review Required',
  SUCCESS: 'Success',
  RESOLVED: 'Resolved',
  NOT_FOUND: 'Not Found',
  REJECTED: 'Rejected',
  ALL: 'All',
  REPROCESSING: 'Reprocessing',
}
export const EXCEPTION_MAPPING = {
  All: { name: EXCEPTION_STATUSES.ALL, color: 'blue', index: 4 },
  REVIEW: { name: EXCEPTION_STATUSES.REVIEW, color: 'yellow', index: 1 },
  SUCCESS: { name: EXCEPTION_STATUSES.SUCCESS, color: 'green', index: 2 },
  RESOLVED: { name: EXCEPTION_STATUSES.RESOLVED, color: 'cyan', index: 3 },
  REJECTED: { name: EXCEPTION_STATUSES.REJECTED, color: 'red', index: 5 },
  REPROCESSING: {
    name: EXCEPTION_STATUSES.REPROCESSING,
    color: 'purple',
    index: 6,
  },
}
export const EXCEPTION_REASON = {
  DOCTOR_NOT_FOUND: 'Doctor Not Found',
  FACILITY_NOT_FOUND: 'Facility Not Found',
  DOCTOR_NOT_SP: 'Check If Doctor Sole Proprietor',
}

// List of vendors that doesn't have skip on import option
export const NO_SKIP_ON_IMPORT_VENDOR_LIST = []
