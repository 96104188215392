import React from 'react'
import { Row, Col, Typography } from 'antd'

import MainLayout from '../MainLayout'
import Breadcrumb from '../../components/Breadcrumbs'

const ActivityLogLayout = ({ children, breadcrumbs, title }) => (
  <MainLayout>
    <>
      {(breadcrumbs || title) && (
        <div className="search-layout__header">
          <Row justify="center">
            <Col xxl={21} xl={20} lg={19}>
              {breadcrumbs && <Breadcrumb crumbs={breadcrumbs} />}
              <Typography.Title level={3}>{title}</Typography.Title>
            </Col>
          </Row>
        </div>
      )}
      <Row justify="center">
        <Col xxl={21} xl={20} lg={19}>
          <div className="search-layout__content">{children}</div>
        </Col>
      </Row>
    </>
  </MainLayout>
)

export default ActivityLogLayout
