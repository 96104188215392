import React, { useEffect } from 'react'
import { Redirect, Switch, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import DefaultRoute from './default'
import { privateRoutes, PrivateRoute } from './private'
import { publicRoutes, PublicRoute } from './public'

import { getUserPermissions, setUserDataLoaded } from '../../state/modules/auth'
import { useUser } from '../../providers/UserProvider'
import useDispatchHttp from '../../hooks/dispatchHttpHandler'
import { getAuthUser } from '../../state/modules/auth/reducer'

const App = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const dispatchHttp = useDispatchHttp()
  const { token } = useSelector(state => state.auth)
  const isUserDataLoaded = useSelector(state => state.auth.isUserDataLoaded)
  const { setUserPermissions, setCurrentUser } = useUser()

  useEffect(() => {
    async function fetchData() {
      if (token && !isUserDataLoaded) {
        try {
          const user = await dispatchHttp(getAuthUser())
          const permissions = await dispatchHttp(getUserPermissions())
          setCurrentUser(user)
          setUserPermissions(permissions)
          dispatch(setUserDataLoaded(true))
        } catch (e) {
          dispatch(setUserDataLoaded(true))
        }
      }
    }
    fetchData()
  }, [
    isUserDataLoaded,
    dispatchHttp,
    token,
    pathname,
    setCurrentUser,
    setUserPermissions,
    dispatch,
  ])

  if (token && !isUserDataLoaded) {
    return (
      <div className="main-loading">
        <Spin size="large" />
      </div>
    )
  }

  return (
    <Switch>
      <Route component={DefaultRoute} path="/" exact />
      {privateRoutes.map(
        ({ name, component, path, permission, title }, index, routes) => (
          <PrivateRoute
            exact
            title={title}
            permission={permission}
            key={name}
            path={path}
            component={component}
            routes={routes}
          />
        )
      )}

      {publicRoutes.map(({ name, component, path, title }) => (
        <PublicRoute
          exact
          key={name}
          path={path}
          component={component}
          title={title}
        />
      ))}

      <Redirect to="/" />
    </Switch>
  )
}

export default App
