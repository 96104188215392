import React from 'react'
import PropTypes from 'prop-types'
import { PageHeader, Col, Row } from 'antd'

import Breadcrumbs from '../Breadcrumbs'

const SubHeader = ({ title, subTitle, button, breadcrumb }) => (
  <div className="header__sub">
    <Row justify="center">
      <Col xxl={16} sm={20}>
        <PageHeader
          title={breadcrumb ? breadcrumb[breadcrumb.length - 1].name : title}
          subTitle={subTitle}
          ghost={false}
          extra={button}
          breadcrumbRender={() =>
            breadcrumb ? <Breadcrumbs crumbs={breadcrumb} /> : null
          }
        />
      </Col>
    </Row>
  </div>
)

SubHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  button: PropTypes.element,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
}

SubHeader.defaultProps = {
  subTitle: null,
  button: null,
  breadcrumb: null,
  title: null,
}

export default SubHeader
