import Api from './index'
import { API_URL } from '../constants'

class VendorFacility extends Api {
  getVendorFacilities(_, params) {
    const { id, ...rest } = params
    return this.get(`/?vendor_id=${id}`, { params: rest })
  }

  addVendorFacility(data) {
    return this.post('/', { data })
  }

  removeVendorFacility(id) {
    return this.del(`${id}/`)
  }

  updateVendorFacility(id, data) {
    return this.put(`${id}/`, { data })
  }
}

export default new VendorFacility({
  baseURL: `${API_URL}facility-vendor/`,
})
