import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'

import { LOADING_STATUS } from '../../../constants'

const ProfileCard = ({ title, content, color, loading, children, style }) =>
  loading === LOADING_STATUS ? (
    <Skeleton />
  ) : (
    <div
      className={`profile-card profile-card--${color}`}
      style={{ borderColor: style.bodyColor }}
    >
      <div
        className="profile-card__head"
        style={{ background: style.headerColor }}
      >
        {title}
      </div>
      <div className="profile-card__body">
        {content
          ? content.map(({ label, value }) => (
              <div className="profile-card__content" key={label}>
                <div className="profile-card__label">{label}</div>
                <div className="profile-card__value">{value ?? '-'}</div>
              </div>
            ))
          : children}
      </div>
    </div>
  )

ProfileCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
      ]),
    })
  ),
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['yellow', 'orange', 'green', 'blue', 'magenta', 'smalt']),
    PropTypes.string,
  ]),
  loading: PropTypes.string,
  children: PropTypes.element,
  style: PropTypes.shape({
    headerColor: PropTypes.string,
    bodyColor: PropTypes.string,
  }),
}

ProfileCard.defaultProps = {
  title: null,
  content: null,
  color: '',
  loading: '',
  children: null,
  style: {
    headerColor: '',
    bodyColor: '',
  },
}

export default ProfileCard
