import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useParams, Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Row,
  Col,
  Button,
  Space,
  Typography,
  Tabs as Tab,
  Spin,
  Collapse,
  Modal,
  Alert,
  Input,
  Form,
  Card,
  Tooltip,
} from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  WarningOutlined,
  RetweetOutlined,
  DownSquareOutlined,
  RightCircleOutlined,
  BarChartOutlined,
} from '@ant-design/icons'

import { useHotkeys } from 'react-hotkeys-hook'

import MainLayout from '../../layouts/MainLayout'
import SearchLayout from '../../layouts/SearchLayout'
import NoResults from '../../components/NoResults/NoResults'
import Table from '../../components/Tables/SearchTable'
import FacilityProfileCard from '../../components/FacilityProfileCard'
import Tabs from '../../components/Tabs'
import showConfirmDialog from '../../components/Modals/DeleteConfirm'
import applyChangesDialog from '../../components/Modals/ApplyChangesDialog'
import ExceptionFacilityTable from '../../components/ExceptionFacilityTable'
import ExceptionDoctorTable from '../../components/ExceptionDoctorTable'
import CreateFacilityModal from '../../components/Exception/CreateFacilityModal'
import CreateDoctorModal from '../../components/Exception/CreateDoctorModal'
import EditFacilityModal from '../../components/Exception/EditFacilityModal'
import EditDoctorModal from '../../components/Exception/EditDoctorModal'
import CaseGoalsCustomRender from '../../components/CaseGoalsCustomRender'
import { formatCaseInfoTableData, getColumnIcon } from '../../../utils/helpers'
import { getConfirmationDialogState } from './helpers/resolveStateMapping'
import { getHighlightFields } from './helpers/getHighlightFields'
import { getFacilityFeedbackLoopData } from './helpers/getFacilityFeedbackLoopData'
import { getDoctorFeedbackLoopData } from './helpers/getDoctorFeedbackLoopData'
import { getFeedbackLoopOriginalFields } from './helpers/getFeedbackLoopOriginalFields'

import {
  facilityTableColumns,
  doctorTableColumns,
  exceptionColumnCompact,
  caseInformationColumns,
} from '../../../data'
import {
  EXCEPTION,
  EXCEPTION_STATUSES,
  EXCEPTION_MAPPING,
  CASE_GOALS_MRD_DETECTED,
  CASE_GOALS_MRD_NOT_DETECTED,
  CASE_SEARCH_OPTIONS,
  FACILITY,
  DOCTOR,
} from '../../../constants'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import {
  fetchExceptionById,
  selectExceptionById,
  relateException,
  changeExceptionStatus,
  removeRelation,
  changeDoctorSuggestionStatus,
  changeFacilitySuggestionStatus,
} from '../../../state/modules/exceptions'
import {
  fetchFacilityProfileById,
  selectFacilityProfileById,
  fetchFacilityById,
  selectFacilityById,
  updateFacility,
} from '../../../state/modules/facilities'
import {
  fetchDoctorById,
  selectDoctorById,
  updateDoctor,
} from '../../../state/modules/doctors'
import {
  searchCaseDetailsFacilities,
  searchCaseDetailsDoctors,
  selectAllDoctor,
  selectAllFacility,
  resetState as resetSearchState,
} from '../../../state/modules/search'
import { routePaths } from '../../../utils/routes'
import { setIdsFromIndexes } from '../../../utils/functions'
import RelateNotesModal from '../../components/Modals/RelateNotesModal'
import { getHotkeysSearch } from './helpers/getHotkeysSearch'
import {
  transformSearchCandidateId,
  transformSearchValues,
} from './helpers/transformSearchValues'
import { useUser } from '../../../providers/UserProvider'
import TableLink from '../../components/TableLink'

const { TextArea } = Input
const { REVIEW } = EXCEPTION_STATUSES

const goalsMrdDetected = [
  CASE_GOALS_MRD_DETECTED.FIND_DOCTOR,
  CASE_GOALS_MRD_DETECTED.FIND_TREATMENT_FACILITY,
  CASE_GOALS_MRD_DETECTED.FIND_MRD_FACILITY,
]
const goalsMrdNotDetected = [
  CASE_GOALS_MRD_NOT_DETECTED.FIND_DOCTOR,
  CASE_GOALS_MRD_NOT_DETECTED.FIND_FACILITY,
]

const genExtra = () => (
  <div>
    <WarningOutlined className="suggestion__icon" />
  </div>
)

const Exception = () => {
  const { id } = useParams()
  const location = useLocation()
  const { isUserActionAllowed } = useUser()
  const dispatch = useDispatch()
  const dispatchHttp = useDispatchHttp()
  const [isBackToReviewModalVisible, setBackToReviewModalVisible] = useState(
    false
  )
  const [backToReviewForm] = Form.useForm()
  const [activeTabKey, setActiveTabKey] = useState('all')
  const [prevExceptionId, setPrevExceptionId] = useState('all')
  const [formValues, setFormValues] = useState({})
  const [searchSuggestions, setSearchSuggestions] = useState(null)
  const [currentSearch, setCurrentSearch] = useState(null)
  const [isCreateNewFacilityEnabled, setIsCreateNewFacilityEnabled] = useState(
    false
  )
  const [isCreateNewDoctorEnabled, setIsCreateNewDoctorEnabled] = useState(
    false
  )
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [isStatusChanging, setIsStatusChanging] = useState(false)
  const [activeRowId, setActiveRowId] = useState(null)
  const exceptionData = useSelector(state => selectExceptionById(state, id))
  const [searchOptions, setSearchOptions] = useState([])
  const facilityProfileData = useSelector(state =>
    selectFacilityProfileById(state, exceptionData?.facility_profile)
  )
  const { status: fetchStatus } = useSelector(state => state.exceptions)
  const { status: fetchFacilityStatus } = useSelector(state => state.facilities)
  const { status: fetchDoctorStatus } = useSelector(state => state.doctors)
  const { facilityStatus: fetchSearchFacilityStatus } = useSelector(
    state => state.search
  )
  const { doctorStatus: fetchSearchDoctorStatus } = useSelector(
    state => state.search
  )
  const [warningModalGoals, setWarningModalGoals] = useState(false)
  const [notesModalData, setNotesModalData] = useState(false)
  const [hotkeysWarning, setHotkeysWarning] = useState('')
  const [hotkeysModal, setHotkeysModal] = useState(false)
  const [mappingScoreLogModal, setMappingScoreLogModal] = useState(false)

  const mrdFacilityData = useSelector(state =>
    selectFacilityById(state, exceptionData?.mrd_facility)
  )
  const treatmentFacilityData = useSelector(state =>
    selectFacilityById(state, exceptionData?.treatment_facility)
  )
  const doctorData = useSelector(state =>
    selectDoctorById(state, exceptionData?.doctor)
  )
  const allFacilities = useSelector(selectAllFacility)
  const allDoctors = useSelector(selectAllDoctor)
  const { count: facilityCount } = useSelector(state => state.search.facility)
  const { count: doctorsCount } = useSelector(state => state.search.doctor)
  const isMrdCase = exceptionData?.is_mrd_case
  const mrdFacilityId = exceptionData?.mrd_facility
  const treatmentFacilityId = exceptionData?.treatment_facility
  const doctorId = exceptionData?.doctor
  const facilityProfileId = exceptionData?.facility_profile
  const isActiveByStatus = exceptionData?.status !== REVIEW
  const hasRelatedDoctor = exceptionData?.doctor
  const hasRelatedFacility = exceptionData?.mrd_facility
  const hasRelatedTreatmentFacility = exceptionData?.treatment_facility
  const { data: locationData } = location

  const sort = locationData?.sort || '-importance'
  const goals = isMrdCase ? goalsMrdDetected : goalsMrdNotDetected
  const selectedGoals = goals.reduce((acc, item) => {
    if (exceptionData) {
      const itemValue = exceptionData[item]

      if (itemValue === true) {
        return [...acc, item]
      }
    }

    return acc
  }, [])

  const exceptionReason = useMemo(() => exceptionData?.reason ?? [], [
    exceptionData?.reason,
  ])

  const searchSuggestionData = useMemo(
    () =>
      exceptionData && exceptionData?.search_suggestions?.length
        ? exceptionData?.search_suggestions
        : [],
    [exceptionData]
  )

  const extraSearchSuggestionData = useMemo(() => {
    let data = []
    if (exceptionData?.feedback_loop_suggestions?.length) {
      data = [...data, ...exceptionData?.feedback_loop_suggestions]
    }

    return data
  }, [exceptionData])

  const suggestionData = useMemo(
    () => [...searchSuggestionData, ...extraSearchSuggestionData],
    [searchSuggestionData, extraSearchSuggestionData]
  )

  const caseInformationData = useMemo(() => {
    const npiData = {
      treatment: treatmentFacilityData && treatmentFacilityData.npi,
      mrd: mrdFacilityData && mrdFacilityData.npi,
      doctor: doctorData && doctorData.npi,
    }
    return setIdsFromIndexes(formatCaseInfoTableData(suggestionData, npiData))
  }, [suggestionData, mrdFacilityData, treatmentFacilityData, doctorData])

  const breadcrumbs = [
    { name: 'CMS Cases', path: '/cases' },
    {
      name: 'Case',
      path: `/cases/${exceptionData?.id}`,
    },
  ]

  const handleChangeTab = key => {
    setActiveTabKey(key)
  }

  useEffect(() => {
    document.title = `Case #${exceptionData?.id}`
  }, [exceptionData?.id])

  useEffect(() => {
    const data = suggestionData.map(item => {
      let search_options = [
        {
          active: true,
          key: 'full_search',
        },
      ]

      if (item.primary_search.candidate_ids?.length) {
        search_options = [
          {
            active: true,
            key: 'top_candidates',
          },
          { active: false, key: 'full_search' },
        ]
      }

      return search_options
    })

    setSearchOptions(data)
  }, [suggestionData])
  const isViewSearchPermission =
    isUserActionAllowed('view_facility') && isUserActionAllowed('view_doctor')

  useEffect(() => {
    const { suggestion_type } = currentSearch || {}

    if (suggestion_type === 'doctor' && isUserActionAllowed('view_doctor')) {
      handleChangeTab('doctors')
    } else if (suggestion_type && isUserActionAllowed('view_facility')) {
      handleChangeTab('facilities')
    } else if (isUserActionAllowed('view_doctor')) {
      handleChangeTab('doctors')
    } else if (isUserActionAllowed('view_facility')) {
      handleChangeTab('facilities')
    } else {
      handleChangeTab('all')
    }
    // eslint-disable-next-line
  }, [currentSearch])

  const handleSearch = useCallback(
    values => {
      const { updateTime, ...rest } = values

      dispatchHttp(
        searchCaseDetailsDoctors({
          data: rest,
        })
      )

      dispatchHttp(
        searchCaseDetailsFacilities({
          data: rest,
        })
      )

      setFormValues(values)
    },
    // eslint-disable-next-line
    [dispatchHttp, setFormValues]
  )

  const handleRelateFacility = useCallback(
    async (facility, type) => {
      const data = await dispatchHttp(
        relateException({ exception: id, facility, type }),
        `Facility has been successfully related to Exception #${id}`
      )
      if (data?.notes?.length) {
        setNotesModalData(data?.notes)
      }
      await dispatchHttp(
        fetchExceptionById({
          id,
        })
      )
    },
    // eslint-disable-next-line
    [dispatchHttp, id]
  )

  const handleRelateDoctor = useCallback(
    async doctor => {
      const data = await dispatchHttp(
        relateException({ exception: id, doctor }),
        `Doctor has been successfully related to Exception #${id}`
      )

      if (data?.notes?.length) {
        setNotesModalData(data?.notes)
      }
      await dispatchHttp(
        fetchExceptionById({
          id,
        })
      )
    },
    // eslint-disable-next-line
    [dispatchHttp, id]
  )

  const handleUpdateFacility = useCallback(
    async (facilityId, data) => {
      await dispatchHttp(
        updateFacility({
          facilityId,
          data: {
            ...data,
            npi: data.npi || null,
            taxonomy_group_name: data.taxonomy_group_name || null,
          },
        }),
        'Facility has been successfully updated'
      )

      if (isUserActionAllowed('view_facility')) {
        dispatchHttp(fetchFacilityById(facilityId))
      }
    },
    // eslint-disable-next-line
    [dispatchHttp]
  )

  // eslint-disable-next-line
  const handleUpdateDoctor = useCallback(
    async (updateDoctorId, data) => {
      await dispatchHttp(
        updateDoctor({
          id: updateDoctorId,
          data: {
            ...data,
            npi: data.npi || null,
            taxonomy_group_name: data.taxonomy_group_name || null,
          },
        }),
        'Doctor has been successfully updated'
      )

      if (isUserActionAllowed('view_doctor')) {
        dispatchHttp(fetchDoctorById(updateDoctorId))
      }
    },
    // eslint-disable-next-line
    [dispatchHttp]
  )

  const handleChangeSuggestionStatus = useCallback(
    data => {
      const { doctor, facility } = data

      if (doctor && doctor.length) {
        dispatchHttp(changeDoctorSuggestionStatus({ data: doctor }))
      }

      if (facility && facility.length) {
        dispatchHttp(changeFacilitySuggestionStatus({ data: facility }))
      }
    },
    [dispatchHttp]
  )

  const handleChangeStatus = useCallback(
    (exceptionStatus, condition) => {
      setIsStatusChanging(true)
      dispatch(
        changeExceptionStatus({
          id,
          status: condition,
        })
      )
        .then(unwrapResult)
        .then(async resp => {
          setIsStatusChanging(false)
          if (resp) {
            const { doctor, facility, mrd_facility, treatment_facility } = resp
            let data = {}
            if (isUserActionAllowed('change_facility')) {
              if (facility) data = { ...data, facility }
              if (mrd_facility) data = { ...data, mrd_facility }
              if (treatment_facility) data = { ...data, treatment_facility }
            }

            if (isUserActionAllowed('change_doctor')) {
              if (doctor) data = { ...data, doctor }
            }

            if (Object.keys(data).length) {
              applyChangesDialog({
                data,
                handleConfirm: handleChangeSuggestionStatus,
                onClose: dispatchHttp(
                  fetchExceptionById({
                    id,
                  })
                ),
              })
            }
          }

          await dispatchHttp(
            fetchExceptionById({
              id,
            })
          )
        })
        .catch(() => setIsStatusChanging(false))
    },
    [
      dispatch,
      dispatchHttp,
      handleChangeSuggestionStatus,
      id,
      isUserActionAllowed,
    ]
  )

  const handleBackToReview = useCallback(async () => {
    try {
      await backToReviewForm.validateFields(['back-to-review-note'], {
        validateMessages: {
          default: "Reason can't be empty",
        },
      })
      const backToReviewNote = backToReviewForm
        .getFieldValue('back-to-review-note')
        .trim()
      setIsStatusChanging(true)
      await dispatchHttp(
        changeExceptionStatus({
          id,
          data: {
            notes: backToReviewNote,
          },
          status: 'return_to_review',
        })
      )
      setIsStatusChanging(false)
      setBackToReviewModalVisible(false)
      await dispatchHttp(
        fetchExceptionById({
          id,
        })
      )
    } catch (e) {
      setIsStatusChanging(false)
    }
  }, [id, dispatchHttp, backToReviewForm])

  const changeStatusHandler = useCallback(
    (exceptionStatus, status) => {
      if (
        exceptionData?.is_completed &&
        !doctorData?.is_sole_proprietor &&
        exceptionStatus === EXCEPTION_MAPPING.RESOLVED.index &&
        confirmDialog
      ) {
        showConfirmDialog({
          title: `Do you confirm this doctor ${doctorData?.first_name} ${doctorData?.last_name} is a sole proprietor and you
          couldn't find a related facility?`,
          handleConfirm: handleChangeStatus,
          data: EXCEPTION_MAPPING.RESOLVED.index,
          okText: 'Confirm',
          status,
        })

        return
      }

      handleChangeStatus(exceptionStatus, status)
    },
    [
      confirmDialog,
      exceptionData?.is_completed,
      doctorData?.is_sole_proprietor,
      doctorData?.first_name,
      doctorData?.last_name,
      handleChangeStatus,
    ]
  )

  const handleRemoveRelation = useCallback(
    async relation => {
      await dispatchHttp(
        removeRelation({ id, data: { ...relation } }),
        'Relation has been successfully deleted'
      )
      await dispatchHttp(
        fetchExceptionById({
          id,
        })
      )
    },
    // eslint-disable-next-line
    [dispatchHttp, id]
  )

  const handlePickInfoToSearch = rowId => {
    setActiveRowId(rowId)
  }

  useEffect(() => {
    dispatch(resetSearchState())
  }, [id, dispatch])

  useEffect(() => {
    const candidate_ids = currentSearch?.primary_search?.candidate_ids?.length
    const activeSearchOption = currentSearch?.search_options?.find(
      item => item.active
    )
    const isCandidatesActive = activeSearchOption?.key === 'top_candidates'

    if (!currentSearch) {
      setSearchSuggestions(null)
      return
    }

    if (candidate_ids && isCandidatesActive) {
      setSearchSuggestions(null)
      return
    }

    setSearchSuggestions({
      replace: {
        first_name:
          currentSearch?.primary_search?.first_name_options?.length > 1
            ? currentSearch?.primary_search?.first_name_options
            : [],
        phone:
          currentSearch?.all_phone_numbers?.length > 1
            ? currentSearch?.all_phone_numbers
            : [],
        fax:
          currentSearch?.all_fax_numbers?.length > 1
            ? currentSearch?.all_fax_numbers
            : [],
        address:
          currentSearch?.all_addresses?.length > 1
            ? currentSearch?.all_addresses
            : [],
      },
    })
  }, [currentSearch])

  useEffect(() => {
    if (suggestionData?.length) {
      const mapping = {
        find_treatment_facility: 'facility',
        find_mrd_facility: 'facility',
        find_facility: 'facility',
        find_doctor: 'doctor',
      }

      const isRelated = {
        facility: hasRelatedFacility,
        mrd_facility: hasRelatedFacility,
        treatment_facility: hasRelatedFacility,
        doctor: hasRelatedDoctor,
      }

      const mappedValues = selectedGoals.map(item => mapping[item])
      let index = suggestionData?.findIndex(
        ({ suggestion_type }) =>
          mappedValues.includes(suggestion_type) && isRelated[suggestion_type]
      )

      if (index === -1) {
        index = 0
      }

      if (!activeRowId || prevExceptionId !== exceptionData?.id) {
        setActiveRowId([index + 1])
      }

      setPrevExceptionId(exceptionData?.id)
    }
    // eslint-disable-next-line
  }, [exceptionData])

  useEffect(() => {
    if (suggestionData && activeRowId) {
      const data = caseInformationData.map((item, index) => ({
        ...item,
        search_options: searchOptions[index],
      }))

      setCurrentSearch(data[activeRowId[0] - 1])
    }
    // eslint-disable-next-line
  }, [activeRowId])

  useEffect(() => {
    if (currentSearch) {
      const activeSearchOption = currentSearch.search_options.find(
        item => item.active
      )
      let values = transformSearchValues(currentSearch.primary_search)

      if (activeSearchOption?.key === 'top_candidates') {
        values = transformSearchCandidateId(values)
      }
      handleSearch(values)
    }
    // eslint-disable-next-line
  }, [currentSearch, handleSearch])

  const handleReset = values => {
    setFormValues(values)
  }

  useEffect(() => {
    const isConfirmDialog = getConfirmationDialogState(
      selectedGoals,
      isMrdCase,
      hasRelatedDoctor,
      hasRelatedFacility,
      hasRelatedTreatmentFacility
    )
    setConfirmDialog(isConfirmDialog)
  }, [
    hasRelatedFacility,
    hasRelatedDoctor,
    hasRelatedTreatmentFacility,
    isMrdCase,
    selectedGoals,
  ])

  useEffect(() => {
    dispatchHttp(
      fetchExceptionById({
        id,
      })
    )
    // eslint-disable-next-line
  }, [dispatchHttp, id])

  useEffect(() => {
    if (facilityProfileId && isUserActionAllowed('view_facilityprofile')) {
      dispatchHttp(fetchFacilityProfileById(facilityProfileId))
    }
    // eslint-disable-next-line
  }, [dispatchHttp, facilityProfileId])

  useEffect(() => {
    if (mrdFacilityId && isUserActionAllowed('view_facility')) {
      dispatchHttp(fetchFacilityById(mrdFacilityId))
    }
    // eslint-disable-next-line
  }, [dispatchHttp, mrdFacilityId, facilityProfileId])

  useEffect(() => {
    if (treatmentFacilityId && isUserActionAllowed('view_facility')) {
      dispatchHttp(fetchFacilityById(treatmentFacilityId))
    }
    // eslint-disable-next-line
  }, [dispatchHttp, treatmentFacilityId, facilityProfileId])

  useEffect(() => {
    if (doctorId && isUserActionAllowed('view_doctor')) {
      dispatchHttp(fetchDoctorById(doctorId))
    }
    // eslint-disable-next-line
  }, [dispatchHttp, doctorId, facilityProfileId])

  useEffect(() => {
    const suggestionType = currentSearch?.suggestion_type
    const isFeedbackLoop = suggestionType === 'contact_form'
    const isNeedCreateDoctor =
      (!hasRelatedDoctor && suggestionType === 'doctor') ||
      (!hasRelatedDoctor &&
        isFeedbackLoop &&
        currentSearch?.form_type === 'doctor')
    const isNeedCreateFacility =
      (!isMrdCase && !hasRelatedFacility && suggestionType === 'facility') ||
      (!isMrdCase &&
        !hasRelatedFacility &&
        isFeedbackLoop &&
        currentSearch?.form_type === 'facility')
    const isNeedCreateMrdFacility =
      (isMrdCase && !hasRelatedFacility && suggestionType === 'mrd_facility') ||
      (isMrdCase &&
        !hasRelatedFacility &&
        isFeedbackLoop &&
        currentSearch?.form_type === 'facility')
    const isNeedCreateTreatmentFacility =
      (isMrdCase &&
        !hasRelatedTreatmentFacility &&
        suggestionType === 'treatment_facility') ||
      (isMrdCase &&
        !hasRelatedTreatmentFacility &&
        isFeedbackLoop &&
        currentSearch?.form_type === 'treatment')

    const isEnabledCreateFacility =
      !isActiveByStatus &&
      (isNeedCreateFacility ||
        isNeedCreateMrdFacility ||
        isNeedCreateTreatmentFacility)

    const isEnabledCreateDoctor = isNeedCreateDoctor

    setIsCreateNewFacilityEnabled(isEnabledCreateFacility)
    setIsCreateNewDoctorEnabled(isEnabledCreateDoctor)
  }, [
    currentSearch,
    isActiveByStatus,
    exceptionReason,
    hasRelatedFacility,
    hasRelatedTreatmentFacility,
    hasRelatedDoctor,
    isMrdCase,
    setIsCreateNewFacilityEnabled,
  ])

  const exceptionColumns = exceptionColumnCompact.map(item => {
    if (item.key === 'id') {
      return {
        ...item,
        link: false,
      }
    }
    return item
  })

  const changeSearchOption = useCallback(
    (searchOption, rowId) => {
      const searchId = rowId - 1
      const options = searchOptions[searchId]
      const newOptions = options.map(option => ({
        ...option,
        active: searchOption === option.key,
      }))
      const newSearchOptions = [...searchOptions]
      newSearchOptions[searchId] = newOptions
      setSearchOptions(newSearchOptions)
      setActiveRowId([rowId])
    },
    [searchOptions]
  )

  const highlightedFields = getHighlightFields(currentSearch, [
    allFacilities,
    allDoctors,
    [mrdFacilityData],
    [treatmentFacilityData],
    [doctorData],
  ])

  useHotkeys(
    'alt+a, alt+p, alt+x, alt+n, alt+m',
    ({ code }) => {
      if (
        isUserActionAllowed('view_facility') ||
        isUserActionAllowed('view_doctor')
      ) {
        const hotkeysSearch = getHotkeysSearch(
          code,
          currentSearch?.primary_search,
          currentSearch?.suggestion_type
        )

        if (hotkeysSearch.missedField) {
          setHotkeysWarning(
            `Sorry, ${hotkeysSearch.missedField}  missing for ${
              CASE_SEARCH_OPTIONS[currentSearch?.suggestion_type]
            } search`
          )

          return
        }

        let values = transformSearchValues(hotkeysSearch)
        values = transformSearchCandidateId(values)

        if (values.gaia_id) {
          changeSearchOption('top_candidates', activeRowId[0])
          return
        }

        handleSearch({ ...values, updateTime: +new Date() })
      }
    },
    {
      enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'],
    },
    [currentSearch]
  )
  let facilityColumns = facilityTableColumns.map(item => {
    const mapping = {
      // eslint-disable-next-line react/display-name
      name: (text, column, data) => (
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TableLink
            text={text}
            navigate={false}
            icon={getColumnIcon(null, data, FACILITY)}
          />
          <Tooltip title="Open in a new tab">
            <Link
              className="redirect"
              to={{ pathname: `/facility/${data.id}` }}
              target="_blank"
              style={{
                padding: '0 4px',
              }}
            >
              <RightCircleOutlined className="search-table__star-icon" />
            </Link>
          </Tooltip>
        </span>
      ),
      // eslint-disable-next-line react/display-name
      related_doctors: text => <TableLink text={text} navigate={false} />,
    }
    if (mapping[item.key]) {
      return {
        ...item,
        link: null,
        partialRender: mapping[item.key],
      }
    }
    return {
      ...item,
    }
  })

  let doctorColumns = doctorTableColumns.map(item => {
    const mapping = {
      // eslint-disable-next-line react/display-name
      name: (text, column, data) => (
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TableLink
            text={text}
            navigate={false}
            icon={getColumnIcon(null, data, DOCTOR)}
          />
          <Tooltip title="Open in a new tab">
            <Link
              className="redirect"
              to={{ pathname: `/doctor/${data.id}` }}
              target="_blank"
              style={{
                padding: '0 4px',
              }}
            >
              <RightCircleOutlined className="search-table__star-icon" />
            </Link>
          </Tooltip>
        </span>
      ),
      // eslint-disable-next-line react/display-name
      related_facilities: text => <TableLink text={text} navigate={false} />,
    }
    if (mapping[item.key]) {
      return {
        ...item,
        link: null,
        partialRender: mapping[item.key],
      }
    }
    return {
      ...item,
    }
  })

  facilityColumns = isUserActionAllowed('view_doctor')
    ? facilityColumns
    : facilityColumns.map(item => {
        if (item.key === 'related_doctors') {
          return { ...item, link: null }
        }
        return { ...item }
      })
  doctorColumns = isUserActionAllowed('view_facility')
    ? doctorColumns
    : doctorColumns.map(item => {
        if (item.key === 'related_facilities') {
          return { ...item, link: null }
        }
        return { ...item }
      })

  const isShowBackToReviewButton =
    (EXCEPTION_STATUSES.RESOLVED === exceptionData?.status ||
      EXCEPTION_STATUSES.SUCCESS === exceptionData?.status ||
      (EXCEPTION_STATUSES.REJECTED === exceptionData?.status &&
        [1, 6, 7].includes(exceptionData?.rejection_reason?.id))) &&
    isUserActionAllowed('return_case_to_review')

  const feedbackLoopDataMrd = caseInformationData.find(
    item => item.form_type === 'mrd' || item.form_type === 'facility'
  )

  const feedbackLoopDataTreatment = caseInformationData.find(
    item => item.form_type === 'treatment'
  )

  const feedbackLoopDataDoctor = caseInformationData.find(
    item => item.form_type === 'doctor'
  )

  let feedbackLoopMrdChangedData = null
  let feedbackLoopTreatmentChangedData = null
  let feedbackLoopDoctorChangedData = null

  if (hasRelatedFacility && feedbackLoopDataMrd) {
    feedbackLoopMrdChangedData = getFacilityFeedbackLoopData(
      mrdFacilityData,
      feedbackLoopDataMrd
    )
  }

  if (hasRelatedTreatmentFacility && feedbackLoopDataTreatment) {
    feedbackLoopTreatmentChangedData = getFacilityFeedbackLoopData(
      treatmentFacilityData,
      feedbackLoopDataTreatment
    )
  }

  if (hasRelatedDoctor && feedbackLoopDataDoctor) {
    feedbackLoopDoctorChangedData = getDoctorFeedbackLoopData(
      doctorData,
      feedbackLoopDataDoctor
    )
  }

  return (
    <MainLayout>
      <Spin spinning={false}>
        <SearchLayout
          handleSearch={handleSearch}
          initialFormValues={formValues}
          handleReset={handleReset}
          breadcrumbs={breadcrumbs}
          title={
            <div>
              Jaia Case #{exceptionData?.id || ''}{' '}
              {exceptionData?.is_completed ? (
                <Tooltip title="Completed">
                  <CheckOutlined
                    style={{ color: '#1890ff', fontSize: '20px' }}
                  />
                </Tooltip>
              ) : null}
              {exceptionData?.reported_by_retriever ? (
                <Tooltip title="Reported by retriever">
                  <WarningOutlined
                    className="search-table__link-icon"
                    style={{ color: '#A8071A', fontSize: '20px' }}
                  />
                </Tooltip>
              ) : null}
              {exceptionData?.approved_by_retriever ? (
                <Tooltip title="Approved by retriever">
                  <DownSquareOutlined
                    className="search-table__link-icon"
                    style={{ color: '#00a386', fontSize: '20px' }}
                  />
                </Tooltip>
              ) : null}
            </div>
          }
          searchSuggestion={searchSuggestions}
          searchRequest={handleSearch}
          type={currentSearch?.suggestion_type}
          isHideSearch={
            !isUserActionAllowed('view_doctor') &&
            !isUserActionAllowed('view_facility')
          }
        >
          {exceptionData?.reopened_old_case?.cms_case_id && (
            <Card className="message" style={{ marginBottom: '10px' }}>
              <RetweetOutlined
                style={{ fontSize: '32px', marginRight: '12px' }}
              />
              <span>
                This case reopened the old case. CMS ID:{' '}
                <b>{exceptionData?.reopened_old_case?.cms_case_id}</b>. Reason
                code: <b>{exceptionData?.reopened_old_case?.reason}</b>
              </span>
            </Card>
          )}
          {exceptionData?.reopened_new_case?.cms_case_id && (
            <Card className="message" style={{ marginBottom: '10px' }}>
              <RetweetOutlined
                style={{ fontSize: '32px', marginRight: '12px' }}
              />
              <span>
                This case was reopened by the new case. CMS ID:{' '}
                <b>{exceptionData?.reopened_new_case?.cms_case_id}</b>. Reason
                code: <b>{exceptionData?.reopened_new_case?.reason}</b>
              </span>
            </Card>
          )}
          {exceptionData?.notes?.length > 0 && (
            <Collapse className="notes_collapse" defaultActiveKey={['1']}>
              <Collapse.Panel header="Notes" key="1" extra={genExtra()}>
                {exceptionData?.notes.map(item => (
                  <p
                    key={item}
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {item}
                  </p>
                ))}
              </Collapse.Panel>
            </Collapse>
          )}
          <Table
            columns={exceptionColumns.map(item => {
              if (item.customRender) {
                return {
                  ...item,
                  // eslint-disable-next-line react/display-name
                  render: text => (
                    <CaseGoalsCustomRender
                      sort={sort}
                      goals={goals}
                      id={text}
                      selectedGoals={selectedGoals}
                      setWarningModalGoals={setWarningModalGoals}
                      disabled={
                        exceptionData?.status !== EXCEPTION_STATUSES.REVIEW ||
                        !isUserActionAllowed('update_goals_case')
                      }
                    />
                  ),
                }
              }

              return item
            })}
            entity={EXCEPTION}
            totalData={[exceptionData]}
            loadingStatus={fetchStatus}
            footer={
              <>
                {!!exceptionData?.parent_exception && (
                  <Row className="search-table__footer--mb">
                    <Link
                      to={routePaths.openCaseById(
                        exceptionData?.parent_exception
                      )}
                    >
                      <Typography.Link>{`Parent case ID: ${exceptionData?.parent_exception}`}</Typography.Link>
                    </Link>
                  </Row>
                )}
                {exceptionData?.rejection_reason?.name && (
                  <Alert
                    style={{ height: 50, marginBottom: 15 }}
                    message={exceptionData?.rejection_reason?.name}
                    type="error"
                    showIcon
                  />
                )}

                <Row gutter={24} justify="space-between">
                  <Col>
                    <Space size={12}>
                      {isUserActionAllowed('view_history_case') && (
                        <Link
                          to={{
                            pathname: `/cases/${id}/history/`,
                            data: {
                              sort,
                            },
                          }}
                        >
                          <Button type="ghost">
                            <HistoryOutlined />
                            View History
                          </Button>
                        </Link>
                      )}

                      <Space size={12}>
                        {isUserActionAllowed('add_facility') &&
                          isUserActionAllowed('view_facility') &&
                          isUserActionAllowed('manage_relations_case') && (
                            <CreateFacilityModal
                              isMrdCase={isMrdCase}
                              currentSearch={currentSearch}
                              isCreateNewFacilityEnabled={
                                isCreateNewFacilityEnabled
                              }
                              handleRelateFacility={handleRelateFacility}
                              hasRelatedFacility={hasRelatedFacility}
                              hasRelatedTreatmentFacility={
                                hasRelatedTreatmentFacility
                              }
                            />
                          )}

                        {isUserActionAllowed('add_doctor') &&
                          isUserActionAllowed('view_doctor') &&
                          isUserActionAllowed('manage_relations_case') && (
                            <CreateDoctorModal
                              isCreateNewDoctorEnabled={
                                isCreateNewDoctorEnabled
                              }
                              currentSearch={currentSearch}
                              handleRelateDoctor={handleRelateDoctor}
                            />
                          )}
                      </Space>
                    </Space>
                  </Col>
                  {isShowBackToReviewButton && (
                    <Col>
                      <Space size={12}>
                        <Button
                          ghost
                          type="primary"
                          danger
                          onClick={() => setBackToReviewModalVisible(true)}
                        >
                          Back to Review Required
                        </Button>
                      </Space>
                    </Col>
                  )}
                  {REVIEW === exceptionData?.status && (
                    <Col>
                      <Space size={12}>
                        {isUserActionAllowed('resolve_case') && (
                          <Button
                            ghost
                            type="primary"
                            className="button--success"
                            disabled={
                              !exceptionData?.can_be_resolved ||
                              isStatusChanging
                            }
                            onClick={() =>
                              changeStatusHandler(
                                EXCEPTION_MAPPING.RESOLVED.index,
                                'resolve'
                              )
                            }
                          >
                            Resolve
                          </Button>
                        )}
                        {isUserActionAllowed('reject_case') && (
                          <Button
                            ghost
                            type="primary"
                            danger
                            disabled={isStatusChanging}
                            onClick={() =>
                              changeStatusHandler(
                                EXCEPTION_MAPPING.REJECTED.index,
                                'reject'
                              )
                            }
                          >
                            Reject
                          </Button>
                        )}
                      </Space>
                    </Col>
                  )}
                </Row>
              </>
            }
          />
          <Modal
            title="Please add the reason of returning a case to review"
            destroyOnClose
            visible={isBackToReviewModalVisible}
            closeIcon={<CloseOutlined className="relate-modal__close-icon" />}
            onCancel={() => setBackToReviewModalVisible(false)}
            footer={[
              <Button
                key="back"
                loading={isStatusChanging}
                onClick={() => handleBackToReview()}
              >
                Save
              </Button>,
            ]}
          >
            <Form
              form={backToReviewForm}
              name="basic"
              onFinish={handleBackToReview}
              autoComplete="off"
            >
              <Form.Item
                name="back-to-review-note"
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      if (!getFieldValue('back-to-review-note')?.trim()) {
                        return Promise.reject(
                          new Error("Reason can't be empty")
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <TextArea
                  rows={4}
                  maxlength="1024"
                  name="back-to-review-note"
                  placeholder="Add notes here"
                />
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            visible={hotkeysWarning}
            closeIcon
            title="Search Shortcut Issue"
            onCancel={() => setHotkeysWarning('')}
            footer={[
              <Button key="back" onClick={() => setHotkeysWarning('')}>
                OK
              </Button>,
            ]}
          >
            {hotkeysWarning}
          </Modal>
          <Modal
            visible={warningModalGoals}
            closeIcon
            title={warningModalGoals}
            onCancel={() => setWarningModalGoals(false)}
            footer={[
              <Button key="back" onClick={() => setWarningModalGoals(false)}>
                OK
              </Button>,
            ]}
          >
            <p>
              {' '}
              {warningModalGoals === 'mrd'
                ? 'System identified this case as MRD. MRD facility is skipped and\n' +
                  '              this case will be resolved as regular.'
                : 'System identified this case as MRD. Treatment facility is skipped\n' +
                  '              and this case will be resolved as regular.'}
            </p>
          </Modal>

          <RelateNotesModal
            visible={notesModalData}
            title="Notes"
            handleClose={() => setNotesModalData(false)}
            notes={notesModalData}
          />

          <Modal
            visible={hotkeysModal}
            closeIcon
            title="Search Shortcuts"
            onCancel={() => setHotkeysModal(!hotkeysModal)}
            footer={[
              <Button key="back" onClick={() => setHotkeysModal(!hotkeysModal)}>
                OK
              </Button>,
            ]}
          >
            ALT+A - Search by primary address excluding Address Line 2 <br />{' '}
            ALT+P - Search by primary Phone <br />
            ALT+X - Search by primary Fax <br />
            ALT+M - Search Jaia top matches <br />
            ALT+N - Search by First and Last Name or Facility Name
          </Modal>

          <Modal
            visible={mappingScoreLogModal}
            closeIcon
            title="Mapping Score Log"
            onCancel={() => setMappingScoreLogModal(!mappingScoreLogModal)}
            footer={[
              <Button
                key="back"
                onClick={() => setMappingScoreLogModal(!mappingScoreLogModal)}
              >
                OK
              </Button>,
            ]}
          >
            <div style={{ whiteSpace: 'pre-line' }}>
              {exceptionData?.mapping_score_logs}
            </div>
          </Modal>

          <Table
            className="case-info-table"
            tableTitle="Case Information"
            titleAction={
              (isUserActionAllowed('view_facility') ||
                isUserActionAllowed('view_doctor')) && {
                icon: (
                  <Space size={12}>
                    <InfoCircleOutlined
                      className="suggestion__icon"
                      onClick={() => setHotkeysModal(!hotkeysModal)}
                    />
                    {exceptionData?.mapping_score_logs && (
                      <BarChartOutlined
                        className="suggestion__icon"
                        onClick={() =>
                          setMappingScoreLogModal(!mappingScoreLogModal)
                        }
                      />
                    )}
                  </Space>
                ),
              }
            }
            editFacility={{
              facility: exceptionData?.status === REVIEW && (
                <div className="feedback-loop-edit-facility-buttons">
                  {feedbackLoopMrdChangedData && (
                    <EditFacilityModal
                      facilityType="facility"
                      editItemValues={feedbackLoopMrdChangedData.editValues}
                      originalValues={feedbackLoopMrdChangedData.originalValues}
                      facilityData={mrdFacilityData}
                      handleUpdateFacility={handleUpdateFacility}
                    />
                  )}
                </div>
              ),
              mrd: exceptionData?.status === REVIEW && (
                <div className="feedback-loop-edit-facility-buttons">
                  {feedbackLoopMrdChangedData && (
                    <EditFacilityModal
                      facilityType="mrd"
                      editItemValues={feedbackLoopMrdChangedData.editValues}
                      originalValues={feedbackLoopMrdChangedData.originalValues}
                      facilityData={mrdFacilityData}
                      handleUpdateFacility={handleUpdateFacility}
                    />
                  )}
                </div>
              ),
              treatment: exceptionData?.status === REVIEW && (
                <div className="feedback-loop-edit-facility-buttons">
                  {feedbackLoopTreatmentChangedData && (
                    <EditFacilityModal
                      facilityType="treatment"
                      editItemValues={
                        feedbackLoopTreatmentChangedData.editValues
                      }
                      originalValues={
                        feedbackLoopTreatmentChangedData.originalValues
                      }
                      facilityData={treatmentFacilityData}
                      handleUpdateFacility={handleUpdateFacility}
                    />
                  )}
                </div>
              ),
              doctor: exceptionData?.status === REVIEW && (
                <div className="feedback-loop-edit-facility-buttons">
                  {feedbackLoopDoctorChangedData && (
                    <EditDoctorModal
                      editItemValues={feedbackLoopDoctorChangedData.editValues}
                      originalValues={
                        feedbackLoopDoctorChangedData.originalValues
                      }
                      doctorData={doctorData}
                      handleUpdateDoctor={handleUpdateDoctor}
                    />
                  )}
                </div>
              ),
            }}
            columns={caseInformationColumns}
            totalData={caseInformationData}
            loadingStatus={fetchStatus}
            changeSearchOption={changeSearchOption}
            searchOptions={searchOptions}
            activeRowId={activeRowId && activeRowId[0]}
            useClearData
            onRow={record => ({
              onClick: ({ target }) => {
                const isTableRow = target.closest('.ant-table-row')
                if (!isTableRow) return

                handlePickInfoToSearch([record.id])
              },
            })}
            rowClassName={record =>
              record.id === activeRowId[0] ? 'active_row' : 'case_info_row'
            }
            primarySearch={currentSearch?.primary_search}
            highlightedFields={highlightedFields}
            originalFields={{
              facility: getFeedbackLoopOriginalFields(
                feedbackLoopMrdChangedData
              ),
              mrd: getFeedbackLoopOriginalFields(feedbackLoopMrdChangedData),
              treatment: getFeedbackLoopOriginalFields(
                feedbackLoopTreatmentChangedData
              ),
              doctor: getFeedbackLoopOriginalFields(
                feedbackLoopDoctorChangedData
              ),
            }}
          />

          {isUserActionAllowed('view_facilityprofile') && facilityProfileData && (
            <FacilityProfileCard
              data={facilityProfileData}
              loadingStatus={fetchFacilityStatus}
              title={
                <>
                  <LinkOutlined className="search-table__title--icon" />
                  Related Facility Profile
                </>
              }
            />
          )}
          {isUserActionAllowed('view_facility') && mrdFacilityData && (
            <ExceptionFacilityTable
              id={id}
              type="mrd"
              title={isMrdCase ? `Related MRD Facility` : `Related Facility`}
              data={[mrdFacilityData]}
              disabledRemove={isActiveByStatus || !mrdFacilityData}
              handleRemoveRelation={handleRemoveRelation}
              loadingStatus={fetchFacilityStatus}
              isMrdCase={isMrdCase}
              handleRelateFacility={handleRelateFacility}
              hasRelatedFacility={hasRelatedFacility}
              hasRelatedTreatmentFacility={hasRelatedTreatmentFacility}
              exceptionData={exceptionData}
              dataCaseInformation={caseInformationData}
              caseInformationId={exceptionData?.id}
              primarySearch={currentSearch?.primary_search}
              highlightedFields={highlightedFields}
              facilityColumns={facilityColumns}
            />
          )}
          {isUserActionAllowed('view_facility') &&
            isMrdCase &&
            treatmentFacilityData && (
              <ExceptionFacilityTable
                id={id}
                type="treatment"
                title="Related Treatment Facility"
                data={[treatmentFacilityData]}
                disabledRemove={isActiveByStatus || !treatmentFacilityData}
                handleRemoveRelation={handleRemoveRelation}
                loadingStatus={fetchFacilityStatus}
                isMrdCase={isMrdCase}
                handleRelateFacility={handleRelateFacility}
                hasRelatedFacility={hasRelatedFacility}
                hasRelatedTreatmentFacility={hasRelatedTreatmentFacility}
                exceptionData={exceptionData}
                dataCaseInformation={caseInformationData}
                caseInformationId={exceptionData?.id}
                primarySearch={currentSearch?.primary_search}
                highlightedFields={highlightedFields}
                facilityColumns={facilityColumns}
              />
            )}

          {isUserActionAllowed('view_doctor') && doctorData && (
            <ExceptionDoctorTable
              id={id}
              title="Related Doctor"
              data={[doctorData]}
              disabledRemove={isActiveByStatus || !doctorData}
              handleRemoveRelation={handleRemoveRelation}
              loadingStatus={fetchDoctorStatus}
              handleRelateDoctor={handleRelateDoctor}
              hasRelatedDoctor={hasRelatedDoctor}
              exceptionData={exceptionData}
              dataCaseInformation={caseInformationData}
              caseInformationId={exceptionData?.id}
              primarySearch={currentSearch?.primary_search}
              highlightedFields={highlightedFields}
              doctorColumns={doctorColumns}
            />
          )}

          <Tabs
            fullWidth
            theme="dark"
            tabsProps={{
              size: 'small',
              tabBarGutter: 12,
              activeKey: activeTabKey,
              onChange: handleChangeTab,
            }}
          >
            {isViewSearchPermission && (
              <Tab.TabPane tab="All" key="all">
                {allDoctors &&
                  allFacilities &&
                  !allDoctors.length &&
                  !allFacilities.length && <NoResults />}
                <ExceptionFacilityTable
                  id={id}
                  tableTitle="Facilities"
                  data={allFacilities}
                  onLoadData={searchCaseDetailsFacilities}
                  disabledRemove={isActiveByStatus}
                  handleRemoveRelation={handleRemoveRelation}
                  loadingStatus={fetchSearchFacilityStatus}
                  isMrdCase={isMrdCase}
                  handleRelateFacility={handleRelateFacility}
                  hasRelatedFacility={hasRelatedFacility}
                  hasRelatedTreatmentFacility={hasRelatedTreatmentFacility}
                  exceptionData={exceptionData}
                  dataCaseInformation={caseInformationData}
                  caseInformationId={exceptionData?.id}
                  primarySearch={currentSearch?.primary_search}
                  highlightedFields={highlightedFields}
                  facilityColumns={facilityColumns}
                  totalCount={facilityCount}
                  formData={formValues}
                />
                <ExceptionDoctorTable
                  id={id}
                  tableTitle="Doctors"
                  data={allDoctors}
                  onLoadData={searchCaseDetailsDoctors}
                  totalCount={doctorsCount}
                  disabledRemove={isActiveByStatus}
                  handleRemoveRelation={handleRemoveRelation}
                  loadingStatus={fetchSearchDoctorStatus}
                  handleRelateDoctor={handleRelateDoctor}
                  hasRelatedDoctor={hasRelatedDoctor}
                  exceptionData={exceptionData}
                  dataCaseInformation={caseInformationData}
                  caseInformationId={exceptionData?.id}
                  primarySearch={currentSearch?.primary_search}
                  highlightedFields={highlightedFields}
                  doctorColumns={doctorColumns}
                  formData={formValues}
                />
              </Tab.TabPane>
            )}
            {isUserActionAllowed('view_facility') && (
              <Tab.TabPane tab="Only Facilities" key="facilities">
                {allFacilities && !allFacilities.length && <NoResults />}
                <ExceptionFacilityTable
                  id={id}
                  tableTitle="Facilities"
                  data={allFacilities}
                  onLoadData={searchCaseDetailsFacilities}
                  disabledRemove={isActiveByStatus}
                  handleRemoveRelation={handleRemoveRelation}
                  loadingStatus={fetchSearchFacilityStatus}
                  isMrdCase={isMrdCase}
                  handleRelateFacility={handleRelateFacility}
                  hasRelatedFacility={hasRelatedFacility}
                  hasRelatedTreatmentFacility={hasRelatedTreatmentFacility}
                  exceptionData={exceptionData}
                  dataCaseInformation={caseInformationData}
                  caseInformationId={exceptionData?.id}
                  primarySearch={currentSearch?.primary_search}
                  highlightedFields={highlightedFields}
                  facilityColumns={facilityColumns}
                  totalCount={facilityCount}
                  formData={formValues}
                />
              </Tab.TabPane>
            )}
            {isUserActionAllowed('view_doctor') && (
              <Tab.TabPane tab="Only Doctors" key="doctors">
                {allDoctors && !allDoctors.length && <NoResults />}
                <ExceptionDoctorTable
                  id={id}
                  tableTitle="Doctors"
                  data={allDoctors}
                  onLoadData={searchCaseDetailsDoctors}
                  totalCount={doctorsCount}
                  disabledRemove={isActiveByStatus}
                  handleRemoveRelation={handleRemoveRelation}
                  loadingStatus={fetchSearchDoctorStatus}
                  handleRelateDoctor={handleRelateDoctor}
                  hasRelatedDoctor={hasRelatedDoctor}
                  exceptionData={exceptionData}
                  dataCaseInformation={caseInformationData}
                  caseInformationId={exceptionData?.id}
                  primarySearch={currentSearch?.primary_search}
                  highlightedFields={highlightedFields}
                  doctorColumns={doctorColumns}
                  formData={formValues}
                />
              </Tab.TabPane>
            )}
          </Tabs>
        </SearchLayout>
      </Spin>
    </MainLayout>
  )
}

export default Exception
