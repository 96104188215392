import React from 'react'

import { DoctorMain, Addresses, Contact } from '../FormFields'

const FormComponent = ({ errorMessage, formData, type, action, original }) => (
  <>
    <DoctorMain title="Main Info" original={original} />
    <Addresses
      values={formData}
      errorMessage={errorMessage}
      type={type}
      action={action}
      original={original}
    />
    <Contact
      title="Contacts"
      values={formData}
      errorMessage={errorMessage}
      type={type}
      action={action}
      original={original}
    />
  </>
)

export default FormComponent
