import { object } from 'yup'

import { email, password, firstname, lastname, supervisors } from './fields'

export const updateUserSchema = object().shape({
  email,
  password: password.notRequired(),
  firstname,
  lastname,
  supervisors,
})
