import React from 'react'
import { Form, Button } from 'antd'
import { Formik, Field } from 'formik'
import { Link } from 'react-router-dom'
import { MailOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import TextField from '../../../shared/TextField'
import { forgotPasswordSchema } from '../../../../schema/auth'

const ForgotPasswordForm = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={forgotPasswordSchema}
    initialValues={{ email: '' }}
  >
    {({ handleSubmit }) => (
      <Form onFinish={handleSubmit}>
        <Field
          component={TextField}
          name="email"
          placeholder="E-mail"
          type="email"
          icon={<MailOutlined className="form-input-icon" />}
        />
        <Form.Item>
          <Link to="/login" className="forgot-password-link">
            Back to Log in page
          </Link>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Send message
          </Button>
        </Form.Item>
      </Form>
    )}
  </Formik>
)

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ForgotPasswordForm
