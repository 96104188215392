import React from 'react'
import { Formik, Field, FieldArray } from 'formik'
import { Form, Button, Row, Col, Card, Skeleton } from 'antd'
import PropTypes from 'prop-types'

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import TextField from '../../../shared/TextField'
import SelectField from '../../../shared/SelectField'
import ToggleField from '../../../shared/ToggleField'

const UserForm = ({
  submitButtonText,
  initialValues,
  loading,
  onSubmit,
  validationSchema,
  formType,
  roleOptions,
  userRole,
  isSubmitAllowed,
}) => (
  <Card className="user-info-card">
    {loading ? (
      <Skeleton loading active />
    ) : (
      <Formik
        initialValues={{ role: userRole || '', ...initialValues }}
        onSubmit={data => {
          const supervisors = data.supervisors?.map(item => +item.cms_id) || []
          return onSubmit({ ...data, supervisors })
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleSubmit, values, isSubmitting, dirty, errors, touched }) => (
          <Form
            onFinish={handleSubmit}
            className="user-form"
            layout="vertical"
            initialValues={values}
          >
            <Row justify="space-between">
              <Col>
                <Form.Item>
                  <div className="user-form__title">Personal Details</div>
                </Form.Item>
              </Col>
              <Col>
                <Row gutter={16}>
                  {formType === 'update' && (
                    <>
                      <Col>
                        <Form.Item>
                          <div className="user-form__label--status">Active</div>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Field
                            name="is_active"
                            component={ToggleField}
                            noStyle
                            valuePropName="checked"
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col>
                    <Form.Item>
                      <div className="user-form__label--status">Admin</div>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Field
                        name="is_superuser"
                        component={ToggleField}
                        noStyle
                        valuePropName="checked"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Field
                  component={TextField}
                  label="First Name"
                  size="medium"
                  name="firstname"
                  required
                />
              </Col>
              <Col span={12}>
                <Field
                  component={TextField}
                  label="Last Name"
                  size="medium"
                  name="lastname"
                  required
                />
              </Col>
              <Col span={12}>
                <Field
                  component={TextField}
                  label="Email Address"
                  size="medium"
                  name="email"
                  required
                />
              </Col>
              <Col span={12}>
                <Field
                  component={TextField}
                  label={formType === 'update' ? 'New Password' : 'Password'}
                  type="password"
                  size="medium"
                  name="password"
                  required={formType !== 'update'}
                />
              </Col>
              <Col span={24}>
                <Field
                  component={SelectField}
                  label="Role"
                  size="medium"
                  options={[{ label: 'No Role', value: '' }, ...roleOptions]}
                  name="role"
                />
              </Col>
              <Col span={24}>
                <FieldArray
                  name="supervisors"
                  render={({ name, push, remove }) => (
                    <>
                      {values?.supervisors?.map((field, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={index} align="top" style={{ gap: '10px' }}>
                          <Col span={22}>
                            <Field
                              component={TextField}
                              placeholder="Type Here"
                              name={`${name}[${index}].cms_id`}
                              label="Supervisor CMS ID"
                              size="medium"
                              error={
                                errors &&
                                touched &&
                                errors[name] &&
                                touched[name] &&
                                errors[name][index] &&
                                touched[name][index] &&
                                touched[name][index].cms_id &&
                                errors[name][index].cms_id
                              }
                              required
                            />
                          </Col>
                          <Col
                            style={{ marginTop: '25px', marginLeft: 'auto' }}
                          >
                            <Form.Item>
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  remove(index)
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            push({
                              cms_id: '',
                              id: '',
                            })
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Supervisor
                        </Button>
                      </Form.Item>
                    </>
                  )}
                />
              </Col>
            </Row>
            {isSubmitAllowed && (
              <Button
                type="primary"
                ghost
                htmlType="submit"
                disabled={!dirty}
                loading={isSubmitting}
              >
                {submitButtonText}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    )}
  </Card>
)

UserForm.propTypes = {
  submitButtonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  initialValues: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
  }),
  validationSchema: PropTypes.shape({}).isRequired,
  formType: PropTypes.oneOf(['create', 'update']),
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  userRole: PropTypes.number,
  isSubmitAllowed: PropTypes.bool,
}

UserForm.defaultProps = {
  initialValues: {
    firstname: '',
    lastname: '',
    password: '',
    email: '',
  },
  loading: false,
  formType: 'create',
  roleOptions: [],
  userRole: null,
  isSubmitAllowed: null,
}

export default UserForm
