import React from 'react'
import Table from '../../../components/Tables/SearchTable/SearchTable.container'
import {
  formatCaseInfoTableData,
  formatCaseFeedbackLoopTableData,
  capitalizeFirsLetter,
} from '../../../../utils/helpers'
import {
  caseInformationHistoryColumns,
  caseFeedbackLoopColumns,
} from '../../../../data'
import { highlightDataDifference } from './highlightCaseHistorySuggestionDifferences'

export const updateChanges = (changes, id, isMrd) => {
  const mapping = {
    new_is_created: () => ({
      title: `#${id} Case Created`,
    }),
    old_doctor: ({ value }) => ({
      title: `Doctor Unlinked:`,
      name: '',
      value: value?.id && `${value?.first_name} ${value?.last_name}`,
      link: `/doctor/${value?.id}/`,
    }),
    new_doctor: ({ value }) => ({
      title: `Doctor Linked:`,
      name: '',
      value: value?.id && `${value?.first_name} ${value?.last_name}`,
      link: `/doctor/${value?.id}/`,
    }),
    old_mrd_facility: ({ value }) => ({
      title: isMrd ? `MRD Facility Unlinked:` : 'Facility Unlinked:',
      name: '',
      value: value?.id && value?.name,
      link: `/facility/${value?.id}/`,
    }),
    new_mrd_facility: ({ value, reason }) => ({
      title: isMrd ? `MRD Facility Linked:` : ` Facility Linked:`,
      name: '',
      value: value?.id && value?.name,
      reason,
      link: `/facility/${value?.id}/`,
    }),
    old_treatment_facility: ({ value }) => ({
      title: `Treatment Facility Unlinked:`,
      name: '',
      value: value?.id && value?.name,
      link: `/facility/${value?.id}/`,
    }),
    new_treatment_facility: ({ value }) => ({
      title: `Treatment Facility Linked:`,
      name: '',
      value: value?.id && value?.name,
      link: `/facility/${value?.id}/`,
    }),
    new_find_doctor: ({ value }) => ({
      title: `Goal Find Doctor:`,
      name: '',
      value: value ? 'Added' : 'Removed',
    }),
    new_find_treatment_facility: ({ value }) => ({
      title: `Goal Find Treatment Facility:`,
      name: '',
      value: value ? 'Added' : 'Removed',
    }),
    new_find_mrd_facility: ({ value }) => ({
      title: `Goal Find MRD Facility:`,
      name: '',
      value: value ? 'Added' : 'Removed',
    }),
    new_find_facility: ({ value }) => ({
      title: `Goal Find Facility:`,
      name: '',
      value: value ? 'Added' : 'Removed',
    }),
    new_status: ({ value, reason, rejectionReason }) => {
      const data = (
        <>
          {value}
          {reason && ` (${reason})`}
          {rejectionReason && ` (${rejectionReason})`}
        </>
      )

      return {
        title: 'Status Changed:',
        name: '',
        value: data,
        status: value,
      }
    },
    new_notes: ({ value }) => {
      if (value && value.length) {
        // take length of an array and subtract 1 to get index of the last element
        const lastElementIndex = value.length - 1
        const notes = value.map((note, index) => (
          <div
            key={note}
            style={{
              fontWeight: '400',
              marginBottom: lastElementIndex === index ? 0 : '10px',
            }}
          >
            {note}
          </div>
        ))

        return {
          title: 'Notes:',
          name: '',
          value: notes,
        }
      }

      return null
    },
    old_notes: ({ value }) => {
      const notes = <div style={{ fontWeight: '400' }}>{value}</div>

      return {
        title: 'Notes Removed:',
        name: '',
        value: notes,
      }
    },
    new_feedback_loop: ({ value, oldValue }) => {
      const formattedOldValues = formatCaseFeedbackLoopTableData(oldValue)
      let values = {
        ...value,
      }

      if (formattedOldValues && Object.keys(formattedOldValues).length) {
        values = { ...values, old: formattedOldValues }
      }

      const feedbackData = formatCaseFeedbackLoopTableData(values)

      let formType =
        value.form_type && `${capitalizeFirsLetter(value.form_type)} `

      if (value.form_type && value.form_type === 'MRD') {
        formType = `${value.form_type} `
      }

      const data = (
        <div>
          <div
            className="history-log"
            style={{ fontWeight: 'normal', marginTop: '4px' }}
          >
            <Table
              className="case-info-table"
              columns={caseFeedbackLoopColumns.map(column => ({
                ...column,
                partialRender: highlightDataDifference,
              }))}
              totalData={[feedbackData]}
              useClearData
            />
          </div>
        </div>
      )
      return {
        title: `${formType || ''}Feedback Loop:`,
        name: '',
        value: data,
        type: 'feedback_loop',
      }
    },
    new_retriever_review_status: ({ value }) => ({
      title: '',
      name: '',
      value,
    }),
    new_feedback_loop_cms_retriever_id: ({ value }) => ({
      title: 'Feedback Loop CMS Retriever ID:',
      name: '',
      value,
    }),
    new_is_completed: ({ value }) => ({
      title: 'Case Completed:',
      name: '',
      value: value ? 'Yes' : 'No',
    }),
    new_provider: ({ value, isCreated }) => {
      const title = isCreated ? 'Provider ID:' : 'Provider ID Changed:'

      return {
        title,
        name: '',
        value,
      }
    },
    new_first_name: ({ value, provider, providerOld, isCreated }) => {
      let title = isCreated
        ? 'Provider First Name:'
        : 'Provider First Name Changed:'

      if (provider === providerOld) {
        title = 'Provider First Name Updated:'
      }

      return {
        title,
        name: '',
        value: value === 'None' ? '-' : value,
      }
    },
    new_last_name: ({ value, provider, providerOld, isCreated }) => {
      let title = isCreated
        ? 'Provider Last Name:'
        : 'Provider Last Name Changed:'

      if (provider === providerOld) {
        title = 'Provider Last Name Updated:'
      }

      return {
        title,
        name: '',
        value: value === 'None' ? '-' : value,
      }
    },
    new_servgroup: ({ value, isCreated }) => {
      const title = isCreated ? 'Servgroup:' : 'Servgroup Changed:'

      return {
        title,
        name: '',
        value,
      }
    },
    new_service: ({ value, isCreated }) => {
      const title = isCreated ? 'Service:' : 'Service Changed:'

      return {
        title,
        name: '',
        value,
      }
    },
    new_search_suggestions: ({ value, oldValue }) => {
      const formattedOldValues = formatCaseInfoTableData(oldValue)
      const values = value.map(item => ({
        ...item,
        old: formattedOldValues.find(
          old => old.suggestion_type === item.suggestion_type
        ),
      }))
      const caseInformationData = formatCaseInfoTableData(values)

      const data = (
        <div
          className="history-log"
          style={{ fontWeight: 'normal', marginTop: '4px' }}
        >
          <Table
            className="case-info-table"
            columns={caseInformationHistoryColumns.map(column => ({
              ...column,
              partialRender: highlightDataDifference,
            }))}
            totalData={caseInformationData}
            useClearData
          />
        </div>
      )
      return {
        title: 'Search Suggestions:',
        name: '',
        value: data,
        type: 'search_suggestions',
      }
    },
  }

  const items = [
    'is_created',
    'is_completed',
    'provider',
    'first_name',
    'last_name',
    'servgroup',
    'service',
    'search_suggestions',
    'find_doctor',
    'find_facility',
    'find_mrd_facility',
    'find_treatment_facility',
    'doctor',
    'mrd_facility',
    'treatment_facility',
    'feedback_loop',
    'status',
    'retriever_review_status',
    'notes',
    'feedback_loop_cms_retriever_id',
  ]

  const prepareData = key => {
    let currentKey = ''
    let currentValue = null
    let oldValue = changes?.old && changes?.old[key]
    let newValue = changes?.new && changes?.new[key]
    const isCreated = changes?.new.is_created

    if (
      Array.isArray(oldValue) &&
      key !== 'search_suggestions' &&
      key !== 'notes'
    ) {
      oldValue = oldValue.join(', ')
    }

    if (
      Array.isArray(newValue) &&
      key !== 'search_suggestions' &&
      key !== 'notes'
    ) {
      newValue = newValue.join(', ')
    }

    if (
      Object.keys(changes?.old).includes(key) &&
      oldValue !== undefined &&
      oldValue !== null
    ) {
      currentKey = `old_${key}`
      currentValue = oldValue
    }

    if (
      Object.keys(changes?.new).includes(key) &&
      newValue !== undefined &&
      newValue !== null
    ) {
      if (key !== 'notes') {
        currentKey = `new_${key}`
        currentValue = newValue
      } else if (key === 'notes' && isCreated) {
        currentKey = `new_${key}`
        currentValue = newValue
      } else if (key === 'notes' && newValue && newValue.length > 0) {
        currentKey = `new_${key}`
        currentValue = newValue
      }
    }

    return (
      (mapping[currentKey] &&
        mapping[currentKey]({
          value: currentValue,
          oldValue,
          reason: changes?.reason,
          rejectionReason: changes?.new.rejection_reason,
          isCreated,
          provider: changes?.new.provider,
          providerOld: changes?.old.provider,
        })) ||
      null
    )
  }

  return items.map(key => prepareData(key)).filter(Boolean)
}
