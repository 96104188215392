import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Spin } from 'antd'
import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import RelateFacilityModal from '../../components/Modals/RelateDoctorFacilityModal'

import {
  getClusters,
  selectAllClusters,
  createCluster,
  resetState as resetClustersState,
} from '../../../state/modules/clusters'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import { clusterTableColumns } from '../../../data'
import { CLUSTER } from '../../../constants'
import { routePaths } from '../../../utils/routes'
import { useUser } from '../../../providers/UserProvider'

const Clusters = () => {
  const history = useHistory()
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const clustersList = useSelector(selectAllClusters)
  const { count } = useSelector(state => state.clusters)
  const loadingStatus = useSelector(state => state.clusters.status)
  const { isUserActionAllowed } = useUser()

  const handleClusterCreate = async ({ id }) => {
    const result = await dispatchHttp(
      createCluster({ headquarter_id: id }),
      'Cluster has been successfully created'
    )

    history.push(routePaths.openClusterById(result.id))
  }

  useEffect(() => {
    dispatchHttp(getClusters())
  }, [dispatchHttp])

  useEffect(
    () => () => {
      dispatch(resetClustersState())
    },
    [dispatch]
  )

  return (
    <Spin spinning={false}>
      <PageLayout
        title={
          <>
            Clusters List
            <span className="badge">{count}</span>
          </>
        }
        extraButtons={
          isUserActionAllowed('add_facilitycluster') && (
            <RelateFacilityModal
              title="Create new Cluster"
              entity="Main Facility"
              onSubmit={handleClusterCreate}
              closeAfterSubmit
              submitButtonText="Create"
              trigger={
                <Button ghost type="primary">
                  Create New Cluster
                </Button>
              }
            />
          )
        }
      >
        <Table
          columns={clusterTableColumns}
          onLoadData={getClusters}
          totalCount={count}
          totalData={clustersList}
          loadingStatus={loadingStatus}
          entity={CLUSTER}
        />
      </PageLayout>
    </Spin>
  )
}

export default Clusters
