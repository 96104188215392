import React, { useCallback, useState } from 'react'
import { Button, Modal, Select, Form, DatePicker } from 'antd'
import { useUser } from '../../../../providers/UserProvider'

const { RangePicker } = DatePicker
const { Option } = Select
const permissionMapping = {
  case_assignment: 'generate_case_assignment',
  feedback_loop: 'generate_feedback_loop',
}
const allReports = {
  case_assignment: 'Case Assignment',
  feedback_loop: 'Feedback Loop',
}

const CreateReportModal = ({ visible, title, handleClose, handleGenerate }) => {
  const { isUserActionAllowed } = useUser()
  const [form] = Form.useForm()
  const allowedReports = Object.keys(allReports).filter(key => {
    const permission = permissionMapping[key]
    return isUserActionAllowed(permission)
  })
  const [defaultReport] = allowedReports
  const [currentReportType, setCurrentReportType] = useState(defaultReport)
  const handleChange = useCallback(
    val => {
      setCurrentReportType(val)
      form.setFieldsValue({ report_type: val })
    },
    [setCurrentReportType, form]
  )
  const caseAssignmentContent = (
    <>
      <Form.Item label="Date range" name="date_range">
        <RangePicker />
      </Form.Item>
    </>
  )

  const feedbackLoopContent = (
    <>
      <Form.Item label="Date range" name="date_range">
        <RangePicker />
      </Form.Item>
      <Form.Item label="Internal Case" name="internal_retriever">
        <Select defaultValue="all" style={{ width: 100 }}>
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="internal">Yes</Select.Option>
          <Select.Option value="not_internal">No</Select.Option>
        </Select>
      </Form.Item>
    </>
  )
  const typeMapping = {
    case_assignment: caseAssignmentContent,
    feedback_loop: feedbackLoopContent,
  }
  return (
    <Modal
      style={{
        padding: '0px',
      }}
      className="create-report-modal"
      visible={visible}
      title={title}
      onCancel={() => {
        setCurrentReportType(defaultReport)
        handleClose()
      }}
      destroyOnClose
      footer={false}
    >
      {defaultReport ? (
        <Form
          style={{
            padding: '20px 20px 0 20px',
          }}
          preserve={false}
          initialValues={{ report_type: defaultReport }}
          onFinish={async data => {
            await handleGenerate(data)
            setCurrentReportType(defaultReport)
          }}
          labelWra={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
        >
          <Form.Item label="Report Type" name="report_type">
            <Select
              // defaultValue={defaultReport}
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              {allowedReports.map(report => (
                <Option value={report} key={report}>
                  {allReports[report]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {typeMapping[currentReportType]}

          {currentReportType && (
            <Form.Item
              style={{
                textAlign: 'right',
                margin: '36px -20px 0 -20px',
                padding: '10px 20px 10px 20px',
                borderTop: '1px solid #f0f0f0',
                justifyContent: 'end',
              }}
            >
              <Button htmlType="submit">Generate</Button>
            </Form.Item>
          )}
        </Form>
      ) : (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          No available reports
        </div>
      )}
    </Modal>
  )
}

export default CreateReportModal
