import { adapters } from './reducer'
import { DOCTOR, FACILITY } from '../../../constants'

export const { selectAll: selectAllFacility } = adapters[FACILITY].getSelectors(
  state => state.search[FACILITY]
)

export const { selectAll: selectAllDoctor } = adapters[DOCTOR].getSelectors(
  state => state.search[DOCTOR]
)
