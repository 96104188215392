import React, { useState } from 'react'
import { Typography, Modal, Form, Button, Row, Col } from 'antd'
import { Formik, Field } from 'formik'

import TextField from '../../../shared/TextField'
import { updateCreateHealthNetworkSchema } from '../../../../schema/createHealthNetwork'

const CreateHealthNetworkModal = ({
  trigger,
  onSubmit,
  title,
  submitButtonText,
  initialValues,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleOpenModal = e => {
    e.preventDefault()
    setIsModalVisible(!isModalVisible)
  }

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: handleOpenModal,
      })}
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={false}
        destroyOnClose
        centered
        onCancel={() => setIsModalVisible(false)}
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Typography.Title level={3}>{title}</Typography.Title>
        <Formik
          initialValues={initialValues}
          onSubmit={async (...data) => {
            try {
              await onSubmit(...data)
              setIsModalVisible(false)
            } catch (err) {
              /* empty */
            }
          }}
          validationSchema={updateCreateHealthNetworkSchema}
        >
          {({ handleSubmit }) => (
            <Form onFinish={handleSubmit} layout="vertical">
              <Field
                component={TextField}
                placeholder="Type Here"
                name="name"
                label="Name"
              />
              <Row justify="space-between" align="middle">
                <Col>
                  <Button onClick={handleOpenModal}>Close window</Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    {submitButtonText}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default CreateHealthNetworkModal
