import React from 'react'
import { Row, Col, Divider } from 'antd'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import TextField from '../../../shared/TextField'
import SelectField from '../../../shared/SelectField'
// import InputMaskField from '../../../shared/InputMaskField'

import TimePickerField from '../../../shared/TimePickerField'

import { TIME_FORMAT } from '../../../../constants'
import { weekDays } from '../../../../data/selectOptions'
// import { daysMaskOptions } from '../../../../data/inputMaskOptions'

export const Schedule = ({ title }) => (
  <>
    <Divider orientation="left" className="form-divider">
      {title}
    </Divider>
    <Row gutter={12}>
      <Col span={24}>
        <Field
          component={SelectField}
          label="Days Open"
          size="medium"
          name="days_open"
          placeholder="Select Here"
          options={weekDays}
          multiple
        />
      </Col>
      <Col span={12}>
        <Field
          component={TimePickerField}
          label="Hours Open"
          size="medium"
          name="working_hours_start"
          placeholder="Select Here"
          format={TIME_FORMAT.half}
        />
      </Col>
      <Col span={12}>
        <Field
          label
          component={TimePickerField}
          size="medium"
          name="working_hours_end"
          placeholder="Select Here"
          format={TIME_FORMAT.half}
        />
      </Col>
    </Row>

    {/* <Row gutter={12} align="bottom"> */}
    {/*  <Col span={8}> */}
    {/*    <Field */}
    {/*      component={InputMaskField} */}
    {/*      label={ */}
    {/*        <> */}
    {/*          Turn-around Time */}
    {/*          <br /> Min */}
    {/*        </> */}
    {/*      } */}
    {/*      name="turn_around_min" */}
    {/*      placeholder="Type Here" */}
    {/*      maskOptions={daysMaskOptions} */}
    {/*      disabled */}
    {/*    /> */}
    {/*  </Col> */}
    {/*  <Col span={8}> */}
    {/*    <Field */}
    {/*      component={InputMaskField} */}
    {/*      label="Max" */}
    {/*      size="medium" */}
    {/*      name="turn_around_max" */}
    {/*      placeholder="Type Here" */}
    {/*      maskOptions={daysMaskOptions} */}
    {/*      disabled */}
    {/*    /> */}
    {/*  </Col> */}
    {/*  <Col span={8}> */}
    {/*    <Field */}
    {/*      component={InputMaskField} */}
    {/*      label="Avg" */}
    {/*      size="medium" */}
    {/*      name="turn_around_avg" */}
    {/*      placeholder="Type Here" */}
    {/*      maskOptions={daysMaskOptions} */}
    {/*      disabled */}
    {/*    /> */}
    {/*  </Col> */}
    {/* </Row> */}
    <Row gutter={12}>
      <Col span={12}>
        <Field
          component={TextField}
          label="Contact Name of Medical Department"
          size="medium"
          name="contact_name"
          placeholder="Type Here"
          maxlength="128"
        />
      </Col>
      <Col span={12}>
        <Field
          component={TextField}
          label="Email"
          size="medium"
          name="email"
          placeholder="Type Here"
        />
      </Col>
    </Row>
  </>
)

Schedule.propTypes = {
  title: PropTypes.string,
}

Schedule.defaultProps = {
  title: '',
}
