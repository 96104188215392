const importLogsStatuses = {
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  WITH_WARNINGS: 'WITH WARNINGS',
}

export const importLogsMappingColor = {
  [importLogsStatuses.FAILED]: 'red',
  [importLogsStatuses.SUCCESS]: 'cyan',
  [importLogsStatuses.WITH_WARNINGS]: 'orange',
}
