import Api from './index'
import { API_URL } from '../constants'

class Exceptions extends Api {
  getExceptionList(id, params) {
    return this.get('search_exceptions/', { params })
  }

  getExceptionById(id, params) {
    return this.get(`search_exceptions/${id}/`, { params })
  }

  getPrevNextCases(id, params) {
    return this.get(`search_exceptions/${id}/prev_next_case/`, { params })
  }

  getExceptionHistoryLog(id, params) {
    return this.get('exception_transition/', { params })
  }

  relateException(data) {
    return this.post('relate/', { data })
  }

  changeExceptionStatus(id, data, status) {
    return this.post(`search_exceptions/${id}/${status}/`, { data })
  }

  removeRelation(id, data) {
    return this.del(`search_exceptions/${id}/`, { data })
  }

  changeDoctorSuggestionStatus(data) {
    return this.post('suggestion_status/doctors/', { data })
  }

  changeFacilitySuggestionStatus(data) {
    return this.post('suggestion_status/facilities/', { data })
  }

  changeExceptionGoals(id, data) {
    return this.post(`search_exceptions/${id}/statuses/`, { data })
  }
}

export default new Exceptions({
  baseURL: API_URL,
})
