import React, { useCallback } from 'react'
import { Form, Switch } from 'antd'
import PropTypes from 'prop-types'

const ToggleField = ({
  field: { onChange, ...field },
  form: { touched, ...form },
  label,
  size,
  onClick,
  disabled,
  ...props
}) => {
  const onChangeHandle = useCallback(
    value => {
      form.setFieldValue(field.name, value)
    },
    [field.name, form]
  )

  return (
    <Form.Item {...props} label={label} name={field.name}>
      <Switch
        {...field}
        disabled={disabled}
        onChange={onChangeHandle}
        checked={!!field.value}
        onClick={onClick}
        size={size}
      />
    </Form.Item>
  )
}

ToggleField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.objectOf(PropTypes.array),
    ]),
  }).isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf(['large', 'small', 'medium']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

ToggleField.defaultProps = {
  field: PropTypes.shape({
    value: false,
    onChange: () => null,
    onBlur: () => null,
  }),
  label: '',
  size: 'large',
  onClick: () => null,
  disabled: false,
}

export default ToggleField
