import React, { useState, useEffect } from 'react'

import { Tabs as Tab, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from '../../../components/Tabs/Tabs'
import PageLayout from '../../../layouts/DetailPageLayout/DetailPageLayout'
import {
  getMergersList,
  selectAllMergers,
  resetState as resetMergersState,
} from '../../../../state/modules/mergers'
import useDispatchHttp from '../../../../hooks/dispatchHttpHandler'
import { facilityProfilesColumns } from '../../../../data'
import SearchTable from '../../../components/Tables/SearchTable/SearchTable.container'
import { MERGER } from '../../../../constants'

const tabs = [
  { name: 'New', key: 1 },
  { name: 'In Progress', key: 2 },
  { name: 'Completed', key: 3 },
]

const MergersList = () => {
  const dispatch = useDispatch()
  const dispatchHttp = useDispatchHttp()
  const [activeTab, setActiveTab] = useState(1)
  const mergersList = useSelector(selectAllMergers)
  const { count, status } = useSelector(state => state.mergers.mergers)

  useEffect(() => {
    dispatchHttp(getMergersList({ status: activeTab }))
    // eslint-disable-next-line
  }, [dispatchHttp, activeTab])

  useEffect(
    () => () => {
      dispatch(resetMergersState())
    },
    [dispatch]
  )

  return (
    <PageLayout
      title={
        <>
          Facility Profile Mergers
          {count ? <span className="badge">{count}</span> : null}
        </>
      }
    >
      <Spin spinning={false}>
        <Tabs
          fullWidth
          theme="dark"
          tabsProps={{
            size: 'small',
            tabBarGutter: 24,
            onChange: key => setActiveTab(key),
            activeKey: `${activeTab}`,
          }}
        >
          {tabs.map(({ key, name }) => (
            <Tab.TabPane tab={name} key={key}>
              <SearchTable
                onLoadData={getMergersList}
                formData={{ status: activeTab }}
                onFormatData={data =>
                  data.map(item => ({
                    ...item.mrd_facility,
                    mrd_facility_id: item.mrd_facility.id,
                    id: item.id,
                  }))
                }
                columns={facilityProfilesColumns.filter(
                  item => item.title !== 'Main Retriever'
                )}
                entity={MERGER}
                totalData={mergersList}
                totalCount={count}
                loadingStatus={status}
              />
            </Tab.TabPane>
          ))}
        </Tabs>
      </Spin>
    </PageLayout>
  )
}

export default MergersList
