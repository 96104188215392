/* eslint-disable no-template-curly-in-string */
import { object, string } from 'yup'

export const role_name = string()
  .required('Please enter a Role Name')
  .min(2, 'Role Name must be at least ${min} characters')
  .max(64, 'Role Name cannot include more than ${max} characters')
  .matches(/^[A-Za-z\-`'\s]+$/, {
    message:
      "Role Name cannot include any numbers, non-latin or special characters except -'`",
  })

export const createRoleSchema = object().shape({
  role_name,
})
