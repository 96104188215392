export const CASE_GOALS_MRD_DETECTED = {
  FIND_DOCTOR: 'find_doctor',
  FIND_TREATMENT_FACILITY: 'find_treatment_facility',
  FIND_MRD_FACILITY: 'find_mrd_facility',
}
export const CASE_GOALS_MRD_NOT_DETECTED = {
  FIND_DOCTOR: 'find_doctor',
  FIND_FACILITY: 'find_facility',
}

export const CASE_SEARCH_OPTIONS = {
  contact_form_doctor: 'Doctor Feedback Loop',
  contact_form_treatment: 'Treatment Facility Feedback Loop',
  contact_form_mrd: 'MRD Facility Feedback Loop',
  contact_form_facility: 'Facility Feedback Loop',
  contact_form: 'Facility Feedback Loop',
  doctor: 'Doctor',
  facility: 'Facility',
  mrd_facility: 'MRD Facility',
  treatment_facility: 'Treatment Facility',
}
